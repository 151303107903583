import { FC } from 'react';

import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker, TDatePickerProps } from '../datepicker';

import styles from './form.module.scss';

export interface IFormInputProps {
  name: string;
  label: string;
  placeholder?: string;
  className?: string;
  inputContainerClassName?: string;
  disabled?: boolean;
}

export const FormDatepicker: FC<
  Pick<TDatePickerProps, 'inputClassName' | 'required' | 'maxDate'> &
    IFormInputProps
> = ({
  name,
  placeholder,
  label,
  className,
  inputContainerClassName,
  inputClassName,
  maxDate,
  required,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({ field: { onChange, name, value }, fieldState: { error } }) => {
        const handleChange = (value: string | null) => {
          onChange(value);
        };

        return (
          <div className={cn(styles.container, className)}>
            <DatePicker
              label={label}
              name={name}
              disabled={disabled}
              className={inputContainerClassName}
              inputClassName={inputClassName}
              placeholder={placeholder}
              onDateChanged={handleChange}
              maxDate={maxDate}
              error={error?.message}
              value={value}
              required={required}
            />
          </div>
        );
      }}
    />
  );
};

import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IExitInterviewQuestion } from 'components/api/types/dictionary-types';
import { Button } from 'design/button';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { useFormContext } from 'react-hook-form';
import { useSubmit } from 'utils/useSubmit';

import { exitInterviewQuestionSchema } from '../../../utils/validation';

import { IFormContentProps } from './type';

import styles from './forms.module.scss';

type TExitInterviewQuestion = Omit<IExitInterviewQuestion, 'isActive'> & {
  isActive: string;
};

type TExitInterviewQuestionProps = Partial<
  TExitInterviewQuestion & { setQuestionId: (value: ID) => void }
>;

type TFormValue = Omit<TExitInterviewQuestion, 'id'>;

export const ExitInterviewQuestionFormContent: FC<IFormContentProps> = ({
  loading,
  isEdit,
  setId,
  setSuccess,
  isSuccess,
}) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      reset({ question: '' });
      setSuccess(false);
    }
  }, [isSuccess, reset, setSuccess]);

  return (
    <div
      className={cn(styles.form, {
        [styles.container]: !isEdit,
      })}
    >
      <div className={styles.form__section}>
        <div>
          <FormInput required name="question" label="Вопрос" />
        </div>
        <FormSelect
          required
          name="isActive"
          label="Статус"
          options={[
            { label: 'Активный', value: 'true' },
            { label: 'Не активный', value: 'false' },
          ]}
        />
      </div>
      {isEdit ? (
        <div className={styles.form__button}>
          <Button
            label="Сохранить"
            loading={loading}
            type="submit"
            buttonType="secondary"
          />
          <Button
            label="Отмена"
            type="button"
            buttonType="secondary"
            onClick={() => setId?.('')}
          />
        </div>
      ) : (
        <Button
          label="Добавить"
          loading={loading}
          type="submit"
          className={styles.form__add}
        />
      )}
    </div>
  );
};

export const ExitInterviewQuestionForm: FC<TExitInterviewQuestionProps> = ({
  id,
  question,
  isActive,
  setQuestionId,
}) => {
  const [isSuccess, setSuccess] = useState(false);

  const { loading, submit } = useSubmit(() => {
    setQuestionId?.('');
    setSuccess(true);
  });

  const onSubmit = (value: TFormValue) => {
    submit({
      entity: 'exitInterviewQuestion',
      data: {
        exitInterviewQuestion: {
          ...value,
          isActive: JSON.parse(value.isActive),
        },
        idInterview: id,
      },
    });
  };

  return (
    <Form
      id="exit-interview-question"
      onSubmit={onSubmit}
      initialValues={{ question, isActive }}
      validation={exitInterviewQuestionSchema}
    >
      <ExitInterviewQuestionFormContent
        isEdit={!!id}
        loading={loading}
        setId={setQuestionId}
        setSuccess={setSuccess}
        isSuccess={isSuccess}
      />
    </Form>
  );
};

import { FC } from 'react';

import { ITax } from 'components/api/types/salary';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { taxSchema } from '../../../utils/validation';

import styles from './modal.module.scss';

interface IConfigureTaxesModalProps extends IModalOverride {
  formId: string;
  initialValue?: ITax;
}

type IFormValue = Omit<ITax, 'taxNdfl' | 'taxInsurance'> & {
  taxNdfl: string;
  taxInsurance: string;
};

const FormContent = () => (
  <div>
    <FormSelect
      name="legalEntityId"
      label="Юр Лицо"
      options={async () => {
        const options = await queryClient.fetchQuery({
          queryKey: ['legalEntities'],
          queryFn: requests.getLegalEntities,
        });

        return options.data.map((option) => ({
          value: option.id,
          label: option.name,
        }));
      }}
    />
    <FormInput required name="name" label="Название" placeholder="Название" />
    <FormInput
      required
      name="taxNdfl"
      label="Налог НДФЛ"
      placeholder="Налог НДФЛ"
    />
    <FormInput
      required
      name="taxInsurance"
      label="Налог Взносы"
      placeholder="Налог Взносы"
    />
  </div>
);

export const ConfigureTaxesModal: FC<IConfigureTaxesModalProps> = ({
  formId,
  initialValue,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = ({
    id,
    name,
    taxInsurance,
    taxNdfl,
    legalEntityId,
  }: IFormValue) => {
    submit({
      entity: 'taxes',
      data: {
        tax: {
          id,
          name,
          taxInsurance: Number(taxInsurance),
          taxNdfl: Number(taxNdfl),
          legalEntityId: Number(legalEntityId),
        },
        taxId: initialValue ? id : null,
      },
    });
  };

  const modifiedInitialValue = {
    ...initialValue,
    taxNdfl: initialValue?.taxNdfl.toString(),
    taxInsurance: initialValue?.taxInsurance.toString(),
  };

  return (
    <Form
      id={formId}
      initialValues={modifiedInitialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={taxSchema}
    >
      <div className={styles.form}>
        <FormContent />
      </div>
    </Form>
  );
};

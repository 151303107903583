import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule } from '../types/enums';
import {
  ISalary,
  ISalaryHistoty,
  ISalaryPaymentDayConfig,
  ISalaryRevisionRequest,
} from '../types/salary';

export const getSalaries =
  (module: EModule) =>
  (
    dismissed: boolean,
    search?: string | null
  ): Promise<AxiosResponse<ISalary[]>> =>
    $host.get(`/${module}/employees/salary-revisions/salary-reports`, {
      params: { dismissed, search },
    });

export const getEmployeeSalary =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<ISalary>> =>
    $host.get(`/${module}/employees/salary-revisions/salary-reports`, {
      params: { employeeId },
    });

export const getSalaryHistory =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<ISalaryHistoty[]>> =>
    $host.get(`/${module}/employees/${employeeId}/salary-revisions/history`, {
      params: { employeeId },
    });

export const postSalaryRevisionRequestData =
  (module: EModule) =>
  (
    employeeId: ID,
    values: Omit<ISalaryRevisionRequest, 'id'>
  ): Promise<AxiosResponse<ISalaryRevisionRequest>> =>
    $host.post(`/${module}/employees/${employeeId}/salary-revision-requests`, {
      ...values,
    });

export const getEmployeeRevisionRequests =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<ISalaryRevisionRequest[]>> =>
    $host.get(`/${module}/employees/${employeeId}/salary-revision-requests`, {
      params: { employeeId },
    });

export const getSalaryPaymentDaysConfigs =
  (module: EModule) => (): Promise<AxiosResponse<ISalaryPaymentDayConfig[]>> =>
    $host.get(`/${module}/config/salary-payment-days-configs`);

export const postSalaryPaymentDaysConfigs =
  (module: EModule) =>
  (
    values: Omit<ISalaryPaymentDayConfig, 'id'>
  ): Promise<AxiosResponse<ISalaryPaymentDayConfig>> =>
    $host.post(`/${module}/config/salary-payment-days-configs`, { ...values });

export const patchSalaryPaymentDaysConfigs =
  (module: EModule) =>
  (
    id: ID,
    values: Omit<ISalaryPaymentDayConfig, 'legalEntityId' | 'paymentFunctionId'>
  ): Promise<AxiosResponse<ISalaryPaymentDayConfig>> =>
    $host.patch(`/${module}/config/salary-payment-days-configs/${id}`, {
      ...values,
    });

export const deleteSalaryPaymentDaysConfigs =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/salary-payment-days-configs/${id}`);

export const postSalaryForcedActivation =
  (module: EModule) => (): Promise<AxiosResponse<ISalaryPaymentDayConfig>> =>
    $host.post(
      `/${module}/config/salary-payment-days-configs/forced-activation`
    );

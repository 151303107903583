import { FC } from 'react';

import { IBankAccount } from 'components/api/types/bank-account';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { namePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { bankAccountSchema } from '../../../utils/validation';

import styles from './modal.module.scss';

interface IBankAccountsModalProps extends IModalOverride {
  initialValue?: IBankAccount;
}

export const BankAccountsModal: FC<IBankAccountsModalProps> = ({
  initialValue,
  formId,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = ({
    id,
    accountNumber,
    currencyId,
    legalEntityId,
    owner,
    bankName,
  }: IBankAccount) => {
    submit({
      entity: 'bankAccounts',
      data: {
        bankAccount: {
          id,
          owner,
          bankName,
          accountNumber: accountNumber,
          currencyId: Number(currencyId),
          legalEntityId: Number(legalEntityId),
        },
        bankAccountId: initialValue ? id : null,
      },
    });
  };

  return (
    <Form
      id={formId}
      initialValues={initialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={bankAccountSchema}
    >
      <div className={styles.form}>
        <FormInput
          required
          name="accountNumber"
          label="Номер счета"
          placeholder="Номер счета"
        />
        <FormInput
          required
          name="bankName"
          label="Название банка"
          placeholder="Тинькофф"
        />
        <FormSelect
          name="legalEntityId"
          label="Юр Лицо"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['legalEntities'],
              queryFn: requests.getLegalEntities,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
        <FormInput
          name="owner"
          label="Владелец"
          placeholder={namePlaceholder}
        />
        <FormSelect
          name="currencyId"
          label="Валюта"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['currencies'],
              queryFn: requests.getCurrencies,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
      </div>
    </Form>
  );
};

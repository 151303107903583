import { FC, useEffect, useRef, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import cn from 'clsx';
import { useDropdownToggle } from 'design/common/hooks';
import { ArrowDown } from 'design/icon';
import { Text } from 'design/text';
import { CSSTransition } from 'react-transition-group';
import { ISideMenuItem } from 'utils/types';

import { ListItem } from '../list-item';
import { Popover } from '../menu-item/popover';

import styles from './menu-list.module.scss';

type TMenuListProps = Omit<ISideMenuItem, 'permissions'> & {
  isOpenSidebar: boolean;
  setOpenTooltip: (value: boolean) => void;
  rotateIcon?: boolean;
};

const HEIGHT_OFFSET = 5;

export const MenuList: FC<TMenuListProps> = ({
  text,
  routes,
  icon: Icon,
  href,
  isOpenSidebar,
  setOpenTooltip,
  rotateIcon,
}) => {
  const [isOpenMenu, setOpenMenu] = useState(false);

  const [popoverPosition, setPopoverPosition] = useState(0);

  const { isOpen, setIsOpen, rootRef } = useDropdownToggle();

  const popoverRef = useRef(null);

  const location = useLocation();

  const handlePopover = (e: React.BaseSyntheticEvent) => {
    const positions = e.currentTarget.getBoundingClientRect();

    setPopoverPosition(positions.top);

    setIsOpen((v) => !v);
  };

  useEffect(() => {
    if (location.pathname.includes(href)) {
      setOpenMenu(true);
    }

    if (isOpenSidebar && location.pathname.includes(href)) {
      setOpenMenu(true);
    }
  }, [href, isOpenSidebar, location.pathname]);

  return (
    <div ref={rootRef}>
      <div className={styles.list}>
        {!isOpenSidebar ? (
          <div
            className={cn(styles.list__parent, {
              [styles.list__parent_active]: !!matchRoutes(
                routes,
                location.pathname
              ),
            })}
            onClick={handlePopover}
          >
            {!!Icon && <Icon />}
          </div>
        ) : (
          <>
            <div
              className={cn(styles.list__parent, {
                [styles.list__parent_active]: !!matchRoutes(
                  routes,
                  location.pathname
                ),
              })}
              onClick={() => setOpenMenu(!isOpenMenu)}
            >
              {!!Icon && (
                <Icon
                  className={cn(styles.list__parent__icon, {
                    [styles.list__parent__icon_rotate]:
                      isOpenMenu && rotateIcon,
                  })}
                />
              )}
              <Text
                className={cn(styles.list__parent__text, {
                  [styles.list__parent__tex_active]: !!matchRoutes(
                    routes,
                    location.pathname
                  ),
                })}
                size="text-16"
                as="span"
              >
                {text}
              </Text>
              <ArrowDown
                className={cn(styles.list__parent__icon, {
                  [styles.list__parent__icon_rotate]: isOpenMenu && rotateIcon,
                })}
              />
            </div>
            <div
              className={cn(styles.list__children, {
                [styles.list__children_open]: isOpenMenu,
              })}
            >
              <ul className={styles.list__children__list}>
                <div className={styles.list__children__list__item}>
                  {routes.map(({ text, path, filter }) => (
                    <ListItem
                      key={text}
                      routes={routes}
                      text={text ?? ''}
                      href={path}
                      filter={filter}
                    />
                  ))}
                </div>
              </ul>
            </div>
          </>
        )}
      </div>
      <CSSTransition
        nodeRef={popoverRef}
        in={isOpen}
        timeout={0}
        unmountOnExit
        classNames={{
          enterActive: styles.enter_active,
          enterDone: styles.enter_done,
          exit: styles.exit,
          exitActive: styles.exit_active,
        }}
      >
        <Popover top={popoverPosition - HEIGHT_OFFSET}>
          <ul className={styles.list__children__list}>
            {routes.map(({ text, path, filter }) => (
              <ListItem
                key={text}
                routes={routes}
                text={text ?? ''}
                href={path}
                filter={filter}
                className={styles.list__children__list__popover}
                onClick={() => {
                  setIsOpen(false);
                  setOpenTooltip(false);
                }}
              />
            ))}
          </ul>
        </Popover>
      </CSSTransition>
    </div>
  );
};

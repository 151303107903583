import { FC } from 'react';

import cn from 'clsx';
import { Text } from 'design/text';
import { getInitialsEmployee } from 'utils';

import { infoTableColumns } from '../constants';
import { IScheduleTableProps } from '../types';

import styles from './info-table.module.scss';

type TInfoTableProps = Omit<IScheduleTableProps, 'year'>;

export const InfoTable: FC<TInfoTableProps> = ({ employees }) => (
  <div className={styles.container}>
    <table
      className={cn(styles.container__table, {
        [styles.container__table_border]: !employees.length,
      })}
    >
      <thead className={styles.container__table__header}>
        <tr className={styles.container__table__header__cell}>
          {infoTableColumns.map((column) => (
            <th
              className={cn(styles.container__table__header__cell__column, {
                [styles.container__table__header__cell_border]:
                  !employees.length,
              })}
              key={column.dataIndex}
            >
              <div
                className={styles.container__table__header__cell__column__title}
              >
                <Text size="text-12" weight={500} as="span">
                  {column.title}
                </Text>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.container__table__body}>
        {employees.map((employee) => (
          <tr className={styles.container__table__body__row} key={employee.id}>
            {infoTableColumns.map((column) => (
              <td
                className={styles.container__table__body__row__column}
                key={column.dataIndex}
              >
                <div
                  className={styles.container__table__body__row__column__text}
                >
                  <Text size="text-14" weight={400} as="span">
                    {column.dataIndex === 'firstName'
                      ? `${getInitialsEmployee(
                          employee.lastName,
                          employee.firstName,
                          employee?.middleName
                        )}`
                      : employee[column.dataIndex as 'office']?.name}
                  </Text>
                </div>
              </td>
            ))}
          </tr>
        ))}
        {!employees.length && (
          <div className={styles.container__table__body__no_data}>
            <Text size="text-14" weight={500} as="span">
              Нет данных
            </Text>
          </div>
        )}
      </tbody>
    </table>
  </div>
);

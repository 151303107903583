import { ID } from 'components/api/types/common';
import {
  ISalaryTransaction,
  ITransactionPaid,
} from 'components/api/types/transaction';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  paymentSalary: async (data: {
    removedId: ID | null;
    paymentSalaryId: ID | null;
    paymentSalary: ISalaryTransaction;
    ids: ITransactionPaid;
    isForcedActivation: boolean;
  }) => {
    data.isForcedActivation && (await requests.postSalaryForcedActivation());

    data.ids && (await requests.patchSalaryTransactions(data.ids));

    data.removedId && (await requests.deleteSalaryTransaction(data.removedId));

    data.paymentSalaryId &&
      (await requests.patchSalaryTransactionById(
        data.paymentSalaryId,
        data.paymentSalary
      ));

    data.paymentSalary &&
      !data.paymentSalaryId &&
      (await requests.postSalaryTransaction(data.paymentSalary));

    await queryClient.invalidateQueries({
      queryKey: ['paymentSalary'],
    });
  },
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { ILegalEntity } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getLegalEntities =
  (module: EModule) => (): Promise<AxiosResponse<ILegalEntity[]>> =>
    $host.get(`/${module}/dictionary/legal-entities`);

export const patchLegalEntityData =
  (module: EModule) =>
  (
    id: ID,
    values: Partial<ILegalEntity>
  ): Promise<AxiosResponse<ILegalEntity>> =>
    $host.patch(`/${module}/dictionary/legal-entities/${id}`, { ...values });

export const postLegalEntityData =
  (module: EModule) =>
  (values: Omit<ILegalEntity, 'id'>): Promise<AxiosResponse<ILegalEntity>> =>
    $host.post(`/${module}/dictionary/legal-entities`, { ...values });

export const deleteLegalEntityData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<ILegalEntity>> =>
    $host.delete(`/${module}/dictionary/legal-entities/${id}`);

import { useLayoutEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { Text } from 'design/text';

import { ILoaderData } from './loaders';
import { Tree } from './tree';

import styles from './expenses-categories.module.scss';

export const ExpensesCategoriesPage = () => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardHeight, setCardHeight] = useState(0);
  const { expensesCategories } = useLoaderData() as ILoaderData;

  useLayoutEffect(() => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      setCardHeight(rect.height);
    }
  }, [cardRef]);

  return (
    <div className={styles.container}>
      <div className={styles.container__title}>
        <Text size="text-20" weight={500} as="span">
          Категории трат
        </Text>
      </div>
      <div
        className={styles.container__card}
        style={{ height: `${cardHeight}px` }}
        ref={cardRef}
      >
        <div className={styles.container__card__tree}>
          <Tree data={expensesCategories} />
        </div>
      </div>
    </div>
  );
};

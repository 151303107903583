import { FC } from 'react';

import { IEmployee } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { datePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../../api';
import { employmentInfoSchema } from '../../../../utils/validation';

import styles from './modals.module.scss';

type TEmploymentInformationData = Pick<
  IEmployee,
  | 'legalEntityId'
  | 'employmentDate'
  | 'endOfProbationPeriod'
  | 'positionId'
  | 'departmentId'
  | 'assignedHrId'
>;

export type TEmploymentInformationModalProps = TEmploymentInformationData & {
  formId: string;
} & IModalOverride;

export const EmploymentInformationModal: FC<
  TEmploymentInformationModalProps
> = ({
  formId,
  legalEntityId,
  departmentId,
  positionId,
  assignedHrId,
  employmentDate,
  endOfProbationPeriod,
  modalClose,
  setLoading,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (values: TEmploymentInformationData) => {
    submit({
      entity: 'personal',
      data: values,
    });
  };

  return (
    <Form
      id={formId}
      initialValues={{
        legalEntityId,
        departmentId,
        positionId,
        assignedHrId,
        employmentDate,
        endOfProbationPeriod,
      }}
      onSubmit={onSubmit}
      validation={employmentInfoSchema}
    >
      <div className={styles.content}>
        <FormSelect
          name="legalEntityId"
          label="Куда устроен (ИП)"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['legalEntities'],
              queryFn: requests.getLegalEntities,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
        <FormSelect
          name="departmentId"
          label="Отдел"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['department'],
              queryFn: requests.getDepartments,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.nameRu,
            }));
          }}
        />
        <FormSelect
          name="assignedHrId"
          label="HR"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['employees'],
              queryFn: () => requests.getCurrentEmployees(false),
            });

            return options.data.map((option) => ({
              value: option.id,
              label: `${option.lastName} ${option.firstName}`,
            }));
          }}
        />
        <FormSelect
          name="positionId"
          label="Должность"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['positions'],
              queryFn: requests.getPositions,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.nameRu,
            }));
          }}
        />
        <FormDatepicker name="employmentDate" label="Начало работы" />
        <FormDatepicker
          name="endOfProbationPeriod"
          label="Конец испытательного срока"
          placeholder={datePlaceholder}
        />
      </div>
    </Form>
  );
};

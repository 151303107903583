import { LoaderFunctionArgs } from 'react-router-dom';

import { EPaymentStatus } from 'components/api/types/enums';
import { AnyType } from 'utils/types';

import { requests } from '../../api';

const TRANSACTIONS_PARAMS = {
  planned: EPaymentStatus.Создан,
  paid: EPaymentStatus.Оплачен,
};

export const loaders = ({ request }: LoaderFunctionArgs<AnyType>) => [
  {
    name: 'salaryTransactions',
    fn: () => {
      const status = new URL(request.url).searchParams.get('filter')
        ? TRANSACTIONS_PARAMS[
            new URL(request.url).searchParams.get(
              'filter'
            ) as keyof typeof TRANSACTIONS_PARAMS
          ]
        : EPaymentStatus.Создан;

      return requests.getSalaryTransactions(status);
    },
  },
];

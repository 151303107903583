import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { ICurrency } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getCurrencies =
  (module: EModule) => (): Promise<AxiosResponse<ICurrency[]>> =>
    $host.get(`/${module}/dictionary/currencies`);

export const patchCurrencyData =
  (module: EModule) =>
  (id: ID, values: Partial<ICurrency>): Promise<AxiosResponse<ICurrency>> =>
    $host.patch(`/${module}/dictionary/currencies/${id}`, { ...values });

export const postCurrencyData =
  (module: EModule) =>
  (values: Omit<ICurrency, 'id'>): Promise<AxiosResponse<ICurrency>> =>
    $host.post(`/${module}/dictionary/currencies`, { ...values });

export const deleteCurrencyData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<ICurrency>> =>
    $host.delete(`/${module}/dictionary/currencies/${id}`);

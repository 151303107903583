import { useLoaderData } from 'react-router-dom';

import { IBankCard } from 'components/api/types/bank-card';
import { ID } from 'components/api/types/common';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { numberSorting, textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { ILoaderData } from './loaders';
import { BankCardsModal } from './modal';

import styles from './bank-cards.module.scss';

const openAddBankCardsModal = () => {
  modal.open({
    id: 'add-bank-card-modal',
    title: 'Добавление карты',
    formId: 'add-bank-card',
    content: <BankCardsModal />,
  });
};

const openEditBankCardsModal = (initialValue: IBankCard) => {
  modal.open({
    id: 'edit-bank-card-modal',
    title: 'Редактирование карты',
    formId: 'edit-bank-card',
    content: <BankCardsModal initialValue={initialValue} />,
  });
};

export const BankCardsPage = () => {
  const { submit } = useSubmit();

  const { bankCards } = useLoaderData() as ILoaderData;

  const handleDelete = (id: ID) => {
    submit({
      entity: 'bankCards',
      data: {
        removedId: id,
      },
    });
  };

  const columns: TTableColumn<IBankCard>[] = [
    {
      title: 'Название карты',
      dataIndex: ['name'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Номер карты',
      dataIndex: ['cardNumber'],
      sortable: true,
      sorting: numberSorting,
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccount'],
      sortable: true,
      sorting: textSorting,
      render: ({ bankAccount }) => (
        <Text size="text-14" as="span">
          {bankAccount ? bankAccount.bankName : ''}
        </Text>
      ),
    },
    {
      title: 'Валюта',
      dataIndex: ['currency'],
      render: ({ currency }) => (
        <Text size="text-14" as="span">
          {currency ? currency.name : ''}
        </Text>
      ),
    },
    {
      title: 'Офис',
      dataIndex: ['office'],
      render: ({ office }) => (
        <Text size="text-14" as="span">
          {office ? office.name : ''}
        </Text>
      ),
    },
    {
      title: 'Действие',
      actions: {
        onDelete: handleDelete,
        onEdit: openEditBankCardsModal,
      },
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container__title}>
        <Text size="text-20" weight={500}>
          Банковские карты
        </Text>
      </div>
      <div className={styles.container__info}>
        <div className={styles.container__info__button}>
          <Button
            label="Добавить"
            onClick={openAddBankCardsModal}
            prefixIcon={<AddBoxLine />}
          />
        </div>
        <ContentContainer>
          <Table columns={columns} data={bankCards} key="id" />
        </ContentContainer>
      </div>
    </div>
  );
};

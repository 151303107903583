import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import {
  IEducation,
  IEmployee,
  TWorkHistoryFormData,
} from 'components/api/types/employee';
import { Form } from 'design/form';
import { IModalOverride } from 'design/modal';
import { useSubmit } from 'utils/useSubmit';

import { workHistorySchema } from '../../../../utils/validation';
import {
  EmploymentHistoryFormContent,
  INITIAL_VALUE_WORK_HISTORY,
} from '../../../new-employee/forms/employment-history';

type TWorkHistoryInformationData = Pick<IEmployee, 'workHistory'>;

export type TWorkHistoryInformationModalProps = TWorkHistoryInformationData &
  Pick<IEducation, 'employeeId'> & {
    formId: string;
  } & IModalOverride;

export const WorkHistoryInformationModal: FC<
  TWorkHistoryInformationModalProps
> = ({
  formId,
  employeeId,
  workHistory,
  setConfirm,
  modalClose,
  setLoading,
}) => {
  const [removedIds, setRemovedIds] = useState<ID[]>([]);

  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (values: TWorkHistoryInformationData) => {
    const formData = values.workHistory?.map((history) => ({
      ...history,
      employeeId: employeeId,
      documents: history.documents?.map((el) => el.url),
    })) as TWorkHistoryFormData[];

    submit({
      entity: 'workHistory',
      data: {
        employeeId,
        workHistory: formData,
        removedIds,
      },
    });
  };

  const handleRemove = (id: ID) => {
    setRemovedIds((prewIds: ID[]) => [...prewIds, id]);
  };

  return (
    <Form
      id={formId}
      initialValues={{
        workHistory: workHistory.length
          ? workHistory
          : [INITIAL_VALUE_WORK_HISTORY],
      }}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={workHistorySchema}
    >
      <EmploymentHistoryFormContent handleRemove={handleRemove} />
    </Form>
  );
};

import { ID } from 'components/api/types/common';
import { ISalaryPaymentDayConfig } from 'components/api/types/salary';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

interface ISalaryPaymentDayData {
  salaryPaymentDay: ISalaryPaymentDayConfig;
  salaryPaymentDayId: ID | null;
  removedId: ID | null;
}

export const actions = {
  salaryPaymentDays: async (data: ISalaryPaymentDayData) => {
    data.removedId &&
      (await requests.deleteSalaryPaymentDaysConfigs(data.removedId));

    data.salaryPaymentDayId &&
      (await requests.patchSalaryPaymentDaysConfigs(
        data.salaryPaymentDayId,
        data.salaryPaymentDay
      ));

    data.salaryPaymentDay &&
      !data.salaryPaymentDayId &&
      (await requests.postSalaryPaymentDaysConfigs(data.salaryPaymentDay));

    await queryClient.invalidateQueries({
      queryKey: ['salaryPaymentDays'],
    });
  },
};

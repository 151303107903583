import { FC, useMemo } from 'react';

import { ID } from 'components/api/types/common';
import { IMissingDay } from 'components/api/types/employee';

import { getWeeks } from '../../utils';

import { Week } from './week';

import styles from '../../calendar-table.module.scss';

interface ICellProps {
  column: number;
  year: number;
  missingDays: IMissingDay[];
  employeeId: ID;
}

export const Cell: FC<ICellProps> = ({
  missingDays,
  column,
  year,
  employeeId,
}) => {
  const weeks = useMemo(() => getWeeks(column, year), [column, year]);

  return (
    <div className={styles.table__body__row__cell__container}>
      {weeks.map((week, index) => (
        <div
          key={index}
          className={styles.table__body__row__cell__container__week}
        >
          <Week
            week={week}
            missingDays={missingDays}
            column={column}
            year={year}
            employeeId={employeeId}
          />
        </div>
      ))}
    </div>
  );
};

import {
  FC,
  ChangeEvent,
  FocusEvent,
  forwardRef,
  MutableRefObject,
  useState,
  useEffect,
} from 'react';

import cn from 'clsx';

import { Text } from '../text';

import styles from './input.module.scss';

export type TInputProps = {
  name?: string;
  label?: string;
  placeholder?: string;
  size?: 'small' | 'normal'; // ToDo check if this prop is needed
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  className?: string;
  inputClassName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  error?: string;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  isActive?: boolean;
};

export const Input: FC<TInputProps> = forwardRef(
  (
    {
      label,
      placeholder,
      className,
      isActive,
      inputClassName,
      prefixIcon: PrefixIcon,
      suffixIcon: SuffixIcon,
      size = 'normal',
      name,
      onChange,
      onFocus,
      onBlur,
      error,
      required,
      value,
      disabled,
      ...rest
    },
    ref
  ) => {
    const [valueInput, setValueInput] = useState<string>(value ?? '');

    useEffect(() => {
      if (typeof value === 'string') {
        setValueInput(value);
      }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValueInput(e.target.value);

      onChange?.(e);
    };

    const classNames = cn(inputClassName, styles.input, {
      [styles.withSuffixIcon]: !!SuffixIcon,
      [styles.withPrefixIcon]: !!PrefixIcon,
      [styles.small]: size === 'small',
      [styles.error]: !!error,
      [styles.active]: isActive,
      [styles.withValue]: !!value,
      [styles.notDisabled]: !disabled,
    });

    return (
      <div className={cn(className, styles.container)}>
        <input
          ref={ref as MutableRefObject<HTMLInputElement>}
          id={name ?? ''}
          className={classNames}
          type="text"
          placeholder={placeholder ?? label}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          name={name}
          value={valueInput}
          disabled={disabled}
          {...rest}
        />
        {!!label && (
          <label
            htmlFor={name}
            className={cn(styles.label, {
              [styles.error]: !!error,
              [styles.right]: !!PrefixIcon,
            })}
          >
            <Text size="text-14" weight={500} className={styles.placeholder}>
              {label}
            </Text>
            {required && (
              <Text size="text-14" weight={500} className={styles.required}>
                *
              </Text>
            )}
          </label>
        )}
        {!!PrefixIcon && (
          <div
            data-testid="prefixIcon"
            className={cn(styles.icon, styles.prefixIcon)}
          >
            {PrefixIcon}
          </div>
        )}
        {!!SuffixIcon && (
          <div
            data-testid="suffixIcon"
            className={cn(styles.icon, styles.suffixIcon)}
          >
            {SuffixIcon}
          </div>
        )}
        {!!error && (
          <Text as="span" size="text-12" className={styles.errorMessage}>
            {error}
          </Text>
        )}
      </div>
    );
  }
);

import { EPaymentStatus } from 'components/api/types/enums';
import { requiredField } from 'utils/validation-messages';
import {
  AnyObject,
  TestContext,
  array,
  mixed,
  number,
  object,
  string,
} from 'yup';

const dependentFieldValidation = (
  path: string,
  parentField: string,
  errorMessage: string
) =>
  function (
    this: TestContext<AnyObject>,
    value: string | null | undefined,
    { parent }: TestContext<AnyObject>
  ) {
    const otherFieldValue = parent[parentField];

    if (value && otherFieldValue) {
      return this.createError({
        path: path,
        message: errorMessage,
      });
    }

    return true;
  };

export const fileSchema = object().shape({
  id: string().nullable(),
  key: string().required(requiredField),
  url: string().required(requiredField),
});

export const taxSchema = object().shape({
  name: string().required(requiredField),
  taxNdfl: string().required(requiredField),
  taxInsurance: string().required(requiredField),
  legalEntityId: string(),
});

export const bankAccountSchema = object().shape({
  accountNumber: string().required(requiredField),
  bankName: string().required(requiredField),
  owner: string()
    .nullable()
    .test(
      'exclusive-fields',
      dependentFieldValidation(
        'owner',
        'legalEntityId',
        'Поле `Юр Лицо` и `Владелец` должны быть взаимоисключающими'
      )
    ),
  legalEntityId: string()
    .test(
      'exclusive-fields',
      dependentFieldValidation(
        'legalEntityId',
        'owner',
        'Поле `Юр Лицо` и `Владелец` должны быть взаимоисключающими'
      )
    )
    .nullable(),
  currencyId: string().required(requiredField),
});

export const configureCurrencySchema = object().shape({
  name: string().required(requiredField),
  sign: string().required(requiredField),
});

export const legalEntitySchema = object().shape({
  name: string().required(requiredField),
  fullName: string().required(requiredField),
  country: string().required(requiredField),
});

export const revisionSchema = object().shape({
  startDate: string().required(requiredField),
  comment: string(),
  employeeSalaryRevisionParts: array().of(
    object().shape({
      amount: string().required(' '),
      partTaxId: string(),
      bankAccountId: string().test('requiredBankAccount', (value, ctx) => {
        if (ctx.parent.amount > 0) {
          if (!value) {
            return ctx.createError({ message: 'Поле обязательно' });
          }

          return true;
        }

        return true;
      }),
    })
  ),
});

export const transactionSchema = object().shape({
  currencyId: number().required(requiredField),
  employeeId: number().required(requiredField),
  bankAccountId: number().required(requiredField),
  amount: string().required(requiredField),
  status: string().required(requiredField),
  paymentDate: string().required(requiredField),
  periodEndDate: string().required(requiredField),
  periodStartDate: string().required(requiredField),
  paymentType: string().required(requiredField),
});

export const salaryPaymentDaysSchema = object().shape({
  paymentFunctionId: number().required(requiredField),
  paymentDate: string().required(requiredField),
  periodStartDate: string().required(requiredField),
  periodEndDate: string().required(requiredField),
  legalEntityId: number().required(requiredField),
});

export const expensesCategorySchema = object().shape({
  name: string().required(requiredField),
});

export const bankCardSchema = object().shape({
  name: string().required(requiredField),
  cardNumber: string().required(requiredField),
  bankAccountId: string().required(requiredField),
  currencyId: string().required(requiredField),
  officeId: string().required(requiredField),
});

export const expensesTransactionsSchema = object().shape({
  expensesCategoryId: string().required(requiredField),
  amount: string().required(requiredField),
  bankAccountId: string().required(requiredField),
  paymentDate: string().required(requiredField),
  comment: string().nullable(),
  isApproved: mixed().oneOf(['true', 'false']),
  documents: array().of(fileSchema),
});

export const selectedExpensesTransactionsSchema = object().shape({
  status: mixed()
    .oneOf(Array.from(Object.values(EPaymentStatus)))
    .required(requiredField),
  paidAt: string().test('paidAt', requiredField, (value, ctx) => {
    if (ctx.parent.status === EPaymentStatus.Оплачен && !value) {
      return false;
    }

    return true;
  }),
});

export const moneyBalanceSchema = object().shape({
  name: string().required(requiredField),
  amount: string().required(requiredField),
  bankAccountId: string().required(requiredField),
  isCash: mixed().oneOf(['true', 'false']),
});

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { IUserInfo } from '../types/common';

export const getBansheeLogin = (): Promise<AxiosResponse<string>> =>
  $host.get('/hr/banshee/login');

export const getBansheeUserInfo = (): Promise<AxiosResponse<IUserInfo>> =>
  $host.get('/hr/banshee/userinfo');

export const getBansheeCallback = (
  code: string,
  state: string | null,
  redirectUri: string
): Promise<AxiosResponse<string>> =>
  $host.get(`/hr/banshee/callback?redirectUri=${redirectUri}`, {
    params: { code, state },
  });

export const postBansheeRefresh = (): Promise<AxiosResponse<string>> =>
  $host.post('/hr/banshee/refresh');

export const postBansheeLogout = (): Promise<AxiosResponse<string>> =>
  $host.post('/hr/banshee/logout');

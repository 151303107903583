import { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react';

import styles from './content-container.module.scss';

interface IContentContainer {
  children: ReactNode;
}

export const ContentContainer: FC<IContentContainer> = ({ children }) => {
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (contentRef.current) {
      const rect = contentRef.current.getBoundingClientRect();
      setContentHeight(rect.height);
    }
  }, [contentRef]);

  return (
    <div
      style={{ height: `${contentHeight}px` }}
      ref={contentRef}
      className={styles.content}
    >
      {children}
    </div>
  );
};

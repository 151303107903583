import { FC } from 'react';

import { IExchangeRate } from 'components/api/types/dictionary-types';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { datePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';

import styles from './modal.module.scss';

interface IExchangeRateModalProps extends IModalOverride {
  initialValue?: IExchangeRate;
}

type IFormValue = Omit<IExchangeRate, 'rate'> & {
  rate: string;
};

export const ExchangeRateModal: FC<IExchangeRateModalProps> = ({
  formId,
  initialValue,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (data: IFormValue) => {
    submit({
      entity: 'exchangeRates',
      data: {
        exchangeRate: {
          ...data,
          rate: Number(data.rate),
          fromCurrencyId: Number(data.fromCurrencyId),
          toCurrencyId: Number(data.toCurrencyId),
        },
        exchangeRateId: initialValue ? data.id : null,
      },
    });
  };

  const modifiedInitialValue = {
    ...initialValue,
    rate: initialValue?.rate.toString(),
  };

  return (
    <Form
      id={formId}
      initialValues={modifiedInitialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
    >
      <div className={styles.form}>
        <div className={styles.horisontalContainer}>
          <FormDatepicker
            required
            name="startDate"
            label="Начало периода"
            placeholder={datePlaceholder}
          />
          <FormDatepicker
            required
            name="endDate"
            label="Конец периода"
            placeholder={datePlaceholder}
          />
        </div>
        <div className={styles.horisontalContainer}>
          <FormInput required name="rate" label="Курс" placeholder="Курс" />
          <FormSelect
            required
            name="fromCurrencyId"
            label="Исходная валюта"
            options={async () => {
              const options = await queryClient.fetchQuery({
                queryKey: ['currencies'],
                queryFn: requests.getCurrencies,
              });

              return options.data.map((option) => ({
                value: option.id,
                label: option.name,
              }));
            }}
          />
          <FormSelect
            required
            name="toCurrencyId"
            label="Целевая валюта"
            options={async () => {
              const options = await queryClient.fetchQuery({
                queryKey: ['currencies'],
                queryFn: requests.getCurrencies,
              });

              return options.data.map((option) => ({
                value: option.id,
                label: option.name,
              }));
            }}
          />
        </div>
      </div>
    </Form>
  );
};

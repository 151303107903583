import { FC } from 'react';

import { IMissingDayInfo } from 'components/api/types/employee';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import { getWordDayForm } from '../../../utils';

import styles from '../employee.module.scss';

type TVacationCard = Pick<
  IMissingDayInfo,
  | 'lastVacationDate'
  | 'nextAvailableVacationDate'
  | 'nextScheduledVacationDate'
  | 'vacationDaysLeft'
>;

export const VacationCard: FC<TVacationCard> = ({
  lastVacationDate,
  nextAvailableVacationDate,
  nextScheduledVacationDate,
  vacationDaysLeft,
}) => (
  <div className={styles.cardContent}>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Накоплено дней
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {`${vacationDaysLeft ?? 0} ${getWordDayForm(vacationDaysLeft ?? 0)}`}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Дата выхода с последнего отпуска
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {lastVacationDate ? formatDate(lastVacationDate) : 'Не указано'}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Ближайшая возможная дата отпуска
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {nextAvailableVacationDate
          ? formatDate(nextAvailableVacationDate)
          : 'Не указано'}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Запланированный отпуск
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {nextScheduledVacationDate
          ? formatDate(nextScheduledVacationDate)
          : 'Не запланирован'}
      </Text>
    </div>
  </div>
);

import { useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'clsx';

import { Text } from '../text';

import { Column, ESorting, TTableColumn } from './column';
import { Row } from './row';
import { getNestedProperty } from './utils';

import styles from './table.module.scss';

export interface ITableProps<T> {
  key: string;
  className?: string;
  columns: TTableColumn<T>[];
  data: T[];
  onRowClick?: (data: T) => void;
}

export interface IGroupHoverNumber {
  groupNumber?: number;
}

const sortOrderFlow = {
  [ESorting.NONE]: ESorting.ASC,
  [ESorting.ASC]: ESorting.DESC,
  [ESorting.DESC]: ESorting.NONE,
};

export const Table = <T extends Record<string, any>>({
  columns,
  data,
  className,
  onRowClick,
}: ITableProps<T>) => {
  const [tableData, setTableData] = useState<T[]>([...data]);

  const [groupHoverNumber, setGroupHoverNumber] = useState<IGroupHoverNumber>();
  const [isRowsGroupHover, setIsRowsGroupHover] = useState(false);

  const [currentSortableColumn, setCurrentSortableColumn] = useState({
    column: '',
    direction: ESorting.NONE,
  });

  useEffect(() => {
    setTableData([...data]);
  }, [data]);

  const hoverHandler = (groupHoverNumber?: number, isRowsHover = false) => {
    if (isRowsHover) {
      setGroupHoverNumber({ groupNumber: groupHoverNumber });
      setIsRowsGroupHover(isRowsHover);
    } else {
      setGroupHoverNumber({ groupNumber: groupHoverNumber });
      setIsRowsGroupHover(false);
    }
  };

  const handleSort = useCallback(
    (column: TTableColumn<T>, direction: ESorting) => {
      const nextSortingDirection = sortOrderFlow[direction];

      if (column.dataIndex && column.dataIndex.length) {
        const { dataIndex } = column;

        setCurrentSortableColumn(() => ({
          column: dataIndex.join('.'),
          direction: nextSortingDirection,
        }));

        if (column.sorting) {
          const { sorting } = column;

          setTableData((td) =>
            td.sort(
              (valueA, valueB) =>
                sorting(
                  getNestedProperty(valueA, dataIndex),
                  getNestedProperty(valueB, dataIndex)
                ) * (direction === ESorting.ASC ? 1 : -1)
            )
          );
        } else {
          setTableData((td) =>
            td.sort(
              (valueA, valueB) =>
                (getNestedProperty(valueB, dataIndex) <
                getNestedProperty(valueA, dataIndex)
                  ? -1
                  : 1) * (direction === ESorting.ASC ? 1 : -1)
            )
          );
        }
      }

      if (nextSortingDirection === ESorting.NONE) {
        return setTableData([...data]);
      }
    },
    [data]
  );

  const sorting = useMemo(
    () =>
      columns.reduce(
        (sorting, column) =>
          column.sortable
            ? {
                ...sorting,
                [column.dataIndex.join('.')]: {
                  direction:
                    currentSortableColumn.column === column.dataIndex.join('.')
                      ? currentSortableColumn.direction
                      : ESorting.NONE,
                  handleSort: handleSort,
                },
              }
            : sorting,
        {} as Record<
          keyof T,
          {
            direction: ESorting;
            handleSort: (
              dataIndex: TTableColumn<T>,
              direction: ESorting
            ) => void;
          }
        >
      ),
    [columns, handleSort, currentSortableColumn]
  );

  return (
    <div className={styles.container}>
      <table className={cn(className, styles.table)}>
        <thead className={styles.header}>
          <tr>
            {columns.map((column, index) => (
              <Column
                {...column}
                key={column.dataIndex ? column.dataIndex.join('.') : index}
                sortOrder={
                  column.dataIndex &&
                  sorting[column.dataIndex.join('.')]?.direction
                }
                sortHandler={
                  column.dataIndex &&
                  sorting[column.dataIndex.join('.')]?.handleSort
                }
              />
            ))}
          </tr>
        </thead>
        <tbody className={styles.body}>
          {tableData.map((rowData: T) => (
            <Row
              onRowClick={onRowClick}
              key={rowData.id}
              data={rowData}
              columns={columns}
              groupHoverNumber={groupHoverNumber}
              setGroupHoverNumber={setGroupHoverNumber}
              isRowsGroupHover={isRowsGroupHover}
              hoverHandler={hoverHandler}
            />
          ))}
        </tbody>
      </table>
      {!tableData.length && (
        <div className={styles.no_data}>
          <Text size="text-14" as="span" weight={500}>
            Нет данных
          </Text>
        </div>
      )}
    </div>
  );
};

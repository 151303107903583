import { useLoaderData } from 'react-router-dom';

import { IEmployee } from 'components/api/types/employee';
import { IHRSalary, IHRSalaryHistoty } from 'components/api/types/salary';
import { Card } from 'design/card';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { Text } from 'design/text';
import { getInitialsEmployeeFromFullName } from 'utils';

import { Paths } from '../../../../../utils/types';

import styles from '../salary.module.scss';

export const General = () => {
  const { salary, salaryHistory } = useLoaderData() as {
    employee: IEmployee;
    salary: IHRSalary[];
    salaryHistory: IHRSalaryHistoty[];
  };

  const generateColumns = <
    T extends IHRSalary | IHRSalaryHistoty
  >(): TTableColumn<T>[] => [
    {
      title: 'Фио',
      dataIndex: ['fullName'] as Paths<T>,
      render: ({ fullName }) => (
        <Text size="text-14" weight={400}>
          {getInitialsEmployeeFromFullName(fullName)}
        </Text>
      ),
      minWidth: '150px',
    },
    {
      title: 'Должность',
      minWidth: '210px',
      dataIndex: ['positionNameRu'] as Paths<T>,
    },
    {
      title: 'Юр Лицо',
      dataIndex: ['legalEntityName'] as Paths<T>,
    },
    {
      title: 'ЗП до Вычета',
      dataIndex: ['salaryWithoutNdfl'] as Paths<T>,
    },
    {
      title: 'ЗП после Вычета',
      dataIndex: ['salaryWithNdfl'] as Paths<T>,
    },
    {
      title: 'Доп Нал',
      dataIndex: ['addCash'] as Paths<T>,
    },
    {
      title: 'Тотал до Вычета',
      dataIndex: ['totalSalary'] as Paths<T>,
    },
    {
      title: 'Тотал после Вычета',
      dataIndex: ['salaryOnHands'] as Paths<T>,
    },
  ];

  return (
    <div className={styles.generalTab}>
      <Card
        bodyClassName={styles.cardContent}
        title={
          <div className={styles.cardHeader}>
            <Text size="text-18" weight={500}>
              Текущая заработная плата
            </Text>
          </div>
        }
      >
        <Table columns={generateColumns<IHRSalary>()} data={salary} key="id" />
      </Card>
      <Card
        bodyClassName={styles.cardContent}
        title={
          <Text size="text-18" weight={500}>
            История пересмотров заработной платы
          </Text>
        }
      >
        <div className={styles.tableContent}>
          <Table
            columns={generateColumns<IHRSalaryHistoty>()}
            data={salaryHistory}
            key="id"
          />
        </div>
      </Card>
    </div>
  );
};

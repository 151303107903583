import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears,
} from 'date-fns';
import { differenceInMonths, differenceInDays } from 'date-fns';

export const requestSimulation = <T>(fn: () => T, timeout = 1000): Promise<T> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(fn());
    }, timeout);
  });

export const calculateDifferenceDate = (
  startDate: string,
  endDate: string,
  formatDate: 'days' | 'months' | 'years' = 'years'
) => {
  if (formatDate === 'days') {
    return differenceInCalendarDays(new Date(endDate), new Date(startDate));
  }

  if (formatDate === 'months') {
    return differenceInCalendarMonths(new Date(endDate), new Date(startDate));
  }

  return differenceInCalendarYears(new Date(endDate), new Date(startDate));
};

export const getWordDayForm = (quantity = 0) => {
  const string = quantity.toString();
  const lastChar = string.charAt(string.length - 1);

  switch (true) {
    case lastChar === '1' && !(quantity === 11):
      return 'день';
    case lastChar === '2' && !(quantity === 12):
    case lastChar === '3' && !(quantity === 13):
    case lastChar === '4' && !(quantity === 14):
      return 'дня';

    default:
      return 'дней';
  }
};

// TODO: remove this from here
//duplicated from file "design/table/utils"
export const dateSorting = (valueA: string, valueB: string) =>
  new Date(valueA).getTime() - new Date(valueB).getTime();

// TODO: remove this from here
//duplicated from file "design/table/utils"
export const textSorting = (valueA: string, valueB: string) =>
  valueB < valueA ? -1 : 1;

export const formatDateDistance = (
  date: Date,
  baseDate = new Date()
): string => {
  const distanceInMonths = differenceInMonths(baseDate, date);
  const distanceInDays = differenceInDays(baseDate, date);

  if (distanceInMonths < 1) {
    if (distanceInDays < 1) {
      return 'Сегодня';
    } else if (distanceInDays === 1) {
      return 'Вчера';
    } else {
      return `${distanceInDays} д.`;
    }
  } else if (distanceInMonths < 12) {
    return `${distanceInMonths} мес.`;
  } else {
    const years = Math.floor(distanceInMonths / 12);
    const remainingMonths = distanceInMonths % 12;

    if (remainingMonths === 0) {
      return `${years} г.`;
    } else {
      const yearsStr = years.toString();
      const lastDigit = parseInt(yearsStr[yearsStr.length - 1], 10);

      if (lastDigit >= 1 && lastDigit <= 4) {
        return `${years} г. ${remainingMonths} мес.`;
      } else {
        return `${years} лет ${remainingMonths} мес.`;
      }
    }
  }
};

import { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';

import { Card } from 'design/card';
import { Text } from 'design/text';

import { DepartmentForm } from './forms/department';
import { DismissalReasonForm } from './forms/dismissal-reason';
import { EducationDegreeForm } from './forms/education-degree';
import { ExitInterviewQuestionForm } from './forms/exit-interview-question';
import { OfficeForm } from './forms/office';
import { PositionForm } from './forms/position';
import { DepartmentList } from './lists/department-list';
import { DismissalReasonList } from './lists/dismissal-reason-list';
import { EducationDegreeList } from './lists/education-degree-list';
import { ExitInterviewQuestionList } from './lists/exit-interview-question-list';
import { OfficeList } from './lists/office-list';
import { PositionList } from './lists/position-list';
import { IDictionaryLoaderData } from './loaders';

import styles from './dictionary.module.scss';

export const DictionaryPage = () => {
  const loaderData = useLoaderData();

  const {
    educationDegrees,
    exitInterviewQuestions,
    offices,
    departments,
    dismissalReasons,
    positions,
  } = loaderData as IDictionaryLoaderData;

  const PARENT_DEPARTMENT_OPTIONS = useMemo(
    () =>
      departments.map((department) => ({
        label: department.nameRu,
        value: department.id,
      })),
    [departments]
  );

  return (
    <div className={styles.dictionary}>
      <Text size="text-20" weight={500}>
        Словарь
      </Text>
      <Card
        title={
          <Text size="text-18" weight={500}>
            Список городов
          </Text>
        }
      >
        <OfficeForm />
        <OfficeList offices={offices} />
      </Card>
      <Card
        title={
          <Text size="text-18" weight={500}>
            Степень образования
          </Text>
        }
      >
        <EducationDegreeForm />
        <EducationDegreeList educationDegrees={educationDegrees} />
      </Card>
      <Card
        title={
          <Text size="text-18" weight={500}>
            Должность
          </Text>
        }
      >
        <PositionForm />
        <PositionList positions={positions} />
      </Card>
      <Card
        title={
          <Text size="text-18" weight={500}>
            Причины увольнения
          </Text>
        }
      >
        <DismissalReasonForm />
        <DismissalReasonList dismissalReasons={dismissalReasons} />
      </Card>
      <Card
        title={
          <Text size="text-18" weight={500}>
            Вопросы выходного интервью
          </Text>
        }
      >
        <ExitInterviewQuestionForm />
        <ExitInterviewQuestionList questions={exitInterviewQuestions} />
      </Card>
      <Card
        title={
          <Text size="text-18" weight={500}>
            Отделы
          </Text>
        }
      >
        <DepartmentForm parentDepartmentOptions={PARENT_DEPARTMENT_OPTIONS} />
        <DepartmentList
          parentDepartmentOptions={PARENT_DEPARTMENT_OPTIONS}
          departments={departments}
        />
      </Card>
    </div>
  );
};

import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ArrowLeft } from 'design/icon';

import { INavigationItem } from '../../../../utils/types';

import { Menu } from './menu';

import styles from './side-menu.module.scss';

export interface ISideMenuProps {
  setOpenSidebar: (flag: boolean) => void;
  navItem?: INavigationItem;
}

export const SideMenu: FC<ISideMenuProps> = ({ setOpenSidebar, navItem }) => {
  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    setOpenSidebar(isOpen);
  }, [isOpen, setOpenSidebar]);

  return (
    <nav className={cn(styles.container, { [styles.container_open]: isOpen })}>
      <Menu items={navItem?.items} isOpenSidebar={isOpen} />
      <div
        className={cn(styles.container__button, {
          [styles.container__button_rotate]: !isOpen,
        })}
        onClick={() => setOpen(!isOpen)}
      >
        <ArrowLeft />
      </div>
    </nav>
  );
};

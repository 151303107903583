import { useLoaderData } from 'react-router-dom';

import { ICurrency, ILegalEntity } from 'components/api/types/dictionary-types';
import { Card } from 'design/card';
import { Text } from 'design/text';

import { ConfigureCurrenciesForm } from './forms/currency';
import { LegalEntityForm } from './forms/legal-entity';
import { CurrencyList } from './lists/currency-list';
import { LegalEntityList } from './lists/legal-entity-list';

import styles from './finance-dictionary.module.scss';

export const FinanceDictionary = () => {
  const { currencies, legalEntities } = useLoaderData() as {
    currencies: ICurrency[];
    legalEntities: ILegalEntity[];
  };

  return (
    <div className={styles.wrapper}>
      <Text size="text-20" weight={500}>
        Словарь
      </Text>
      <Card
        title={
          <div>
            <Text size="text-18" weight={500}>
              Конфигурация валют
            </Text>
          </div>
        }
      >
        <ConfigureCurrenciesForm />
        <CurrencyList currencies={currencies} />
      </Card>
      <Card
        title={
          <div>
            <Text size="text-18" weight={500}>
              Юридические лица
            </Text>
          </div>
        }
      >
        <LegalEntityForm />
        <LegalEntityList legalEntities={legalEntities} />
      </Card>
    </div>
  );
};

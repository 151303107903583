import { useState } from 'react';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';

import { ISalary } from 'components/api/types/salary';
import { ContentContainer } from 'components/content-container';
import { ButtonSwitch } from 'design/button-switch';
import { SearchLine } from 'design/icon';
import { Input } from 'design/input';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { debounce, getInitialsEmployeeFromFullName } from 'utils';

import styles from './salaries-page.module.scss';

export const SalariesPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState('');

  const { salaries } = useLoaderData() as {
    salaries: ISalary[];
  };

  const columns: TTableColumn<ISalary>[] = [
    {
      title: 'Фио',
      dataIndex: ['fullName'],
      render: ({ fullName }) => (
        <Text size="text-14" weight={400}>
          {getInitialsEmployeeFromFullName(fullName)}
        </Text>
      ),
      minWidth: '200px',
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Должность',
      dataIndex: ['positionNameRu'],
      minWidth: '210px',
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Юр Лицо',
      dataIndex: ['legalEntityName'],
      minWidth: '200px',
    },
    {
      title: 'Оклад',
      dataIndex: ['salary'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Надбавка',
      dataIndex: ['surcharge'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Авторские',
      dataIndex: ['authorAllowances'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Премия',
      dataIndex: ['bonus'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Доп Нал',
      dataIndex: ['addCash'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'ЗП с НДФЛ',
      dataIndex: ['salaryWithNdfl'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Чистыми без НДФЛ',
      dataIndex: ['salaryWithoutNdfl'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Тотал до вычета',
      dataIndex: ['totalSalary'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Тотал на руки',
      dataIndex: ['salaryOnHands'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Страх. взносы',
      dataIndex: ['taxInsurance'],
      minWidth: '80px',
      maxWidth: '120px',
    },
    {
      title: 'Тотал + НДФЛ + взносы',
      dataIndex: ['salaryWithTaxInsurance'],
      minWidth: '80px',
      maxWidth: '120px',
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    debounce(() =>
      setSearchParams((prev) => {
        if (e.target.value) {
          prev.set('search', e.target.value);
        } else {
          prev.delete('search');
        }

        return prev;
      })
    )();
  };

  return (
    <div className={styles.salaries}>
      <div className={styles.header}>
        <Text size="text-20" weight={500} className={styles.heading}>
          Зарплаты
        </Text>
      </div>
      <div className={styles.body}>
        <div className={styles.tableHeader}>
          <Input
            className={styles.search}
            size="small"
            placeholder="Поиск"
            value={searchValue}
            onChange={handleSearch}
            prefixIcon={<SearchLine />}
          />
          <div className={styles.buttons}>
            <ButtonSwitch
              options={[
                { value: 'active', label: 'Действующие' },
                { value: 'dismissed', label: 'Уволенные' },
              ]}
              onChange={(option) => {
                setSearchParams((prev) => {
                  prev.set('filter', `${option.value}`);

                  return prev;
                });
              }}
              active={searchParams.get('filter') || ''}
            />
          </div>
        </div>
        <ContentContainer>
          <Table
            columns={columns}
            data={salaries}
            key="id"
            onRowClick={({ employeeId }) =>
              navigate(`/finance/employee/${employeeId}?filter=general`)
            }
          />
        </ContentContainer>
      </div>
    </div>
  );
};

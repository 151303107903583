import { ID } from 'components/api/types/common';
import { ITax } from 'components/api/types/salary';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  taxes: async (data: {
    taxId: ID | null;
    tax: ITax;
    removedId: ID | null;
  }) => {
    data.taxId && (await requests.patchTaxData(data.taxId, data.tax));

    data.tax && !data.taxId && (await requests.postTaxData(data.tax));

    data.removedId && (await requests.deleteTaxData(data.removedId));

    await queryClient.invalidateQueries({ queryKey: ['taxes'] });
  },
};

import { LoaderFunctionArgs } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import {
  IEmployee,
  IExitInterviewQuestion,
  IMissingDayInfo,
} from 'components/api/types/employee';
import { EMissingDays } from 'components/api/types/enums';
import { AnyType } from 'utils/types';

import { requests } from '../../api';
import { MISSING_DAYS_PARAMS } from '../../utils/constants';

export interface IEmployeeDetailsLoader {
  employee: IEmployee;
  missingDays: IMissingDayInfo;
  exitInterviewQuestions: IExitInterviewQuestion[];
}

export const loaders = ({ params, request }: LoaderFunctionArgs<AnyType>) => [
  {
    name: 'employee',
    fn: () => requests.getEmployeeDataById(params.employeeId as ID),
  },
  {
    name: 'missingDays',
    fn: () => {
      const type = new URL(request.url).searchParams.get('type')
        ? MISSING_DAYS_PARAMS[
            new URL(request.url).searchParams.get(
              'type'
            ) as keyof typeof MISSING_DAYS_PARAMS
          ]
        : EMissingDays.Отпуск;

      return requests.getMissingDaysDataById(
        params.employeeId as ID,
        type ?? EMissingDays.Отпуск
      );
    },
  },
  {
    name: 'exitInterviewQuestions',
    fn: () => requests.getExitInterviewQuestions(true),
  },
];

import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';

import { IEmployee, IMissingDayInfo } from 'components/api/types/employee';
import { Avatar } from 'design/avatar';
import { Card } from 'design/card';
import { DropdownButton, IAction } from 'design/dropdown-button';
import { Edit2Fill, UserUnfollowLine, UserLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { EConfirmOptions } from 'design/modal/types';
import { Tabs, ITab } from 'design/tabs';
import { Text } from 'design/text';
import { fullNameEmployee } from 'utils';

import { MissingDays } from './missing-days';
import { Profile } from './profile';
import { DismissalModal } from './profile/modals/dismissal';
import { EmployeeEditModal } from './profile/modals/employee';
import { EmployeeExitInterviewModal } from './profile/modals/employee-exit-interview';

import styles from './employee.module.scss';

type TActiveTab = 'profile' | 'missing-days';

export const EmployeePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState<TActiveTab>(
    (searchParams.get('filter') as TActiveTab | null) ?? 'profile'
  );

  const loaderData = useLoaderData();

  const navigate = useNavigate();

  const { employee, missingDays } = loaderData as {
    employee: IEmployee;
    missingDays: IMissingDayInfo;
  };

  useEffect(() => {
    const filter = searchParams.get('filter');

    setActiveTab(filter as TActiveTab);
  }, [searchParams, setSearchParams]);

  if (!employee) {
    return null;
  }

  const openEmployeeExitInterview = () => {
    modal.open({
      id: 'employee-exit-inteview',
      formId: 'employee-exit-interview-form',
      title: 'Данные об увольнении',
      confirmType: EConfirmOptions.submit,
      confirmContent: (
        <DismissalModal
          name={`${employee.lastName} ${employee.firstName} ${employee.middleName}`}
        />
      ),
      subTitle:
        'Заполните данные для завершения процесса увольнения. Все поля являются обязательными.',
      submit: 'Продолжить',
      content: (
        <EmployeeExitInterviewModal formId="employee-exit-interview-form" />
      ),
    });
  };

  const openEmployeeEdit = () => {
    modal.open({
      id: 'employee-edit',
      formId: 'employee-edit-form',
      title: 'Основные данные – редактирование',
      content: (
        <EmployeeEditModal
          formId="employee-edit-form"
          officeId={employee.officeId}
          name={fullNameEmployee(
            employee.firstName,
            employee.lastName,
            employee.middleName
          )}
          positionId={employee.positionId}
        />
      ),
    });
  };

  const employeeActions: IAction[] = [
    {
      label: 'Редактировать',
      onClick: openEmployeeEdit,
      icon: <Edit2Fill />,
    },
    {
      label: !employee.dismissal?.id
        ? 'Уволить работника'
        : 'Изменить данные об увольнении',
      onClick: openEmployeeExitInterview,
      variant: !employee.dismissal?.id ? 'red' : 'default',
      icon: !employee.dismissal?.id ? <UserUnfollowLine /> : <UserLine />,
    },
  ];

  const tabs: ITab[] = [
    {
      id: 'profile',
      title: 'Профиль',
    },
    {
      id: 'missing-days',
      title: 'Отпуск и больничный',
    },
  ];

  return (
    <div className={styles.employee}>
      <Card
        className={styles.generalCard}
        bodyClassName={styles.generalCardBody}
      >
        <div className={styles.general}>
          <div className={styles.infoTabsContainer}>
            <div className={styles.informationContainer}>
              <Avatar url={employee.photo?.url} name={employee.lastName} />
              <div className={styles.info}>
                <div>
                  <Text size="text-18" weight={500}>
                    {fullNameEmployee(
                      employee.firstName,
                      employee.lastName,
                      employee.middleName
                    )}
                  </Text>
                  <Text className={styles.position} size="text-14" weight={500}>
                    {employee.position?.nameRu}
                  </Text>
                </div>
                <Text className={styles.office} size="text-14" weight={500}>
                  {employee.office?.name}
                </Text>
              </div>
            </div>
            <Tabs
              active={activeTab}
              tabs={tabs}
              onChange={(value) => {
                if (value === 'missing-days') {
                  navigate(
                    `/human-resources/employee/${employee.id}?filter=missing-days&type=vacation`
                  );
                } else {
                  setSearchParams({ filter: `${value}` });
                }
              }}
            />
          </div>
          <div className={styles.actions}>
            {/* {employee.candidate && (
              <div className={styles.candidate}>
                <Text size="text-12" weight={500}>
                  {`Кандидат #${employee.candidate}`}
                </Text>
              </div>
            )} */}
            <DropdownButton label="Действия" actions={employeeActions} />
          </div>
        </div>
      </Card>
      {activeTab === 'profile' && <Profile employee={employee} />}
      {activeTab === 'missing-days' && (
        <MissingDays missingDaysInfo={missingDays} employeeId={employee.id} />
      )}
    </div>
  );
};

import { FC } from 'react';

import { Text } from 'design/text';

import styles from './tooltip.module.scss';

interface ITooltipProps {
  top: number;
  text: string;
}

export const Tooltip: FC<ITooltipProps> = ({ text, top }) => (
  <div
    className={styles.tooltip}
    style={{
      top: top,
    }}
  >
    <Text size="text-14" as="span">
      {text}
    </Text>
  </div>
);

import cn from 'clsx';

import { Cell } from './cell';
import { TTableColumn } from './column';

import { IGroupHoverNumber } from '.';

import styles from './table.module.scss';

export interface IRowProps<T> {
  data: T;
  columns: TTableColumn<T>[];
  groupHoverNumber?: IGroupHoverNumber;
  isRowsGroupHover?: boolean;
  onRowClick?: (data: T) => void;
  setGroupHoverNumber: (value: IGroupHoverNumber) => void;
  hoverHandler: (groupHoverNumber?: number, isRowsHover?: boolean) => void;
}

export const Row = <T extends Record<string, any>>({
  data,
  columns,
  onRowClick,
  groupHoverNumber,
  hoverHandler,
  isRowsGroupHover,
}: IRowProps<T>) => (
  <tr className={cn(styles.row)}>
    {columns.map((column, index) =>
      column.rowSpanCount && column.queue?.(data) !== 0 ? null : (
        <td
          style={{
            minWidth: column.minWidth ?? 'auto',
            maxWidth: column.maxWidth ?? 'auto',
          }}
          className={cn(styles.cell, {
            [styles.action]: !!onRowClick,
            [styles.middle]: !!column.rowSpanCount,
            [styles.hover]:
              (column.rowSpanCount &&
                groupHoverNumber?.groupNumber === column.groupNumber?.(data)) ||
              (groupHoverNumber?.groupNumber === column.groupNumber?.(data) &&
                isRowsGroupHover),
          })}
          onMouseEnter={() =>
            hoverHandler(column.groupNumber?.(data), !!column.rowSpanCount)
          }
          onMouseLeave={() => hoverHandler(undefined)}
          key={column.dataIndex ? (column.dataIndex.join('') as string) : index}
          rowSpan={column.rowSpanCount ? column.rowSpanCount(data) : undefined}
          {...(onRowClick ? { onClick: () => onRowClick(data) } : {})}
        >
          <Cell column={column} data={data} />
        </td>
      )
    )}
  </tr>
);

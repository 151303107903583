import { FC, useState } from 'react';

import { ISideMenuItem } from 'utils/types';

import { ListItem } from '../list-item';
import { MenuList } from '../menu-list';

import { Tooltip } from './tooltip';

import styles from './menu-item.module.scss';

type TMenuItemProps = ISideMenuItem & {
  isOpenSidebar: boolean;
};

const HEIGHT_OFFSET = 5;

export const MenuItem: FC<TMenuItemProps> = ({
  isOpenSidebar,
  href,
  text,
  routes,
  icon,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState(0);
  const [isOpenTooltip, setOpenTooltip] = useState(false);

  const handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
    if (isOpenSidebar) {
      return e.stopPropagation();
    }

    setOpenTooltip(true);

    const positions = e.currentTarget.getBoundingClientRect();

    setTooltipPosition(positions.top);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
    if (isOpenSidebar) {
      return e.stopPropagation();
    }

    setTooltipPosition(0);
    setOpenTooltip(false);
  };

  const routesWithItems = !!routes.filter((route) => route?.text).length;

  return (
    <>
      <li
        key={href}
        className={styles.item}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {routesWithItems ? (
          <MenuList
            routes={routes}
            text={text}
            icon={icon}
            href={href}
            isOpenSidebar={isOpenSidebar}
            setOpenTooltip={setOpenTooltip}
            rotateIcon
          />
        ) : (
          <ListItem routes={routes} text={text} icon={icon} href={href} />
        )}
      </li>
      {!isOpenSidebar && isOpenTooltip && (
        <Tooltip top={tooltipPosition - HEIGHT_OFFSET} text={text} />
      )}
    </>
  );
};

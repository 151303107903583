import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { IEmployee } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Button } from 'design/button';
import { ArrowLeft, CloseLine, ArrowRight, UserFollowLine } from 'design/icon';
import { StepPanel } from 'design/step-panel';
import { Text } from 'design/text';

import { requests } from '../../api';

import { CommonDataForm, TStepCommonData } from './forms/common';
import { EducationDataForm, IStepEducationData } from './forms/education';
import { EmploymentDataForm, TStepEmploymentData } from './forms/employment';
import {
  EmploymentHistoryDataForm,
  IStepWorkHistoryData,
} from './forms/employment-history';
import { Summary } from './summary';

import styles from './new-employee.module.scss';

const STEPS = [
  {
    id: 'common',
    label: 'Общие сведения',
  },
  {
    id: 'employment',
    label: 'Трудоустройство',
  },
  {
    id: 'education',
    label: 'Образование',
  },
  {
    id: 'workHistory',
    label: 'Предыдущие места работы',
  },
  {
    id: 'summary',
    label: '',
  },
];

const stepFormIds = STEPS.reduce((acc, step) => {
  acc[step.id] = `${step.id}-form`;

  return acc;
}, {} as Record<(typeof STEPS)[number]['id'], string>);

export const NewEmployeePage = () => {
  const [activeStep, setActiveStep] = useState<(typeof STEPS)[number]>(
    STEPS[0]
  );

  const [submitting, setSubmitting] = useState(false);

  const [employeeId, setEmployeeId] = useState<ID>();

  const [completedSteps, setCompletedSteps] = useState<
    (typeof STEPS)[number][]
  >([]);

  const navigate = useNavigate();

  const isPrevStepDisabled = useMemo(
    () => !!STEPS.findIndex((step) => step.id === activeStep.id),
    [activeStep]
  );

  const onSubmitStepOne = async (values: TStepCommonData) => {
    setSubmitting(true);
    const [lastName, firstName, middleName] = (values.name ?? '').split(' ');

    const formData = {
      ...values,
      lastName,
      firstName,
      middleName,
      //ToDo: temporarily mock data, since there is no such field in the form yet
      location: 'Tаганрог',
    };

    delete formData.name;

    try {
      if (employeeId) {
        await requests.patchEmployeeData(employeeId, formData as IEmployee);
      } else {
        const { data } = await requests.postEmployeeData(formData as IEmployee);

        setEmployeeId(data.id);
      }

      await queryClient.invalidateQueries({ queryKey: ['employee'] });

      setActiveStep(STEPS[1]);

      setCompletedSteps((completedSteps) => [...completedSteps, STEPS[0]]);

      return {
        ok: true,
      };
    } catch (e) {
      console.log(
        `Failed to ${employeeId ? 'update' : 'save'} common data:>> `,
        e
      );

      return e;
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitStepTwo = async (values: TStepEmploymentData) => {
    if (employeeId) {
      setSubmitting(true);

      try {
        await requests.patchEmployeeData(employeeId, values);

        await queryClient.invalidateQueries({ queryKey: ['employee'] });

        setActiveStep(STEPS[2]);

        setCompletedSteps((completedSteps) => [...completedSteps, STEPS[1]]);
      } catch (e) {
        console.log('Failed to save employment data:>> ', e);

        return e;
      } finally {
        setSubmitting(false);
      }
    }
  };

  const onSubmitStepThree = async (
    values: IStepEducationData,
    removedId?: ID[]
  ) => {
    setSubmitting(true);

    try {
      values.education &&
        employeeId &&
        (await Promise.all(
          values.education?.map(async (data) => {
            if (data.id) {
              await requests.patchEducationData(employeeId, data.id, data);
            } else {
              await requests.postEducationData(employeeId, data);
            }
          })
        ));

      removedId?.length &&
        employeeId &&
        (await Promise.all(
          removedId?.map((id) => requests.deleteEducationData(employeeId, id))
        ));

      await queryClient.invalidateQueries({ queryKey: ['education'] });
    } catch (e) {
      console.log('Failed to save education data :>> ', e);

      return e;
    } finally {
      setActiveStep(STEPS[3]);
      setSubmitting(false);
      setCompletedSteps((completedSteps) => [...completedSteps, STEPS[2]]);
    }
  };

  const onSubmitStepFour = async (
    values: IStepWorkHistoryData,
    removedId?: ID[]
  ) => {
    setSubmitting(true);

    try {
      values.workHistory &&
        employeeId &&
        (await Promise.all(
          values.workHistory.map(async (data) => {
            if (data.id) {
              await requests.patchWorkHistoryData(employeeId, data.id, data);
            } else {
              await requests.postWorkHistoryData(employeeId, data);
            }
          })
        ));

      removedId?.length &&
        employeeId &&
        (await Promise.all(
          removedId?.map((id) => requests.deleteWorkHistoryData(employeeId, id))
        ));

      await queryClient.invalidateQueries({ queryKey: ['workHistory'] });
    } catch (e) {
      console.log('Failed to save work history data :>> ', e);

      return e;
    } finally {
      setActiveStep(STEPS[4]);
      setSubmitting(false);
      setCompletedSteps((completedSteps) => [...completedSteps, STEPS[3]]);
    }
  };

  return (
    <section className={styles.new_employee}>
      <div className={styles.new_employee__heading}>
        <Text size="text-24" weight={500} as="h2">
          Создание нового сотрудника
        </Text>
        <Button
          prefixIcon={<CloseLine />}
          buttonType="secondary"
          size="small"
          label="Закрыть"
          onClick={() => navigate('/human-resources/accounting')}
        />
      </div>
      <StepPanel
        steps={[...STEPS].slice(0, -1)}
        activeStep={activeStep}
        className={styles.new_employee__steps}
        completedSteps={completedSteps}
      />
      <div className={styles.new_employee__form_container}>
        {activeStep.id === 'common' && (
          <CommonDataForm
            formId={stepFormIds.common}
            employeeId={employeeId}
            submit={onSubmitStepOne}
          />
        )}
        {activeStep.id === 'employment' && (
          <EmploymentDataForm
            formId={stepFormIds.employment}
            employeeId={employeeId}
            submit={onSubmitStepTwo}
          />
        )}
        {activeStep.id === 'education' && (
          <EducationDataForm
            formId={stepFormIds.education}
            employeeId={employeeId}
            submit={onSubmitStepThree}
          />
        )}
        {activeStep.id === 'workHistory' && (
          <EmploymentHistoryDataForm
            formId={stepFormIds.workHistory}
            employeeId={employeeId}
            submit={onSubmitStepFour}
          />
        )}
        {activeStep.id === 'summary' && <Summary employeeId={employeeId} />}
      </div>
      <div className={styles.new_employee__form_buttons}>
        <Button
          label={activeStep.id === 'summary' ? 'Назад' : 'Предыдущий шаг'}
          buttonType="secondary"
          type="button"
          prefixIcon={<ArrowLeft />}
          disabled={!isPrevStepDisabled}
          onClick={() => {
            const lastActiveStep = completedSteps[completedSteps.length - 1];
            setActiveStep(lastActiveStep);

            setCompletedSteps(
              completedSteps.filter((step) => step.id !== lastActiveStep.id)
            );
          }}
        />
        {activeStep.id !== 'summary' && (
          <Button
            label="Следующий шаг"
            suffixIcon={<ArrowRight />}
            loading={submitting}
            form={stepFormIds[activeStep.id]}
          />
        )}
        {activeStep.id === 'summary' && (
          <Button
            label="Создать"
            prefixIcon={<UserFollowLine />}
            onClick={() => navigate('/human-resources/accounting')}
          />
        )}
      </div>
    </section>
  );
};

import { FC } from 'react';

import { ID } from 'components/api/types/common';
import { ESalaryRevisionRequestsStatus } from 'components/api/types/enums';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { formatDate } from 'utils';
import { datePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { REVISION_REQUEST_STATUS_OPTIONS } from '../../../utils/constants';
import { revisionRequestSchema } from '../../../utils/validation';

interface IRevisionRequestModalProps extends IModalOverride {
  id: ID;
}

interface IFormValue {
  startDate: Date;
  newSalary: string;
  comment: string;
  status: ESalaryRevisionRequestsStatus;
}

const FormContent = () => (
  <>
    <FormDatepicker
      required
      name="startDate"
      label="Дата начала"
      placeholder={datePlaceholder}
    />
    <FormInput
      required
      name="newSalary"
      label="Новая ЗП"
      placeholder="Новая ЗП"
    />
    <FormSelect
      required
      name="status"
      label="Статус"
      options={REVISION_REQUEST_STATUS_OPTIONS}
    />
    <FormInput name="comment" label="Комментарий" placeholder="Новая ЗП" />
  </>
);

export const RevisionRequestModal: FC<IRevisionRequestModalProps> = ({
  formId,
  setLoading,
  setConfirm,
  modalClose,
  id,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = ({ startDate, newSalary, comment, status }: IFormValue) => {
    submit({
      entity: 'revisionRequest',
      data: {
        employeeId: Number(id),
        employeeSalaryRevisionRequest: {
          startDate: formatDate(`${startDate}`, 'yyyy-MM-dd'),
          newSalary: Number(newSalary),
          comment,
          status,
        },
      },
    });
  };

  return (
    <Form
      id={formId}
      validation={revisionRequestSchema}
      initialValues={{ status: ESalaryRevisionRequestsStatus.created }}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      onSubmit={onSubmit}
    >
      <FormContent />
    </Form>
  );
};

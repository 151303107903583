import { useLoaderData, useNavigate } from 'react-router-dom';

import { IHRSalary } from 'components/api/types/salary';
import { ContentContainer } from 'components/content-container';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { getInitialsEmployeeFromFullName } from 'utils';

import styles from './salaries.module.scss';

export const SalariesPage = () => {
  const navigate = useNavigate();

  const { salaries } = useLoaderData() as {
    salaries: IHRSalary[];
  };

  const columns: TTableColumn<IHRSalary>[] = [
    {
      title: 'Фио',
      dataIndex: ['fullName'],
      render: ({ fullName }) => (
        <Text size="text-14" weight={400}>
          {getInitialsEmployeeFromFullName(fullName)}
        </Text>
      ),
      minWidth: '200px',
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Должность',
      dataIndex: ['positionNameRu'],
      minWidth: '210px',
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Юр Лицо',
      dataIndex: ['legalEntityName'],
      minWidth: '150px',
    },
    {
      title: 'ЗП до Вычета',
      dataIndex: ['salaryWithoutNdfl'],
    },
    {
      title: 'ЗП после Вычета',
      dataIndex: ['salaryWithNdfl'],
    },
    {
      title: 'Доп Нал',
      dataIndex: ['addCash'],
    },
    {
      title: 'Тотал до Вычета',
      dataIndex: ['totalSalary'],
    },
    {
      title: 'Тотал после Вычета',
      dataIndex: ['salaryOnHands'],
    },
  ];

  return (
    <div className={styles.accounting}>
      <div className={styles.header}>
        <Text size="text-20" weight={500} className={styles.heading}>
          Зарплаты
        </Text>
      </div>
      <div className={styles.body}>
        <ContentContainer>
          <Table
            columns={columns}
            data={salaries}
            key="id"
            onRowClick={({ employeeId }) =>
              navigate(`/human-resources/salaries/${employeeId}?filter=general`)
            }
          />
        </ContentContainer>
      </div>
    </div>
  );
};

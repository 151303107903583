import { useState } from 'react';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';

import { IEmployee } from 'components/api/types/employee';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { ButtonSwitch } from 'design/button-switch';
import { AddBoxLine, SearchLine } from 'design/icon';
import { Input } from 'design/input';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { Text } from 'design/text';
import { debounce, formatDate, getInitialsEmployee } from 'utils';

import { dateSorting, formatDateDistance, textSorting } from '../../utils';

import styles from './accounting.module.scss';

export const AccountingPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') ?? ''
  );

  const { employees } = useLoaderData() as {
    employees: IEmployee[];
  };

  const columns: TTableColumn<IEmployee>[] = [
    {
      title: 'Фио',
      dataIndex: ['lastName'],
      sortable: true,
      sorting: textSorting,
      render: (data) => (
        <Text size="text-14" weight={400}>
          {getInitialsEmployee(data.lastName, data.firstName, data.middleName)}
        </Text>
      ),
    },
    {
      title: 'Должность',
      dataIndex: ['position', 'nameRu'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Дата рожд.',
      dataIndex: ['birthDate'],
      render: ({ birthDate }) => (
        <Text size="text-14" weight={400} as="span">
          {birthDate ? formatDate(birthDate) : ''}
        </Text>
      ),
    },
    {
      title: 'Город',
      dataIndex: ['office', 'name'],
    },
    {
      title: 'Начало работы (стаж)',
      dataIndex: ['employmentDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ employmentDate }) => (
        <div className={styles.date}>
          <Text size="text-14" weight={400} as="span">
            {employmentDate ? formatDate(employmentDate) : ''}
          </Text>
          <Text
            className={styles.date__experience}
            size="text-14"
            weight={400}
            as="span"
          >
            {employmentDate ? formatDateDistance(new Date(employmentDate)) : ''}
          </Text>
        </div>
      ),
    },
  ];

  const handleClick = () => {
    navigate('/human-resources/employee/new');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    debounce(() =>
      setSearchParams((prev) => {
        if (e.target.value) {
          prev.set('search', e.target.value);
        } else {
          prev.delete('search');
        }

        return prev;
      })
    )();
  };

  return (
    <div className={styles.accounting}>
      <div className={styles.header}>
        <Text size="text-20" weight={500} className={styles.heading}>
          Учет сотрудников
        </Text>
        {/* ToDo: temporarily hidden */}
        {/* <Button
          className={styles.export}
          size="small"
          buttonType="secondary"
          prefixIcon={<FileUploadLine />}
          label="Экспорт"
        /> */}
      </div>
      <div className={styles.body}>
        <div className={styles.tableHeader}>
          <div className={styles.search}>
            <Input
              size="small"
              placeholder="Поиск"
              className={styles.input}
              value={searchValue}
              onChange={handleSearch}
              prefixIcon={<SearchLine />}
            />
            <div>
              <ButtonSwitch
                options={[
                  { value: 'active', label: 'Действующие' },
                  { value: 'dismissed', label: 'Уволенные' },
                ]}
                onChange={(option) => {
                  setSearchParams((prev) => {
                    prev.set('filter', `${option.value}`);

                    return prev;
                  });
                }}
                active={searchParams.get('filter') || ''}
              />
            </div>
          </div>
          {/* ToDo: temporarily hidden */}
          {/* <Button
              buttonType="secondary"
              label="Фильтры"
              prefixIcon={<FilterLine />}
            /> */}
          <Button
            className={styles.button}
            label="Новый сотрудник"
            onClick={handleClick}
            prefixIcon={<AddBoxLine />}
          />
        </div>
        <ContentContainer>
          <Table
            columns={columns}
            data={employees}
            key="id"
            onRowClick={({ id }) =>
              navigate(`/human-resources/employee/${id}?filter=profile`)
            }
          />
        </ContentContainer>
      </div>
    </div>
  );
};

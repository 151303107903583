import { FC, useEffect, useState } from 'react';

import { IBaseEmployee, ID } from 'components/api/types/common';
import {
  IOffice,
  IPosition,
  ILegalEntity,
  IDepartment,
} from 'components/api/types/dictionary-types';
import { IEmployee } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Divider } from 'design/divider';
import { Spinner } from 'design/spinner';
import { Text } from 'design/text';
import { formatDate, fullNameEmployee } from 'utils';

import { requests } from '../../api';

import styles from './new-employee.module.scss';

export interface ISummaryProps {
  employeeId?: ID;
}

const COMMON: {
  label: string;
  name: keyof Pick<
    IEmployee,
    | 'birthDate'
    | 'gender'
    | 'position'
    | 'office'
    | 'englishLevel'
    | 'phone'
    | 'email'
    | 'personalEmail'
  >;
  formatter?: (value: string | IOffice | IPosition) => string;
}[] = [
  {
    label: 'Дата рождения',
    name: 'birthDate',
    formatter: (value) => (value ? formatDate(value as string) : ''),
  },
  {
    label: 'Пол',
    name: 'gender',
  },
  {
    label: 'Должность',
    name: 'position',
    formatter: (value) => (value ? (value as IPosition)?.nameRu : ''),
  },
  {
    label: 'Город',
    name: 'office',
    formatter: (value) => (value ? (value as IOffice)?.name : ''),
  },
  {
    label: 'Уровень английского языка',
    name: 'englishLevel',
  },
  {
    label: 'Телефон',
    name: 'phone',
  },
  {
    label: 'Почта',
    name: 'email',
  },
  {
    label: 'Персональная почта',
    name: 'personalEmail',
  },
];

const EMPLOYMENT: {
  label: string;
  name: keyof Pick<
    IEmployee,
    | 'legalEntity'
    | 'department'
    | 'employmentDate'
    | 'endOfProbationPeriod'
    | 'head'
  >;
  formatter?: (
    value: string | ILegalEntity | IDepartment | IBaseEmployee
  ) => string;
}[] = [
  {
    label: 'Куда устроен',
    name: 'legalEntity',
    formatter: (value) => (value ? (value as ILegalEntity)?.name : ''),
  },
  {
    label: 'Отдел',
    name: 'department',
    formatter: (value) => (value ? (value as IDepartment)?.nameRu : ''),
  },
  {
    label: 'Руководитель',
    name: 'head',
    formatter: (value) => {
      if (value as IBaseEmployee) {
        const { firstName, lastName, middleName } = value as IBaseEmployee;

        return fullNameEmployee(firstName, lastName, middleName);
      }

      return '';
    },
  },
  {
    label: 'Начало работы',
    name: 'employmentDate',
    formatter: (value) => (value ? formatDate(value as string) : ''),
  },
  {
    label: 'Конец испытательного срока',
    name: 'endOfProbationPeriod',
    formatter: (value) => (value ? formatDate(value as string) : ''),
  },
];

export const Summary: FC<ISummaryProps> = ({ employeeId }) => {
  const [employee, setEmployee] = useState<IEmployee>();

  const [isLoading, setLoading] = useState(true);

  const getEmployeeById = async (id: ID) => {
    try {
      const { data } = await queryClient.fetchQuery(['employee'], () =>
        requests.getEmployeeDataById(id)
      );

      setEmployee(data);
    } catch (e) {
      console.log('Failed to fetch employee data :>> ', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getEmployeeById(employeeId);
    }
  }, [employeeId]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="gray-500" />
      </div>
    );
  }

  return (
    <div className={styles.summary}>
      <div className={styles.summary__heading}>
        <Text
          className={styles.summary__heading__name}
          size="text-20"
          weight={500}
          as="h1"
        >
          {`Сотрудник - ${
            employee &&
            fullNameEmployee(
              employee.firstName,
              employee.lastName,
              employee?.middleName
            )
          }`}
        </Text>
        <Text as="span" className={styles.summary__heading__tip} size="text-12">
          Проверьте правильность введенных данных
        </Text>
      </div>
      <Text
        size="text-20"
        weight={500}
        className={styles.summary__section__heading}
        as="h2"
      >
        Общие сведения
      </Text>
      <div className={styles.summary__section__content}>
        {COMMON.map((infoItem) => (
          <div key={`common-${infoItem.label}`} className={styles.content__row}>
            <Text
              as="span"
              size="text-14"
              weight={500}
              className={styles.content__row__title}
            >
              {infoItem.label}
            </Text>
            <Text as="span" size="text-14">
              {infoItem.formatter
                ? infoItem.formatter(
                    (employee?.[infoItem?.name] as string) ?? ''
                  )
                : (employee?.[infoItem?.name] as string) ?? ''}
            </Text>
          </div>
        ))}
      </div>
      <Text
        size="text-20"
        weight={500}
        className={styles.summary__section__heading}
        as="h2"
      >
        Трудоустройство
      </Text>
      <div className={styles.summary__section__content}>
        {EMPLOYMENT.map((infoItem) => (
          <div
            key={`employment-${infoItem.label}`}
            className={styles.content__row}
          >
            <Text
              as="span"
              size="text-14"
              weight={500}
              className={styles.content__row__title}
            >
              {infoItem.label}
            </Text>
            <Text as="span" size="text-14">
              {infoItem.formatter
                ? infoItem.formatter(
                    (employee?.[infoItem.name] as string) ?? ''
                  )
                : (employee?.[infoItem.name] as string) ?? ''}
            </Text>
          </div>
        ))}
      </div>
      {!!employee?.education.length && (
        <>
          <Text
            size="text-20"
            weight={500}
            className={styles.summary__section__heading}
            as="h2"
          >
            Образование
          </Text>
          {employee.education.map((educationHistoryItem) => (
            <div
              className={styles.summary__section__content}
              key={educationHistoryItem.id}
            >
              <div className={styles.content__heading}>
                <Text
                  as="span"
                  size="text-16"
                  weight={500}
                  className={styles.content__heading__name}
                >
                  {educationHistoryItem?.educationalInstitution}
                </Text>
                <Divider />
              </div>
              <div className={styles.content__row}>
                <Text
                  as="span"
                  size="text-14"
                  weight={500}
                  className={styles.content__row__title}
                >
                  Направление
                </Text>
                <Text as="span" size="text-14">
                  {educationHistoryItem?.direction}
                </Text>
              </div>
              <div className={styles.content__row}>
                <Text
                  as="span"
                  size="text-14"
                  weight={500}
                  className={styles.content__row__title}
                >
                  Степень
                </Text>
                <Text as="span" size="text-14">
                  {educationHistoryItem?.degree?.degree}
                </Text>
              </div>
              <div className={styles.content__row}>
                <Text
                  as="span"
                  size="text-14"
                  weight={500}
                  className={styles.content__row__title}
                >
                  Начало обучения
                </Text>
                <Text as="span" size="text-14">
                  {formatDate(educationHistoryItem?.startDate)}
                </Text>
              </div>
              <div className={styles.content__row}>
                <Text
                  as="span"
                  size="text-14"
                  weight={500}
                  className={styles.content__row__title}
                >
                  Окончание обучения
                </Text>
                <Text as="span" size="text-14">
                  {formatDate(educationHistoryItem?.endDate)}
                </Text>
              </div>
            </div>
          ))}
        </>
      )}
      {!!employee?.workHistory.length && (
        <>
          <Text
            size="text-20"
            weight={500}
            className={styles.summary__section__heading}
            as="h2"
          >
            Предыдущее место работы
          </Text>
          {employee.workHistory.map((employmentHistoryItem) => (
            <div
              className={styles.summary__section__content}
              key={employmentHistoryItem.id}
            >
              <div className={styles.content__heading}>
                <Text
                  as="span"
                  size="text-16"
                  weight={500}
                  className={styles.content__heading__name}
                >
                  {employmentHistoryItem?.companyName}
                </Text>
                <Divider />
              </div>
              <div className={styles.content__row}>
                <Text
                  as="span"
                  size="text-14"
                  weight={500}
                  className={styles.content__row__title}
                >
                  Должность
                </Text>
                <Text as="span" size="text-14">
                  {employmentHistoryItem?.position}
                </Text>
              </div>
              <div className={styles.content__row}>
                <Text
                  as="span"
                  size="text-14"
                  weight={500}
                  className={styles.content__row__title}
                >
                  Начало работы
                </Text>
                <Text as="span" size="text-14">
                  {formatDate(employmentHistoryItem?.startDate)}
                </Text>
              </div>
              <div className={styles.content__row}>
                <Text
                  as="span"
                  size="text-14"
                  weight={500}
                  className={styles.content__row__title}
                >
                  Окончание работы
                </Text>
                <Text as="span" size="text-14">
                  {formatDate(employmentHistoryItem?.endDate)}
                </Text>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

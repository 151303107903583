import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IDepartment, IDismissalReason } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getDepartments =
  (module: EModule) => (): Promise<AxiosResponse<IDepartment[]>> =>
    $host.get(`/${module}/dictionary/departments`);

export const patchDepartmentData =
  (module: EModule) =>
  (
    id: ID,
    values: Partial<IDismissalReason>
  ): Promise<AxiosResponse<IDepartment>> =>
    $host.patch(`/${module}/dictionary/departments/${id}`, { ...values });

export const postDepartmentData =
  (module: EModule) =>
  (values: Omit<IDepartment, 'id'>): Promise<AxiosResponse<IDepartment>> =>
    $host.post(`/${module}/dictionary/departments`, { ...values });

export const deleteDepartmentData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IDepartment>> =>
    $host.delete(`/${module}/dictionary/departments/${id}`);

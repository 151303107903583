import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  Params,
} from 'react-router-dom';

import { AxiosResponse } from 'axios';
import { EModule } from 'components/api/types/enums';
import { queryClient } from 'components/context/query';
import { format as formatDateFNS } from 'date-fns';

import { AnyType } from './types';

interface IRequests {
  name: string;
  fn: () => Promise<AxiosResponse<unknown>>;
}

interface IItemsWithPermissions {
  permissions: string[];
  [key: string]: AnyType;
}

export const configureLoaders =
  (
    callback: ({ params, request }: LoaderFunctionArgs<AnyType>) => IRequests[]
  ) =>
  async ({ params, request }: LoaderFunctionArgs<AnyType>) => {
    if (Object.values(params).some((value) => !value)) {
      throw new Response('Not Found', { status: 404 });
    }

    const requests = callback({ params, request });

    return await Promise.all(
      requests.map(({ name, fn }) => queryClient.fetchQuery([name], fn))
    )
      .then((res) =>
        res.reduce(
          (acc, { data }, index) => ({
            ...acc,
            [requests[index].name]: data,
          }),
          {}
        )
      )
      .catch((err) => {
        throw new Response(err.message, { status: err.status });
      });
  };

export const configureActions =
  (
    entities: Record<
      string,
      (data: AnyType, params: Params<string>) => Promise<void>
    >
  ) =>
  async ({ params, request }: ActionFunctionArgs<AnyType>) => {
    const { entity, data } = await request.json();

    try {
      await entities[entity ?? 'default'](data, params);

      return {
        ok: true,
      };
    } catch (e) {
      console.log(`This error occuered in entity: ${entity} :>> `, e);

      return e;
    }
  };

export const debounce = (func: () => void, delay = 500) => {
  let timeout: ReturnType<typeof setTimeout>;

  return () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      func.apply(this);
    }, delay);
  };
};

export const formatDate = (date?: string, format?: string, locale?: Locale) =>
  formatDateFNS(date ? new Date(date) : new Date(), format ?? 'dd.MM.yyyy', {
    locale,
  });

export const makeOptionsFromEnum = (enumValues: Record<string, string>) => {
  const values = Array.from(Object.values(enumValues));

  return values.map((level) => ({
    label: level,
    value: level,
  }));
};

export const fullNameEmployee = (
  firstName: string,
  lastName: string,
  middleName?: string
) => `${lastName} ${firstName} ${middleName ?? ''}`.trim();

export const getInitialsEmployee = (
  lastName: string,
  firstName: string,
  middleName?: string
) =>
  `${lastName} ${firstName.charAt(0).toUpperCase()}. 
  ${middleName ? `${middleName.charAt(0).toUpperCase()}.` : ''}`.trim();

export const getInitialsEmployeeFromFullName = (fullName: string) => {
  const splitName = fullName.split(' ');

  return getInitialsEmployee(splitName[0], splitName[1], splitName[2] ?? '');
};

export const getModulesAccess = (arr: string[]) =>
  arr.reduce((acc: EModule[], el: string) => {
    if (!acc.find((item) => item === el.split(':')[1].split('/')[0])) {
      return [...acc, el.split(':')[1].split('/')[0] as EModule];
    }

    return acc;
  }, []);

export const filterRoutes = (
  permissions: string[],
  items: IItemsWithPermissions[]
) =>
  items.filter((data) =>
    data.permissions.every((permission) => permissions.includes(permission))
  );

import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IDepartment } from 'components/api/types/dictionary-types';
import { queryClient } from 'components/context/query';
import { Button } from 'design/button';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IOption } from 'design/select';
import { useFormContext } from 'react-hook-form';
import { fullNameEmployee } from 'utils';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { departmentSchema } from '../../../utils/validation';

import { IFormContentProps } from './type';

import styles from './forms.module.scss';

export type TDepartmentFormProps = {
  parentDepartmentOptions: IOption[];
} & Partial<{
  department: IDepartment;
  setDepartmentId: (value: ID) => void;
}>;

type TFormValue = Omit<IDepartment, 'id'>;

export const DepartmentFormContent: FC<
  IFormContentProps & { parentDepartmentOptions: IOption[] }
> = ({
  loading,
  isEdit,
  setId,
  setSuccess,
  isSuccess,
  parentDepartmentOptions,
}) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      reset({
        nameEn: '',
        nameRu: '',
        code: '',
      });

      setSuccess(false);
    }
  }, [isSuccess, reset, setSuccess]);

  return (
    <div
      className={cn(styles.form, {
        [styles.container]: !isEdit,
      })}
    >
      <div className={styles.form__section}>
        <FormInput required name="nameEn" label="Отдел на английском" />
        <FormInput required name="nameRu" label="Отдел на русском" />
        <FormInput required name="code" label="Код" />
        <FormSelect
          name="headId"
          label="Руководитель отдела"
          search
          options={async () => {
            const employees = await queryClient.fetchQuery({
              queryKey: ['employee'],
              queryFn: () => requests.getCurrentEmployees(false),
            });

            const options = employees.data.map((option) => ({
              value: option.id,
              label: fullNameEmployee(
                option.firstName,
                option.lastName,
                option?.middleName
              ),
            }));

            return options;
          }}
        />
        <FormSelect
          name="parentDepartmentId"
          label="Основной отдел"
          search
          options={parentDepartmentOptions}
        />
      </div>
      {isEdit ? (
        <div className={styles.form__button}>
          <Button
            label="Сохранить"
            loading={loading}
            type="submit"
            buttonType="secondary"
          />
          <Button
            label="Отмена"
            type="button"
            buttonType="secondary"
            onClick={() => setId?.('')}
          />
        </div>
      ) : (
        <Button
          label="Добавить"
          loading={loading}
          type="submit"
          className={styles.form__add}
        />
      )}
    </div>
  );
};

export const DepartmentForm: FC<TDepartmentFormProps> = ({
  department,
  parentDepartmentOptions,
  setDepartmentId,
}) => {
  const [isSuccess, setSuccess] = useState(false);

  const { loading, submit } = useSubmit(() => {
    setDepartmentId?.('');
    setSuccess(true);
  });

  const onSubmit = (value: TFormValue) => {
    submit({
      entity: 'department',
      data: {
        department: {
          ...value,
          headId: value.headId ? Number(value.headId) : null,
          parentDepartmentId: value.parentDepartmentId
            ? Number(value.parentDepartmentId)
            : null,
        },
        departmentId: department?.id,
      },
    });
  };

  return (
    <Form
      id="department"
      initialValues={{ ...department }}
      onSubmit={onSubmit}
      validation={departmentSchema}
    >
      <DepartmentFormContent
        isEdit={!!department?.id}
        loading={loading}
        setId={setDepartmentId}
        setSuccess={setSuccess}
        parentDepartmentOptions={parentDepartmentOptions}
        isSuccess={isSuccess}
      />
    </Form>
  );
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule } from '../types/enums';
import { IHRSalary } from '../types/salary';

export const getSalariesReportsForHr =
  (module: EModule) => (): Promise<AxiosResponse<IHRSalary[]>> =>
    $host.get(`/${module}/employees/salary-revisions/salary-reports-for-hr`);

export const getSalaryByIdReportsForHr =
  (module: EModule) =>
  (employeeId?: ID): Promise<AxiosResponse<IHRSalary[]>> =>
    $host.get(`/${module}/employees/salary-revisions/salary-reports-for-hr`, {
      params: { employeeId },
    });

export const getSalaryRevisionById =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<IHRSalary>> =>
    $host.get(`/${module}/employees/${employeeId}/salary-revisions`, {
      params: { employeeId },
    });

export const getSalaryHistoryById =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<IHRSalary>> =>
    $host.get(
      `/${module}/employees/${employeeId}/salary-revisions/history-for-hr`,
      {
        params: { employeeId },
      }
    );

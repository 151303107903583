import {
  deleteCurrencyData,
  deleteLegalEntityData,
  getCurrencies,
  getLegalEntities,
  getOffices,
  patchCurrencyData,
  patchLegalEntityData,
  postCurrencyData,
  postLegalEntityData,
} from 'components/api/common';
import {
  getCurrentEmployees,
  getEmployeeDataById,
} from 'components/api/employee';
import {
  deleteBankAccountData,
  getBankAccounts,
  patchBankAccountData,
  postBankAccountData,
} from 'components/api/finance/bank-accounts';
import {
  deleteBankCardData,
  getBankCards,
  patchBankCardData,
  postBankCardData,
} from 'components/api/finance/bank-cards';
import {
  deleteExchangeRateData,
  getExchangeRates,
  patchExchangeRateData,
  postExchangeRateData,
} from 'components/api/finance/exchange-rates';
import {
  deleteExpensesCategory,
  getExpensesCategories,
  patchExpensesCategoryById,
  postExpensesCategory,
} from 'components/api/finance/expenses-categories';
import {
  deleteExpensesTransactions,
  deleteExpensesTransactionsById,
  getExpensesTransactions,
  patchExpensesTransactions,
  patchExpensesTransactionsById,
  postExpensesTransactions,
} from 'components/api/finance/expenses-transactions';
import {
  deleteMoneyBalanceData,
  getMoneyBalances,
  patchMoneyBalanceData,
  postMoneyBalanceData,
} from 'components/api/finance/money-balances';
import { getPaymentFunctions } from 'components/api/finance/payment-functions';
import { postRepeatedExpensesForcedActivation } from 'components/api/finance/repeated-expenses';
import { postRevisionData } from 'components/api/finance/revisions';
import {
  deleteSalaryPaymentDaysConfigs,
  getEmployeeRevisionRequests,
  getEmployeeSalary,
  getSalaries,
  getSalaryHistory,
  getSalaryPaymentDaysConfigs,
  patchSalaryPaymentDaysConfigs,
  postSalaryForcedActivation,
  postSalaryPaymentDaysConfigs,
} from 'components/api/finance/salary';
import {
  deleteTaxData,
  getTaxes,
  patchTaxData,
  postTaxData,
} from 'components/api/finance/taxes';
import {
  deleteSalaryTransaction,
  getSalaryTransactionById,
  getSalaryTransactions,
  patchSalaryTransactionById,
  patchSalaryTransactions,
  postSalaryTransaction,
} from 'components/api/finance/transaction';
import {
  deleteNonWorkingDaysData,
  getNonWorkingDays,
  postNonWorkingDaysData,
} from 'components/api/finance/working-days';
import { EModule } from 'components/api/types/enums';

export const requests = {
  getBankAccounts: getBankAccounts(EModule.finance),
  postBankAccountData: postBankAccountData(EModule.finance),
  patchBankAccountData: patchBankAccountData(EModule.finance),
  deleteBankAccountData: deleteBankAccountData(EModule.finance),
  getLegalEntities: getLegalEntities(EModule.finance),
  deleteLegalEntityData: deleteLegalEntityData(EModule.finance),
  patchLegalEntityData: patchLegalEntityData(EModule.finance),
  postLegalEntityData: postLegalEntityData(EModule.finance),
  getCurrencies: getCurrencies(EModule.finance),
  deleteCurrencyData: deleteCurrencyData(EModule.finance),
  patchCurrencyData: patchCurrencyData(EModule.finance),
  postCurrencyData: postCurrencyData(EModule.finance),
  getOffices: getOffices(EModule.finance),
  getBankCards: getBankCards(EModule.finance),
  patchBankCardData: patchBankCardData(EModule.finance),
  postBankCardData: postBankCardData(EModule.finance),
  deleteBankCardData: deleteBankCardData(EModule.finance),
  patchTaxData: patchTaxData(EModule.finance),
  postTaxData: postTaxData(EModule.finance),
  deleteTaxData: deleteTaxData(EModule.finance),
  getTaxes: getTaxes(EModule.finance),
  postRevisionData: postRevisionData(EModule.finance),
  getEmployeeSalary: getEmployeeSalary(EModule.finance),
  getSalaryHistory: getSalaryHistory(EModule.finance),
  getCurrentEmployees: getCurrentEmployees(EModule.finance),
  getEmployeeDataById: getEmployeeDataById(EModule.finance),
  getEmployeeRevisionRequests: getEmployeeRevisionRequests(EModule.finance),
  getSalaryTransactionById: getSalaryTransactionById(EModule.finance),
  postSalaryForcedActivation: postSalaryForcedActivation(EModule.finance),
  getSalaryTransactions: getSalaryTransactions(EModule.finance),
  patchSalaryTransactions: patchSalaryTransactions(EModule.finance),
  deleteSalaryTransaction: deleteSalaryTransaction(EModule.finance),
  patchSalaryTransactionById: patchSalaryTransactionById(EModule.finance),
  postSalaryTransaction: postSalaryTransaction(EModule.finance),
  getSalaries: getSalaries(EModule.finance),
  deleteExchangeRateData: deleteExchangeRateData(EModule.finance),
  patchExchangeRateData: patchExchangeRateData(EModule.finance),
  postExchangeRateData: postExchangeRateData(EModule.finance),
  getExchangeRates: getExchangeRates(EModule.finance),
  getExpensesCategories: getExpensesCategories(EModule.finance),
  deleteExpensesCategory: deleteExpensesCategory(EModule.finance),
  patchExpensesCategoryById: patchExpensesCategoryById(EModule.finance),
  postExpensesCategory: postExpensesCategory(EModule.finance),
  postRepeatedExpensesForcedActivation: postRepeatedExpensesForcedActivation(
    EModule.finance
  ),
  deleteExpensesTransactionsById: deleteExpensesTransactionsById(
    EModule.finance
  ),
  deleteExpensesTransactions: deleteExpensesTransactions(EModule.finance),
  patchExpensesTransactions: patchExpensesTransactions(EModule.finance),
  patchExpensesTransactionsById: patchExpensesTransactionsById(EModule.finance),
  postExpensesTransactions: postExpensesTransactions(EModule.finance),
  getExpensesTransactions: getExpensesTransactions(EModule.finance),
  patchMoneyBalanceData: patchMoneyBalanceData(EModule.finance),
  postMoneyBalanceData: postMoneyBalanceData(EModule.finance),
  deleteMoneyBalanceData: deleteMoneyBalanceData(EModule.finance),
  getMoneyBalances: getMoneyBalances(EModule.finance),
  deleteNonWorkingDaysData: deleteNonWorkingDaysData(EModule.finance),
  postNonWorkingDaysData: postNonWorkingDaysData(EModule.finance),
  getNonWorkingDays: getNonWorkingDays(EModule.finance),
  getPaymentFunctions: getPaymentFunctions(EModule.finance),
  deleteSalaryPaymentDaysConfigs: deleteSalaryPaymentDaysConfigs(
    EModule.finance
  ),
  patchSalaryPaymentDaysConfigs: patchSalaryPaymentDaysConfigs(EModule.finance),
  postSalaryPaymentDaysConfigs: postSalaryPaymentDaysConfigs(EModule.finance),
  getSalaryPaymentDaysConfigs: getSalaryPaymentDaysConfigs(EModule.finance),
};

import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IEmployee, TEducationFormData } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Button } from 'design/button';
import { Divider } from 'design/divider';
import { Form, IFormChildrenProps } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { FormUploader } from 'design/form/form-uploader';
import { Spinner } from 'design/spinner';
import { Text } from 'design/text';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  educationPlaceholder,
  directionPlaceholder,
  datePlaceholder,
  linkPlaceholder,
} from 'utils/placeholder-text';

import { requests } from '../../../api';
import { educationHistorySchema } from '../../../utils/validation';

import styles from './forms.module.scss';

export interface IStepEducationData {
  education: TEducationFormData[];
}

export type TEmployeeEducationData = Partial<Pick<IEmployee, 'education'>>;

export interface IEducationDataFormProps {
  formId: string;
  submit: (values: IStepEducationData, removedIds?: ID[]) => void;
  employeeId?: ID;
}

export interface IEducationFromProps {
  index: number;
  removeEducation: () => void;
}

export interface IFormContentProps extends IFormChildrenProps {
  handleRemove: (id: ID) => void;
}

export const INITIAL_VALUE_EDUCATION = {
  educationalInstitution: '',
  direction: '',
  startDate: '',
  endDate: '',
};

export const EducationFrom: FC<IEducationFromProps> = ({
  index,
  removeEducation,
}) => (
  <>
    <div className={styles.form_container__form__section_heading}>
      <Text size="text-16" weight={500} className={styles.text} as="h3">
        {`Учебное заведение ${index + 1}`}
      </Text>
      <Divider />
      <Button
        buttonType="secondary"
        size="small"
        label="Удалить"
        type="button"
        onClick={removeEducation}
      />
    </div>
    <div className={cn(styles.form_container__form__section, styles.education)}>
      <FormInput
        required
        name={`education.${index}.educationalInstitution`}
        label="Учебное заведение"
        className={styles.educationalInstitution}
        placeholder={educationPlaceholder}
      />
      <FormInput
        required
        name={`education.${index}.direction`}
        label="Направление"
        placeholder={directionPlaceholder}
      />
      <FormSelect
        required
        name={`education.${index}.degreeId`}
        label="Степень"
        options={async () => {
          const options = await queryClient.fetchQuery({
            queryKey: ['educationDegrees'],
            queryFn: requests.getEducationDegrees,
          });

          return options.data.map((option) => ({
            value: option.id,
            label: option.degree,
          }));
        }}
      />
      <FormDatepicker
        required
        name={`education.${index}.startDate`}
        label="Начало обучение"
        placeholder={datePlaceholder}
      />
      <FormDatepicker
        required
        name={`education.${index}.endDate`}
        label="Окончание обучения"
        placeholder={datePlaceholder}
      />
      <FormUploader
        name={`education.${index}.documents`}
        className={styles.fileUploader}
        placeholder={linkPlaceholder}
      />
    </div>
  </>
);

export const EducationFormContent: FC<IFormContentProps> = ({
  form,
  handleRemove,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: form?.control,
    name: 'education',
  });

  const { getValues } = useFormContext();

  const formData = getValues();

  const addNewEducation = () => {
    append(INITIAL_VALUE_EDUCATION);
  };

  const removeEducation = async (index: number, id?: ID) => {
    remove(index);

    if (id) {
      handleRemove(id);
    }
  };

  return (
    <div className={styles.form_container__form}>
      {fields.map((education, index) => (
        <EducationFrom
          index={index}
          key={education.id}
          removeEducation={() =>
            removeEducation(index, formData.education[index].id)
          }
        />
      ))}
      <div className={styles.form_container__form__section_heading}>
        <Divider />
        <Button
          buttonType="secondary"
          size="small"
          label="Добавить образование"
          className={styles.add}
          onClick={addNewEducation}
          type="button"
        />
      </div>
    </div>
  );
};

export const EducationDataForm: FC<IEducationDataFormProps> = ({
  formId,
  submit,
  employeeId,
}) => {
  const [education, setEducation] = useState<TEmployeeEducationData>({
    education: [],
  });

  const [removedIds, setRemovedIds] = useState<ID[]>([]);

  const [isLoading, setLoading] = useState(false);

  const getEducationById = async (id: ID) => {
    setLoading(true);

    try {
      const { data } = await queryClient.fetchQuery(['employee'], () =>
        requests.getEducationDataById(id)
      );

      setEducation({ education: data });
    } catch (e) {
      console.log('Failed to fetch employee education data :>> ', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getEducationById(employeeId);
    }
  }, [employeeId]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="gray-500" />
      </div>
    );
  }

  const onSubmit = (values: TEmployeeEducationData) => {
    const formData = values.education?.map((education) => ({
      educationalInstitution: education.educationalInstitution,
      direction: education.direction,
      startDate: education.startDate,
      endDate: education.endDate,
      employeeId: employeeId,
      degreeId: education.degreeId,
      id: education.id,
      documents: education.documents?.map((el) => el.url),
    })) as TEducationFormData[];

    submit({ education: formData }, removedIds);
  };

  const handleRemove = (id: ID) => {
    setRemovedIds((prewIds: ID[]) => [...prewIds, id]);
  };

  return (
    <div className={styles.form_container}>
      <Text
        size="text-20"
        weight={500}
        className={styles.form_container__heading}
        as="h2"
      >
        Образование
      </Text>
      <Form
        id={formId}
        initialValues={education}
        onSubmit={onSubmit}
        validation={educationHistorySchema}
      >
        <EducationFormContent handleRemove={handleRemove} />
      </Form>
    </div>
  );
};

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';
import { AnyType } from 'utils/types';

import { Toast } from '../toast';

import { postBansheeRefresh } from './banshee';

interface IHttpExceptionError {
  message: string;
  field: string | null;
}

interface IHttpException {
  status: number;
  code: string;
  errors: IHttpExceptionError[];
}

const EXCLUDE_PATHS = ['/hr/banshee/logout'];

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

function createAxiosInterceptorResponse() {
  const responseInterceptors = $host.interceptors.response.use(
    (data) => {
      if (data.config.url && EXCLUDE_PATHS.includes(data.config.url)) {
        return data;
      }

      if (data.config.method === 'post') {
        toast.custom(<Toast type="success" text="Данные успешно созданы" />);
      }

      if (data.config.method === 'delete') {
        toast.custom(<Toast type="success" text="Данные успешно удалены" />);
      }

      if (data.config.method === 'patch' || data.config.method === 'put') {
        toast.custom(<Toast type="success" text="Данные успешно обновлены" />);
      }

      return data;
    },
    async (
      errorResponse: AxiosError<IHttpException>
    ): Promise<AxiosError<IHttpException>> => {
      const data = errorResponse.response?.data;

      if (data && Array.isArray(data?.errors)) {
        if (data.status === 401) {
          $host.interceptors.response.eject(responseInterceptors);

          postBansheeRefresh()
            .then(() => {
              window.location.href = '/';

              return $host(errorResponse.config as AxiosRequestConfig<AnyType>);
            })
            .catch(() => {
              localStorage.removeItem('loggedIn');

              window.location.href = '/auth';
            })
            .finally(createAxiosInterceptorResponse);
        }

        const formErrors = data.errors.reduce((errors, error) => {
          if (error.field) {
            return {
              ...errors,
              [error.field]: error.message,
            };
          } else {
            toast.custom(<Toast type="error" text={error.message} />);

            return errors;
          }
        }, {});

        return Promise.reject({
          formErrors,
          status: errorResponse.response?.data.status,
          message: errorResponse.response?.data.errors[0].message,
        });
      }

      return Promise.reject(errorResponse.response?.data);
    }
  );
}

createAxiosInterceptorResponse();

export { $host };

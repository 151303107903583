import { IRevisionData } from 'components/api/types/salary';

import { requests } from '../../api';

export const actions = {
  revision: async (data: { revision: IRevisionData }) => {
    data.revision &&
      (await requests.postRevisionData(
        data.revision.employeeId,
        data.revision
      ));
  },
};

import { DeleteBinLine, Edit2Fill } from '../icon';
import { Text } from '../text';

import { TTableColumn } from './column';
import { getNestedProperty } from './utils';

import styles from './table.module.scss';

export interface ICellProps<T> {
  column: TTableColumn<T>;
  data: T;
}

export interface ITextCellProps {
  text: string;
}

export interface IActionCellProps<T> {
  data: T;
  onEdit?: (data: T) => void;
  onDelete?: (id: number) => void;
}

export interface ILinkCellProps {
  href: string;
  text?: string;
}

const ActionCell = <T extends Record<string, any>>({
  onEdit,
  onDelete,
  data,
}: IActionCellProps<T>) => {
  const handleEdit = () => {
    onEdit?.(data);
  };

  const handleDelete = () => {
    onDelete?.(data.id);
  };

  return (
    <div className={styles.table_actions}>
      <Edit2Fill onClick={handleEdit} />
      <DeleteBinLine onClick={handleDelete} />
    </div>
  );
};

export const Cell = <T extends Record<string, any>>({
  column,
  data,
}: ICellProps<T>) => {
  if (column.render) {
    return <>{column.render(data)}</>;
  }

  if (!column.dataIndex) {
    return <ActionCell data={data} {...column.actions} />;
  }

  return (
    <Text size="text-14" weight={400} as="span">
      {getNestedProperty(data, column.dataIndex)}
    </Text>
  );
};

import { FC, useMemo } from 'react';
import { useRouteLoaderData } from 'react-router-dom';

import { ID, TSODate } from 'components/api/types/common';
import { IExitInterviewAnswer } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormSelect } from 'design/form/form-select';
import { FormTextarea } from 'design/form/form-textarea';
import { IModalOverride } from 'design/modal';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../../api';
import { exitInterviewShema } from '../../../../utils/validation';
import { IEmployeeDetailsLoader } from '../../loaders';

import styles from './modals.module.scss';

export interface IExitInterviewModalProps extends IModalOverride {
  formId: string;
}

export interface IExitInterviewForm {
  reason: ID;
  dismissalDate: TSODate;
  question: Record<
    IExitInterviewAnswer['question'],
    IExitInterviewAnswer['answer']
  >;
}

export const EmployeeExitInterviewModal: FC<IExitInterviewModalProps> = ({
  formId,
  setLoading,
  modalClose,
  setConfirm,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const { exitInterviewQuestions, employee } = useRouteLoaderData(
    'employee-details'
  ) as IEmployeeDetailsLoader;

  const initialData = useMemo(
    () => ({
      reason: employee.dismissal?.reasonId,
      dismissalDate: employee.dismissal?.date,
      question: employee.dismissal?.exitInterviewAnswers.reduce(
        (question, answers) => ({
          ...question,
          [answers.question]: answers.answer,
        }),
        {} as IExitInterviewForm['question']
      ),
    }),
    [employee.dismissal]
  );

  const questions = useMemo(
    () =>
      employee.dismissal?.id && employee.dismissal.exitInterviewAnswers.length
        ? employee.dismissal.exitInterviewAnswers.map((answer) => ({
            id: answer.id,
            question: answer.question,
          }))
        : exitInterviewQuestions,
    [exitInterviewQuestions, employee.dismissal]
  );

  const handleSubmitApproved = (values: IExitInterviewForm) => {
    const dismissal = {
      reasonId: values.reason,
      employeeId: employee.id,
      date: values.dismissalDate,
    };

    const exitInterviewAnswers = Object.entries(values.question)
      .map(([question, answer]) => ({
        question,
        answer,
        ...(employee.dismissal?.id
          ? {
              dismissalId: employee.dismissal?.id,
              id: employee.dismissal.exitInterviewAnswers.find(
                (answer) => answer.question === question
              )?.id,
            }
          : {}),
      }))
      .filter((answer) => answer.answer);

    submit({
      entity: 'exitInterview',
      data: {
        employeeId: employee.id,
        dismissalId: employee.dismissal?.id,
        dismissal,
        exitInterviewAnswers,
      },
    });
  };

  const onSubmit = (values: IExitInterviewForm) => {
    if (employee.dismissal?.id) {
      handleSubmitApproved(values);
    } else {
      setConfirm?.(true, () => {
        handleSubmitApproved(values);
      });
    }
  };

  return (
    <div className={styles.exit_interview}>
      <Form
        id={formId}
        validation={exitInterviewShema}
        initialValues={initialData}
        onSubmit={onSubmit}
      >
        <>
          <div className={styles.exit_interview__heading}>
            <FormDatepicker name="dismissalDate" label="Дата увольнения" />
            <FormSelect
              name="reason"
              label="Причина увольнения"
              options={async () => {
                const options = await queryClient.fetchQuery({
                  queryKey: ['dismissal-reasons'],
                  queryFn: requests.getDismissalReasons,
                });

                return options.data.map((option) => ({
                  value: option.id,
                  label: option.reason,
                }));
              }}
            />
          </div>
          <div className={styles.exit_interview__questionnaire}>
            <Text
              className={styles.exit_interview__questionnaire__title}
              size="text-16"
              weight={500}
            >
              Анкета
            </Text>
            {questions.map(({ id, question }) => (
              <FormTextarea
                key={id}
                name={`question.${question}`}
                label={question}
              />
            ))}
          </div>
        </>
      </Form>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useFetcher, SubmitOptions, useLocation } from 'react-router-dom';

type TSubmitData<T> = SubmitOptions & {
  entity: string;
  data: T;
};

export const useSubmit = (
  callback?: () => void,
  loadingCallback?: (loading: boolean) => void
) => {
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();

  const fetcher = useFetcher();

  const submit = <T>({
    data,
    entity,
    method = 'post',
    action = location.pathname,
  }: TSubmitData<T>) => {
    setLoading(true);
    loadingCallback?.(true);

    fetcher.submit(
      JSON.stringify({
        entity,
        data,
      }),
      {
        method,
        action,
        encType: 'application/json',
      }
    );
  };

  useEffect(() => {
    if (fetcher.data?.ok) {
      callback?.();
    }

    setLoading?.(false);
    loadingCallback?.(false);
  }, [fetcher, callback, setLoading, loadingCallback]);

  return {
    loading: isLoading,
    submit,
  };
};

import { FC, ChangeEvent } from 'react';

import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { Textarea, TTextareaProps } from '../textarea';

import styles from './form.module.scss';

export interface IFormTextareaProps {
  name: string;
  label: string;
  className?: string;
  textareaContainerClassName?: string;
}

export const FormTextarea: FC<
  Pick<TTextareaProps, 'textareaClassName' | 'placeholder'> & IFormTextareaProps
> = ({
  name,
  label,
  placeholder,
  className,
  textareaContainerClassName,
  textareaClassName,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, name, onBlur, value },
        fieldState: { error },
      }) => {
        const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
          onChange(evt.target.value);
        };

        const handleBlur = () => {
          onBlur();
        };

        return (
          <div className={cn(styles.container, className)}>
            <Textarea
              label={label}
              name={name}
              className={textareaContainerClassName}
              textareaClassName={textareaClassName}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error?.message}
              value={value}
            />
          </div>
        );
      }}
    />
  );
};

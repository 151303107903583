import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import {
  IEmployee,
  IEducation,
  TEducationFormData,
} from 'components/api/types/employee';
import { Form } from 'design/form';
import { IModalOverride } from 'design/modal';
import { useSubmit } from 'utils/useSubmit';

import { educationHistorySchema } from '../../../../utils/validation';
import {
  EducationFormContent,
  INITIAL_VALUE_EDUCATION,
} from '../../../new-employee/forms/education';

type TEducationInformationData = Pick<IEmployee, 'education'>;

export type TEducationInformationModalProps = TEducationInformationData &
  Pick<IEducation, 'employeeId'> & {
    formId: string;
  } & IModalOverride;

export const EducationInformationModal: FC<TEducationInformationModalProps> = ({
  formId,
  employeeId,
  education,
  setConfirm,
  modalClose,
  setLoading,
}) => {
  const [removedIds, setRemovedIds] = useState<ID[]>([]);

  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (values: TEducationInformationData) => {
    const formData = values.education?.map((education) => ({
      educationalInstitution: education.educationalInstitution,
      direction: education.direction,
      startDate: education.startDate,
      endDate: education.endDate,
      employeeId: employeeId,
      degreeId: education.degreeId,
      id: education.id,
      documents: education.documents?.map((el) => el.url),
    })) as TEducationFormData[];

    submit({
      entity: 'education',
      data: {
        employeeId,
        education: formData,
        removedIds,
      },
    });
  };

  const handleRemove = (id: ID) => {
    setRemovedIds((prewIds: ID[]) => [...prewIds, id]);
  };

  return (
    <Form
      id={formId}
      initialValues={{
        education: education.length ? education : [INITIAL_VALUE_EDUCATION],
      }}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={educationHistorySchema}
    >
      <EducationFormContent handleRemove={handleRemove} />
    </Form>
  );
};

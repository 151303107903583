import { FC } from 'react';
import { Link as RRDLink } from 'react-router-dom';

import cn from 'clsx';

import styles from './link.module.scss';

export interface ILinkProps {
  children: JSX.Element;
  href: string;
  className?: string;
  icon?: React.ElementType;
  placement?: 'prefix' | 'suffix';
  target?: '_blank' | '_parent' | '_self' | '_top';
}

export const Link: FC<ILinkProps> = ({
  children,
  href,
  className,
  icon: Icon,
  placement,
  target,
}) => (
  <RRDLink to={href} target={target} className={cn(styles.link, className)}>
    {!!Icon && placement === 'prefix' && <Icon />}
    {children}
    {!!Icon && placement === 'suffix' && <Icon />}
  </RRDLink>
);

import { FC, useEffect, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IEmployee } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Divider } from 'design/divider';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { Spinner } from 'design/spinner';
import { Text } from 'design/text';
import { fullNameEmployee } from 'utils';
import {
  datePlaceholder,
  emailPlaceholder,
  namePlaceholder,
  phonePlaceholder,
} from 'utils/placeholder-text';

import { requests } from '../../../api';
import {
  ENGLISH_LEVEL_OPTIONS,
  GENDER_OPTIONS,
} from '../../../utils/constants';
import { commonInfoSchema } from '../../../utils/validation';

import styles from './forms.module.scss';

export type TStepCommonData = Partial<
  Pick<
    IEmployee,
    | 'gender'
    | 'officeId'
    | 'birthDate'
    | 'positionId'
    | 'englishLevel'
    | 'phone'
    | 'email'
    | 'personalEmail'
  > & { name: string }
>;

export interface ICommonDataFormProps {
  formId: string;
  submit: (values: TStepCommonData) => void;
  employeeId?: ID;
}

interface ICommonForm {
  variant: 'basic' | 'creation' | 'personal';
}

export const CommonForm: FC<ICommonForm> = ({ variant }) => (
  <div className={styles.form_container__form}>
    {variant === 'creation' && (
      <div className={styles.form_container__form__section_heading}>
        <Text as="h3" size="text-16" weight={500} className={styles.text}>
          Личные данные
        </Text>
        <Divider />
      </div>
    )}
    <div className={styles.form_container__form__section}>
      {variant !== 'personal' && (
        <FormInput
          required
          name="name"
          label="ФИО"
          placeholder={namePlaceholder}
        />
      )}
      {variant !== 'basic' && (
        <FormSelect
          required
          name="gender"
          label="Пол"
          options={GENDER_OPTIONS}
        />
      )}
      {variant !== 'personal' && (
        <>
          <FormSelect
            name="officeId"
            label="Город"
            options={async () => {
              const options = await queryClient.fetchQuery({
                queryKey: ['offices'],
                queryFn: requests.getOffices,
              });

              return options.data.map((option) => ({
                value: option.id,
                label: option.name,
              }));
            }}
          />
          <FormSelect
            name="positionId"
            label="Должность"
            options={async () => {
              const options = await queryClient.fetchQuery({
                queryKey: ['positions'],
                queryFn: requests.getPositions,
              });

              return options.data.map((option) => ({
                value: option.id,
                label: option.nameRu,
              }));
            }}
          />
        </>
      )}
      {variant !== 'basic' && (
        <>
          <FormDatepicker
            name="birthDate"
            label="Дата рождения"
            placeholder={datePlaceholder}
          />
          <FormSelect
            name="englishLevel"
            label="Уровень английского языка"
            options={ENGLISH_LEVEL_OPTIONS}
          />
        </>
      )}
    </div>
    {variant !== 'basic' && (
      <>
        <div className={styles.form_container__form__section_heading}>
          <Text as="h3" size="text-16" weight={500} className={styles.text}>
            Контактные данные
          </Text>
          <Divider />
        </div>
        <div className={styles.form_container__form__section}>
          <FormInput
            name="phone"
            label="Телефон"
            placeholder={phonePlaceholder}
          />
          <FormInput
            name="email"
            label="Почта"
            placeholder={emailPlaceholder}
          />
          <FormInput
            name="personalEmail"
            label="Персональная почта"
            placeholder={emailPlaceholder}
          />
        </div>
      </>
    )}
  </div>
);

export const CommonDataForm: FC<ICommonDataFormProps> = ({
  formId,
  submit,
  employeeId,
}) => {
  const [data, setData] = useState<TStepCommonData>({});

  const [isLoading, setLoading] = useState(false);

  const getEmployeeById = async (id: ID) => {
    setLoading(true);

    try {
      const { data } = await queryClient.fetchQuery(['employee'], () =>
        requests.getEmployeeDataById(id)
      );

      const name = fullNameEmployee(
        data.firstName,
        data.lastName,
        data.middleName
      );

      setData({
        ...data,
        name: name,
      });
    } catch (e) {
      console.log('Failed to fetch employee common data :>> ', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getEmployeeById(employeeId);
    }
  }, [employeeId]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="gray-500" />
      </div>
    );
  }

  return (
    <div className={styles.form_container}>
      <Text
        size="text-20"
        weight={500}
        className={styles.form_container__heading}
        as="h2"
      >
        Общие сведения
      </Text>
      <Form
        id={formId}
        initialValues={data}
        onSubmit={submit}
        validation={commonInfoSchema}
      >
        <CommonForm variant="creation" />
      </Form>
    </div>
  );
};

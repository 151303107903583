import { FC, useEffect, useRef, useState } from 'react';

import { IMissingDay } from 'components/api/types/employee';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import { getWordDayForm } from '../../../../../../utils';

import styles from './tooltip.module.scss';

interface ITooltipProps {
  day: number;
  top: number;
  left: number;
  missingDay: IMissingDay;
}

const MISSING_BLOCK_HEIGHT = 27;

export const Tooltip: FC<ITooltipProps> = ({ missingDay, day, top, left }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const blockElement = useRef<HTMLDivElement | null>(null);
  const rootElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    setPosition({ top, left });
  }, [top, left]);

  useEffect(() => {
    rootElement.current = document.getElementById('schedule-table');

    const rootPosition = rootElement.current?.getBoundingClientRect();
    const blockPosition = blockElement.current?.getBoundingClientRect();

    if (rootPosition && blockPosition) {
      const { right: rootRight } = rootPosition;
      const { right: blockRight } = blockPosition;

      const differenceRight = Math.ceil(rootRight - blockRight);

      if (differenceRight < 0) {
        setPosition((prev) => ({
          ...prev,
          left: prev.left + differenceRight,
        }));
      }
    }
  }, [position]);

  return (
    <div
      className={styles.container}
      ref={blockElement}
      style={{
        top: position.top - MISSING_BLOCK_HEIGHT,
        left: position.left,
      }}
    >
      <Text size="text-14" weight={400} as="span">
        {`${formatDate(missingDay.startDate)}
        - 
        ${formatDate(missingDay.endDate)} 
        (${day} ${getWordDayForm(day)} 
        - 
        ${missingDay.type} 
        - 
        ${missingDay.status})`}
      </Text>
    </div>
  );
};

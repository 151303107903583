import { ISalaryPaymentDayConfig } from 'components/api/types/salary';

import { requests } from '../../api';

export interface ILoaderData {
  salaryPaymentDays: ISalaryPaymentDayConfig[];
}

export const loaders = () => [
  {
    name: 'salaryPaymentDays',
    fn: requests.getSalaryPaymentDaysConfigs,
  },
];

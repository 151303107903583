import { FC, useLayoutEffect, useRef, useState } from 'react';

import cn from 'clsx';

import { Text } from '../text';

import styles from './tabs.module.scss';

export interface ITab {
  id: string;
  title: string;
}

export interface ITabsProps {
  tabs: ITab[];
  onChange: (id: string) => void;
  active?: string | null;
}

export const Tabs: FC<ITabsProps> = ({ tabs, onChange, active }) => {
  const [activeTab, setActiveTab] = useState(active ?? tabs[0].id);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [gliderPosition, setGliderPosition] = useState(0);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    active && setActiveTab(active);
  }, [active]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const $activeTab = (containerRef.current as HTMLDivElement).querySelector(
        `label[id="${activeTab}"]`
      ) as HTMLLabelElement;

      const rect = $activeTab?.getBoundingClientRect();

      if (rect && $activeTab) {
        setActiveTabWidth(rect.width);
        setGliderPosition($activeTab.offsetLeft);
      }
    }
  }, [activeTab, containerRef]);

  const handleChange = (id: string) => {
    setActiveTab(id);
    onChange(id);
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <div key={tab.id}>
            <input
              type="radio"
              id={tab.id}
              name="tabs"
              checked={tab.id === activeTab}
              onChange={() => handleChange(tab.id)}
            />
            <label
              id={tab.id}
              className={cn(styles.tab, {
                [styles.active]: activeTab === tab.id,
              })}
              htmlFor={tab.id}
            >
              <Text size="text-14" weight={activeTab === tab.id ? 500 : 400}>
                {tab.title}
              </Text>
            </label>
          </div>
        ))}
        <span
          className={styles.glider}
          style={{
            transform: `translateX(${gliderPosition}px)`,
            width: `${activeTabWidth}px`,
          }}
        />
      </div>
    </div>
  );
};

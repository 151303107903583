import { useMemo } from 'react';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { EModule } from 'components/api/types/enums';
import { useUserContext } from 'components/context/context-user';
import { RootBoundary } from 'components/error-boundary';
import { PageAuth, PageCallback } from 'components/page-auth';
import { PageLayout } from 'components/page-layout';
import { FinanceRoutes } from 'modules/finance/routes';
import { HrRoutes } from 'modules/human-resources/routes';
import { getModulesAccess } from 'utils';

// ToDo: revork routes. Monolith should only configure hight level routes.

export const AppRoutes = () => {
  const { user, appLoading } = useUserContext();

  const children = useMemo(
    () => ({
      [EModule.hr]: HrRoutes(user),
      [EModule.finance]: FinanceRoutes(user),
    }),
    [user]
  );

  const availableRoutes = useMemo(
    () =>
      (!appLoading &&
        user &&
        getModulesAccess(user.permissions).reduce(
          (acc: RouteObject[], module: EModule) => [
            ...acc,
            ...children[module],
          ],
          []
        )) ||
      undefined,
    [appLoading, user, children]
  );

  const routes = [
    {
      path: '/',
      element: <PageLayout />,
      errorElement: <RootBoundary />,
      children: availableRoutes,
    },
    {
      path: '/auth',
      element: <PageAuth />,
    },
    {
      path: '/callback',
      element: <PageCallback />,
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

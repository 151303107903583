import { FC } from 'react';

import { Text } from 'design/text';

import { calendarTableColumns } from '../../constants';

import { Week } from './week';

import styles from '../calendar-table.module.scss';

interface IHeaderTableProps {
  year: number;
}

export const HeaderTable: FC<IHeaderTableProps> = ({ year }) => (
  <thead className={styles.table__header}>
    <tr className={styles.table__header__cell}>
      {calendarTableColumns.map((column) => (
        <th
          className={styles.table__header__cell__column}
          key={column.dataIndex}
        >
          <div className={styles.table__header__cell__column__title}>
            <Text size="text-12" weight={500} as="span">
              {column.title}
            </Text>
          </div>
          <Week column={column.dataIndex} year={year} />
        </th>
      ))}
    </tr>
  </thead>
);

import { FC, useLayoutEffect, useState, useRef } from 'react';

import cn from 'clsx';
import { IOption } from 'design/select';

import { Text } from '../text';

import styles from './button-switch.module.scss';

export interface IButtonSwitchProps {
  options: IOption[];
  onChange?: (value: IOption) => void;
  active?: IOption['value'];
}

export const ButtonSwitch: FC<IButtonSwitchProps> = ({
  options,
  onChange,
  active,
}) => {
  const activeOption =
    options.find((option) => option.value === active) || options[0];

  const [activeTab, setActiveTab] = useState(activeOption.value);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [gliderPosition, setGliderPosition] = useState(0);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    active && setActiveTab(active);
  }, [active]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const $activeTab = (containerRef.current as HTMLDivElement).querySelector(
        `label[id="${activeTab}"]`
      ) as HTMLLabelElement;

      const rect = $activeTab?.getBoundingClientRect();

      if (rect) {
        setActiveTabWidth(rect.width);
        setGliderPosition($activeTab.offsetLeft);
      }
    }
  }, [activeTab, containerRef]);

  const handleChange = (option: IOption) => {
    setActiveTab(option.value);
    onChange?.(option);
  };

  return (
    <div className={styles.switch} ref={containerRef}>
      {options.map((option) => (
        <div key={option.value}>
          <input
            type="radio"
            id={`${option.value}`}
            name="tabs"
            checked={option.value === activeTab}
            onChange={() => handleChange(option)}
          />
          <label
            id={`${option.value}`}
            className={cn(styles.switch__tab, {
              [styles.switch__tab_active]: activeTab === option.value,
            })}
            htmlFor={`${option.value}`}
          >
            <Text
              size="text-14"
              weight={500}
              className={styles.switch__tab__label}
            >
              {option.label}
            </Text>
          </label>
        </div>
      ))}
      <span
        className={styles.switch__glider}
        style={{
          transform: `translateX(${gliderPosition}px)`,
          width: `${activeTabWidth}px`,
        }}
      />
    </div>
  );
};

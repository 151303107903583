import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IPosition } from 'components/api/types/dictionary-types';
import { DeleteBinLine, Edit2Fill } from 'design/icon';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { PositionForm } from '../forms/position';

import styles from '../dictionary.module.scss';

interface IPositionListProps {
  positions: IPosition[];
}

export const PositionList: FC<IPositionListProps> = ({ positions }) => {
  const [positionId, setPositionId] = useState<ID>();
  const { submit } = useSubmit();

  const handleDelete = (id: ID) => {
    submit({
      entity: 'position',
      data: {
        removedId: id,
      },
    });
  };

  const handleEdit = (id: ID) => {
    setPositionId(id);
  };

  if (!positions.length) {
    return (
      <div className={styles.no_data}>
        <Text size="text-16" weight={500}>
          Нет данных
        </Text>
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {positions.map(({ id, nameEn, nameRu, code }) => (
        <li key={id}>
          <div className={styles.list__container}>
            {positionId !== id ? (
              <>
                <div className={styles.list__container__item}>
                  <Text size="text-16">{`${nameEn} (русское наименование: ${nameRu}, код: ${code})`}</Text>
                </div>
                <div className={styles.list__container__actions}>
                  <Edit2Fill onClick={() => handleEdit(id)} />
                  <DeleteBinLine onClick={() => handleDelete(id)} />
                </div>
              </>
            ) : (
              <PositionForm
                id={id}
                nameEn={nameEn}
                nameRu={nameRu}
                code={code}
                setPositionId={setPositionId}
              />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

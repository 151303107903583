import { ID } from 'components/api/types/common';
import {
  IDepartment,
  IDismissalReason,
  IEducationDegree,
  IOffice,
  IPosition,
} from 'components/api/types/dictionary-types';
import { IExitInterviewQuestion } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  position: async (data: {
    removedId: ID | null;
    positionId: ID | null;
    position: IPosition;
  }) => {
    data.removedId && (await requests.deletePositionData(data.removedId));

    data.positionId
      ? await requests.patchPositionData(data.positionId, data.position)
      : await requests.postPositionData(data.position);

    await queryClient.invalidateQueries({
      queryKey: ['position'],
    });
  },
  office: async (data: {
    removedId: ID | null;
    officeId: ID | null;
    office: IOffice;
  }) => {
    data.removedId && (await requests.deleteOfficeData(data.removedId));

    data.officeId
      ? await requests.patchOfficeData(data.officeId, data.office)
      : await requests.postOfficeData(data.office);

    await queryClient.invalidateQueries({
      queryKey: ['office'],
    });
  },
  educationDegree: async (data: {
    removedId: ID | null;
    educationDegreeId: ID | null;
    educationDegree: IEducationDegree;
  }) => {
    data.removedId &&
      (await requests.deleteEducationDegreeData(data.removedId));

    data.educationDegreeId &&
      (await requests.patchEducationDegreeData(
        data.educationDegreeId,
        data.educationDegree
      ));

    data.educationDegree &&
      !data.educationDegreeId &&
      (await requests.postEducationDegreeData(data.educationDegree));

    await queryClient.invalidateQueries({
      queryKey: ['educationDegree'],
    });
  },
  exitInterviewQuestion: async (data: {
    removedId: ID | null;
    idInterview: ID | null;
    exitInterviewQuestion: IExitInterviewQuestion;
  }) => {
    data.removedId &&
      (await requests.deleteExitInterviewQuestionData(data.removedId));

    data.idInterview &&
      (await requests.patchExitInterviewQuestionData(
        data.idInterview,
        data.exitInterviewQuestion
      ));

    data.exitInterviewQuestion &&
      !data.idInterview &&
      (await requests.postExitInterviewQuestionData(
        data.exitInterviewQuestion
      ));

    await queryClient.invalidateQueries({
      queryKey: ['exitInterviewQuestion'],
    });
  },
  dismissalReason: async (data: {
    removedId: ID | null;
    dismissalReasonId: ID | null;
    dismissalReason: IDismissalReason;
  }) => {
    data.removedId &&
      (await requests.deleteDismissalReasonData(data.removedId));

    data.dismissalReasonId &&
      (await requests.patchDismissalReasonData(
        data.dismissalReasonId,
        data.dismissalReason
      ));

    data.dismissalReason &&
      !data.dismissalReasonId &&
      (await requests.postDismissalReasonData(data.dismissalReason));

    await queryClient.invalidateQueries({
      queryKey: ['dismissalReason'],
    });
  },
  department: async (data: {
    removedId: ID | null;
    departmentId: ID | null;
    department: IDepartment;
  }) => {
    data.removedId && (await requests.deleteDepartmentData(data.removedId));

    data.departmentId &&
      (await requests.patchDepartmentData(data.departmentId, data.department));

    data.department &&
      !data.departmentId &&
      (await requests.postDepartmentData(data.department));

    await queryClient.invalidateQueries({
      queryKey: ['department'],
    });
  },
};

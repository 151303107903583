import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IExitInterviewQuestion } from 'components/api/types/dictionary-types';
import { DeleteBinLine, Edit2Fill } from 'design/icon';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { ExitInterviewQuestionForm } from '../forms/exit-interview-question';

import styles from '../dictionary.module.scss';

interface IExitInterviewQuestionListProps {
  questions: IExitInterviewQuestion[];
}

export const ExitInterviewQuestionList: FC<IExitInterviewQuestionListProps> = ({
  questions,
}) => {
  const [questionId, setQuestionId] = useState<ID>();
  const { submit } = useSubmit();

  const handleDelete = (id: ID) => {
    submit({
      entity: 'exitInterviewQuestion',
      data: {
        removedId: id,
      },
    });
  };

  const handleEdit = (id: ID) => {
    setQuestionId(id);
  };

  if (!questions.length) {
    return (
      <div className={styles.no_data}>
        <Text size="text-16" weight={500}>
          Нет данных
        </Text>
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {questions.map(({ id, question, isActive }) => (
        <li key={id}>
          <div className={styles.list__container}>
            {questionId !== id ? (
              <>
                <div className={styles.list__container__item}>
                  <Text size="text-16">{question}</Text>
                  <Text size="text-16">
                    {isActive ? '(Активный)' : '(Не активный)'}
                  </Text>
                </div>
                <div className={styles.list__container__actions}>
                  <Edit2Fill onClick={() => handleEdit(id)} />
                  <DeleteBinLine onClick={() => handleDelete(id)} />
                </div>
              </>
            ) : (
              <ExitInterviewQuestionForm
                id={id}
                question={question}
                isActive={`${isActive}`}
                setQuestionId={setQuestionId}
              />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

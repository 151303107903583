import { FC } from 'react';

import { Button } from 'design/button';
import { Text } from 'design/text';

import styles from './page-login.module.scss';

export const PageAuth: FC = () => (
  <div className={styles.container}>
    <div className={styles.container__action}>
      <Text size="text-16" as="span">
        Для авторизации нажмите на кнопку ниже
      </Text>
      <Button
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/hr/banshee/login?redirectUri=${window.location.origin}/callback`;
        }}
        label="Войти"
      />
    </div>
  </div>
);

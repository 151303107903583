import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { INonWorkingDay } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getNonWorkingDays =
  (module: EModule) => (): Promise<AxiosResponse<INonWorkingDay[]>> =>
    $host.get(`/${module}/config/non-working-days`);

export const postNonWorkingDaysData =
  (module: EModule) =>
  (
    value: Pick<INonWorkingDay, 'date' | 'country'>
  ): Promise<AxiosResponse<INonWorkingDay>> =>
    $host.post(`/${module}/config/non-working-days`, { ...value });

export const patchNonWorkingDaysData =
  (module: EModule) =>
  (
    id: ID,
    value: Pick<INonWorkingDay, 'date' | 'country'>
  ): Promise<AxiosResponse<INonWorkingDay>> =>
    $host.patch(`/${module}/config/non-working-days/${id}`, { ...value });

export const deleteNonWorkingDaysData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/non-working-days/${id}`);

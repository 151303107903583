import { FC } from 'react';

import { IEmployee } from 'components/api/types/employee';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import styles from '../employee.module.scss';

export type TPersonalDataProps = Pick<
  IEmployee,
  'birthDate' | 'gender' | 'email' | 'phone' | 'englishLevel' | 'personalEmail'
>;

export const Personal: FC<TPersonalDataProps> = ({
  birthDate,
  gender,
  email,
  phone,
  englishLevel,
  personalEmail,
}) => (
  <div className={styles.cardContent}>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Дата рождения
      </Text>
      <Text className={styles.value} size="text-14">
        {birthDate ? formatDate(birthDate) : ''}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Пол
      </Text>
      <Text className={styles.value} size="text-14">
        {gender}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Почта
      </Text>
      <Text className={styles.value} size="text-14">
        {email}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Персональная почта
      </Text>
      <Text className={styles.value} size="text-14">
        {personalEmail}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Телефон
      </Text>
      <Text className={styles.value} size="text-14">
        {phone}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Уровень английского языка
      </Text>
      <Text className={styles.value} size="text-14">
        {englishLevel}
      </Text>
    </div>
  </div>
);

import { useLoaderData } from 'react-router-dom';

import { IBankAccount } from 'components/api/types/bank-account';
import { ICurrency } from 'components/api/types/dictionary-types';
import { ISalaryRevisionRequest } from 'components/api/types/salary';
import { ISalaryTransaction } from 'components/api/types/transaction';
import { Card } from 'design/card';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import styles from '../employee-salary.module.scss';

export const PaymentHistory = () => {
  const { paymentHistory, bankAccounts } = useLoaderData() as {
    revisionRequests: ISalaryRevisionRequest[];
    bankAccounts: IBankAccount[];
    currencies: ICurrency[];
    paymentHistory: ISalaryTransaction[];
  };

  const columns: TTableColumn<ISalaryTransaction>[] = [
    {
      title: 'Тип оплаты',
      dataIndex: ['paymentType'],
    },
    {
      title: 'Статус',
      dataIndex: ['status'],
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccountId'],
      render: (data) => {
        const bankAccount = bankAccounts.find(
          (bankAccount) => bankAccount.id === data.bankAccountId
        );

        return (
          <Text size="text-14" as="span">
            {bankAccount ? bankAccount.bankName : ''}
          </Text>
        );
      },
    },
    {
      title: 'Сумма',
      dataIndex: ['amount'],
    },
    {
      title: 'Дата выплаты',
      dataIndex: ['paymentDate'],
      render: ({ paymentDate }) => (
        <Text size="text-14" weight={400} as="span">
          {paymentDate ? formatDate(paymentDate) : ''}
        </Text>
      ),
    },
  ];

  return (
    <div className={styles.content}>
      <Card
        bodyClassName={styles.card}
        title={
          <Text size="text-18" weight={500}>
            История выплат
          </Text>
        }
      >
        <div className={styles.content__table}>
          <Table columns={columns} data={paymentHistory} key="id" />
        </div>
      </Card>
    </div>
  );
};

import {
  FC,
  useState,
  useRef,
  ChangeEvent,
  useLayoutEffect,
  useCallback,
  useEffect,
} from 'react';

import cn from 'clsx';
import { CSSTransition } from 'react-transition-group';

import { useDropdownToggle } from '../common/hooks';
import { ArrowDown, CloseLine, SearchLine } from '../icon';
import { Input } from '../input';
import { Spinner } from '../spinner';
import { Text } from '../text';

import styles from './select.module.scss';

export interface IOption {
  label: string;
  value: string | number;
  sublabel?: string;
}

export interface ISelectProps {
  name: string;
  label: string;
  className?: string;
  options: IOption[] | (() => Promise<IOption[]>);
  size?: 'small' | 'large';
  required?: boolean;
  error?: string;
  onChange?: (option: IOption) => void;
  value?: IOption['value'];
  search?: boolean;
}

type IOptionProps = IOption & {
  handleSelect: (lavel: IOption) => void;
  selected: boolean;
};

const emptyOption = {
  label: '',
  value: '',
};

const Option: FC<IOptionProps> = ({
  label,
  value,
  sublabel,
  handleSelect,
  selected = false,
}) => {
  const onSelect = () => {
    handleSelect({ label, value, sublabel });
  };

  return (
    <div
      onClick={onSelect}
      onMouseDown={(e) => e.preventDefault()}
      className={cn(styles.select__list__option, {
        [styles.select__list__option_selected]: selected,
      })}
    >
      <Text size="text-14" as="span" weight={selected ? 500 : 400}>
        {label}
      </Text>
      {sublabel && (
        <Text size="text-12" as="span" className={styles.sublabel}>
          {sublabel}
        </Text>
      )}
    </div>
  );
};

export const Select: FC<ISelectProps> = ({
  className,
  label,
  options,
  size = 'small',
  required,
  error,
  onChange,
  value,
  name,
  search,
}) => {
  const optionsListRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [selectOptions, setSelectOptions] = useState<IOption[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isFetched, setFetched] = useState(false);

  const [initialSelectOptions, setInitialSelectOptions] = useState<IOption[]>(
    []
  );

  const { isOpen, setIsOpen, rootRef } = useDropdownToggle();

  const updateSelectedOption = useCallback(
    (options: IOption[], value?: IOption['value']) => {
      const option = options.find((option) => option.value === value);
      setSelectedOption(option);
    },
    []
  );

  useEffect(() => {
    if (!value) {
      setSelectedOption(emptyOption);
    }
  }, [value]);

  useLayoutEffect(() => {
    if (Array.isArray(options)) {
      updateSelectedOption(options, value);
      setSelectOptions(options);
      setInitialSelectOptions(options);
      setFetched(true);
    }
  }, [options, updateSelectedOption, value]);

  useLayoutEffect(() => {
    if (typeof options === 'function' && !isFetched && (isOpen || value)) {
      const fetchOptions = async () => {
        setLoading(true);

        const opts = await options();

        setLoading(false);
        updateSelectedOption(opts, value);
        setSelectOptions(opts);
        setInitialSelectOptions(opts);
        setFetched(true);
      };

      fetchOptions();
    }
  }, [isOpen, isFetched, options, updateSelectedOption, value]);

  const filterOptions = useCallback(
    (options: IOption[], value: string) =>
      options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      ),
    []
  );

  const handleToggle = () => {
    setIsOpen((v) => !v);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSelect = (option: IOption) => {
    if (search) {
      const filteredOptions = filterOptions(initialSelectOptions, option.label);

      setSelectOptions(filteredOptions);
    }

    setSelectedOption(option);
    setIsOpen(false);
    onChange?.(option);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleOpen();

    const { value: searchValue } = e.target;

    const filteredOptions = filterOptions(initialSelectOptions, searchValue);

    updateSelectedOption(initialSelectOptions, searchValue);
    setSelectOptions(filteredOptions);

    if (!searchValue) {
      onChange?.(emptyOption);
    }
  };

  const handleResetOption = () => {
    setIsOpen(false);

    setSelectedOption(emptyOption);

    setSelectOptions(initialSelectOptions);

    onChange?.(emptyOption);
  };

  return (
    <div ref={rootRef} className={cn(className, styles.select)}>
      <div
        data-testid="select"
        onClick={search ? handleOpen : handleToggle}
        className={cn({
          [styles.select__input]: !search,
          [styles.select__input_small]: size === 'small',
          [styles.select__input_large]: size === 'large',
          [styles.select__input_active]: isOpen && !search,
          [styles.select__input_error]: !!error,
          // ToDo: add better loading handler
          [styles.select__input_loading]: isLoading,
        })}
        tabIndex={0}
      >
        {search ? (
          <>
            <Input
              data-testid="searchable-select"
              name={name}
              label={label}
              placeholder={' '}
              value={selectedOption?.label}
              prefixIcon={<SearchLine />}
              suffixIcon={<div />}
              error={error}
              required={required}
              onChange={handleSearchChange}
            />
            {selectedOption?.label && (
              <CloseLine
                className={styles.reset_icon}
                onClick={handleResetOption}
              />
            )}
          </>
        ) : (
          <>
            <label
              htmlFor=""
              className={cn(styles.select__input__label, {
                [styles.select__input__label_red]: !!error,
                [styles.select__input__label_filled]:
                  isOpen || !!selectedOption?.label,
              })}
            >
              <Text size="text-14" weight={500} as="span">
                {label}
              </Text>
              {required && (
                <Text
                  size="text-14"
                  weight={500}
                  as="span"
                  className={cn(styles.select__input__label_required, {
                    [styles.select__input__label_red]:
                      !!error || isOpen || !!selectedOption?.label,
                  })}
                >
                  *
                </Text>
              )}
            </label>
            <Text
              size="text-14"
              weight={400}
              as="span"
              className={cn(styles.select__input__value, {
                [styles.select__input__value_visible]: !!selectedOption?.label,
                [styles.select__input__value_placeholder]:
                  !selectedOption?.label && isOpen,
              })}
            >
              {selectedOption?.label ?? 'Выбрать'}
            </Text>
            <div className={styles.select__input__arrow}>
              <ArrowDown />
            </div>
          </>
        )}
      </div>
      {!!selectedOption?.label && !!selectedOption.sublabel && (
        <div
          className={cn(styles.select__content, {
            [styles.select__content_search]: search,
          })}
        >
          <Text
            size="text-14"
            as="span"
            className={cn(styles.select__content_value)}
          >
            {selectedOption.label}
          </Text>
          <Text size="text-12" as="span" className={styles.sublabel}>
            {selectedOption.sublabel}
          </Text>
        </div>
      )}
      {!!error && !search && (
        <Text size="text-12" as="span" className={styles.select__error}>
          {error}
        </Text>
      )}
      <CSSTransition
        nodeRef={optionsListRef}
        in={isOpen}
        timeout={0}
        unmountOnExit
        classNames={{
          enterActive: styles.enter_active,
          enterDone: styles.enter_done,
          exit: styles.exit,
          exitActive: styles.exit_active,
        }}
      >
        {isLoading ? (
          <div
            className={cn(styles.select__list, styles.select__list_loading, {
              [styles.select__list_open]: isOpen,
            })}
          >
            <Spinner color="gray-500" type="small" />
          </div>
        ) : (
          <div
            data-testid="option"
            ref={optionsListRef}
            className={cn(styles.select__list, {
              [styles.select__list_empty]:
                isOpen && isFetched && !selectOptions.length,
              [styles.select__list_open]: isOpen,
            })}
          >
            {isFetched && (
              <>
                {selectOptions.length ? (
                  selectOptions.map((option) => (
                    <Option
                      key={option.value}
                      label={option.label}
                      value={option.value}
                      sublabel={option?.sublabel}
                      selected={option.value === selectedOption?.value}
                      handleSelect={handleSelect}
                    />
                  ))
                ) : (
                  <Text size="text-14" as="span" weight={500}>
                    Нет данных
                  </Text>
                )}
              </>
            )}
          </div>
        )}
      </CSSTransition>
    </div>
  );
};

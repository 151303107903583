import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getBansheeUserInfo, postBansheeLogout } from 'components/api/banshee';
import { IUserInfo } from 'components/api/types/common';
import { Spinner } from 'design/spinner';

import styles from './context-user.module.scss';

interface IUserInfoContext {
  user?: IUserInfo;
  appLoading?: boolean;
  setUser: (value: IUserInfo) => void;
  setLoading: (value: boolean) => void;
}

export const UserInfoContext = createContext<IUserInfoContext>({
  user: undefined,
  setUser: () => undefined,
  setLoading: () => undefined,
});

export const UserInfoProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUserInfo | undefined>();
  const [appLoading, setAppLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn');

    const getUserInfo = async () => {
      try {
        const { data } = await getBansheeUserInfo();

        setUser(data);
      } catch (error) {
        console.log('Failing get user info :>> ', error);
      } finally {
        setLoading(false);
      }
    };

    const logout = async () => {
      try {
        await postBansheeLogout();

        window.location.href = '/auth';
      } catch (error) {
        console.log('Failing logout :>> ', error);
      } finally {
        setLoading(false);
      }
    };

    if (
      user &&
      !loggedIn &&
      window.location.pathname !== '/auth' &&
      window.location.pathname !== '/callback'
    ) {
      logout();
    }

    if (
      !user &&
      loggedIn &&
      window.location.pathname !== '/auth' &&
      window.location.pathname !== '/callback'
    ) {
      getUserInfo();
    } else {
      setLoading(false);
    }

    setAppLoading(false);
  }, [setUser, user]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="gray-500" />
      </div>
    );
  }

  if (appLoading) return null;

  return (
    <UserInfoContext.Provider value={{ user, setUser, appLoading, setLoading }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserInfoContext);

  if (context) {
    return context;
  }

  throw new Error('useUserContext must be used within a UserInfoProvider');
};

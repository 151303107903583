import { ID } from 'components/api/types/common';
import { ISalaryRevisionRequest } from 'components/api/types/salary';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  revisionRequest: async (data: {
    employeeId: ID;
    employeeSalaryRevisionRequest: ISalaryRevisionRequest;
  }) => {
    data.employeeSalaryRevisionRequest &&
      (await requests.postSalaryRevisionRequestData(
        data.employeeId,
        data.employeeSalaryRevisionRequest
      ));

    await queryClient.invalidateQueries({
      queryKey: ['revisionRequests'],
    });
  },
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule } from '../types/enums';
import {
  IExpensesCategories,
  INewExpensesCategories,
} from '../types/expenses-categories';

export const getExpensesCategories =
  (module: EModule) =>
  (flat?: boolean): Promise<AxiosResponse<IExpensesCategories[]>> =>
    $host.get(`/${module}/config/expenses-categories`, { params: { flat } });

export const getExpensesCategoryById =
  (module: EModule) =>
  (categoryId: ID): Promise<AxiosResponse<IExpensesCategories>> =>
    $host.get(`/${module}/config/expenses-categories`, {
      params: { categoryId },
    });

export const postExpensesCategory =
  (module: EModule) =>
  (
    values: INewExpensesCategories
  ): Promise<AxiosResponse<IExpensesCategories>> =>
    $host.post(`/${module}/config/expenses-categories`, { ...values });

export const patchExpensesCategoryById =
  (module: EModule) =>
  (
    id: ID,
    values: INewExpensesCategories
  ): Promise<AxiosResponse<IExpensesCategories>> =>
    $host.patch(`/${module}/config/expenses-categories/${id}`, { ...values });

export const deleteExpensesCategory =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/expenses-categories/${id}`);

import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import cn from 'clsx';
import { EModule } from 'components/api/types/enums';
import { useUserContext } from 'components/context/context-user';
import { ModalContainer } from 'design/modal';
import { getModulesAccess } from 'utils';

import { FinanceMenuItems } from '../../../modules/finance/routes';
import { HrMenuItems } from '../../../modules/human-resources/routes';
import { INavigationItem, NavigationItemPathType } from '../../../utils/types';

import { Header } from './header';
import { SideMenu } from './side-menu';

import styles from './page-layout.module.scss';

export interface IPageLayout {
  children?: ReactNode;
}

export const PageLayout: FC<IPageLayout> = ({ children }) => {
  const [isOpenSidebar, setOpenSidebar] = useState(true);

  const { user } = useUserContext();

  const location = useLocation();

  const navItems: INavigationItem[] = [
    {
      label: 'human resources',
      selectedItemPath: 'human-resources',
      path: '/human-resources/accounting?filter=active',
      key: EModule.hr,
      items: HrMenuItems(user),
    },
    {
      label: 'finance',
      selectedItemPath: 'finance',
      path: '/finance/salaries?filter=active',
      key: EModule.finance,
      items: FinanceMenuItems(user),
    },
  ];

  const filteredNavItems = user
    ? navItems.filter((item) =>
        getModulesAccess(user.permissions).some(
          (permission: EModule) => permission === item.key
        )
      )
    : [];

  const [selectedItemPath, setSelectedItemPath] =
    useState<NavigationItemPathType>(
      () => location.pathname.match(/^\/([^/]+)/)?.[1] as NavigationItemPathType
    );

  useEffect(() => {
    setSelectedItemPath(
      location.pathname.match(/^\/([^/]+)/)?.[1] as NavigationItemPathType
    );
  }, [location.pathname]);

  if (!user) {
    return <Navigate to="/auth" replace={true} />;
  }

  const selectedNavItem = navItems.find(
    (navItem) => navItem.selectedItemPath === selectedItemPath
  );

  return (
    <div className={styles.page}>
      <SideMenu navItem={selectedNavItem} setOpenSidebar={setOpenSidebar} />
      <div
        id="page-container"
        className={cn(styles.page__container, {
          [styles.page__container_left]: !isOpenSidebar,
        })}
      >
        <Header
          className={styles.page__container__header}
          navItems={filteredNavItems}
          setItemPath={setSelectedItemPath}
        />
        <div className={styles.page__container__content}>
          {children ? children : <Outlet />}
        </div>
      </div>
      <ModalContainer />
    </div>
  );
};

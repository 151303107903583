import { redirect } from 'react-router-dom';

import { IUserInfo } from 'components/api/types/common';
import { ErrorPage } from 'components/error-boundary';
import {
  BankCardLine,
  BookMarkLine,
  Coins,
  HandCoinLine,
  Settings4Line,
  Wallet3Line,
} from 'design/icon';
import { configureLoaders, configureActions, filterRoutes } from 'utils';
import { IRoute, ISideMenuItem } from 'utils/types';

import {
  BankAccountsPage,
  loaders as bankAccountsLoaders,
  actions as bankAccountsActions,
} from './pages/bank-accounts';
import {
  BankCardsPage,
  loaders as bankCardsLoaders,
  actions as bankCardsActions,
} from './pages/bank-cards';
import {
  ConfigureTaxesPage,
  loaders as configureTaxesLoader,
  actions as configureTaxesActions,
} from './pages/configure-taxes';
import {
  FinanceDictionary,
  loaders as dictionaryLoaders,
  actions as dictionaryActions,
} from './pages/dictionary';
import {
  EmployeeSalaryPage,
  loaders as employeeSalaryRoutes,
  actions as employeeSalaryActions,
} from './pages/employee-salary';
import {
  ExchangeRates,
  loaders as exchangeRatesLoaders,
  actions as exchangeRatesActions,
} from './pages/exchange-rates';
import {
  ExpensesCategoriesPage,
  loaders as expensesCategoriesLoaders,
  actions as expensesCategoriesActions,
} from './pages/expenses-categories';
import {
  ExpensesTransactionsPage,
  loaders as expensesTransactionsLoaders,
  actions as expensesTransactionsActions,
} from './pages/expenses-transactions';
import {
  MoneyBalancesPage,
  loaders as moneyBalancesLoaders,
  actions as moneyBalancesActions,
} from './pages/money-balances';
import {
  NonWorkingDays,
  loaders as nonWorkingDaysLoaders,
  actions as nonWorkingDaysActions,
} from './pages/non-working-days';
import {
  PaymentSalaryPage,
  loaders as paymentSalaryLoaders,
  actions as paymentSalaryActions,
} from './pages/payment-salary';
import { SalariesPage, loaders as salariesRoutes } from './pages/salaries-page';
import {
  SalaryPaymentDaysPage,
  loaders as paymentSalaryPaymentLoaders,
  actions as paymentSalaryPaymentActions,
} from './pages/salary-payment-days';

const config = [
  {
    path: '/finance/configure/taxes',
    text: 'налоги ЗП',
    filter: 'default',
    permissions: ['read:finance/config/tax-configurations'],
  },
  {
    path: '/finance/configure/bank-accounts',
    text: 'счета',
    permissions: ['read:finance/config/bank-accounts'],
  },
  {
    path: '/finance/configure/exchange-rates',
    text: 'курсы валют',
    permissions: ['read:finance/config/exchange-rates'],
  },
  {
    path: '/finance/configure/working-days',
    text: 'рабочие дни',
    permissions: ['read:finance/config/non-working-days'],
  },
  {
    path: '/finance/configure/salary-payment-days',
    text: 'дни выплат',
    permissions: ['read:finance/config/salary-payment-days-configs'],
  },
  {
    path: '/finance/configure/expenses-categories',
    text: 'категории трат',
    permissions: ['read:finance/config/expenses-categories'],
  },
  {
    path: '/finance/configure/bank-cards',
    text: 'банковские карты',
    permissions: ['read:finance/config/bank-cards'],
  },
];

export const FinanceRoutes = (user?: IUserInfo) => {
  const routes = [
    {
      path: '/finance/salaries',
      loader: configureLoaders(salariesRoutes),
      element: <SalariesPage />,
      permissions: ['read:finance/employees/salary-revisions/salary-reports'],
    },
    {
      path: '/finance/employee/:employeeId',
      action: configureActions(employeeSalaryActions),
      loader: configureLoaders(employeeSalaryRoutes),
      errorElement: <ErrorPage code={404} text="Сотрудник не найден" />,
      element: <EmployeeSalaryPage />,
      permissions: [
        'read:finance/employees/salary-revisions/salary-reports',
        'read:finance/employees/salary-revisions/history',
        'read:finance/employees',
        'read:finance/employees/salary-revision-requests',
        'read:finance/salary-transactions',
        'read:finance/config/bank-accounts',
      ],
    },
    {
      path: '/finance/expenses-transactions',
      loader: configureLoaders(expensesTransactionsLoaders),
      action: configureActions(expensesTransactionsActions),
      element: <ExpensesTransactionsPage />,
      permissions: ['read:finance/expenses-transactions'],
    },
    {
      path: '/finance/money-balances',
      loader: configureLoaders(moneyBalancesLoaders),
      action: configureActions(moneyBalancesActions),
      element: <MoneyBalancesPage />,
      permissions: ['read:finance/money-balances'],
    },
    {
      path: '/finance/dictionary',
      loader: configureLoaders(dictionaryLoaders),
      action: configureActions(dictionaryActions),
      element: <FinanceDictionary />,
      permissions: [
        'write:finance/dictionary/legal-entities',
        'write:finance/dictionary/currencies',
      ],
    },
    {
      path: '/finance/configure/taxes',
      loader: configureLoaders(configureTaxesLoader),
      action: configureActions(configureTaxesActions),
      element: <ConfigureTaxesPage />,
      permissions: ['read:finance/config/tax-configurations'],
    },
    {
      path: '/finance/configure/bank-accounts',
      loader: configureLoaders(bankAccountsLoaders),
      action: configureActions(bankAccountsActions),
      element: <BankAccountsPage />,
      permissions: ['read:finance/config/bank-accounts'],
    },
    {
      path: '/finance/configure/exchange-rates',
      loader: configureLoaders(exchangeRatesLoaders),
      action: configureActions(exchangeRatesActions),
      element: <ExchangeRates />,
      permissions: ['read:finance/config/exchange-rates'],
    },
    {
      path: '/finance/configure/working-days',
      loader: configureLoaders(nonWorkingDaysLoaders),
      action: configureActions(nonWorkingDaysActions),
      element: <NonWorkingDays />,
      permissions: ['read:finance/config/non-working-days'],
    },
    {
      path: '/finance/payment-salary',
      loader: configureLoaders(paymentSalaryLoaders),
      action: configureActions(paymentSalaryActions),
      element: <PaymentSalaryPage />,
      permissions: ['read:finance/salary-transactions'],
    },
    {
      path: '/finance/configure/salary-payment-days',
      loader: configureLoaders(paymentSalaryPaymentLoaders),
      action: configureActions(paymentSalaryPaymentActions),
      element: <SalaryPaymentDaysPage />,
      permissions: ['read:finance/config/salary-payment-days-configs'],
    },
    {
      path: '/finance/configure/expenses-categories',
      loader: configureLoaders(expensesCategoriesLoaders),
      action: configureActions(expensesCategoriesActions),
      element: <ExpensesCategoriesPage />,
      permissions: ['read:finance/config/expenses-categories'],
    },
    {
      path: '/finance/configure/bank-cards',
      loader: configureLoaders(bankCardsLoaders),
      action: configureActions(bankCardsActions),
      element: <BankCardsPage />,
      permissions: ['read:finance/config/bank-cards'],
    },
  ];

  const filteredRoutes: IRoute[] = filterRoutes(
    user?.permissions ?? [],
    routes
  );

  return [
    ...filteredRoutes,
    {
      path: '/',
      loader: () =>
        redirect(
          filteredRoutes.filter((route) => route?.path?.indexOf(':') === -1)[0]
            .path ?? '/'
        ),
      permissions: [],
    },
  ];
};

export const FinanceMenuItems = (user?: IUserInfo) => {
  const menu = [
    {
      icon: Wallet3Line,
      href: '/finance/salaries?filter=active',
      routes: [
        { path: '/finance/employee/:employeeId' },
        { path: '/finance/salaries' },
      ],
      text: 'зарплаты',
      permissions: ['read:finance/employees/salary-revisions/salary-reports'],
    },
    {
      icon: HandCoinLine,
      href: '/finance/payment-salary?filter=planned',
      routes: [{ path: '/finance/payment-salary' }],
      text: 'выплата зп',
      permissions: ['read:finance/salary-transactions'],
    },
    {
      icon: BankCardLine,
      href: '/finance/money-balances',
      routes: [{ path: '/finance/money-balances' }],
      text: 'балансы',
      permissions: ['read:finance/money-balances'],
    },
    {
      icon: Coins,
      href: '/finance/expenses-transactions?filter=planned',
      routes: [{ path: '/finance/expenses-transactions' }],
      text: 'траты',
      permissions: ['read:finance/expenses-transactions'],
    },
    {
      icon: BookMarkLine,
      href: '/finance/dictionary',
      routes: [{ path: '/finance/dictionary' }],
      text: 'cловарь',
      permissions: [
        'write:finance/dictionary/legal-entities',
        'write:finance/dictionary/currencies',
      ],
    },
    user?.permissions.filter(
      (permission) => permission.indexOf('finance/config') !== -1
    ).length && {
      icon: Settings4Line,
      href: '/finance/configure',
      routes: filterRoutes(user?.permissions ?? [], config),
      text: 'конфигурация',
      permissions: [],
    },
  ];

  const filteredRoutes = filterRoutes(
    user?.permissions ?? [],
    menu.filter((item) => item) as ISideMenuItem[]
  ) as ISideMenuItem[];

  return filteredRoutes;
};

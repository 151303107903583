import { FC } from 'react';

import { queryClient } from 'components/context/query';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { Text } from 'design/text';

import { requests } from '../../../api';

import styles from './modal.module.scss';

export const SubForms = () => {
  const partCodes = ['Оклад', 'Надбавка', 'Aвторские', 'Премия', 'Доп нал'];

  return (
    <div className={styles.subforms}>
      {partCodes.map((partCode, index) => (
        <SubForm
          key={index}
          partCode={partCode}
          name={`employeeSalaryRevisionParts[${index}]`}
        />
      ))}
    </div>
  );
};

interface SubFormProps {
  partCode: string;
  name: string;
}

const SubForm: FC<SubFormProps> = ({ partCode, name }) => (
  <div className={styles.subForm}>
    <Text className={styles.partCode} size="text-14">
      {partCode}
    </Text>
    <div className={styles.inputs}>
      <FormInput
        className={styles.input}
        name={`${name}.amount`}
        label="Сумма"
        placeholder="Сумма"
      />
      <FormSelect
        className={styles.select}
        name={`${name}.partTaxId`}
        label="Налог"
        options={async () => {
          const options = await queryClient.fetchQuery({
            queryKey: ['taxes'],
            queryFn: requests.getTaxes,
          });

          return options.data.map((option) => ({
            value: option.id,
            label: option.name,
          }));
        }}
      />
      <FormSelect
        className={styles.select}
        name={`${name}.bankAccountId`}
        label="Банк"
        options={async () => {
          const options = await queryClient.fetchQuery({
            queryKey: ['bankAccounts'],
            queryFn: requests.getBankAccounts,
          });

          return options.data.map((option) => ({
            value: option.id,
            label: `${option.legalEntity.name} ${option.bankName} ${option.currency.sign}`,
          }));
        }}
      />
    </div>
  </div>
);

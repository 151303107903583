import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { ILegalEntity } from 'components/api/types/dictionary-types';
import { Button } from 'design/button';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { useFormContext } from 'react-hook-form';
import { useSubmit } from 'utils/useSubmit';

import { legalEntitySchema } from '../../../utils/validation';

import { IFormContentProps } from './type';

import styles from './forms.module.scss';

export type TLegalEntityFormProps = Partial<
  ILegalEntity & { setLegalEntityId: (value: ID) => void }
>;

type TFormValue = Omit<ILegalEntity, 'id'>;

export const LegalEntityFormContent: FC<IFormContentProps> = ({
  loading,
  isEdit,
  setId,
  setSuccess,
  isSuccess,
}) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      reset({ name: '', fullName: '', country: '' });
      setSuccess(false);
    }
  }, [isSuccess, reset, setSuccess]);

  return (
    <div
      className={cn(styles.form, {
        [styles.container]: !isEdit,
      })}
    >
      <div className={styles.form__section}>
        <FormInput required name="name" label="Название юридического лица" />
        <FormInput
          required
          name="fullName"
          label="Полное название юридического лица"
        />
        <FormInput required name="country" label="Страна" />
      </div>
      {isEdit ? (
        <div className={styles.form__button}>
          <Button
            label="Сохранить"
            loading={loading}
            type="submit"
            buttonType="secondary"
          />
          <Button
            label="Отмена"
            type="button"
            buttonType="secondary"
            onClick={() => setId?.('')}
          />
        </div>
      ) : (
        <Button
          label="Добавить"
          loading={loading}
          type="submit"
          className={styles.form__add}
        />
      )}
    </div>
  );
};

export const LegalEntityForm: FC<TLegalEntityFormProps> = ({
  id,
  name,
  fullName,
  country,
  setLegalEntityId,
}) => {
  const [isSuccess, setSuccess] = useState(false);

  const { loading, submit } = useSubmit(() => {
    setLegalEntityId?.('');
    setSuccess(true);
  });

  const onSubmit = (value: TFormValue) => {
    submit({
      entity: 'legalEntity',
      data: {
        legalEntity: value,
        legalEntityId: id ?? null,
      },
    });
  };

  return (
    <Form
      initialValues={{ name, fullName, country }}
      onSubmit={onSubmit}
      validation={legalEntitySchema}
    >
      <LegalEntityFormContent
        isEdit={!!id}
        loading={loading}
        setId={setLegalEntityId}
        setSuccess={setSuccess}
        isSuccess={isSuccess}
      />
    </Form>
  );
};

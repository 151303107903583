import { useLoaderData } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { IExchangeRate } from 'components/api/types/dictionary-types';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { dateSorting, numberSorting, textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { formatDate } from 'utils';
import { useSubmit } from 'utils/useSubmit';

import { ExchangeRateModal } from './modal';

import styles from './exchange-rates.module.scss';

const openExchangeRate = () => {
  modal.open({
    className: styles.modal,
    id: 'create-exchange-rates-modal',
    title: 'Добавление данных',
    formId: 'create-exchange-rates-form',
    content: <ExchangeRateModal />,
  });
};

const openEditExchangeRate = (initialValue: IExchangeRate) => {
  modal.open({
    className: styles.modal,
    id: 'edit-exchange-rates-modal',
    title: 'Добавление данных',
    formId: 'edit-exchange-rates-form',
    content: <ExchangeRateModal initialValue={initialValue} />,
  });
};

export const ExchangeRates = () => {
  const { submit } = useSubmit();

  const { exchangeRates } = useLoaderData() as {
    exchangeRates: IExchangeRate[];
  };

  const handleDelete = (id: ID) => {
    submit({
      entity: 'exchangeRates',
      data: {
        removedId: id,
      },
    });
  };

  const columns: TTableColumn<IExchangeRate>[] = [
    {
      title: 'Начало периода',
      dataIndex: ['startDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ startDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(startDate)}
        </Text>
      ),
    },
    {
      title: 'Конец периода',
      dataIndex: ['endDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ endDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(endDate)}
        </Text>
      ),
    },
    {
      title: 'Курс',
      dataIndex: ['rate'],
      sortable: true,
      sorting: numberSorting,
    },
    {
      title: 'Исходная валюта',
      dataIndex: ['fromCurrency'],
      sortable: true,
      sorting: textSorting,
      render: ({ fromCurrency }) => (
        <Text size="text-14" as="span">
          {fromCurrency ? fromCurrency.name : ''}
        </Text>
      ),
    },
    {
      title: 'Целевая валюта',
      dataIndex: ['toCurrency'],
      sortable: true,
      sorting: textSorting,
      render: ({ toCurrency }) => (
        <Text size="text-14" as="span">
          {toCurrency ? toCurrency.name : ''}
        </Text>
      ),
    },
    {
      title: 'Действие',
      actions: {
        onEdit: openEditExchangeRate,
        onDelete: handleDelete,
      },
    },
  ];

  return (
    <div className={styles.salaries}>
      <div className={styles.header}>
        <Text size="text-20" weight={500} className={styles.heading}>
          Конфигурация курсов валют
        </Text>
      </div>
      <div className={styles.body}>
        <div className={styles.tableHeader}>
          <Button
            label="Добавить"
            onClick={openExchangeRate}
            prefixIcon={<AddBoxLine />}
          />
        </div>
        <ContentContainer>
          <Table columns={columns} data={exchangeRates} key="id" />
        </ContentContainer>
      </div>
    </div>
  );
};

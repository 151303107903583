import { ChangeEvent, useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { ID, TTableMode } from 'components/api/types/common';
import {
  ISalaryTransaction,
  ITransaction,
} from 'components/api/types/transaction';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { ButtonSwitch } from 'design/button-switch';
import { Checkbox } from 'design/checkbox';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn, TTableGropableColumn } from 'design/table/column';
import { groupableAdapter } from 'design/table/utils';
import { Text } from 'design/text';
import { formatDate, getInitialsEmployeeFromFullName } from 'utils';
import { useSubmit } from 'utils/useSubmit';

import { TransactionModal } from './modal';

import styles from './payment-salary.module.scss';

const openCreateTransactionModal = () => {
  modal.open({
    className: styles.modal,
    id: 'create-transaction-modal',
    title: 'Добавление транзакции',
    formId: 'create-transaction-form',
    content: <TransactionModal />,
  });
};

const openEditTransactionModal = (
  initialValue: TTableGropableColumn<ITransaction, ISalaryTransaction>
) => {
  modal.open({
    id: 'edit-transaction-modal',
    title: 'Редактирование транзакции',
    formId: 'edit-transaction-form',
    content: <TransactionModal initialValue={initialValue} />,
  });
};

export const PaymentSalaryPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [checkedTransactions, setCheckedTransactions] = useState<ID[]>([]);

  const [tableMode, setTableMode] = useState<TTableMode>(
    (searchParams.get('filter') as TTableMode | null) ?? 'planned'
  );

  const { submit } = useSubmit();

  useEffect(() => {
    const filter = searchParams.get('filter');

    setTableMode(filter as TTableMode);
  }, [searchParams]);

  const openForcedActivationModal = () => {
    modal.open({
      id: 'forced-activation-modal',
      submit: 'Да',
      onSubmit: () =>
        submit({
          entity: 'paymentSalary',
          data: {
            isForcedActivation: true,
          },
        }),
      content: (
        <Text size="text-20" weight={500}>
          Вы уверены, что хотите принудительно создать транзакции по выплате
          зарплаты?
        </Text>
      ),
    });
  };

  const openPayTransactionModal = () => {
    modal.open({
      id: 'pay-transaction-modal',
      submit: 'Да',
      onSubmit: () => {
        submit({
          entity: 'paymentSalary',
          data: {
            ids: { ids: checkedTransactions },
          },
        });

        setCheckedTransactions([]);
      },
      content: (
        <Text size="text-20" weight={500}>
          Вы уверены, что хотите выплатить выбранные транзакции?
        </Text>
      ),
    });
  };

  const { salaryTransactions } = useLoaderData() as {
    salaryTransactions: ISalaryTransaction[];
  };

  const transactions = salaryTransactions.reduce(
    (transactions: ITransaction[], transaction) => {
      const transactionById = transactions.find(
        (el) => el.employeeId === transaction.employeeId
      );

      if (transactionById) {
        transactionById.salaryTransactions.push(transaction);
      } else {
        transactions.push({
          employeeId: transaction.employeeId,
          fullName: `${transaction.employee.firstName} ${transaction.employee.lastName}`,
          salaryTransactions: [transaction],
        });
      }

      return transactions;
    },
    []
  ) as ITransaction[];

  const tableData = groupableAdapter<ITransaction, ISalaryTransaction>(
    transactions
  );

  const handleDelete = (id: ID) => {
    submit({
      entity: 'paymentSalary',
      data: {
        removedId: id,
      },
    });
  };

  const plannedColumns: TTableColumn<
    TTableGropableColumn<ITransaction, ISalaryTransaction>
  >[] = [
    {
      titleRender: () => (
        <>
          {tableMode === 'planned' && (
            <Checkbox
              checked={
                !!checkedTransactions.length &&
                tableData.length === checkedTransactions.length
              }
              indeterminate={!!checkedTransactions.length}
              onChange={allCheckedHandler}
            />
          )}
        </>
      ),
      groupNumber: (data) => data.groupNumber,
      render: (data) => {
        const checked = checkedTransactions.find((id) => id === data.id);

        const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
          if (e.currentTarget.checked) {
            setCheckedTransactions((state) => [...state, data.id]);
          } else {
            setCheckedTransactions((state) =>
              state.filter((id) => id !== data.id)
            );
          }
        };

        return (
          <Checkbox
            className={styles.columnCheckbox}
            checked={!!checked}
            onChange={onChangeHandler}
          />
        );
      },
    },
    {
      title: 'Фио',
      dataIndex: ['fullName'],
      render: ({ fullName }) => (
        <Text size="text-14" weight={400}>
          {getInitialsEmployeeFromFullName(fullName)}
        </Text>
      ),
      rowSpanCount: (data) => data.rowSpanCount,
      queue: (data) => data.queue,
      groupNumber: (data) => data.groupNumber,
      minWidth: '130px',
    },
    {
      title: 'Тип оплаты',
      dataIndex: ['paymentType'],
      groupNumber: (data) => data.groupNumber,
    },
    {
      title: 'Статус',
      dataIndex: ['status'],
      groupNumber: (data) => data.groupNumber,
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccountId'],
      groupNumber: (data) => data.groupNumber,
      render: ({ bankAccount }) => (
        <Text size="text-14" as="span">
          {bankAccount ? bankAccount.bankName : ''}
        </Text>
      ),
    },
    {
      title: 'Валюта',
      dataIndex: ['bankAccount'],
      groupNumber: (data) => data.groupNumber,
      render: ({ bankAccount }) => (
        <Text size="text-14" as="span">
          {bankAccount.currency ? bankAccount.currency.name : ''}
        </Text>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: ['amount'],
      groupNumber: (data) => data.groupNumber,
    },
    {
      title: 'Дата выплаты',
      dataIndex: ['paymentDate'],
      groupNumber: (data) => data.groupNumber,
      render: ({ paymentDate }) => (
        <Text size="text-14" weight={400} as="span">
          {paymentDate ? formatDate(paymentDate) : ''}
        </Text>
      ),
    },
    {
      title: 'Период',
      dataIndex: ['periodStartDate'],
      render: (data) => (
        <Text size="text-14" weight={400} as="span">
          {`${formatDate(data.periodStartDate)} 
          - 
          ${formatDate(data.periodEndDate)}`}
        </Text>
      ),
    },
    {
      title: 'Действия',
      groupNumber: (data) => data.groupNumber,
      actions: {
        onEdit: openEditTransactionModal,
        onDelete: handleDelete,
      },
    },
  ];

  const paidColumns: TTableColumn<
    TTableGropableColumn<ITransaction, ISalaryTransaction>
  >[] = [
    {
      title: 'Фио',
      dataIndex: ['fullName'],
      render: ({ fullName }) => (
        <Text size="text-14" weight={400}>
          {getInitialsEmployeeFromFullName(fullName)}
        </Text>
      ),
      rowSpanCount: (data) => data.rowSpanCount,
      queue: (data) => data.queue,
      groupNumber: (data) => data.groupNumber,
    },
    {
      title: 'Тип оплаты',
      dataIndex: ['paymentType'],
      groupNumber: (data) => data.groupNumber,
    },
    {
      title: 'Статус',
      dataIndex: ['status'],
      groupNumber: (data) => data.groupNumber,
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccountId'],
      groupNumber: (data) => data.groupNumber,
      render: ({ bankAccount }) => (
        <Text size="text-14" as="span">
          {bankAccount ? bankAccount.bankName : ''}
        </Text>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: ['amount'],
      groupNumber: (data) => data.groupNumber,
    },
    {
      title: 'Валюта',
      dataIndex: ['bankAccount'],
      groupNumber: (data) => data.groupNumber,
      render: ({ bankAccount }) => (
        <Text size="text-14" as="span">
          {bankAccount.currency ? bankAccount.currency.name : ''}
        </Text>
      ),
    },
    {
      title: 'Дата оплаты',
      dataIndex: ['paidAt'],
      groupNumber: (data) => data.groupNumber,
      render: ({ paidAt }) => (
        <Text size="text-14" weight={400} as="span">
          {paidAt ? formatDate(paidAt) : ''}
        </Text>
      ),
    },
  ];

  const allCheckedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setCheckedTransactions(
        transactions
          .map((transaction) =>
            transaction.salaryTransactions.map(
              (salaryTransaction) => salaryTransaction.id
            )
          )
          .flat()
      );
    } else {
      setCheckedTransactions([]);
    }
  };

  return (
    <div className={styles.payments}>
      <div className={styles.payments__header}>
        <Text
          size="text-20"
          weight={500}
          className={styles.payments__header_heading}
        >
          Выплата ЗП
        </Text>
      </div>
      <div className={styles.payments__body}>
        <div className={styles.payments__table__header}>
          <Button label="ФОРС" onClick={openForcedActivationModal} />
          <div className={styles.payments__buttons}>
            <ButtonSwitch
              options={[
                { value: 'planned', label: 'Запланировано' },
                { value: 'paid', label: 'Выплачено' },
              ]}
              onChange={(option) => {
                setSearchParams({ filter: `${option.value}` });
              }}
              active={tableMode}
            />
            <Button
              label="Выплатить отмеченные"
              disabled={!checkedTransactions.length}
              onClick={openPayTransactionModal}
            />
            <Button
              label="Добавить"
              onClick={openCreateTransactionModal}
              prefixIcon={<AddBoxLine />}
            />
          </div>
        </div>
        <ContentContainer>
          <Table
            columns={tableMode === 'planned' ? plannedColumns : paidColumns}
            data={tableData}
            key="id"
          />
        </ContentContainer>
      </div>
    </div>
  );
};

import { FC } from 'react';

import { ID } from 'components/api/types/common';
import { EPaymentStatus } from 'components/api/types/enums';
import { ISelectedExpensesTransaction } from 'components/api/types/expenses-transactions';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { useFormContext } from 'react-hook-form';
import { datePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { PAYMENT_STATUS_OPTIONS } from '../../../utils/constants';
import { selectedExpensesTransactionsSchema } from '../../../utils/validation';

import styles from './modal.module.scss';

interface IExpensesTransactionsModalProps extends IModalOverride {
  ids: ID[];
  setCheckedTransactions: (value: ID[]) => void;
}

type TFormData = Omit<ISelectedExpensesTransaction, 'ids'>;

const FormContent = () => {
  const { watch } = useFormContext();
  const status = watch('status');

  return (
    <div className={styles.content}>
      <FormSelect
        required
        name="status"
        label="Статус"
        options={PAYMENT_STATUS_OPTIONS}
      />
      <FormDatepicker
        name="paidAt"
        label="Дата оплаты"
        required={status === 'Оплачен'}
        disabled={status !== 'Оплачен'}
        placeholder={datePlaceholder}
      />
    </div>
  );
};

export const SelectedTransactionsModal: FC<IExpensesTransactionsModalProps> = ({
  formId,
  ids,
  setCheckedTransactions,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const actions = () => {
    setCheckedTransactions([]);
    modalClose?.();
  };

  const { submit } = useSubmit(actions, setLoading);

  const onSubmit = ({ paidAt, status }: TFormData) => {
    submit({
      entity: 'expensesTransactions',
      data: {
        selectedExpensesTransaction: {
          ids,
          paidAt,
          status,
        },
      },
    });
  };

  return (
    <Form
      id={formId}
      initialValues={{ status: EPaymentStatus.Создан }}
      validation={selectedExpensesTransactionsSchema}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      onSubmit={onSubmit}
    >
      <FormContent />
    </Form>
  );
};

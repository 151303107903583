import { FC } from 'react';

import { IRepeatedExpenses } from 'components/api/types/repeated-expenses';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { repeatedExpensesSchema } from 'modules/human-resources/utils/validation';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';

import styles from './modal.module.scss';

interface IRepeatedExpensesModalProps extends IModalOverride {
  initialValue?: IRepeatedExpenses;
}

type TFormValue = Omit<IRepeatedExpenses, 'repeatAt'> & {
  repeatAt: string;
};

export const RepeatedExpensesModal: FC<IRepeatedExpensesModalProps> = ({
  formId,
  initialValue,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = ({
    repeatAt,
    amount,
    expensesCategoryId,
    bankAccountId,
    id,
    comment,
    name,
  }: TFormValue) => {
    submit({
      entity: 'repeatedExpenses',
      data: {
        repeatedExpenses: {
          repeatAt: Number(repeatAt),
          amount: Number(amount),
          expensesCategoryId: Number(expensesCategoryId),
          bankAccountId: Number(bankAccountId),
          comment,
          name,
        },
        repeatedExpensesId: initialValue ? id : null,
      },
    });
  };

  const modifiedInitialValue = {
    ...initialValue,
    repeatAt: initialValue?.repeatAt?.toString(),
  };

  return (
    <Form
      id={formId}
      validation={repeatedExpensesSchema}
      initialValues={modifiedInitialValue ?? {}}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      onSubmit={onSubmit}
    >
      <div className={styles.content}>
        <FormSelect
          required
          search
          name="expensesCategoryId"
          label="Категория"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['expensesCategory'],
              queryFn: () => requests.getExpensesCategories(true),
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
        <FormInput required name="name" label="Наименование" />
        <FormInput required name="amount" label="Сумма" />
        <FormSelect
          required
          name="bankAccountId"
          label="Банк аккаунт"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['bankAccounts'],
              queryFn: requests.getBankAccounts,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.bankName,
            }));
          }}
        />
        <FormInput required name="repeatAt" label="Дата выплаты" />
        <FormInput name="comment" label="Комментарий" />
      </div>
    </Form>
  );
};

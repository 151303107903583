import { FC } from 'react';

import { ISideMenuItem } from 'utils/types';

import { MenuItem } from './menu-item';

import styles from './menu.module.scss';

interface IMenuProps {
  isOpenSidebar: boolean;
  items?: ISideMenuItem[];
}

export const Menu: FC<IMenuProps> = ({ isOpenSidebar, items }) => (
  <ul className={styles.menu}>
    {items?.map((item) => (
      <MenuItem key={item.text} {...item} isOpenSidebar={isOpenSidebar} />
    ))}
  </ul>
);

import { FC } from 'react';

import { IMoneyBalance } from 'components/api/types/money-balances';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { moneyBalanceSchema } from '../../../utils/validation';

import styles from './modal.module.scss';

const CASH_STATUS_OPTIONS = [
  {
    label: 'Да',
    value: 'true',
  },
  {
    label: 'Нет',
    value: 'false',
  },
];

interface IMoneyBalanceModalProps extends IModalOverride {
  initialValue?: IMoneyBalance;
}

type TFormData = Omit<IMoneyBalance, 'bankAccount' | 'isCash'> & {
  isCash: string;
};

export const MoneyBalanceModal: FC<IMoneyBalanceModalProps> = ({
  initialValue,
  formId,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = ({ bankAccountId, amount, isCash, name, id }: TFormData) => {
    submit({
      entity: 'moneyBalances',
      data: {
        moneyBalances: {
          bankAccountId: Number(bankAccountId),
          amount: Number(amount),
          isCash: isCash && JSON.parse(isCash),
          name,
        },
        moneyBalanceId: initialValue ? id : null,
      },
    });
  };

  const modifiedInitialValue = {
    ...initialValue,
    isCash: initialValue?.isCash ? initialValue?.isCash?.toString() : 'false',
  } as unknown as TFormData;

  return (
    <Form
      id={formId}
      initialValues={modifiedInitialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={moneyBalanceSchema}
    >
      <div className={styles.content}>
        <FormInput
          required
          name="name"
          label="Название карты"
          placeholder="Название карты"
        />
        <FormSelect
          required
          name="bankAccountId"
          label="Банк аккаунт"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['bankAccounts'],
              queryFn: requests.getBankAccounts,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.bankName,
            }));
          }}
        />
        <FormInput required name="amount" label="Сумма" />
        <FormSelect
          required
          name="isCash"
          label="Наличка"
          options={CASH_STATUS_OPTIONS}
        />
      </div>
    </Form>
  );
};

import { IMoneyBalance } from 'components/api/types/money-balances';

import { requests } from '../../api';

export interface ILoaderData {
  moneyBalances: IMoneyBalance[];
}

export const loaders = () => [
  {
    name: 'moneyBalances',
    fn: requests.getMoneyBalances,
  },
];

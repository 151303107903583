import { FC } from 'react';

import { IScheduleTableProps } from '../types';

import { BodyTable } from './body-table';
import { HeaderTable } from './header-table';

import styles from './calendar-table.module.scss';

export const CalendarTable: FC<IScheduleTableProps> = ({ employees, year }) => (
  <table className={styles.table}>
    <HeaderTable year={year} />
    <BodyTable employees={employees} year={year} />
  </table>
);

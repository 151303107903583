import {
  IEducationDegree,
  IExitInterviewQuestion,
  IOffice,
  IDepartment,
  IDismissalReason,
  IPosition,
} from 'components/api/types/dictionary-types';

import { requests } from '../../api';

export interface IDictionaryLoaderData {
  educationDegrees: IEducationDegree[];
  exitInterviewQuestions: IExitInterviewQuestion[];
  offices: IOffice[];
  departments: IDepartment[];
  dismissalReasons: IDismissalReason[];
  positions: IPosition[];
}

export const loaders = () => [
  {
    name: 'educationDegrees',
    fn: requests.getEducationDegrees,
  },
  {
    name: 'exitInterviewQuestions',
    fn: () => requests.getExitInterviewQuestions(true),
  },
  {
    name: 'offices',
    fn: requests.getOffices,
  },
  {
    name: 'departments',
    fn: requests.getDepartments,
  },
  {
    name: 'dismissalReasons',
    fn: requests.getDismissalReasons,
  },
  {
    name: 'positions',
    fn: requests.getPositions,
  },
];

import { ID } from 'components/api/types/common';
import { IRepeatedExpenses } from 'components/api/types/repeated-expenses';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  repeatedExpenses: async (data: {
    removedId: ID | null;
    repeatedExpensesId: ID | null;
    repeatedExpenses: IRepeatedExpenses;
  }) => {
    data.removedId &&
      (await requests.deleteRepeatedExpensesById(data.removedId));

    data.repeatedExpensesId &&
      (await requests.patchRepeatedExpensesById(
        data.repeatedExpensesId,
        data.repeatedExpenses
      ));

    data.repeatedExpenses &&
      !data.repeatedExpensesId &&
      (await requests.postRepeatedExpenses(data.repeatedExpenses));

    await queryClient.invalidateQueries({
      queryKey: ['repeatedExpenses'],
    });
  },
};

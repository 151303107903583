import React, { ButtonHTMLAttributes } from 'react';

import CN from 'clsx';

import { Spinner } from '../spinner';
import { Text } from '../text';

import styles from './button.module.scss';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  size?: 'large' | 'small';
  buttonType?: 'primary' | 'secondary' | 'table';
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  loading?: boolean;
  className?: string;
  fullWidth?: boolean;
  label: string;
}

export const Button: React.FC<IButtonProps> = ({
  label,
  size = 'large',
  buttonType = 'primary',
  disabled,
  loading,
  className,
  fullWidth,
  prefixIcon: PrefixIcon,
  suffixIcon: SuffixIcon,
  ...buttonProps
}) => {
  const classNames = CN(className, styles.button, {
    [styles.large]: size === 'large',
    [styles.small]: size === 'small',
    [styles.table]: buttonType === 'table',
    [styles.primary]: buttonType === 'primary',
    [styles.secondary]: buttonType === 'secondary',
    [styles.fullWidth]: fullWidth,
    [styles.withIcon]: !!PrefixIcon || !!SuffixIcon,
  });

  return (
    <button className={classNames} disabled={disabled} {...buttonProps}>
      {loading && (
        <div className={styles.buttonIcon}>
          <Spinner
            type="small"
            color={buttonType === 'secondary' ? 'gray-500' : 'white'}
          />
        </div>
      )}
      {!!PrefixIcon && !loading && (
        <div
          data-testid="prefixIcon"
          className={CN(styles.buttonIcon, {
            [styles.primaryIcon]: buttonType === 'primary',
          })}
        >
          {PrefixIcon}
        </div>
      )}
      {!(loading && !PrefixIcon) && (
        <Text size={size === 'large' ? 'text-14' : 'text-12'} as="span">
          {label}
        </Text>
      )}
      {!!SuffixIcon && !loading && (
        <div
          data-testid="suffixIcon"
          className={CN(styles.buttonIcon, {
            [styles.primaryIcon]: buttonType === 'primary',
          })}
        >
          {SuffixIcon}
        </div>
      )}
    </button>
  );
};

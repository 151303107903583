export const checkDirtyFormFields = (
  formFields: Partial<Readonly<Record<string, any>>>
) => {
  if (!Object.values(formFields).length) {
    return false;
  }

  let flag = false;

  const checkFieldValue = (value: Partial<Readonly<Record<string, any>>>) => {
    for (const key in value) {
      if (typeof value[key] === 'object') {
        checkFieldValue(value[key]);
      } else if (value[key]) {
        flag = true;
        break;
      }
    }
  };

  checkFieldValue(formFields);

  return flag;
};

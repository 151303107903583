import { FC } from 'react';

import { Text } from 'design/text';

import styles from './modals.module.scss';

export interface IDismissalModal {
  name: string;
}

export const DismissalModal: FC<IDismissalModal> = ({ name }) => (
  <div className={styles.dismissal}>
    <div>
      <Text as="span" size="text-14">
        Вы уверены, что хотите удалить работника
      </Text>{' '}
      <Text as="span" size="text-14" weight={500}>
        {name}
      </Text>{' '}
      <Text as="span" size="text-14">
        ?
      </Text>
    </div>
    <Text size="text-14" weight={500} as="span">
      Это действие невозможно отменить.
    </Text>
  </div>
);

import { useLoaderData } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { IMoneyBalance } from 'components/api/types/money-balances';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { numberSorting, textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { ILoaderData } from './loaders';
import { MoneyBalanceModal } from './modal';

import styles from './money-balances.module.scss';

const openAddMoneyBalanceModal = () => {
  modal.open({
    id: 'add-money-balances-modal',
    title: 'Добавление данных',
    formId: 'add-money-balances',
    content: <MoneyBalanceModal />,
  });
};

const openEditMoneyBalanceModal = (initialValue: IMoneyBalance) => {
  modal.open({
    id: 'edit-money-balances-modal',
    title: 'Редактирование данных',
    formId: 'edit-money-balances',
    content: <MoneyBalanceModal initialValue={initialValue} />,
  });
};

export const MoneyBalancesPage = () => {
  const { submit } = useSubmit();

  const { moneyBalances } = useLoaderData() as ILoaderData;

  const handleDelete = (id: ID) => {
    submit({
      entity: 'moneyBalances',
      data: {
        removedId: id,
      },
    });
  };

  const columns: TTableColumn<IMoneyBalance>[] = [
    {
      title: 'Название карты',
      dataIndex: ['name'],
      sortable: true,
      sorting: textSorting,
      minWidth: '200px',
    },
    {
      title: 'Сумма',
      dataIndex: ['amount'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccount'],
      sortable: true,
      sorting: textSorting,
      render: ({ bankAccount }) => (
        <Text size="text-14" as="span">
          {bankAccount ? bankAccount.bankName : ''}
        </Text>
      ),
    },
    {
      title: 'Наличка',
      dataIndex: ['isCash'],
      sortable: true,
      sorting: numberSorting,
      render: ({ isCash }) => (
        <Text size="text-14" as="span">
          {isCash ? 'Да' : 'Нет'}
        </Text>
      ),
    },
    {
      title: 'Действие',
      actions: {
        onDelete: handleDelete,
        onEdit: openEditMoneyBalanceModal,
      },
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container__title}>
        <Text size="text-20" weight={500}>
          Балансы
        </Text>
      </div>
      <div className={styles.container__info}>
        <div className={styles.container__info__button}>
          <Button
            label="Добавить"
            onClick={openAddMoneyBalanceModal}
            prefixIcon={<AddBoxLine />}
          />
        </div>
        <ContentContainer>
          <Table columns={columns} data={moneyBalances} key="id" />
        </ContentContainer>
      </div>
    </div>
  );
};

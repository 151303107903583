import {
  deleteDepartmentData,
  deleteDismissalReasonData,
  deleteEducationDegreeData,
  deleteExitInterviewQuestionData,
  deleteOfficeData,
  deletePositionData,
  getDepartments,
  getDismissalReasons,
  getEducationDegrees,
  getExitInterviewQuestions,
  getLegalEntities,
  getOffices,
  getPositions,
  patchDepartmentData,
  patchDismissalReasonData,
  patchEducationDegreeData,
  patchExitInterviewQuestionData,
  patchOfficeData,
  patchPositionData,
  postDepartmentData,
  postDismissalReasonData,
  postEducationDegreeData,
  postExitInterviewQuestionData,
  postOfficeData,
  postPositionData,
} from 'components/api/common';
import {
  deleteEducationData,
  deleteWorkHistoryData,
  getCurrentEmployees,
  getEducationDataById,
  getEmployeeDataById,
  getMissingDaysDataById,
  getWorkHistoryDataById,
  patchEducationData,
  patchEmployeeData,
  patchMissingDaysData,
  patchWorkHistoryData,
  postEducationData,
  postEmployeeData,
  postMissingDaysData,
  postWorkHistoryData,
} from 'components/api/employee';
import {
  patchDismissal,
  postDismissal,
} from 'components/api/employee/dismissals';
import {
  getSalariesReportsForHr,
  getSalaryRevisionById,
  getSalaryByIdReportsForHr,
  getSalaryHistoryById,
} from 'components/api/employee/salaries';
import { getBankAccounts } from 'components/api/finance/bank-accounts';
import { getExpensesCategories } from 'components/api/finance/expenses-categories';
import {
  deleteExpensesTransactions,
  deleteExpensesTransactionsById,
  getExpensesTransactions,
  patchExpensesTransactions,
  patchExpensesTransactionsById,
  postExpensesTransactions,
} from 'components/api/finance/expenses-transactions';
import {
  deleteRepeatedExpensesById,
  getRepeatedExpenses,
  patchRepeatedExpensesById,
  postRepeatedExpenses,
  postRepeatedExpensesForcedActivation,
} from 'components/api/finance/repeated-expenses';
import {
  getEmployeeRevisionRequests,
  postSalaryRevisionRequestData,
} from 'components/api/finance/salary';
import { EModule } from 'components/api/types/enums';

export const requests = {
  getCurrentEmployees: getCurrentEmployees(EModule.hr),
  getEmployeeDataById: getEmployeeDataById(EModule.hr),
  patchEmployeeData: patchEmployeeData(EModule.hr),
  postEmployeeData: postEmployeeData(EModule.hr),
  getEmployeeRevisionRequests: getEmployeeRevisionRequests(EModule.hr),
  postDismissal: postDismissal(EModule.hr),
  patchDismissal: patchDismissal(EModule.hr),
  deleteDismissalReasonData: deleteDismissalReasonData(EModule.hr),
  patchDismissalReasonData: patchDismissalReasonData(EModule.hr),
  postDismissalReasonData: postDismissalReasonData(EModule.hr),
  getEducationDataById: getEducationDataById(EModule.hr),
  patchEducationData: patchEducationData(EModule.hr),
  postEducationData: postEducationData(EModule.hr),
  deleteEducationData: deleteEducationData(EModule.hr),
  getBankAccounts: getBankAccounts(EModule.hr),
  getMissingDaysDataById: getMissingDaysDataById(EModule.hr),
  patchMissingDaysData: patchMissingDaysData(EModule.hr),
  postMissingDaysData: postMissingDaysData(EModule.hr),
  getWorkHistoryDataById: getWorkHistoryDataById(EModule.hr),
  patchWorkHistoryData: patchWorkHistoryData(EModule.hr),
  postWorkHistoryData: postWorkHistoryData(EModule.hr),
  deleteWorkHistoryData: deleteWorkHistoryData(EModule.hr),
  getExitInterviewQuestions: getExitInterviewQuestions(EModule.hr),
  deleteExitInterviewQuestionData: deleteExitInterviewQuestionData(EModule.hr),
  patchExitInterviewQuestionData: patchExitInterviewQuestionData(EModule.hr),
  postExitInterviewQuestionData: postExitInterviewQuestionData(EModule.hr),
  getExpensesTransactions: getExpensesTransactions(EModule.hr),
  deleteExpensesTransactions: deleteExpensesTransactions(EModule.hr),
  deleteExpensesTransactionsById: deleteExpensesTransactionsById(EModule.hr),
  patchExpensesTransactions: patchExpensesTransactions(EModule.hr),
  patchExpensesTransactionsById: patchExpensesTransactionsById(EModule.hr),
  postExpensesTransactions: postExpensesTransactions(EModule.hr),
  postRepeatedExpensesForcedActivation: postRepeatedExpensesForcedActivation(
    EModule.hr
  ),
  getRepeatedExpenses: getRepeatedExpenses(EModule.hr),
  deleteRepeatedExpensesById: deleteRepeatedExpensesById(EModule.hr),
  patchRepeatedExpensesById: patchRepeatedExpensesById(EModule.hr),
  postRepeatedExpenses: postRepeatedExpenses(EModule.hr),
  getExpensesCategories: getExpensesCategories(EModule.hr),
  getDismissalReasons: getDismissalReasons(EModule.hr),
  getLegalEntities: getLegalEntities(EModule.hr),
  getDepartments: getDepartments(EModule.hr),
  deleteDepartmentData: deleteDepartmentData(EModule.hr),
  patchDepartmentData: patchDepartmentData(EModule.hr),
  postDepartmentData: postDepartmentData(EModule.hr),
  getPositions: getPositions(EModule.hr),
  deletePositionData: deletePositionData(EModule.hr),
  patchPositionData: patchPositionData(EModule.hr),
  postPositionData: postPositionData(EModule.hr),
  getOffices: getOffices(EModule.hr),
  deleteOfficeData: deleteOfficeData(EModule.hr),
  patchOfficeData: patchOfficeData(EModule.hr),
  postOfficeData: postOfficeData(EModule.hr),
  getEducationDegrees: getEducationDegrees(EModule.hr),
  deleteEducationDegreeData: deleteEducationDegreeData(EModule.hr),
  patchEducationDegreeData: patchEducationDegreeData(EModule.hr),
  postEducationDegreeData: postEducationDegreeData(EModule.hr),
  getSalariesReportsForHr: getSalariesReportsForHr(EModule.hr),
  getSalaryByIdReportsForHr: getSalaryByIdReportsForHr(EModule.hr),
  getSalaryRevisionById: getSalaryRevisionById(EModule.hr),
  getSalaryHistoryById: getSalaryHistoryById(EModule.hr),
  postSalaryRevisionRequestData: postSalaryRevisionRequestData(EModule.hr),
};

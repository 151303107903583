import { FC, useEffect, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IEmployee } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormSelect } from 'design/form/form-select';
import { Spinner } from 'design/spinner';
import { Text } from 'design/text';
import { useFormContext } from 'react-hook-form';
import { datePlaceholder } from 'utils/placeholder-text';

import { requests } from '../../../api';
import { employmentInfoSchema } from '../../../utils/validation';

import styles from './forms.module.scss';

export type TStepEmploymentData = Partial<
  Pick<
    IEmployee,
    'legalEntityId' | 'employmentDate' | 'endOfProbationPeriod' | 'departmentId'
  >
>;

const PROBATION_PERIOD_DURATION = 3;

export interface IEmploymentDataFormProps {
  formId: string;
  submit: (values: TStepEmploymentData) => void;
  employeeId?: ID;
}

const FormContent = () => {
  const { getValues, watch, setValue } = useFormContext();
  const employmentDate = watch('employmentDate');

  useEffect(() => {
    const endOfProbationPeriod = getValues('endOfProbationPeriod');

    if (employmentDate && !endOfProbationPeriod) {
      const startDate = new Date(employmentDate);

      const newDate = startDate.setMonth(
        startDate.getMonth() + PROBATION_PERIOD_DURATION
      );

      setValue('endOfProbationPeriod', new Date(newDate).toISOString());
    }
  }, [employmentDate, getValues, setValue]);

  return (
    <div className={styles.form_container__form}>
      <div className={styles.form_container__form__section}>
        <FormSelect
          name="legalEntityId"
          label="Куда устроен (ИП)"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['legalEntities'],
              queryFn: requests.getLegalEntities,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
        <FormSelect
          name="departmentId"
          label="Отдел"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['department'],
              queryFn: requests.getDepartments,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.nameRu,
            }));
          }}
        />
        <FormDatepicker
          name="employmentDate"
          label="Начало работы"
          placeholder={datePlaceholder}
        />
        <FormDatepicker
          name="endOfProbationPeriod"
          label="Конец испытательного срока"
          placeholder={datePlaceholder}
        />
      </div>
    </div>
  );
};

export const EmploymentDataForm: FC<IEmploymentDataFormProps> = ({
  formId,
  submit,
  employeeId,
}) => {
  const [data, setData] = useState<TStepEmploymentData>({});
  const [isLoading, setLoading] = useState(false);

  const getEmployeeById = async (id: ID) => {
    setLoading(true);

    try {
      const { data } = await queryClient.fetchQuery(['employee'], () =>
        requests.getEmployeeDataById(id)
      );

      setData({
        legalEntityId: data.legalEntityId,
        departmentId: data.departmentId,
        employmentDate: data.employmentDate,
        endOfProbationPeriod: data.endOfProbationPeriod,
      });
    } catch (e) {
      console.log('Failed to fetch employee employment data :>> ', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getEmployeeById(employeeId);
    }
  }, [employeeId]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="gray-500" />
      </div>
    );
  }

  return (
    <div className={styles.form_container}>
      <Text
        size="text-20"
        weight={500}
        className={styles.form_container__heading}
        as="h2"
      >
        Трудоустройство
      </Text>
      <Form
        id={formId}
        initialValues={data}
        onSubmit={submit}
        validation={employmentInfoSchema}
      >
        <FormContent />
      </Form>
    </div>
  );
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule } from '../types/enums';
import { IRepeatedExpenses } from '../types/repeated-expenses';

export const getRepeatedExpenses =
  (module: EModule) => (): Promise<AxiosResponse<IRepeatedExpenses[]>> =>
    $host.get(`/${module}/config/repeated-expenses`);

export const getRepeatedExpensesById =
  (module: EModule) =>
  (expenseId: ID): Promise<AxiosResponse<IRepeatedExpenses>> =>
    $host.get(`/${module}/config/repeated-expenses`, {
      params: { expenseId },
    });

export const postRepeatedExpenses =
  (module: EModule) =>
  (values: IRepeatedExpenses): Promise<AxiosResponse<IRepeatedExpenses>> =>
    $host.post(`/${module}/config/repeated-expenses`, { ...values });

export const postRepeatedExpensesForcedActivation =
  (module: EModule) => (): Promise<AxiosResponse<IRepeatedExpenses>> =>
    $host.post(`/${module}/config/repeated-expenses/forced-activation`);

export const patchRepeatedExpensesById =
  (module: EModule) =>
  (
    id: ID,
    values: IRepeatedExpenses
  ): Promise<AxiosResponse<IRepeatedExpenses>> =>
    $host.patch(`/${module}/config/repeated-expenses/${id}`, { ...values });

export const deleteRepeatedExpensesById =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/repeated-expenses/${id}`);

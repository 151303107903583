import { FC, ReactNode } from 'react';

import cn from 'clsx';
import {
  ErrorWarningLine,
  CheckboxCircleLine,
  InformationLine,
} from 'design/icon';

import styles from './toast.module.scss';

export interface IToastProps {
  type: 'error' | 'success' | 'info';
  text: string;
}

const ICON_TYPE: Record<IToastProps['type'], ReactNode> = {
  error: <ErrorWarningLine />,
  success: <CheckboxCircleLine />,
  info: <InformationLine />,
};

export const Toast: FC<IToastProps> = ({ type, text }) => (
  <div className={cn(styles.toast, styles[type])}>
    {ICON_TYPE[type]}
    {text}
  </div>
);

import { FC } from 'react';

import { IEmployee } from 'components/api/types/employee';
import { ru } from 'date-fns/locale';
import { Divider } from 'design/divider';
import { Attachment } from 'design/icon';
import { Link } from 'design/link';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import styles from '../employee.module.scss';

export type TEducationProps = Pick<IEmployee, 'education'>;

export const Education: FC<TEducationProps> = ({ education }) => (
  <div className={styles.cardContent}>
    {!education?.length && (
      <div className={styles.no_data}>
        <Text size="text-14" as="span" weight={500}>
          Нет данных
        </Text>
      </div>
    )}
    {education.map(
      ({
        id,
        educationalInstitution,
        direction,
        degree,
        startDate,
        endDate,
        documents,
      }) => (
        <div key={id} className={styles.educationContent}>
          <div>
            <div className={styles.heading}>
              <Text size="text-14" weight={500} className={styles.text}>
                {educationalInstitution}
              </Text>
              <Divider />
            </div>
            <div className={styles.row}>
              <Text className={styles.title} size="text-14" weight={500}>
                Направление
              </Text>
              <Text className={styles.value} size="text-14">
                {direction}
              </Text>
            </div>
            <div className={styles.row}>
              <Text className={styles.title} size="text-14" weight={500}>
                Степень
              </Text>
              <Text className={styles.value} size="text-14">
                {degree.degree}
              </Text>
            </div>
            <div className={styles.row}>
              <Text className={styles.title} size="text-14" weight={500}>
                Период обучения
              </Text>
              <Text className={styles.value} size="text-14" capitalize>
                {`${formatDate(startDate, 'LLLL yyyy', ru)}
                - 
                ${formatDate(endDate, 'LLLL yyyy', ru)}`}
              </Text>
            </div>
          </div>
          {!!documents?.length && (
            <div className={styles.files}>
              {documents.map((file) => (
                <div key={file.id} className={styles.file}>
                  <div className={styles.fileName}>
                    <Attachment />
                    <Text size="text-12" weight={500}>
                      {file.key}
                    </Text>
                  </div>
                  <Link
                    href={file.url}
                    target="_blank"
                    className={styles.downloadBtn}
                  >
                    <Text size="text-12">Открыть файл</Text>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      )
    )}
  </div>
);

import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IOffice } from 'components/api/types/dictionary-types';
import { DeleteBinLine, Edit2Fill } from 'design/icon';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { OfficeForm } from '../forms/office';

import styles from '../dictionary.module.scss';

interface IOfficeListProps {
  offices: IOffice[];
}

export const OfficeList: FC<IOfficeListProps> = ({ offices }) => {
  const [officeId, setOfficeId] = useState<ID>();
  const { submit } = useSubmit();

  const handleDelete = (id: ID) => {
    submit({
      entity: 'office',
      data: {
        removedId: id,
      },
    });
  };

  const handleEdit = (id: ID) => {
    setOfficeId(id);
  };

  if (!offices.length) {
    return (
      <div className={styles.no_data}>
        <Text size="text-16" weight={500}>
          Нет данных
        </Text>
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {offices.map(({ id, name }) => (
        <li key={id}>
          <div className={styles.list__container}>
            {officeId !== id ? (
              <>
                <div className={styles.list__container__item}>
                  <Text size="text-16">{name}</Text>
                </div>
                <div className={styles.list__container__actions}>
                  <Edit2Fill onClick={() => handleEdit(id)} />
                  <DeleteBinLine onClick={() => handleDelete(id)} />
                </div>
              </>
            ) : (
              <OfficeForm id={id} name={name} setOfficeId={setOfficeId} />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IEducation, TEducationFormData } from '../types/employee';
import { EModule } from '../types/enums';

export const getEducationDataById =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<IEducation[]>> =>
    $host.get(`/${module}/employees/${employeeId}/education`, {
      params: { employeeId },
    });

export const patchEducationData =
  (module: EModule) =>
  (
    employeeId: ID,
    id: ID,
    values: Partial<TEducationFormData>
  ): Promise<AxiosResponse<IEducation>> =>
    $host.patch(`/${module}/employees/${employeeId}/education/${id}`, {
      ...values,
    });

export const postEducationData =
  (module: EModule) =>
  (
    employeeId: ID,
    values: Omit<TEducationFormData, 'id'>
  ): Promise<AxiosResponse<IEducation>> =>
    $host.post(`/${module}/employees/${employeeId}/education`, { ...values });

export const deleteEducationData =
  (module: EModule) =>
  (employeeId: ID, id: ID): Promise<AxiosResponse<IEducation>> =>
    $host.delete(`/${module}/employees/${employeeId}/education/${id}`);

import { useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { IEmployee } from 'components/api/types/employee';
import { Avatar } from 'design/avatar';
import { Button } from 'design/button';
import { Card } from 'design/card';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { ITab, Tabs } from 'design/tabs';
import { Text } from 'design/text';
import { fullNameEmployee } from 'utils';

import { General } from './general';
import { RevisionRequestModal } from './modal';
import { RevisionRequest } from './revision-request';

import styles from './salary.module.scss';

type TActiveTab = 'general' | 'review' | 'history';

const tabs: ITab[] = [
  {
    id: 'general',
    title: 'Главная',
  },
  {
    id: 'review',
    title: 'Запросы на пересмотр',
  },
  // ToDo: temporarily hide payment history
  // {
  //   id: 'history',
  //   title: 'История выплат',
  // },
];

export const SalaryPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState<TActiveTab>(
    (searchParams.get('filter') as TActiveTab | null) ?? 'general'
  );

  const { employee } = useLoaderData() as {
    employee: IEmployee;
  };

  useEffect(() => {
    const filter = searchParams.get('filter');

    setActiveTab(filter as TActiveTab);
  }, [searchParams, setSearchParams]);

  const openRevisionRequestModal = (id: ID) => {
    modal.open({
      id: 'create-revision-request-modal',
      title: 'Запрос на пересмотр заработной платы',
      formId: 'create-revision-request-day',
      content: <RevisionRequestModal id={id} />,
    });
  };

  if (!employee) {
    return null;
  }

  return (
    <div className={styles.employee}>
      <Card
        className={styles.generalCard}
        bodyClassName={styles.generalCardBody}
      >
        <div className={styles.general}>
          <div className={styles.infoTabsContainer}>
            <div className={styles.informationContainer}>
              <Avatar url={employee.photo?.url} name={employee.lastName} />
              <div className={styles.info}>
                <div>
                  <Text size="text-18" weight={500}>
                    {fullNameEmployee(
                      employee.firstName,
                      employee.lastName,
                      employee.middleName
                    )}
                  </Text>
                  <Text className={styles.position} size="text-14" weight={500}>
                    {employee.position?.nameRu ?? ''}
                  </Text>
                </div>
                <Text className={styles.office} size="text-14" weight={500}>
                  {employee.office?.name ?? ''}
                </Text>
              </div>
            </div>
            <Tabs
              active={activeTab}
              tabs={tabs}
              onChange={(value) => {
                setSearchParams({ filter: `${value}` });
              }}
            />
          </div>
          <div>
            <Button
              label="Запросить пересмотр"
              onClick={() => openRevisionRequestModal(employee.id)}
              prefixIcon={<AddBoxLine />}
            />
          </div>
        </div>
      </Card>
      {activeTab === 'general' && <General />}
      {activeTab === 'review' && <RevisionRequest />}
      {/* ToDo: temporarily hide payment history */}
      {/* {activeTab === 'history' && <div>history</div>} */}
    </div>
  );
};

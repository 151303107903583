import { IBankCard } from 'components/api/types/bank-card';

import { requests } from '../../api';

export interface ILoaderData {
  bankCards: IBankCard[];
}

export const loaders = () => [
  {
    name: 'bankCards',
    fn: requests.getBankCards,
  },
];

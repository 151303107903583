import { FC } from 'react';

import cn from 'clsx';
import { IEmployee } from 'components/api/types/employee';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Button } from 'design/button';
import { Card } from 'design/card';
import { modal } from 'design/modal/core';
import { Text } from 'design/text';

import { Education } from './education';
import { Employment } from './employment';
import { EmploymentHistory } from './employment-history';
import { EducationInformationModal } from './modals/education';
import { EmploymentInformationModal } from './modals/employment';
import { PersonalInformationModal } from './modals/personal';
import { WorkHistoryInformationModal } from './modals/work-history';
import { Personal } from './personal';

import styles from '../employee.module.scss';

export interface IProfileProps {
  employee: IEmployee;
}

export const Profile: FC<IProfileProps> = ({ employee }) => {
  const openEmploymentInformationModal = () => {
    modal.open({
      id: 'edit-employment-employee-info-modal',
      title: 'Данные о трудоустройстве – редактирование',
      formId: 'edit-employment-employee-info',
      content: (
        <EmploymentInformationModal
          formId="edit-employment-employee-info"
          legalEntityId={employee.legalEntityId}
          departmentId={employee.departmentId}
          employmentDate={employee.employmentDate}
          endOfProbationPeriod={employee.endOfProbationPeriod}
          positionId={employee.positionId}
          assignedHrId={employee.assignedHrId}
        />
      ),
    });
  };

  const openPersonalInformationModal = () => {
    modal.open({
      id: 'edit-general-employee-info-modal',
      title: 'Персональные данные – редактирование',
      formId: 'edit-general-employee-info',
      content: (
        <PersonalInformationModal
          formId="edit-general-employee-info"
          birthDate={employee.birthDate}
          email={employee.email}
          phone={employee.phone}
          gender={employee.gender}
          personalEmail={employee.personalEmail}
          englishLevel={employee.englishLevel}
        />
      ),
    });
  };

  const openEducationModal = () => {
    modal.open({
      id: 'edit-education-employee-info-modal',
      title: 'Данные об образовании – редактирование',
      formId: 'edit-education-employee-info',
      content: (
        <EducationInformationModal
          formId="edit-education-employee-info"
          employeeId={employee.id}
          education={employee.education}
        />
      ),
    });
  };

  const openWorkHistoryInformationModal = () => {
    modal.open({
      id: 'edit-work-history-employee-info-modal',
      title: 'Предыдущее место работы – редактирование',
      formId: 'edit-work-history-employee-info',
      content: (
        <WorkHistoryInformationModal
          formId="edit-work-history-employee-info"
          employeeId={employee.id}
          workHistory={employee.workHistory}
        />
      ),
    });
  };

  return (
    <div className={styles.profile}>
      <div className={styles.firstColumn}>
        <Card
          title={
            <div className={styles.cardHeader}>
              <Text size="text-18" weight={500}>
                Персональные данные
              </Text>
            </div>
          }
          footer={
            <div className={styles.cardFooter}>
              <Button
                label="Редактировать"
                size="small"
                onClick={openPersonalInformationModal}
              />
            </div>
          }
        >
          <Personal
            birthDate={employee.birthDate}
            gender={employee.gender}
            email={employee.email}
            phone={employee.phone}
            englishLevel={employee.englishLevel}
            personalEmail={employee.personalEmail}
          />
        </Card>
        <Card
          title={
            <div className={styles.cardHeader}>
              <Text size="text-18" weight={500}>
                Образование
              </Text>
            </div>
          }
          footer={
            <div className={styles.cardFooter}>
              <Button
                label="Редактировать"
                size="small"
                onClick={openEducationModal}
              />
            </div>
          }
        >
          <Education education={employee.education} />
        </Card>
      </div>
      <div className={styles.secondColumn}>
        <Card
          title={
            <div
              className={cn(styles.cardHeader, styles.employmentInformation)}
            >
              <Text size="text-18" weight={500}>
                Данные о трудоустройстве
              </Text>
              {!!employee.employmentDate && (
                <Text size="text-14" className={styles.experience}>
                  {`${formatDistance(
                    new Date(employee.employmentDate),
                    new Date(),
                    { locale: ru }
                  )}`}
                </Text>
              )}
            </div>
          }
          footer={
            <div className={styles.cardFooter}>
              <Button
                label="Редактировать"
                size="small"
                onClick={openEmploymentInformationModal}
              />
            </div>
          }
        >
          <Employment
            legalEntity={employee.legalEntity}
            employmentDate={employee.employmentDate}
            endOfProbationPeriod={employee.endOfProbationPeriod}
            department={employee.department}
            position={employee.position}
            head={employee.head}
            assignedHr={employee.assignedHr}
          />
        </Card>
        <Card
          title={
            <div
              className={cn(styles.cardHeader, styles.employmentInformation)}
            >
              <Text size="text-18" weight={500}>
                Предыдущее место работы
              </Text>
            </div>
          }
          footer={
            <div className={styles.cardFooter}>
              <Button
                label="Редактировать"
                size="small"
                onClick={openWorkHistoryInformationModal}
              />
            </div>
          }
        >
          <EmploymentHistory workHistory={employee.workHistory} />
        </Card>
      </div>
    </div>
  );
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IDismissal } from '../types/employee';
import { EModule } from '../types/enums';

export const postDismissal =
  (module: EModule) =>
  (
    employeeId: ID,
    dismissal: Omit<IDismissal, 'id'>
  ): Promise<AxiosResponse<IDismissal>> =>
    $host.post(`/${module}/employees/${employeeId}/dismissals`, {
      ...dismissal,
    });

export const patchDismissal =
  (module: EModule) =>
  (
    employeeId: ID,
    id: ID,
    dismissal: Omit<IDismissal, 'id'>
  ): Promise<AxiosResponse<IDismissal>> =>
    $host.patch(`/${module}/employees/${employeeId}/dismissals/${id}`, {
      ...dismissal,
    });

import { IBankAccount } from 'components/api/types/bank-account';
import { ID } from 'components/api/types/common';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  bankAccounts: async (data: {
    bankAccount: IBankAccount;
    bankAccountId: ID | null;
    removedId: ID | null;
  }) => {
    if (data.bankAccount && !data.bankAccount.owner) {
      data.bankAccount.owner = null;
    }

    if (data.bankAccount && !data.bankAccount.legalEntityId) {
      data.bankAccount.legalEntityId = null;
    }

    data.bankAccountId &&
      (await requests.patchBankAccountData(
        data.bankAccountId,
        data.bankAccount
      ));

    data.bankAccount &&
      !data.bankAccountId &&
      (await requests.postBankAccountData(data.bankAccount));

    data.removedId && (await requests.deleteBankAccountData(data.removedId));

    await queryClient.invalidateQueries({ queryKey: ['bankAccounts'] });
  },
};

import { FC } from 'react';

import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { Select, ISelectProps, IOption } from '../select';

import styles from './form.module.scss';

export interface IFormSelectProps {
  name: string;
  label: string;
  className?: string;
}

export const FormSelect: FC<ISelectProps & IFormSelectProps> = ({
  name,
  label,
  className,
  options,
  search,
  required,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({ field: { onChange, name, value }, fieldState: { error } }) => {
        const handleChange = (option: IOption) => {
          onChange(option.value);
        };

        return (
          <div className={cn(styles.container, className)}>
            <Select
              name={name}
              label={label}
              error={error?.message}
              value={value}
              options={options}
              onChange={handleChange}
              search={search}
              required={required}
            />
          </div>
        );
      }}
    />
  );
};

import { FC, useEffect } from 'react';

import { CalendarTable } from './calendar-table';
import { InfoTable } from './info-table';
import { IScheduleTableProps } from './types';

import styles from './schedule-table.module.scss';

export const ScheduleTable: FC<IScheduleTableProps> = ({ year, employees }) => {
  useEffect(() => {
    const el = document.getElementById('schedule-table');

    if (year && el?.scrollLeft) {
      if (el.scrollLeft > 0) {
        el.scrollLeft = 0;
      }
    }
  }, [year]);

  return (
    <div id="schedule-table" className={styles.container}>
      <div className={styles.container__table}>
        <InfoTable employees={employees} />
        <CalendarTable year={year} employees={employees} />
      </div>
    </div>
  );
};

import { FC } from 'react';

import { ID } from 'components/api/types/common';
import { IRevisionData } from 'components/api/types/salary';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { IModalOverride } from 'design/modal';
import { datePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { revisionSchema } from '../../../utils/validation';

import { SubForms } from './subForms';

import styles from './modal.module.scss';

interface IRevisionModalProps extends IModalOverride {
  id: ID;
  revisionRequestId?: ID;
}

type IFormValue = Omit<IRevisionData, 'employeeId' | 'revisionRequestId'>;

const FormContent = () => (
  <div className={styles.form}>
    <div className={styles.datepickerBlock}>
      <FormDatepicker
        required
        name="startDate"
        label="Дата начала"
        placeholder={datePlaceholder}
      />
      <FormInput name="comment" label="Комментарий" placeholder="Новая ЗП" />
    </div>
    <SubForms />
  </div>
);

export const RevisionModal: FC<IRevisionModalProps> = ({
  formId,
  setLoading,
  setConfirm,
  modalClose,
  id,
  revisionRequestId,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (data: IFormValue) => {
    submit({
      entity: 'revision',
      data: {
        revision: {
          ...data,
          employeeSalaryRevisionParts: data.employeeSalaryRevisionParts.map(
            ({ partCode, partTaxId, amount, bankAccountId }) => ({
              partCode,
              partTaxId: Number(partTaxId),
              amount: Number(amount),
              bankAccountId: Number(bankAccountId),
            })
          ),
          employeeId: id,
          revisionRequestId,
        },
      },
    });
  };

  return (
    <Form
      id={formId}
      validation={revisionSchema}
      initialValues={{
        employeeSalaryRevisionParts: [
          { partCode: 'mainWhiteSalary', amount: '0' },
          { partCode: 'addWhiteSalary', amount: '0' },
          { partCode: 'ndaWhiteSalary', amount: '0' },
          { partCode: 'bonusWhiteSalary', amount: '0' },
          { partCode: 'addCash', amount: '0' },
        ],
      }}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      onSubmit={onSubmit}
    >
      <FormContent />
    </Form>
  );
};

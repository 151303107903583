import { LoaderFunctionArgs } from 'react-router-dom';

import { AnyType } from 'utils/types';

import { requests } from '../../api';

export const loaders = ({ request }: LoaderFunctionArgs<AnyType>) => [
  {
    name: 'salaries',
    fn: () => {
      const filter = new URL(request.url).searchParams.get('filter');
      const search = new URL(request.url).searchParams.get('search');

      return requests.getSalaries(filter === 'dismissed', search);
    },
  },
];

import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IOffice } from 'components/api/types/dictionary-types';
import { Button } from 'design/button';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { useFormContext } from 'react-hook-form';
import { useSubmit } from 'utils/useSubmit';

import { officeSchema } from '../../../utils/validation';

import { IFormContentProps } from './type';

import styles from './forms.module.scss';

export type TOfficeFormProps = Partial<
  IOffice & { setOfficeId: (value: ID) => void }
>;

type TFormValue = Omit<IOffice, 'id'>;

export const OfficeFormContent: FC<IFormContentProps> = ({
  loading,
  isEdit,
  setId,
  setSuccess,
  isSuccess,
}) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      reset({ name: '' });
      setSuccess(false);
    }
  }, [isSuccess, reset, setSuccess]);

  return (
    <div
      className={cn(styles.form, {
        [styles.container]: !isEdit,
      })}
    >
      <div className={styles.form__section}>
        <FormInput required name="name" label="Город" />
      </div>
      {isEdit ? (
        <div className={styles.form__button}>
          <Button
            label="Сохранить"
            loading={loading}
            type="submit"
            buttonType="secondary"
          />
          <Button
            label="Отмена"
            type="button"
            buttonType="secondary"
            onClick={() => setId?.('')}
          />
        </div>
      ) : (
        <Button
          label="Добавить"
          loading={loading}
          type="submit"
          className={styles.form__add}
        />
      )}
    </div>
  );
};

export const OfficeForm: FC<TOfficeFormProps> = ({ id, name, setOfficeId }) => {
  const [isSuccess, setSuccess] = useState(false);

  const { loading, submit } = useSubmit(() => {
    setOfficeId?.('');
    setSuccess(true);
  });

  const onSubmit = (value: TFormValue) => {
    submit({
      entity: 'office',
      data: {
        office: value,
        officeId: id,
      },
    });
  };

  return (
    <Form
      initialValues={{ name }}
      onSubmit={onSubmit}
      validation={officeSchema}
    >
      <OfficeFormContent
        isEdit={!!id}
        loading={loading}
        setId={setOfficeId}
        setSuccess={setSuccess}
        isSuccess={isSuccess}
      />
    </Form>
  );
};

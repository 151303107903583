import { FC } from 'react';

import { ISalaryPaymentDayConfig } from 'components/api/types/salary';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { datePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { salaryPaymentDaysSchema } from '../../../utils/validation';
import { paymentFunctionDictionary } from '../constant';

import styles from './modal.module.scss';

interface ISalaryPaymentDaysModalProps extends IModalOverride {
  initialValue?: ISalaryPaymentDayConfig;
}

interface IFormContentProps {
  isEdit: boolean;
}

const FormContent: FC<IFormContentProps> = ({ isEdit }) => (
  <div className={styles.form}>
    {!isEdit && (
      <>
        <FormSelect
          required
          name="paymentFunctionId"
          label="Тип"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['paymentFunctions'],
              queryFn: requests.getPaymentFunctions,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: paymentFunctionDictionary[option.name],
            }));
          }}
        />
        <FormSelect
          required
          name="legalEntityId"
          label="Юр. лицо"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['legalEntities'],
              queryFn: requests.getLegalEntities,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
      </>
    )}
    <FormDatepicker
      required
      name="periodStartDate"
      label="Дата начала периода"
      placeholder={datePlaceholder}
    />
    <FormDatepicker
      required
      name="periodEndDate"
      label="Дата окончания периода"
      placeholder={datePlaceholder}
    />
    <FormDatepicker
      required
      name="paymentDate"
      label="Дата выплаты"
      placeholder={datePlaceholder}
    />
  </div>
);

export const SalaryPaymentDaysModal: FC<ISalaryPaymentDaysModalProps> = ({
  formId,
  initialValue,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (data: ISalaryPaymentDayConfig) => {
    submit({
      entity: 'salaryPaymentDays',
      data: {
        salaryPaymentDay: {
          ...data,
        },
        salaryPaymentDayId: initialValue ? data.id : null,
      },
    });
  };

  return (
    <Form
      id={formId}
      initialValues={initialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={
        !initialValue
          ? salaryPaymentDaysSchema
          : salaryPaymentDaysSchema.omit(['paymentFunctionId', 'legalEntityId'])
      }
    >
      <FormContent isEdit={!!initialValue} />
    </Form>
  );
};

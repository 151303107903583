import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule, EPaymentStatus } from '../types/enums';
import {
  IExpensesTransactions,
  IExpensesTransactionsId,
  ISelectedExpensesTransaction,
  TNewExpensesTransactions,
} from '../types/expenses-transactions';

export const getExpensesTransactions =
  (module: EModule) =>
  (status?: EPaymentStatus): Promise<AxiosResponse<IExpensesTransactions[]>> =>
    $host.get(`/${module}/expenses-transactions`, { params: { status } });

export const getExpensesTransactionsById =
  (module: EModule) =>
  (categoryId: ID): Promise<AxiosResponse<IExpensesTransactions>> =>
    $host.get(`/${module}/expenses-transactions`, {
      params: { categoryId },
    });

export const postExpensesTransactions =
  (module: EModule) =>
  (
    values: TNewExpensesTransactions
  ): Promise<AxiosResponse<IExpensesTransactions>> =>
    $host.post(`/${module}/expenses-transactions`, { ...values });

export const patchExpensesTransactionsById =
  (module: EModule) =>
  (
    id: ID,
    values: TNewExpensesTransactions
  ): Promise<AxiosResponse<IExpensesTransactions>> =>
    $host.patch(`/${module}/expenses-transactions/${id}`, { ...values });

export const patchExpensesTransactions =
  (module: EModule) =>
  (
    values: ISelectedExpensesTransaction
  ): Promise<AxiosResponse<IExpensesTransactions>> =>
    $host.patch(`/${module}/expenses-transactions`, { ...values });

export const deleteExpensesTransactionsById =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/expenses-transactions/${id}`);

export const deleteExpensesTransactions =
  (module: EModule) =>
  (values: IExpensesTransactionsId): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/expenses-transactions`, {
      data: {
        ...values,
      },
    });

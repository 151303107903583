import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule } from '../types/enums';
import { IRevisionData, IRevisionResponse } from '../types/salary';

export const postRevisionData =
  (module: EModule) =>
  (
    employeeId: ID,
    values: IRevisionData
  ): Promise<AxiosResponse<IRevisionResponse>> =>
    $host.post(`/${module}/employees/${employeeId}/salary-revisions`, {
      ...values,
    });

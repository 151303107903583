import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { IBankCard } from '../types/bank-card';
import { ID } from '../types/common';
import { EModule } from '../types/enums';

export const getBankCards =
  (module: EModule) => (): Promise<AxiosResponse<IBankCard[]>> =>
    $host.get(`/${module}/config/bank-cards`);

export const postBankCardData =
  (module: EModule) =>
  (values: Omit<IBankCard, 'id'>): Promise<AxiosResponse<IBankCard>> =>
    $host.post(`/${module}/config/bank-cards`, { ...values });

export const patchBankCardData =
  (module: EModule) =>
  (id: ID, values: Omit<IBankCard, 'id'>): Promise<AxiosResponse<IBankCard>> =>
    $host.patch(`/${module}/config/bank-cards/${id}`, { ...values });

export const deleteBankCardData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/bank-cards/${id}`);

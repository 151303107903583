import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule } from '../types/enums';
import { ITax } from '../types/salary';

export const getTaxes =
  (module: EModule) => (): Promise<AxiosResponse<ITax[]>> =>
    $host.get(`/${module}/config/tax-configurations`);

export const postTaxData =
  (module: EModule) =>
  (
    values: Omit<ITax, 'id' | 'createdDate' | 'updatedDate' | 'deletedDate'>
  ): Promise<AxiosResponse<ITax>> =>
    $host.post(`/${module}/config/tax-configurations`, { ...values });

export const patchTaxData =
  (module: EModule) =>
  (
    id: ID,
    values: Omit<ITax, 'id' | 'createdDate' | 'updatedDate' | 'deletedDate'>
  ): Promise<AxiosResponse<ITax>> =>
    $host.patch(`/${module}/config/tax-configurations/${id}`, { ...values });

export const deleteTaxData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/tax-configurations/${id}`);

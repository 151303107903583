import { IModalContent } from '../types';

export const enum Event {
  Open,
  Close,
}

type OnOpenCallback = (content?: IModalContent) => void;
type OnCloseCallback = () => void;

type Callback = OnOpenCallback | OnCloseCallback;

export interface IModalManager {
  list: Map<Event, Callback[]>;
  on(event: Event.Open, callback: OnOpenCallback): IModalManager;
  on(event: Event.Close, callback: OnCloseCallback): IModalManager;
  off(event: Event, callback?: Callback): IModalManager;
  emit(event: Event.Open, content?: IModalContent): void;
  emit(event: Event.Close): void;
}

export const modalManager: IModalManager = {
  list: new Map(),

  on(event: Event, callback: Callback) {
    if (this.list.has(event)) {
      this.list.set(event, [
        ...(this.list.get(event)?.filter((cb) => cb !== callback) ?? []),
        callback,
      ]);
    } else {
      this.list.set(event, [callback]);
    }

    return this;
  },

  off(event: Event, callback: Callback) {
    if (callback && this.list.has(event)) {
      const cb = this.list.get(event)?.filter((cb) => cb !== callback) ?? [];
      this.list.set(event, cb);

      return this;
    }
    this.list.delete(event);

    return this;
  },

  emit(event: Event, content?: IModalContent) {
    const list = this.list.get(event);

    if (list?.length) {
      list.forEach((callback: Callback) => {
        callback(content);
      });
    }
  },
};

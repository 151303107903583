import React, { useRef } from 'react';

import cn from 'clsx';
import { CSSTransition } from 'react-transition-group';

import { Button, IButtonProps } from '../button';
import { useDropdownToggle } from '../common/hooks';
import { ArrowDown } from '../icon';
import { Text } from '../text';

import styles from './dropdown-button.module.scss';

export interface IAction {
  label: string;
  onClick: () => void;
  icon?: JSX.Element;
  variant?: 'default' | 'red';
}

export interface IDropdownButtonProps
  extends Pick<IButtonProps, 'size' | 'buttonType' | 'disabled'> {
  className?: string;
  label: string;
  actions: IAction[];
}

export const DropdownButton: React.FC<IDropdownButtonProps> = ({
  label,
  size,
  buttonType,
  actions,
  disabled,
  ...buttonProps
}) => {
  const optionsListRef = useRef(null);
  const { isOpen, setIsOpen, rootRef } = useDropdownToggle();

  const handleToggleOpen = () => {
    setIsOpen((v) => !v);
  };

  return (
    <div ref={rootRef} className={styles.dropdown_button}>
      <Button
        label={label}
        size={size}
        className={styles.dropdown_button__button}
        buttonType={buttonType}
        type="button"
        disabled={disabled}
        onClick={handleToggleOpen}
        suffixIcon={
          <div className={styles.dropdown_button__icon}>
            <div className={styles.dropdown_button__icon__divider} />
            <div
              className={cn(styles.dropdown_button__icon__arrow, {
                [styles.dropdown_button__icon__arrow_open]: isOpen,
              })}
            >
              <ArrowDown />
            </div>
          </div>
        }
        {...buttonProps}
      />
      <CSSTransition
        nodeRef={optionsListRef}
        in={isOpen}
        timeout={0}
        unmountOnExit
        classNames={{
          enterActive: styles.enter_active,
          enterDone: styles.enter_done,
          exit: styles.exit,
          exitActive: styles.exit_active,
        }}
      >
        <div
          className={cn(styles.actions, {
            [styles.actions_open]: isOpen,
          })}
          ref={optionsListRef}
        >
          {actions.map((action) => (
            <div
              key={action.label}
              className={cn(
                styles.actions__action,
                styles.actions__action_default,
                {
                  [styles.actions__action_red]: action.variant === 'red',
                }
              )}
              onClick={() => {
                setIsOpen(false);
                action.onClick();
              }}
            >
              {!!action.icon && (
                <div className={styles.actions__action_icon}>{action.icon}</div>
              )}
              <Text size="text-12" weight={500}>
                {action.label}
              </Text>
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

import { FC, useState } from 'react';

import cn from 'clsx';

import { Button } from '../button';
import { CloseLine } from '../icon';
import { Input, TInputProps } from '../input';
import { Text } from '../text';

import styles from './uploader.module.scss';

export interface IUploaderValue {
  key: string;
  url: string;
}

export type TUploader = Omit<TInputProps, 'onChange'> & {
  labelButton: string;
  value?: IUploaderValue[];
  onChange?: (value: IUploaderValue[]) => void;
};

export const Uploader: FC<TUploader> = ({
  label,
  labelButton,
  placeholder,
  className,
  error,
  required,
  value,
  onBlur,
  onChange,
}) => {
  const [link, setLink] = useState('');

  const [uploaderValues, setUploaderValues] = useState<IUploaderValue[]>(
    value ?? []
  );

  const addLink = () => {
    setUploaderValues((prev) => [
      ...prev,
      {
        key: link,
        url: link,
      },
    ]);

    setLink('');

    onChange?.([
      ...uploaderValues,
      {
        key: link,
        url: link,
      },
    ]);
  };

  const removeLink = (link: string) => {
    setUploaderValues((prev) => prev.filter((el) => el.key !== link));

    onChange?.(uploaderValues.filter((el) => el.key !== link));
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.container__files}>
        {uploaderValues.map((value) => (
          <div key={value.key} className={styles.container__files__item}>
            <Text
              size="text-12"
              className={styles.container__files__item__link}
            >
              {value.url}
            </Text>
            <CloseLine
              className={styles.container__files__item__remove}
              onClick={() => removeLink(value.key)}
            />
          </div>
        ))}
      </div>
      <div className={styles.container__uploader}>
        <Input
          label={label}
          placeholder={placeholder}
          error={error}
          value={link}
          onBlur={onBlur}
          onChange={(e) => setLink(e.target.value)}
          required={required}
          className={styles.container__uploader__input}
        />
        <Button
          disabled={!link}
          type="button"
          label={labelButton}
          className={styles.container__uploader__button}
          onClick={addLink}
        />
      </div>
    </div>
  );
};

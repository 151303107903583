export const emailPlaceholder = 'email@fojin.tech';

export const phonePlaceholder = '+79999999999';

export const datePlaceholder = '01.01.2000';

export const namePlaceholder = 'Иванов Иван Иванович';

export const educationPlaceholder = 'Южный федеральный университет';

export const directionPlaceholder = 'Информационные системы';

export const linkPlaceholder = 'wwww.example.com';

export const positionPlaceholder = 'Руководитель';

export const companyNamePlaceholder = 'ООО Компания';

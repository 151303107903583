import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IDismissalReason } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getDismissalReasons =
  (module: EModule) => (): Promise<AxiosResponse<IDismissalReason[]>> =>
    $host.get(`/${module}/dictionary/dismissal-reasons`);

export const patchDismissalReasonData =
  (module: EModule) =>
  (
    id: ID,
    values: Partial<IDismissalReason>
  ): Promise<AxiosResponse<IDismissalReason>> =>
    $host.patch(`/${module}/dictionary/dismissal-reasons/${id}`, { ...values });

export const postDismissalReasonData =
  (module: EModule) =>
  (
    values: Omit<IDismissalReason, 'id'>
  ): Promise<AxiosResponse<IDismissalReason>> =>
    $host.post(`/${module}/dictionary/dismissal-reasons`, { ...values });

export const deleteDismissalReasonData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IDismissalReason>> =>
    $host.delete(`/${module}/dictionary/dismissal-reasons/${id}`);

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IExitInterviewQuestion } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getExitInterviewQuestions =
  (module: EModule) =>
  (
    active?: IExitInterviewQuestion['isActive']
  ): Promise<AxiosResponse<IExitInterviewQuestion[]>> =>
    $host.get(`/${module}/dictionary/exit-interview-questions`, {
      params: { isActive: active },
    });

export const patchExitInterviewQuestionData =
  (module: EModule) =>
  (
    id: ID,
    values: Partial<IExitInterviewQuestion>
  ): Promise<AxiosResponse<IExitInterviewQuestion>> =>
    $host.patch(`/${module}/dictionary/exit-interview-questions/${id}`, {
      ...values,
    });

export const postExitInterviewQuestionData =
  (module: EModule) =>
  (
    values: Omit<IExitInterviewQuestion, 'id'>
  ): Promise<AxiosResponse<IExitInterviewQuestion>> =>
    $host.post(`/${module}/dictionary/exit-interview-questions`, { ...values });

export const deleteExitInterviewQuestionData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IExitInterviewQuestion>> =>
    $host.delete(`/${module}/dictionary/exit-interview-questions/${id}`);

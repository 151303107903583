import { FC } from 'react';

import { IMissingDayInfo } from 'components/api/types/employee';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import { getWordDayForm } from '../../../utils';

import styles from '../employee.module.scss';

type TSickCard = Pick<
  IMissingDayInfo,
  'sickDaysLeft' | 'sickDaysTaken' | 'employmentDate'
> & { countDaysOff: number };

export const SickCard: FC<TSickCard> = ({
  sickDaysLeft,
  sickDaysTaken,
  employmentDate,
  countDaysOff,
}) => (
  <div className={styles.cardContent}>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Баланс больничных
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {`${sickDaysLeft ?? 0} ${getWordDayForm(sickDaysLeft ?? 0)}`}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Больничных взято
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {`${sickDaysTaken ?? 0} ${getWordDayForm(sickDaysTaken ?? 0)}`}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Начало работы
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {employmentDate ? formatDate(employmentDate) : 'Не указанно'}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" as="span" weight={500}>
        Количество отгулов
      </Text>
      <Text className={styles.value} size="text-14" as="span">
        {`${countDaysOff ?? 0} ${getWordDayForm(countDaysOff ?? 0)}`}
      </Text>
    </div>
  </div>
);

import { useLoaderData } from 'react-router-dom';

import { IBankAccount } from 'components/api/types/bank-account';
import { ID } from 'components/api/types/common';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { BankAccountsModal } from './modal';

import styles from './bank-accounts.module.scss';

const openCreateBankAccountModal = () => {
  modal.open({
    id: 'create-bank-account-modal',
    title: 'Создание нового счета',
    formId: 'create-bank-account',
    content: <BankAccountsModal />,
  });
};

const openEditBankAccountModal = (initialValue: IBankAccount) => {
  modal.open({
    id: 'create-bank-account-modal',
    title: 'Создание нового счета',
    formId: 'create-bank-account',
    content: <BankAccountsModal initialValue={initialValue} />,
  });
};

export const BankAccountsPage = () => {
  const { submit } = useSubmit();

  const { bankAccounts } = useLoaderData() as {
    bankAccounts: IBankAccount[];
  };

  const handleDelete = (id: ID) => {
    submit({
      entity: 'bankAccounts',
      data: {
        removedId: id,
      },
    });
  };

  const columns: TTableColumn<IBankAccount>[] = [
    {
      title: 'Номер счета',
      dataIndex: ['accountNumber'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Название банка',
      dataIndex: ['bankName'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Валюта',
      dataIndex: ['currencyId'],
      sortable: true,
      sorting: textSorting,
      render: ({ currency }) => (
        <Text size="text-14" as="span">
          {currency?.name ?? ''}
        </Text>
      ),
    },
    {
      title: 'Юр Лицо',
      dataIndex: ['legalEntityId'],
      sortable: true,
      sorting: textSorting,
      render: ({ legalEntity }) => (
        <Text size="text-14" as="span">
          {legalEntity?.name ?? ''}
        </Text>
      ),
    },
    {
      title: 'Фио',
      dataIndex: ['owner'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Действие',
      actions: {
        onDelete: handleDelete,
        onEdit: openEditBankAccountModal,
      },
    },
  ];

  return (
    <div className={styles.salaries}>
      <div className={styles.header}>
        <Text size="text-20" weight={500} className={styles.heading}>
          Счета
        </Text>
      </div>
      <div className={styles.body}>
        <div className={styles.tableHeader}>
          <Button
            label="Создать новый счет"
            onClick={openCreateBankAccountModal}
            prefixIcon={<AddBoxLine />}
          />
        </div>
        <ContentContainer>
          <Table columns={columns} data={bankAccounts} key="id" />
        </ContentContainer>
      </div>
    </div>
  );
};

import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import cn from 'clsx';
import { INonWorkingDay } from 'components/api/types/dictionary-types';
import { ru } from 'date-fns/locale';
import { Card } from 'design/card';
import { ArrowLeft } from 'design/icon';
import { Text } from 'design/text';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { formatDate } from 'utils';
import { useSubmit } from 'utils/useSubmit';

import styles from './non-working-days.module.scss';

const monthData = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

const country = 'Россия';

const formatMonth = (date: Date) => {
  const mounth = new Intl.DateTimeFormat('ru-RU', {
    month: 'long',
  }).format(date);

  return mounth.charAt(0).toUpperCase() + mounth.slice(1);
};

const getLastDayOfMonth = (year: number, month: string) =>
  new Date(year, Number(month), 0).getDate();

export const NonWorkingDays = () => {
  const [year, setYear] = useState(Number(formatDate(undefined, 'yyyy')));
  const { submit } = useSubmit();

  registerLocale('ru', ru);

  const { nonWorkingDays } = useLoaderData() as {
    nonWorkingDays: INonWorkingDay[];
  };

  const handleDateChange = (value: Date | null) => {
    const selectedDate = nonWorkingDays.find(
      ({ date }) => date === formatDate(`${value}`, 'yyyy-MM-dd')
    );

    if (selectedDate) {
      submit({
        entity: 'nonWorkingDays',
        data: {
          removedId: selectedDate.id,
        },
      });
    } else {
      submit({
        entity: 'nonWorkingDays',
        data: {
          nonWorkingDay: {
            country,
            date: formatDate(`${value}`, 'yyyy-MM-dd'),
          },
        },
      });
    }
  };

  const renderDayContents = (month: string, dateContent?: Date) => {
    const minDate = new Date(`${year}-${month}-1`);

    const maxDate = new Date(
      `${year}-${month}-${getLastDayOfMonth(year, month)}`
    );

    if (dateContent) {
      if (dateContent < minDate || dateContent > maxDate) {
        return;
      }

      return (
        <div
          className={cn(styles.container__datepicker__content, {
            [styles.container__datepicker__content_selected]:
              !!nonWorkingDays.find(
                ({ date }) =>
                  date === formatDate(`${dateContent}`, 'yyyy-MM-dd')
              ),
          })}
        >
          {dateContent.getDate()}
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <Text size="text-20" weight={500}>
        Конфигурация рабочих дней
      </Text>
      <Card>
        <div className={styles.container__year}>
          <ArrowLeft onClick={() => setYear((prev) => prev - 1)} />
          <Text weight={500} as="span">
            {`${year}`}
          </Text>
          <ArrowLeft
            onClick={() => setYear((prev) => prev + 1)}
            className={styles.container__year_right}
          />
        </div>
        <div className={styles.container__datepicker}>
          {monthData.map((month) => (
            <ReactDatePicker
              key={`month-${month}`}
              inline
              minDate={new Date(`${year}-${month}-01`)}
              maxDate={
                new Date(`${year}-${month}-${getLastDayOfMonth(year, month)}`)
              }
              onChange={handleDateChange}
              selected={new Date(`${year}-${month}-01`)}
              locale="ru"
              renderCustomHeader={({ date }) => (
                <span className={styles.container__datepicker__header}>
                  {formatMonth(date)}
                </span>
              )}
              renderDayContents={(_dayContent, dateContent) =>
                renderDayContents(month, dateContent)
              }
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

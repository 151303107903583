import { FC } from 'react';

import { IEmployee } from 'components/api/types/employee';
import { Form } from 'design/form';
import { IModalOverride } from 'design/modal';
import { useSubmit } from 'utils/useSubmit';

import { generalInfoSchema } from '../../../../utils/validation';
import { CommonForm } from '../../../new-employee/forms/common';

type TEmployeeEditInformationData = Pick<
  IEmployee,
  'officeId' | 'positionId'
> & { name: string };

export type TEditEmployeeModalProps = TEmployeeEditInformationData & {
  formId: string;
} & IModalOverride;

export const EmployeeEditModal: FC<TEditEmployeeModalProps> = ({
  formId,
  officeId,
  positionId,
  name,
  setConfirm,
  modalClose,
  setLoading,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (values: TEmployeeEditInformationData) => {
    const [lastName, firstName, middleName] = (values.name ?? '').split(' ');

    const formData = {
      ...values,
      lastName,
      firstName,
      middleName: middleName ?? null,
      name: values.name.trim(),
    };

    submit({
      entity: 'personal',
      data: formData,
    });
  };

  return (
    <Form
      id={formId}
      initialValues={{ name, officeId, positionId }}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={generalInfoSchema}
    >
      <CommonForm variant="basic" />
    </Form>
  );
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IEducationDegree } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getEducationDegrees =
  (module: EModule) => (): Promise<AxiosResponse<IEducationDegree[]>> =>
    $host.get(`/${module}/dictionary/education-degrees`);

export const patchEducationDegreeData =
  (module: EModule) =>
  (
    id: ID,
    values: Partial<IEducationDegree>
  ): Promise<AxiosResponse<IEducationDegree>> =>
    $host.patch(`/${module}/dictionary/education-degrees/${id}`, { ...values });

export const postEducationDegreeData =
  (module: EModule) =>
  (
    values: Omit<IEducationDegree, 'id'>
  ): Promise<AxiosResponse<IEducationDegree>> =>
    $host.post(`/${module}/dictionary/education-degrees`, { ...values });

export const deleteEducationDegreeData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IEducationDegree>> =>
    $host.delete(`/${module}/dictionary/education-degrees/${id}`);

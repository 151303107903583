import { useCallback, useEffect, useState } from 'react';

import { modalManager, Event } from '../core';
import { IModalContent } from '../types';

export function useModal() {
  const [modalContent, setModalContent] = useState<IModalContent>();

  const modalOpen = useCallback((content?: IModalContent) => {
    document.body.style.overflow = 'hidden';
    setModalContent(content);
  }, []);

  const modalClose = useCallback(() => {
    document.body.style.overflow = 'unset';
    setModalContent(undefined);
  }, []);

  useEffect(() => {
    modalManager.on(Event.Open, modalOpen).on(Event.Close, modalClose);

    return () => {
      modalManager.off(Event.Open, modalOpen).off(Event.Close, modalClose);
    };
  }, [modalClose, modalOpen]);

  return {
    modalContent,
    modalClose,
  };
}

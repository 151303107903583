import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IEmployee, TWorkHistoryFormData } from 'components/api/types/employee';
import { queryClient } from 'components/context/query';
import { Button } from 'design/button';
import { Divider } from 'design/divider';
import { Form, IFormChildrenProps } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { FormUploader } from 'design/form/form-uploader';
import { Spinner } from 'design/spinner';
import { Text } from 'design/text';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  companyNamePlaceholder,
  positionPlaceholder,
  datePlaceholder,
  linkPlaceholder,
} from 'utils/placeholder-text';

import { requests } from '../../../api';
import { workHistorySchema } from '../../../utils/validation';

import styles from './forms.module.scss';

export interface IStepWorkHistoryData {
  workHistory: TWorkHistoryFormData[];
}

export type TEmployeeWorkHistory = Partial<Pick<IEmployee, 'workHistory'>>;

export interface IEmploymentHistoryDataFormProps {
  formId: string;
  submit: (values: IStepWorkHistoryData, removedIds?: ID[]) => void;
  employeeId?: ID;
}

export interface IEmploymentFormProps {
  index: number;
  removeEmployment: () => void;
}

export interface IFormContentProps extends IFormChildrenProps {
  handleRemove: (id: ID) => void;
}

export const INITIAL_VALUE_WORK_HISTORY = {
  companyName: '',
  position: '',
};

export const EmploymentForm: FC<IEmploymentFormProps> = ({
  index,
  removeEmployment,
}) => (
  <>
    <div className={styles.form_container__form__section_heading}>
      <Text size="text-16" weight={500} className={styles.text} as="h3">
        {`Место работы ${index + 1}`}
      </Text>
      <Divider />
      <Button
        buttonType="secondary"
        size="small"
        label="Удалить"
        type="button"
        onClick={removeEmployment}
      />
    </div>
    <div
      className={cn(styles.form_container__form__section, styles.employment)}
    >
      <FormInput
        required
        name={`workHistory.${index}.companyName`}
        label="Место работы"
        placeholder={companyNamePlaceholder}
      />
      <FormInput
        required
        name={`workHistory.${index}.position`}
        label="Должность"
        placeholder={positionPlaceholder}
      />
      <FormDatepicker
        required
        name={`workHistory.${index}.startDate`}
        label="Начало работы"
        placeholder={datePlaceholder}
      />
      <FormDatepicker
        required
        name={`workHistory.${index}.endDate`}
        label="Окончание работы"
        placeholder={datePlaceholder}
      />
      <FormUploader
        name={`workHistory.${index}.documents`}
        className={styles.fileUploader}
        placeholder={linkPlaceholder}
      />
    </div>
  </>
);

export const EmploymentHistoryFormContent: FC<IFormContentProps> = ({
  form,
  handleRemove,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: form?.control,
    name: 'workHistory',
  });

  const { getValues } = useFormContext();

  const formData = getValues();

  const addNewEmployment = () => {
    append(INITIAL_VALUE_WORK_HISTORY);
  };

  const removeEmployment = async (index: number, id?: ID) => {
    remove(index);

    if (id) {
      handleRemove(id);
    }
  };

  return (
    <div className={styles.form_container__form}>
      {fields.map((employment, index) => (
        <EmploymentForm
          index={index}
          key={employment.id}
          removeEmployment={() =>
            removeEmployment(index, formData.workHistory[index].id)
          }
        />
      ))}
      <div className={styles.form_container__form__section_heading}>
        <Divider />
        <Button
          buttonType="secondary"
          size="small"
          label="Добавить место работы"
          className={styles.add}
          onClick={addNewEmployment}
          type="button"
        />
      </div>
    </div>
  );
};

export const EmploymentHistoryDataForm: FC<IEmploymentHistoryDataFormProps> = ({
  formId,
  submit,
  employeeId,
}) => {
  const [data, setData] = useState<TEmployeeWorkHistory>({
    workHistory: [],
  });

  const [removedIds, setRemovedIds] = useState<ID[]>([]);

  const [isLoading, setLoading] = useState(false);

  const getЕducationById = async (id: ID) => {
    setLoading(true);

    try {
      const { data } = await queryClient.fetchQuery(['employee'], () =>
        requests.getWorkHistoryDataById(id)
      );

      setData({ workHistory: data });
    } catch (e) {
      console.log('Failed to fetch employee work history data :>> ', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getЕducationById(employeeId);
    }
  }, [employeeId]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="gray-500" />
      </div>
    );
  }

  const onSubmit = (values: TEmployeeWorkHistory) => {
    const formData = values.workHistory?.map((history) => ({
      ...history,
      employeeId: employeeId,
      documents: history.documents?.map((el) => el.url),
    })) as TWorkHistoryFormData[];

    submit({ workHistory: formData }, removedIds);
  };

  const handleRemove = (id: ID) => {
    setRemovedIds((prewIds: ID[]) => [...prewIds, id]);
  };

  return (
    <div className={styles.form_container}>
      <Text
        size="text-20"
        weight={500}
        className={styles.form_container__heading}
        as="h2"
      >
        Предыдущие места работы
      </Text>
      <Form
        id={formId}
        initialValues={data}
        onSubmit={onSubmit}
        validation={workHistorySchema}
      >
        <EmploymentHistoryFormContent handleRemove={handleRemove} />
      </Form>
    </div>
  );
};

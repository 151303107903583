import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule } from '../types/enums';
import { IMoneyBalance } from '../types/money-balances';

export const getMoneyBalances =
  (module: EModule) => (): Promise<AxiosResponse<IMoneyBalance[]>> =>
    $host.get(`/${module}/money-balances`);

export const getMoneyBalanceById =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IMoneyBalance>> =>
    $host.get(`/${module}/money-balances/${id}`);

export const postMoneyBalanceData =
  (module: EModule) =>
  (values: Omit<IMoneyBalance, 'id'>): Promise<AxiosResponse<IMoneyBalance>> =>
    $host.post(`/${module}/money-balances`, { ...values });

export const patchMoneyBalanceData =
  (module: EModule) =>
  (
    id: ID,
    values: Omit<IMoneyBalance, 'id'>
  ): Promise<AxiosResponse<IMoneyBalance>> =>
    $host.patch(`/${module}/money-balances/${id}`, { ...values });

export const deleteMoneyBalanceData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/money-balances/${id}`);

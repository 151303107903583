import {
  EGender,
  EEnglishLevel,
  EMissingDaysStatus,
  EPaymentStatus,
} from 'components/api/types/enums';
import {
  requiredField,
  fieldFromLetters,
  incorrectData,
  startDate,
  endDate,
  startDateEqual,
  endDateEqual,
} from 'utils/validation-messages';
import { mixed, string, number, object, array, addMethod } from 'yup';

import { emailRegex, letterRegex, phoneRegex } from './constants';

addMethod(
  string,
  'compareDates',
  function compareDates(
    field: string,
    message: string,
    isLess: boolean,
    isEqual: boolean
  ) {
    return this.test('compare-two-date-fields', message, (value, context) => {
      if (value) {
        if (context.from && context.from[0]?.value[field]) {
          const fieldValue = context.from[0]?.value[field];

          if (isEqual) {
            return isLess ? value <= fieldValue : value >= fieldValue;
          }

          return isLess ? value < fieldValue : value > fieldValue;
        }

        return true;
      }

      return true;
    });
  }
);

export const generalInfoSchema = object().shape({
  name: string()
    .required(requiredField)
    .matches(letterRegex, fieldFromLetters)
    .test(
      'name',
      'В поле обязательны фамилия и имя',
      (value) => value.trim().split(' ').length >= 2
    ),
  officeId: number().nullable(),
  positionId: number().nullable(),
});

export const personalInfoSchema = object().shape({
  birthDate: string().nullable(),
  email: string().matches(emailRegex, incorrectData).nullable(),
  phone: string().matches(phoneRegex, incorrectData).nullable(),
  gender: mixed()
    .oneOf(Array.from(Object.values(EGender)))
    .required(requiredField),
  englishLevel: mixed()
    .oneOf(Array.from(Object.values(EEnglishLevel)))
    .nullable(),
  personalEmail: string().matches(emailRegex, incorrectData).nullable(),
});

export const employmentInfoSchema = object().shape({
  legalEntityId: number().nullable(),
  headId: number().nullable(),
  employmentDate: string()
    .compareDates('endOfProbationPeriod', startDate, true)
    .nullable(),
  endOfProbationPeriod: string()
    .compareDates('employmentDate', endDate)
    .nullable(),
});

export const workHistoryItemSchema = object().shape({
  companyName: string().required(requiredField),
  position: string().required(requiredField),
  startDate: string()
    .compareDates('endDate', startDate, true)
    .required(requiredField),
  endDate: string().compareDates('startDate', endDate).required(requiredField),
});

export const fileSchema = object().shape({
  id: string().nullable(),
  key: string().required(requiredField),
  url: string().required(requiredField),
});

export const educationSchema = object().shape({
  educationalInstitution: string().required(requiredField),
  direction: string().required(requiredField),
  degreeId: number().required(requiredField),
  startDate: string()
    .compareDates('endDate', startDate, true)
    .required(requiredField),
  endDate: string().compareDates('startDate', endDate).required(requiredField),
  documents: array().of(fileSchema),
});

export const educationHistorySchema = object({
  education: array().of(educationSchema),
});

export const workHistorySchema = object({
  workHistory: array().of(workHistoryItemSchema),
});

export const commonInfoSchema = generalInfoSchema.concat(personalInfoSchema);

export const exitInterviewQuestionSchema = object().shape({
  question: string().required(requiredField),
  isActive: mixed().oneOf(['true', 'false']).required(requiredField),
});

export const officeSchema = object().shape({
  name: string().required(requiredField),
});

export const missingDaysSchema = object().shape({
  status: mixed()
    .oneOf(Array.from(Object.values(EMissingDaysStatus)))
    .required(requiredField),
  startDate: string()
    .compareDates('endDate', startDateEqual, true, true)
    .required(requiredField),
  endDate: string()
    .compareDates('startDate', endDateEqual, false, true)
    .required(requiredField),
  approved: mixed().oneOf(['true', 'false']).required(requiredField),
  isPayable: mixed().oneOf(['true', 'false']).required(requiredField),
  documents: array().of(fileSchema),
});

export const educationDegreeSchema = object().shape({
  degree: string().required(requiredField),
});

export const dismissalReasonSchema = object().shape({
  reason: string().required(requiredField),
});

export const positionSchema = object().shape({
  nameEn: string().required(requiredField),
  nameRu: string().required(requiredField),
  code: string().required(requiredField),
});

export const departmentSchema = object().shape({
  nameEn: string().required(requiredField),
  nameRu: string().required(requiredField),
  code: string().required(requiredField),
  headId: string().nullable(),
  parentDepartmentId: string().nullable(),
});

export const revisionRequestSchema = object().shape({
  startDate: string().required(requiredField),
  newSalary: string().required(requiredField),
  comment: string(),
  status: string().required(requiredField),
});

export const exitInterviewShema = object().shape({
  dismissalDate: string().required(requiredField),
  reason: number().required(requiredField),
});

export const expensesTransactionsSchema = object().shape({
  expensesCategoryId: string().required(requiredField),
  amount: string().required(requiredField),
  bankAccountId: string().required(requiredField),
  paymentDate: string().required(requiredField),
  comment: string().nullable(),
  isApproved: mixed().oneOf(['true', 'false']),
  documents: array().of(fileSchema),
});

export const selectedExpensesTransactionsSchema = object().shape({
  status: mixed()
    .oneOf(Array.from(Object.values(EPaymentStatus)))
    .required(requiredField),
  paidAt: string().test('paidAt', requiredField, (value, ctx) => {
    if (ctx.parent.status === EPaymentStatus.Оплачен && !value) {
      return false;
    }

    return true;
  }),
});

export const repeatedExpensesSchema = object().shape({
  expensesCategoryId: string().required(requiredField),
  name: string().required(requiredField),
  amount: string().required(requiredField),
  bankAccountId: string().required(requiredField),
  comment: string().nullable(),
  repeatAt: string()
    .required(requiredField)
    .test(
      'repeatAt',
      'Число не должно быть больше 31',
      (value) => Number(value) <= 31
    ),
});

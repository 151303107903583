import { FC, useState, KeyboardEvent, useEffect, forwardRef } from 'react';

import cn from 'clsx';
import { getYear, getMonth, formatISO } from 'date-fns';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';

import { ArrowDropLeftLine, ArrowDropRightLine, CalendarLine } from '../icon';
import { Input, TInputProps } from '../input';

import { getDateISO, range } from './helpers/calendar';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './datepicker.module.scss';

const MIN_DATEPICKER_YEAR = 1950;
const MAX_DATEPICKER_YEAR = 2150;

export type TDatePickerProps = {
  onDateChanged: (value: string | null) => void;
  value: string;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
} & Omit<TInputProps, 'onFocus' | 'value'>;

export const DatePicker: FC<TDatePickerProps> = ({
  onDateChanged,
  value,
  dateFormat = 'dd.MM.yyyy',
  minDate,
  maxDate,
  placeholder,
  disabled,
  ...rest
}) => {
  const [dateState, setDateState] = useState<string | null>(value || null);
  // ToDo: try to fix floating label in another way
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setDateState(value);
    }
  }, [value]);

  const handleDateChange = (date: Date | null) => {
    const newDate = date ? getDateISO(date) : null;

    if (newDate && dateState !== newDate) {
      setDateState(newDate);
      onDateChanged(formatISO(new Date(newDate)));
    }

    setIsOpen(false);
  };

  const DatePickerInput = forwardRef(
    (
      props: Omit<React.HTMLProps<HTMLInputElement>, 'value'> & TInputProps,
      ref
    ) => (
      <Input
        ref={ref}
        value={dateState ?? ''}
        isActive={isOpen}
        suffixIcon={<CalendarLine />}
        disabled={disabled}
        onMouseDown={(e: KeyboardEvent<HTMLInputElement>) => e.preventDefault()}
        {...props}
        {...rest}
        placeholder={placeholder}
      />
    )
  );

  const renderHeader = ({
    date,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
    increaseMonth,
    decreaseMonth,
    increaseYear,
    decreaseYear,
    changeYear,
    changeMonth,
  }: ReactDatePickerCustomHeaderProps) => {
    const years = range(MIN_DATEPICKER_YEAR, MAX_DATEPICKER_YEAR);

    const months = [
      'Янв',
      'Фев',
      'Март',
      'Апр',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сент',
      'Окт',
      'Нояб',
      'Дек',
    ];

    return (
      <div className={styles.datapicker_header}>
        <div className={styles.datapicker_header__left}>
          <button
            className={styles.datapicker_header__arrow}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            type="button"
          >
            <ArrowDropLeftLine />
          </button>
          <select
            className={styles.datapicker_header__select}
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            className={styles.datapicker_header__arrow}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            type="button"
          >
            <ArrowDropRightLine />
          </button>
        </div>
        <div className={styles.datapicker_header__divider} />
        <div className={styles.datapicker_header__right}>
          <button
            className={styles.datapicker_header__arrow}
            onClick={decreaseYear}
            disabled={prevYearButtonDisabled}
            type="button"
          >
            <ArrowDropLeftLine />
          </button>
          <select
            className={styles.datapicker_header__select}
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(+value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button
            type="button"
            className={styles.datapicker_header__arrow}
            onClick={increaseYear}
            disabled={nextYearButtonDisabled}
          >
            <ArrowDropRightLine />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.container_disabled]: disabled,
      })}
    >
      <ReactDatePicker
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
        renderCustomHeader={renderHeader}
        selected={dateState ? new Date(dateState) : null}
        onChange={handleDateChange}
        peekNextMonth
        disabled={disabled}
        customInput={<DatePickerInput />}
      />
    </div>
  );
};

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'design/button';
import { Text } from 'design/text';

import styles from './error-boundary.module.scss';

export interface IErrorPageProps {
  code: 401 | 403 | 404 | 500 | 503;
  text: string;
  btnLabel?: string;
  onClick?: () => void;
}

export const ErrorPage: FC<IErrorPageProps> = ({
  code,
  text,
  btnLabel,
  onClick,
}) => {
  const navigate = useNavigate();

  const buttonHandler = onClick ?? (() => navigate('/'));

  return (
    <div data-testid="error-page" className={styles.container}>
      <div className={styles.error_boundary}>
        <Text size="text-72" className={styles.error_boundary__title}>
          {String(code)}
        </Text>
        <Text size="text-16" className={styles.error_boundary__text}>
          {text}
        </Text>
        {btnLabel && (
          <Button
            className={styles.error_boundary__button}
            onClick={buttonHandler}
            label={btnLabel}
          />
        )}
      </div>
    </div>
  );
};

import { redirect } from 'react-router-dom';

import { IUserInfo } from 'components/api/types/common';
import { ErrorPage } from 'components/error-boundary';
import {
  UserFill,
  BookMarkLine,
  Wallet3Line,
  Coins,
  Settings4Line,
  CalendarLine,
} from 'design/icon';
import { configureActions, configureLoaders, filterRoutes } from 'utils';
import { IRoute, ISideMenuItem } from 'utils/types';

import {
  AccountingPage,
  loaders as accountingLoaders,
} from './pages/accounting';
import {
  DictionaryPage,
  loaders as dictionaryLoaders,
  actions as dictionaryActions,
} from './pages/dictionary';
import {
  EmployeePage,
  actions as employeeActions,
  loaders as employeeLoaders,
} from './pages/employee';
import {
  ExpensesTransactionsPage,
  loaders as expensesTransactionsLoaders,
  actions as expensesTransactionsActions,
} from './pages/expenses-transactions';
import {
  MissingDaysPage,
  loaders as missingDaysLoaders,
} from './pages/missing-days';
import { NewEmployeePage } from './pages/new-employee';
import {
  RepeatedExpensesPage,
  loaders as repeatedExpensesLoaders,
  actions as repeatedExpensesActions,
} from './pages/repeated-expenses';
import { SalariesPage, loaders as salariesLoaders } from './pages/salaries';
import {
  SalaryPage,
  loaders as salaryLoaders,
  actions as salaryActions,
} from './pages/salary';

const config = [
  {
    path: '/human-resources/configure/repeated-expenses',
    text: 'повторяющиеся траты',
    permissions: ['read:hr/config/repeated-expenses'],
  },
];

export const HrRoutes = (user?: IUserInfo) => {
  const routes = [
    {
      path: '/human-resources/accounting',
      loader: configureLoaders(accountingLoaders),
      element: <AccountingPage />,
      permissions: ['read:hr/employees'],
    },
    {
      id: 'employee-details',
      path: '/human-resources/employee/:employeeId',
      loader: configureLoaders(employeeLoaders),
      action: configureActions(employeeActions),
      errorElement: <ErrorPage code={404} text="Сотрудник не найден" />,
      element: <EmployeePage />,
      permissions: [
        'read:hr/employees',
        'read:hr/employees/missing-days',
        'write:hr/dictionary/exit-interview-questions',
      ],
    },
    {
      path: '/human-resources/employee/new',
      element: <NewEmployeePage />,
      permissions: [
        'read:hr/employees',
        'write:hr/dictionary/offices',
        'write:hr/dictionary/positions',
        'read:hr/dictionary/legal-entities',
        'write:hr/dictionary/departments',
        'read:hr/employees/work-history',
        'write:hr/dictionary/education-degrees',
        'read:hr/employees/work-history',
        'write:hr/employees/work-history',
        'read:hr/employees/education',
        'write:hr/employees/education',
      ],
    },
    {
      path: '/human-resources/dictionary',
      loader: configureLoaders(dictionaryLoaders),
      action: configureActions(dictionaryActions),
      element: <DictionaryPage />,
      permissions: [
        'write:hr/dictionary/education-degrees',
        'write:hr/dictionary/exit-interview-questions',
        'write:hr/dictionary/offices',
        'write:hr/dictionary/departments',
        'write:hr/dictionary/dismissal-reasons',
        'write:hr/dictionary/positions',
      ],
    },
    {
      path: '/human-resources/salaries',
      loader: configureLoaders(salariesLoaders),
      element: <SalariesPage />,
      permissions: ['read:hr/employees/salary-revisions/salary-reports'],
    },
    {
      path: '/human-resources/salaries/:employeeId',
      loader: configureLoaders(salaryLoaders),
      action: configureActions(salaryActions),
      element: <SalaryPage />,
      errorElement: <ErrorPage code={404} text="Сотрудник не найден" />,
      permissions: [
        'read:hr/employees/salary-revisions/salary-reports',
        'read:hr/employees',
        'read:hr/employees/salary-revisions/history',
        'read:hr/employees/salary-revision-request',
      ],
    },
    {
      path: '/human-resources/vacations',
      loader: configureLoaders(missingDaysLoaders),
      action: configureActions(employeeActions),
      element: <MissingDaysPage />,
      permissions: ['read:hr/employees'],
    },
    {
      path: '/human-resources/expenses-transactions',
      loader: configureLoaders(expensesTransactionsLoaders),
      action: configureActions(expensesTransactionsActions),
      element: <ExpensesTransactionsPage />,
      permissions: ['read:hr/expenses-transactions'],
    },
    {
      path: '/human-resources/configure/repeated-expenses',
      loader: configureLoaders(repeatedExpensesLoaders),
      action: configureActions(repeatedExpensesActions),
      element: <RepeatedExpensesPage />,
      permissions: ['read:hr/config/repeated-expenses'],
    },
  ];

  const filteredRoutes: IRoute[] = filterRoutes(
    user?.permissions ?? [],
    routes
  );

  return [
    ...filteredRoutes,
    {
      path: '/',
      loader: () =>
        redirect(
          filteredRoutes.filter((route) => route?.path?.indexOf(':') === -1)[0]
            .path ?? '/'
        ),
      permissions: [],
    },
  ];
};

export const HrMenuItems = (user?: IUserInfo) => {
  const menu = [
    {
      icon: UserFill,
      href: '/human-resources/accounting?filter=active',
      routes: filterRoutes(user?.permissions ?? [], [
        {
          path: '/human-resources/employee/:employeeId',
          permissions: [
            'read:hr/employees',
            'read:hr/employees/missing-days',
            'write:hr/dictionary/exit-interview-questions',
          ],
        },
        {
          path: '/human-resources/employee/new',
          permissions: [
            'read:hr/employees',
            'write:hr/dictionary/offices',
            'write:hr/dictionary/positions',
            'read:hr/dictionary/legal-entities',
            'write:hr/dictionary/departments',
            'read:hr/employees/work-history',
            'write:hr/dictionary/education-degrees',
            'read:hr/employees/work-history',
            'write:hr/employees/work-history',
            'read:hr/employees/education',
            'write:hr/employees/education',
          ],
        },
        {
          path: '/human-resources/accounting',
          permissions: ['read:hr/employees'],
        },
      ]),
      text: 'учет сотрудников',
      permissions: ['read:hr/employees'],
    },
    {
      icon: Wallet3Line,
      href: '/human-resources/salaries',
      routes: filterRoutes(user?.permissions ?? [], [
        {
          path: '/human-resources/salaries/:employeeId',
          permissions: [
            'read:hr/employees/salary-revisions/salary-reports',
            'read:hr/employees',
            'read:hr/employees/salary-revisions/history',
            'read:hr/employees/salary-revision-request',
          ],
        },
        {
          path: '/human-resources/salaries',
          permissions: ['read:hr/employees/salary-revisions/salary-reports'],
        },
      ]),
      text: 'зарплаты',
      permissions: ['read:hr/employees/salary-revisions/salary-reports'],
    },
    {
      icon: CalendarLine,
      href: '/human-resources/vacations',
      routes: [{ path: '/human-resources/vacations' }],
      text: 'Дни отсутствия',
      permissions: ['read:hr/employees'],
    },
    {
      icon: Coins,
      href: '/human-resources/expenses-transactions?filter=planned',
      routes: [{ path: '/human-resources/expenses-transactions' }],
      text: 'траты',
      permissions: ['read:hr/expenses-transactions'],
    },
    {
      icon: BookMarkLine,
      href: '/human-resources/dictionary',
      routes: [{ path: '/human-resources/dictionary' }],
      text: 'словарь',
      permissions: [
        'write:hr/dictionary/education-degrees',
        'write:hr/dictionary/exit-interview-questions',
        'write:hr/dictionary/offices',
        'write:hr/dictionary/departments',
        'write:hr/dictionary/dismissal-reasons',
        'write:hr/dictionary/positions',
      ],
    },
    user?.permissions.filter(
      (permission) => permission.indexOf('hr/config') !== -1
    ).length && {
      icon: Settings4Line,
      href: '/human-resources/configure',
      routes: filterRoutes(user?.permissions ?? [], config),
      text: 'конфигурация',
      permissions: [],
    },
  ];

  const filteredRoutes = filterRoutes(
    user?.permissions ?? [],
    menu.filter((item) => item) as ISideMenuItem[]
  ) as ISideMenuItem[];

  return filteredRoutes;
};

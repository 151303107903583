// ToDo: fix after changes on the backend
export enum EMissingDays {
  Отпуск = 'Отпуск',
  Больничный = 'Больничный',
  Отгул = 'Отгул',
}

export enum EMissingDaysStatus {
  scheduled = 'Запланирован',
  inProgress = 'В процессе',
  canceled = 'Отменен',
  finished = 'Завершен',
}

export enum ESalaryRevisionRequestsStatus {
  created = 'Создан',
  inProgress = 'В процессе',
  canceled = 'Отклонен',
  approved = 'Одобрен',
}

export enum EGender {
  male = 'Мужчина',
  female = 'Женщина',
}

export enum EEnglishLevel {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export enum ETaxes {
  Дефолтные = 'Дефолтные',
  Кастомные = 'Кастомные',
}

export enum EPaymentStatus {
  Создан = 'Создан',
  Оплачен = 'Оплачен',
  Отменен = 'Отменен',
}

export enum EModule {
  finance = 'finance',
  hr = 'hr',
}

export enum ERole {
  'CRM HR' = 'CRM HR',
  'CRM FINANCE' = 'CRM FINANCE',
}

import { ID } from 'components/api/types/common';
import { IMoneyBalance } from 'components/api/types/money-balances';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  moneyBalances: async (data: {
    moneyBalances: IMoneyBalance;
    moneyBalanceId: ID | null;
    removedId: ID | null;
  }) => {
    data.moneyBalanceId &&
      (await requests.patchMoneyBalanceData(
        data.moneyBalanceId,
        data.moneyBalances
      ));

    data.moneyBalances &&
      !data.moneyBalanceId &&
      (await requests.postMoneyBalanceData(data.moneyBalances));

    data.removedId && (await requests.deleteMoneyBalanceData(data.removedId));

    await queryClient.invalidateQueries({ queryKey: ['moneyBalances'] });
  },
};

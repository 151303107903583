import { FC } from 'react';

import { IBankCard } from 'components/api/types/bank-card';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { bankCardSchema } from '../../../utils/validation';

interface IBankCardsModalProps extends IModalOverride {
  initialValue?: IBankCard;
}

type TFormValue = Omit<IBankCard, 'cardNumber'> & {
  cardNumber: string;
};

export const BankCardsModal: FC<IBankCardsModalProps> = ({
  initialValue,
  formId,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (data: TFormValue) => {
    submit({
      entity: 'bankCards',
      data: {
        bankCard: {
          ...data,
          bankAccountId: Number(data.bankAccountId),
          cardNumber: Number(data.cardNumber),
          currencyId: Number(data.currencyId),
          officeId: Number(data.officeId),
        },
        bankCardId: initialValue ? data.id : null,
      },
    });
  };

  const modifiedInitialValue = {
    ...initialValue,
    cardNumber: initialValue?.cardNumber.toString(),
  };

  return (
    <Form
      id={formId}
      initialValues={modifiedInitialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={bankCardSchema}
    >
      <div>
        <FormInput
          required
          name="name"
          label="Название карты"
          placeholder="Название карты"
        />
        <FormInput
          required
          name="cardNumber"
          label="Номер карты"
          placeholder="4242"
        />
        <FormSelect
          required
          name="bankAccountId"
          label="Банк аккаунт"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['bankAccounts'],
              queryFn: requests.getBankAccounts,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.bankName,
            }));
          }}
        />
        <FormSelect
          required
          name="currencyId"
          label="Валюта"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['currencies'],
              queryFn: requests.getCurrencies,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
        <FormSelect
          required
          name="officeId"
          label="Офис"
          options={async () => {
            const options = await queryClient.fetchQuery({
              queryKey: ['offices'],
              queryFn: requests.getOffices,
            });

            return options.data.map((option) => ({
              value: option.id,
              label: option.name,
            }));
          }}
        />
      </div>
    </Form>
  );
};

import { FC } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import cn from 'clsx';
import { postBansheeLogout } from 'components/api/banshee';
import { Button } from 'design/button';
import { LogoutBoxRLine } from 'design/icon';
import { Link } from 'design/link';
import { Text } from 'design/text';

import {
  INavigationItem,
  NavigationItemPathType,
} from '../../../../utils/types';

import styles from './header.module.scss';

export interface IHeaderProps {
  className?: string;
  navItems: INavigationItem[];
  setItemPath: (path: NavigationItemPathType) => void;
}

export const Header: FC<IHeaderProps> = ({
  className,
  setItemPath,
  navItems,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await postBansheeLogout();

      localStorage.removeItem('loggedIn');

      navigate('/auth');
    } catch (error) {
      console.log('Failed to logout :>> ', error);
    }
  };

  return (
    <header className={className}>
      <div className={styles.container}>
        <nav>
          <ul className={styles.links}>
            {navItems.map(({ path, label, selectedItemPath }) => (
              <li
                key={selectedItemPath}
                className={cn(styles.link, {
                  [styles.active]: matchPath(
                    `/${selectedItemPath}/*`,
                    location.pathname
                  ),
                })}
                onClick={() => setItemPath(selectedItemPath)}
              >
                <Link href={path}>
                  <Text className={styles.linkText} size="text-16">
                    {label}
                  </Text>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className={styles.button}>
          <Button
            label="Выйти"
            prefixIcon={<LogoutBoxRLine />}
            onClick={handleLogout}
          />
        </div>
      </div>
    </header>
  );
};

import { useLoaderData } from 'react-router-dom';

import { ISalaryRevisionRequest } from 'components/api/types/salary';
import { Card } from 'design/card';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { dateSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { formatDate } from 'utils';

export const RevisionRequest = () => {
  const { revisionRequests } = useLoaderData() as {
    revisionRequests: ISalaryRevisionRequest[];
  };

  const columns: TTableColumn<ISalaryRevisionRequest>[] = [
    {
      title: 'Дата создания',
      dataIndex: ['createdDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ createdDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(createdDate)}
        </Text>
      ),
    },
    {
      title: 'Дата начала',
      dataIndex: ['startDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ startDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(startDate)}
        </Text>
      ),
    },
    {
      title: 'Статус',
      dataIndex: ['status'],
    },
    {
      title: 'Новая ЗП',
      dataIndex: ['newSalary'],
    },
    {
      title: 'Комментарий',
      dataIndex: ['comment'],
    },
  ];

  return (
    <Card
      title={
        <div>
          <Text size="text-18" weight={500}>
            Запросы на пересмотр
          </Text>
        </div>
      }
    >
      <Table columns={columns} data={revisionRequests} key="id" />
    </Card>
  );
};

import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IPosition } from 'components/api/types/dictionary-types';
import { Button } from 'design/button';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { useFormContext } from 'react-hook-form';
import { useSubmit } from 'utils/useSubmit';

import { positionSchema } from '../../../utils/validation';

import { IFormContentProps } from './type';

import styles from './forms.module.scss';

export type TPositionFormProps = Partial<
  IPosition & { setPositionId: (value: ID) => void }
>;

type TFormValue = Omit<IPosition, 'id'>;

export const PositionFormContent: FC<IFormContentProps> = ({
  loading,
  isEdit,
  setId,
  setSuccess,
  isSuccess,
}) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      reset({ nameEn: '', nameRu: '', code: '' });
      setSuccess(false);
    }
  }, [isSuccess, reset, setSuccess]);

  return (
    <div
      className={cn(styles.form, {
        [styles.container]: !isEdit,
      })}
    >
      <div className={styles.form__section}>
        <FormInput required name="nameEn" label="Должность на английском" />
        <FormInput required name="nameRu" label="Должность на русском" />
        <FormInput required name="code" label="Код" />
      </div>
      {isEdit ? (
        <div className={styles.form__button}>
          <Button
            label="Сохранить"
            loading={loading}
            type="submit"
            buttonType="secondary"
          />
          <Button
            label="Отмена"
            type="button"
            buttonType="secondary"
            onClick={() => setId?.('')}
          />
        </div>
      ) : (
        <Button
          label="Добавить"
          loading={loading}
          type="submit"
          className={styles.form__add}
        />
      )}
    </div>
  );
};

export const PositionForm: FC<TPositionFormProps> = ({
  id,
  nameEn,
  nameRu,
  code,
  setPositionId,
}) => {
  const [isSuccess, setSuccess] = useState(false);

  const { loading, submit } = useSubmit(() => {
    setPositionId?.('');
    setSuccess(true);
  });

  const onSubmit = (value: TFormValue) => {
    submit({
      entity: 'position',
      data: {
        position: value,
        positionId: id,
      },
    });
  };

  return (
    <Form
      id="position"
      initialValues={{ nameEn, nameRu, code }}
      onSubmit={onSubmit}
      validation={positionSchema}
    >
      <PositionFormContent
        isEdit={!!id}
        loading={loading}
        setId={setPositionId}
        setSuccess={setSuccess}
        isSuccess={isSuccess}
      />
    </Form>
  );
};

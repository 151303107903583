import { FC } from 'react';

import {
  ISalaryTransaction,
  ITransaction,
} from 'components/api/types/transaction';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { IModalOverride } from 'design/modal';
import { TTableGropableColumn } from 'design/table/column';
import { datePlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { PAYMENT_SALARY_STATUS_OPTIONS } from '../../../utils/constants';
import { transactionSchema } from '../../../utils/validation';

import styles from './modal.module.scss';

interface ITransactionModalProps extends IModalOverride {
  initialValue?: TTableGropableColumn<ITransaction, ISalaryTransaction>;
}

type IFormValue = Omit<
  ISalaryTransaction,
  'employee' | 'currency' | 'bankAccount' | 'paidAt'
>;

const FormContent = () => (
  <div className={styles.form}>
    <FormSelect
      required
      name="employeeId"
      label="Сотрудник"
      search
      options={async () => {
        const options = await queryClient.fetchQuery({
          queryKey: ['employees'],
          queryFn: () => requests.getCurrentEmployees(false),
        });

        return options.data.map((option) => ({
          value: option.id,
          label: `${option.lastName} ${option.firstName} `,
        }));
      }}
    />
    <FormInput required name="amount" label="Сумма" placeholder="Сумма" />
    <FormDatepicker
      required
      name="periodStartDate"
      label="Дата начала периода"
      placeholder={datePlaceholder}
    />
    <FormDatepicker
      required
      name="periodEndDate"
      label="Дата окончания периода"
      placeholder={datePlaceholder}
    />
    <FormDatepicker
      required
      name="paymentDate"
      label="Дата выплаты"
      placeholder={datePlaceholder}
    />
    <FormInput
      required
      name="paymentType"
      label="Тип выплаты"
      placeholder="Тип выплаты"
    />
    <FormSelect
      required
      name="bankAccountId"
      label="Банк аккаунт"
      options={async () => {
        const options = await queryClient.fetchQuery({
          queryKey: ['bankAccounts'],
          queryFn: requests.getBankAccounts,
        });

        return options.data.map((option) => ({
          value: option.id,
          label: option.bankName,
        }));
      }}
    />
    <FormSelect
      required
      name="currencyId"
      label="Валюта"
      options={async () => {
        const options = await queryClient.fetchQuery({
          queryKey: ['сurrencies'],
          queryFn: requests.getCurrencies,
        });

        return options.data.map((option) => ({
          value: option.id,
          label: option.name,
        }));
      }}
    />
    <FormSelect
      required
      name="status"
      label="Статус"
      options={PAYMENT_SALARY_STATUS_OPTIONS}
    />
  </div>
);

export const TransactionModal: FC<ITransactionModalProps> = ({
  initialValue,
  formId,
  modalClose,
  setConfirm,
  setLoading,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (data: IFormValue) => {
    submit({
      entity: 'paymentSalary',
      data: {
        paymentSalary: {
          status: data.status,
          amount: Number(data.amount),
          currencyId: data.currencyId,
          paymentType: data.paymentType,
          employeeId: data.employeeId,
          bankAccountId: data.bankAccountId,
          paymentDate: data.paymentDate,
          periodStartDate: data.periodStartDate,
          periodEndDate: data.periodEndDate,
        },
        paymentSalaryId: initialValue ? data.id : null,
      },
    });
  };

  const modifiedInitialValue = {
    ...initialValue,
    currencyId: initialValue?.bankAccount.currency.id,
  };

  return (
    <Form
      id={formId}
      initialValues={modifiedInitialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={transactionSchema}
    >
      <FormContent />
    </Form>
  );
};

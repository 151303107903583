import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IPosition } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getPositions =
  (module: EModule) => (): Promise<AxiosResponse<IPosition[]>> =>
    $host.get(`/${module}/dictionary/positions`);

export const patchPositionData =
  (module: EModule) =>
  (id: ID, values: Partial<IPosition>): Promise<AxiosResponse<IPosition>> =>
    $host.patch(`/${module}/dictionary/positions/${id}`, { ...values });

export const postPositionData =
  (module: EModule) =>
  (values: Omit<IPosition, 'id'>): Promise<AxiosResponse<IPosition>> =>
    $host.post(`/${module}/dictionary/positions`, { ...values });

export const deletePositionData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IPosition>> =>
    $host.delete(`/${module}/dictionary/positions/${id}`);

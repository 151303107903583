import { FC } from 'react';

import { IEmployee } from 'components/api/types/employee';
import { Text } from 'design/text';
import { formatDate, getInitialsEmployee } from 'utils';

import styles from '../employee.module.scss';

export type TEmploymentProps = Pick<
  IEmployee,
  | 'legalEntity'
  | 'employmentDate'
  | 'endOfProbationPeriod'
  | 'department'
  | 'position'
  | 'head'
  | 'assignedHr'
>;

export const Employment: FC<TEmploymentProps> = ({
  legalEntity,
  employmentDate,
  endOfProbationPeriod,
  department,
  position,
  head,
  assignedHr,
}) => (
  <div className={styles.cardContent}>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Куда устроен
      </Text>
      <Text className={styles.value} size="text-14">
        {legalEntity?.name ?? ''}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Руководитель
      </Text>
      <Text className={styles.value} size="text-14">
        {head?.firstName
          ? getInitialsEmployee(head.lastName, head.firstName, head?.middleName)
          : ''}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        HR
      </Text>
      <Text className={styles.value} size="text-14">
        {assignedHr?.firstName
          ? getInitialsEmployee(
              assignedHr.lastName,
              assignedHr.firstName,
              assignedHr?.middleName
            )
          : ''}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Oтдел
      </Text>
      <Text className={styles.value} size="text-14">
        {department?.nameRu ?? ''}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Должность
      </Text>
      <Text className={styles.value} size="text-14">
        {position?.nameRu ?? ''}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Начало работы
      </Text>
      <Text className={styles.value} size="text-14">
        {employmentDate ? `${formatDate(employmentDate)}` : ''}
      </Text>
    </div>
    <div className={styles.row}>
      <Text className={styles.title} size="text-14" weight={500}>
        Конец испытательного срока
      </Text>
      <Text className={styles.value} size="text-14">
        {endOfProbationPeriod ? `${formatDate(endOfProbationPeriod)}` : ''}
      </Text>
    </div>
  </div>
);

import { FC, useMemo } from 'react';

import { ID } from 'components/api/types/common';
import { IMissingDayInfo } from 'components/api/types/employee';
import { Card } from 'design/card';
import { Text } from 'design/text';

import { MissingDaysHistoryTable } from './missing-days-history';
import { SickCard } from './sick';
import { VacationCard } from './vacation';

import styles from '../employee.module.scss';

export interface IMissingDaysProps {
  missingDaysInfo: IMissingDayInfo;
  employeeId: ID;
}

export const MissingDays: FC<IMissingDaysProps> = ({
  missingDaysInfo,
  employeeId,
}) => {
  const countDaysOff = useMemo(
    () =>
      missingDaysInfo?.missingDays.filter((day) => day.type === 'Отгул')
        .length ?? 0,
    [missingDaysInfo?.missingDays]
  );

  return (
    <>
      <div className={styles.profile}>
        <div className={styles.firstColumn}>
          <Card
            title={
              <div className={styles.cardHeader}>
                <Text size="text-18" as="span" weight={500}>
                  Отпуск
                </Text>
              </div>
            }
          >
            <VacationCard
              lastVacationDate={missingDaysInfo.lastVacationDate}
              nextAvailableVacationDate={
                missingDaysInfo.nextAvailableVacationDate
              }
              nextScheduledVacationDate={
                missingDaysInfo.nextScheduledVacationDate
              }
              vacationDaysLeft={missingDaysInfo.vacationDaysLeft}
            />
          </Card>
        </div>
        <div className={styles.secondColumn}>
          <Card
            title={
              <div className={styles.cardHeader}>
                <Text size="text-18" as="span" weight={500}>
                  Больничные
                </Text>
              </div>
            }
          >
            <SickCard
              sickDaysLeft={missingDaysInfo.sickDaysLeft}
              sickDaysTaken={missingDaysInfo.sickDaysTaken}
              employmentDate={missingDaysInfo.employmentDate}
              countDaysOff={countDaysOff}
            />
          </Card>
        </div>
      </div>
      <Card className={styles.lastCard}>
        <MissingDaysHistoryTable
          missingDays={missingDaysInfo.missingDays}
          id={employeeId}
        />
      </Card>
    </>
  );
};

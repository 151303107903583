import React from 'react';

import CN from 'clsx';

import styles from './text.module.scss';

export const Sizes = [
  'text-8',
  'text-12',
  'text-14',
  'text-16',
  'text-18',
  'text-20',
  'text-24',
  'text-30',
  'text-72',
] as const;
type TSizes = (typeof Sizes)[number];

export const Weights = [400, 500, 700, 900] as const;
type TWeight = (typeof Weights)[number];

export interface ITextProps {
  size?: TSizes;
  weight?: TWeight;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  uppercase?: boolean;
  capitalize?: boolean;
  children: string;
}

export const Text: React.FC<ITextProps> = ({
  size,
  weight = 400,
  as = 'p',
  className,
  uppercase,
  capitalize,
  children,
}) => {
  const sizes = Sizes.reduce(
    (sizeStyles, s) => ({ ...sizeStyles, [styles[s]]: s === size }),
    {}
  );

  const weights = Weights.reduce(
    (weightStyles, w) => ({
      ...weightStyles,
      [styles[`text-weight-${w}`]]: w === weight,
    }),
    {}
  );

  const el = React.createElement(
    as,
    {
      className: CN(styles.text, className, sizes, weights, {
        [styles.uppercase]: uppercase,
        [styles.capitalize]: capitalize,
      }),
    },
    children
  );

  return <>{el}</>;
};

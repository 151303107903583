import React from 'react';

import cn from 'clsx';

import styles from './spinner.module.scss';

export interface ISpinnerProps {
  type?: 'small' | 'medium';
  color?: 'gray-500' | 'white';
}

export const Spinner: React.FC<ISpinnerProps> = ({
  type = 'medium',
  color = 'white',
}) => (
  <div
    data-testid="spinner"
    className={cn(styles.spinner, styles[type], styles[color])}
  />
);

import { requests } from '../../api';

export const loaders = () => [
  {
    name: 'legalEntities',
    fn: requests.getLegalEntities,
  },
  {
    name: 'currencies',
    fn: requests.getCurrencies,
  },
];

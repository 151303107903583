import { useLoaderData } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { ISalaryRevisionRequest } from 'components/api/types/salary';
import { Button } from 'design/button';
import { Card } from 'design/card';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { dateSorting, numberSorting, textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import { RevisionModal } from '../modal';

import styles from '../employee-salary.module.scss';

const openCreateRevisionModal = (id: ID, revisionRequestId: ID) => {
  modal.open({
    id: 'create-revision-modal',
    title: 'Создание пересмотра',
    formId: 'create-revision-form',
    content: <RevisionModal id={id} revisionRequestId={revisionRequestId} />,
  });
};

export const RevisionRequest = () => {
  const { revisionRequests } = useLoaderData() as {
    revisionRequests: ISalaryRevisionRequest[];
  };

  const columns: TTableColumn<ISalaryRevisionRequest>[] = [
    {
      title: 'Дата создания',
      dataIndex: ['createdDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ createdDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(createdDate)}
        </Text>
      ),
    },
    {
      title: 'Дата начала',
      dataIndex: ['startDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ startDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(startDate)}
        </Text>
      ),
    },
    {
      title: 'Статус',
      dataIndex: ['status'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Новая ЗП',
      dataIndex: ['newSalary'],
      sortable: true,
      sorting: numberSorting,
    },
    {
      title: 'Комментарий',
      dataIndex: ['comment'],
    },
    {
      title: 'Действие',
      render: ({ employeeId, id }) => (
        <Button
          label="Создать пересмотр"
          size="small"
          onClick={() => openCreateRevisionModal(employeeId, id)}
        />
      ),
    },
  ];

  return (
    <div className={styles.content}>
      <Card
        bodyClassName={styles.card}
        title={
          <Text size="text-18" weight={500}>
            Запросы на пересмотр
          </Text>
        }
      >
        <div className={styles.content__table}>
          <Table columns={columns} data={revisionRequests} key="id" />
        </div>
      </Card>
    </div>
  );
};

import { cloneElement, useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  FormProvider,
  useForm,
  DefaultValues,
  UseFormReturn,
} from 'react-hook-form';
import { ObjectSchema } from 'yup';

import { checkDirtyFormFields } from './checkDirtyFormFields';

export interface IFormProps<T>
  extends Omit<IInternalFormProps, 'onSubmit' | 'trigger'> {
  initialValues: DefaultValues<T>;
  onSubmit?: (values: T, methods?: UseFormReturn<any>) => void;
  validation?: ObjectSchema<any>;
  validateOnBlur?: boolean;
  onDirty?: (isDirty: boolean, dirtyFields: Partial<Readonly<any>>) => void;
}

interface IInternalFormProps {
  children: React.ReactElement;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  onReset?: () => void;
  className?: string;
  validateOnBlur?: boolean;
  id?: string;
}

export interface IFormChildrenProps {
  form?: UseFormReturn<any>;
}

export const Form = <T extends FieldValues>({
  initialValues,
  children,
  onSubmit,
  onReset,
  className,
  validation,
  validateOnBlur,
  onDirty,
  id = 'purchase',
}: IFormProps<T>): JSX.Element => {
  const formObject = useForm({
    defaultValues: initialValues,
    resolver: validation ? yupResolver(validation) : undefined,
    mode: 'onSubmit',
  });

  const isDirty = checkDirtyFormFields(formObject.formState.dirtyFields);

  useEffect(() => {
    onDirty?.(isDirty, formObject.formState.dirtyFields);
  }, [formObject.formState.dirtyFields, isDirty, onDirty]);

  const submit = useCallback(
    (values: T) => {
      onSubmit?.(values, formObject);
    },
    [formObject, onSubmit]
  );

  const reset = useCallback(() => {
    formObject.reset(initialValues);

    if (onReset) {
      onReset();
    }
  }, [formObject, initialValues, onReset]);

  return (
    <FormProvider {...formObject}>
      <InternalForm
        className={className}
        validateOnBlur={validateOnBlur}
        onSubmit={formObject.handleSubmit(
          submit as (values: FieldValues) => void
        )}
        onReset={reset}
        id={id}
      >
        {cloneElement(children, { form: formObject })}
      </InternalForm>
    </FormProvider>
  );
};

const InternalForm = ({
  children,
  className,
  onSubmit,
  onReset,
  id,
}: IInternalFormProps) => {
  const onSubmitHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      onSubmit?.(e);
    },
    [onSubmit]
  );

  const onResetHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.stopPropagation();

      if (onReset) {
        onReset();
      }
    },
    [onReset]
  );

  return (
    <form
      id={id}
      className={className}
      onSubmit={onSubmitHandler}
      onReset={onResetHandler}
      autoComplete="off"
      aria-label="form"
    >
      {children}
    </form>
  );
};

import { FC, useState } from 'react';

import cn from 'clsx';
import {
  IEducation,
  TMissingDay,
  IMissingDay,
} from 'components/api/types/employee';
import { EMissingDays } from 'components/api/types/enums';
import { Divider } from 'design/divider';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormSelect } from 'design/form/form-select';
import { FormUploader } from 'design/form/form-uploader';
import { IModalOverride } from 'design/modal';
import { IOption, Select } from 'design/select';
import { Text } from 'design/text';
import { formatDate } from 'utils';
import { datePlaceholder, linkPlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import {
  APPROVED_STATUS_OPTIONS,
  MISSING_DAYS_OPTIONS,
  MISSING_DAYS_STATUS_OPTIONS,
  PAYABLE_STATUS_OPTIONS,
} from '../../../../utils/constants';
import { missingDaysSchema } from '../../../../utils/validation';

import styles from './modal.module.scss';

type TMissingDayModalProps = Pick<IEducation, 'employeeId'> & {
  formId: string;
  selectedMissingDayType?: TMissingDay;
  initialValue?: IFormValue;
} & IModalOverride;

interface IFormContentProps {
  type: string;
}

export type IFormValue = Omit<IMissingDay, 'isPayable' | 'approved'> & {
  isPayable: string;
  approved: string;
};

const FormContent: FC<IFormContentProps> = ({ type }) => (
  <div
    className={cn(styles.form__content, {
      [styles.form__content_doff]: type === EMissingDays.Отгул,
    })}
  >
    <FormSelect
      required
      name="status"
      label="Статус"
      options={MISSING_DAYS_STATUS_OPTIONS}
    />
    <FormSelect
      required
      name="approved"
      label="Статус подтверждения"
      options={APPROVED_STATUS_OPTIONS}
    />
    <FormDatepicker
      required
      name="startDate"
      label="Дата начала"
      placeholder={datePlaceholder}
    />
    <FormDatepicker
      required
      name="endDate"
      label="Дата окончания"
      placeholder={datePlaceholder}
    />
    {type !== EMissingDays.Отгул && (
      <FormSelect
        required
        name="isPayable"
        label="Статус оплаты"
        options={PAYABLE_STATUS_OPTIONS}
      />
    )}
    <FormUploader
      name="documents"
      className={styles.form__content__fileUploader}
      placeholder={linkPlaceholder}
    />
  </div>
);

export const MissingDayModal: FC<TMissingDayModalProps> = ({
  formId,
  initialValue,
  employeeId,
  selectedMissingDayType,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const [missingDayType, setMissingDayType] = useState<TMissingDay>(
    selectedMissingDayType ?? EMissingDays.Отпуск
  );

  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (values: IFormValue) => {
    const formData = {
      employeeId: employeeId,
      type: initialValue?.type ?? missingDayType,
      approved: JSON.parse(values.approved),
      isPayable:
        missingDayType === EMissingDays.Отгул
          ? false
          : JSON.parse(values.isPayable),
      startDate: formatDate(values.startDate, 'yyyy-MM-dd'),
      endDate: formatDate(values.endDate, 'yyyy-MM-dd'),
      status: values.status,
      documents: values.documents?.map((el) => el.url),
    };

    submit({
      entity: 'missingDays',
      data: {
        employeeId,
        missingDays: formData,
        missingDaysId: initialValue ? values.id : null,
      },
    });
  };

  const handleChangeMissingDay = (option: IOption) => {
    setMissingDayType(option.value as TMissingDay);
  };

  return (
    <Form
      id={formId}
      initialValues={initialValue ?? {}}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      validation={
        missingDayType === EMissingDays.Отгул
          ? missingDaysSchema.omit(['isPayable'])
          : missingDaysSchema
      }
    >
      <div className={styles.form}>
        {!initialValue && (
          <>
            <div className={styles.form__filter}>
              <Text size="text-14" weight={500} className={styles.text} as="h3">
                Укажите причину пропущенных дней:
              </Text>
              <Select
                name="missingDays"
                label="Причина отсутствия"
                value={missingDayType}
                options={MISSING_DAYS_OPTIONS}
                onChange={handleChangeMissingDay}
              />
            </div>
            <Divider />
          </>
        )}
        <FormContent
          type={
            initialValue?.type === EMissingDays.Отгул
              ? EMissingDays.Отгул
              : missingDayType
          }
        />
      </div>
    </Form>
  );
};

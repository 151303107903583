import { IColumn } from './types';

export const calendarTableColumns: IColumn[] = [
  {
    title: 'Январь',
    dataIndex: '1',
  },
  {
    title: 'Февраль',
    dataIndex: '2',
  },
  {
    title: 'Март',
    dataIndex: '3',
  },
  {
    title: 'Апрель',
    dataIndex: '4',
  },
  {
    title: 'Май',
    dataIndex: '5',
  },
  {
    title: 'Июнь',
    dataIndex: '6',
  },
  {
    title: 'Июль',
    dataIndex: '7',
  },
  {
    title: 'Август',
    dataIndex: '8',
  },
  {
    title: 'Сентябрь',
    dataIndex: '9',
  },
  {
    title: 'Октябрь',
    dataIndex: '10',
  },
  {
    title: 'Ноябрь',
    dataIndex: '11',
  },
  {
    title: 'Декабрь',
    dataIndex: '12',
  },
];

export const infoTableColumns: IColumn[] = [
  {
    title: 'Сотрудники',
    dataIndex: 'firstName',
  },
  {
    title: 'Офис',
    dataIndex: 'office',
  },
];

import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IEducationDegree } from 'components/api/types/dictionary-types';
import { DeleteBinLine, Edit2Fill } from 'design/icon';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { EducationDegreeForm } from '../forms/education-degree';

import styles from '../dictionary.module.scss';

interface IEducationDegreeListProps {
  educationDegrees: IEducationDegree[];
}

export const EducationDegreeList: FC<IEducationDegreeListProps> = ({
  educationDegrees,
}) => {
  const [educationDegreeId, setEducationDegreeId] = useState<ID>();

  const { submit } = useSubmit();

  const handleDelete = (id: ID) => {
    submit({
      entity: 'educationDegree',
      data: {
        removedId: id,
      },
    });
  };

  const handleEdit = (id: ID) => {
    setEducationDegreeId(id);
  };

  if (!educationDegrees.length) {
    return (
      <div className={styles.no_data}>
        <Text size="text-16" weight={500}>
          Нет данных
        </Text>
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {educationDegrees.map(({ id, degree }) => (
        <li key={id}>
          <div className={styles.list__container}>
            {educationDegreeId !== id ? (
              <>
                <div className={styles.list__container__item}>
                  <Text size="text-16">{degree}</Text>
                </div>
                <div className={styles.list__container__actions}>
                  <Edit2Fill onClick={() => handleEdit(id)} />
                  <DeleteBinLine onClick={() => handleDelete(id)} />
                </div>
              </>
            ) : (
              <EducationDegreeForm
                id={educationDegreeId}
                degree={degree}
                setEducationDegreeId={setEducationDegreeId}
              />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

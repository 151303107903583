import { FC } from 'react';

import { IEmployee } from 'components/api/types/employee';
import { Form } from 'design/form';
import { useSubmit } from 'utils/useSubmit';

import { CommonForm } from '../../../../pages/new-employee/forms/common';
import { personalInfoSchema } from '../../../../utils/validation';

export type TPersonalInformationData = Pick<
  IEmployee,
  'birthDate' | 'email' | 'phone' | 'englishLevel' | 'personalEmail' | 'gender'
>;

export type TPersonalInformationModalProps = TPersonalInformationData & {
  formId: string;
  modalClose?: () => void;
  setLoading?: (arg: boolean) => void;
};

export const PersonalInformationModal: FC<TPersonalInformationModalProps> = ({
  formId,
  birthDate,
  email,
  phone,
  gender,
  personalEmail,
  englishLevel,
  modalClose,
  setLoading,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (values: TPersonalInformationData) => {
    submit({
      entity: 'personal',
      data: values,
    });
  };

  return (
    <Form
      id={formId}
      initialValues={{
        birthDate,
        email,
        phone,
        englishLevel,
        gender,
        personalEmail,
      }}
      onSubmit={onSubmit}
      validation={personalInfoSchema}
    >
      <CommonForm variant="personal" />
    </Form>
  );
};

import { FC, ReactNode } from 'react';

import {
  QueryClient,
  QueryClientProvider as Provider,
} from '@tanstack/react-query';

export interface IQueryClientProviderProps {
  children: ReactNode;
}

export const queryClient = new QueryClient();

export const QueryClientProvider: FC<IQueryClientProviderProps> = ({
  children,
}) => <Provider client={queryClient}>{children}</Provider>;

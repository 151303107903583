import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IMissingDay } from 'components/api/types/employee';
import { EMissingDays } from 'components/api/types/enums';
import { getDaysInYear } from 'date-fns';
import { modal } from 'design/modal/core';
import { Text } from 'design/text';
import { createPortal } from 'react-dom';
import { formatDate } from 'utils';

import { getWordDayForm } from '../../../../../../utils';
import {
  IFormValue,
  MissingDayModal,
} from '../../../../../employee/missing-days/modal';
import {
  compareDate,
  getColor,
  getDifferenceDate,
  getDifferenceInMinutes,
} from '../../utils';
import { Tooltip } from '../tooltip';

import styles from '../../calendar-table.module.scss';

interface IWeekProps {
  week: number[];
  column: number;
  missingDays: IMissingDay[];
  employeeId: ID;
  year: number;
}

const formatInfo = (startDate: string, endDate: string) =>
  `${formatDate(startDate)} - ${formatDate(endDate)} 
  (${getDifferenceDate(startDate, endDate)} ${getWordDayForm(
    getDifferenceDate(startDate, endDate)
  )})`;

const getMissingDay = (
  startDate: string,
  endDate: string,
  day: number,
  column: number,
  year: number
) => {
  if (
    (Number(startDate.split('-')[0]) === year &&
      compareDate(startDate, day, column)) ||
    (Number(startDate.split('-')[0]) !== year &&
      Number(endDate.split('-')[0]) === year &&
      compareDate(`${year}-01-01`, day, column))
  ) {
    return true;
  } else {
    return false;
  }
};

const style = (
  startDate: string,
  endDate: string,
  type: EMissingDays,
  year: number
) => ({
  width: `calc((100% * ((
      ${
        getDifferenceInMinutes(startDate, endDate) /
        getDaysInYear(new Date(year, 0, 1))
      }
    ))))`,
  background: getColor(type),
});

const openEditMissingDayModal = (employeeId: ID, initialValue: IFormValue) => {
  modal.open({
    id: 'edit-missing-day-modal',
    title: 'Редактирование данных',
    formId: 'edit-missing-day',
    content: (
      <MissingDayModal
        formId="edit-missing-day"
        employeeId={employeeId}
        initialValue={initialValue}
      />
    ),
  });
};

export const Week: FC<IWeekProps> = ({
  week,
  missingDays,
  employeeId,
  column,
  year,
}) => {
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);

  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
  });

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsShowInfo(true);

    const positions = e.currentTarget.getBoundingClientRect();

    setTooltipPosition({
      top: positions.top,
      left: positions.left,
    });
  };

  return (
    <>
      {week.map((day) => (
        <div
          key={day}
          className={styles.table__body__row__cell__container__week__day}
        >
          {missingDays.map(
            (missingDay) =>
              getMissingDay(
                missingDay.startDate,
                missingDay.endDate,
                day,
                column,
                year
              ) && (
                <div
                  key={missingDay.id}
                  className={
                    styles.table__body__row__cell__container__week__day_missing
                  }
                  style={style(
                    Number(missingDay.startDate.split('-')[0]) === year
                      ? missingDay.startDate
                      : `${year}-01-01`,
                    Number(missingDay.endDate.split('-')[0]) === year
                      ? missingDay.endDate
                      : `${year}-12-31`,
                    missingDay.type,
                    year
                  )}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={() => setIsShowInfo(false)}
                  onClick={() => {
                    const missingDayData = {
                      ...missingDay,
                      approved: `${missingDay.approved}`,
                      isPayable: `${missingDay.isPayable}`,
                    };

                    return openEditMissingDayModal(employeeId, missingDayData);
                  }}
                >
                  <Text size="text-14" weight={400} as="span">
                    {formatInfo(missingDay.startDate, missingDay.endDate)}
                  </Text>
                  {isShowInfo &&
                    createPortal(
                      <Tooltip
                        top={tooltipPosition.top}
                        left={tooltipPosition.left}
                        missingDay={missingDay}
                        day={getDifferenceDate(
                          missingDay.startDate,
                          missingDay.endDate
                        )}
                      />,
                      document.body
                    )}
                </div>
              )
          )}
        </div>
      ))}
    </>
  );
};

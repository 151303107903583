import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IWorkHistory, TWorkHistoryFormData } from '../types/employee';
import { EModule } from '../types/enums';

export const getWorkHistoryDataById =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<IWorkHistory[]>> =>
    $host.get(`/${module}/employees/${employeeId}/work-history`, {
      params: { employeeId },
    });

export const patchWorkHistoryData =
  (module: EModule) =>
  (
    employeeId: ID,
    id: ID,
    values: Partial<TWorkHistoryFormData>
  ): Promise<AxiosResponse<IWorkHistory>> =>
    $host.patch(`/${module}/employees/${employeeId}/work-history/${id}`, {
      ...values,
    });

export const postWorkHistoryData =
  (module: EModule) =>
  (
    employeeId: ID,
    values: Omit<TWorkHistoryFormData, 'id'>
  ): Promise<AxiosResponse<IWorkHistory>> =>
    $host.post(`/${module}/employees/${employeeId}/work-history`, {
      ...values,
    });

export const deleteWorkHistoryData =
  (module: EModule) =>
  (employeeId: ID, id: ID): Promise<AxiosResponse<IWorkHistory>> =>
    $host.delete(`/${module}/employees/${employeeId}/work-history/${id}`);

import { FC } from 'react';

import avatar from './avatar.jpg';

import styles from './avatar.module.scss';

export interface IAvatar {
  name: string;
  url: string;
}

export const Avatar: FC<IAvatar> = ({ name, url }) => (
  <div className={styles.avatarContainer}>
    {url && <img className={styles.avatar} src={url} alt={`${name} avatar`} />}
    {!url && (
      <img className={styles.avatar} src={avatar} alt={`${name} avatar`} />
    )}
  </div>
);

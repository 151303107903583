import { useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { IEmployee } from 'components/api/types/employee';
import { Avatar } from 'design/avatar';
import { Button } from 'design/button';
import { Card } from 'design/card';
import { modal } from 'design/modal/core';
import { ITab, Tabs } from 'design/tabs';
import { Text } from 'design/text';
import { fullNameEmployee } from 'utils';

import { General } from './general';
import { RevisionModal } from './modal';
import { PaymentHistory } from './payment-history';
import { RevisionRequest } from './revision-request';

import styles from './employee-salary.module.scss';

type TActiveTab = 'general' | 'review' | 'history';

const openCreateRevisionModal = (id: ID) => {
  modal.open({
    className: styles.modal,
    id: 'create-revision-modal',
    title: 'Создание пересмотра',
    formId: 'create-revision-form',
    content: <RevisionModal id={id} />,
  });
};

export const EmployeeSalaryPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState<TActiveTab>(
    (searchParams.get('filter') as TActiveTab | null) ?? 'general'
  );

  const { employee } = useLoaderData() as {
    employee: IEmployee;
  };

  useEffect(() => {
    const filter = searchParams.get('filter');

    setActiveTab(filter as TActiveTab);
  }, [searchParams, setSearchParams]);

  const tabs: ITab[] = [
    {
      id: 'general',
      title: 'Главная',
    },
    {
      id: 'review',
      title: 'Запросы на пересмотр',
    },
    {
      id: 'history',
      title: 'История выплат',
    },
  ];

  if (!employee) {
    return null;
  }

  return (
    <div className={styles.employee}>
      <Card
        className={styles.employee__card}
        bodyClassName={styles.employee__card__body}
      >
        <div className={styles.employee__card__general}>
          <div className={styles.employee__card__general__container}>
            <div className={styles.employee__card__general__container__info}>
              <Avatar url={employee.photo?.url} name={employee.lastName} />
              <div
                className={
                  styles.employee__card__general__container__info__text
                }
              >
                <div>
                  <Text size="text-18" weight={500}>
                    {fullNameEmployee(
                      employee.firstName,
                      employee.lastName,
                      employee.middleName
                    )}
                  </Text>
                  <Text
                    className={
                      styles.employee__card__general__container__info__text__position
                    }
                    size="text-14"
                    weight={500}
                  >
                    {employee.position?.nameRu}
                  </Text>
                </div>
                <Text
                  className={
                    styles.employee__card__general__container__info__text__office
                  }
                  size="text-14"
                  weight={500}
                >
                  {employee.office?.name}
                </Text>
              </div>
            </div>
            <Tabs
              active={activeTab}
              tabs={tabs}
              onChange={(value) => {
                setSearchParams({ filter: `${value}` });
              }}
            />
          </div>
          <div>
            <Button
              label="Создать пересмотр"
              onClick={() => openCreateRevisionModal(employee.id)}
            />
          </div>
        </div>
      </Card>
      {activeTab === 'general' && <General />}
      {activeTab === 'review' && <RevisionRequest />}
      {activeTab === 'history' && <PaymentHistory />}
    </div>
  );
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IExchangeRate } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getExchangeRates =
  (module: EModule) => (): Promise<AxiosResponse<IExchangeRate[]>> =>
    $host.get(`/${module}/config/exchange-rates`);

export const postExchangeRateData =
  (module: EModule) =>
  (
    values: Omit<
      IExchangeRate,
      'id' | 'createdDate' | 'updatedDate' | 'deletedDate'
    >
  ): Promise<AxiosResponse<IExchangeRate>> =>
    $host.post(`/${module}/config/exchange-rates`, { ...values });

export const deleteExchangeRateData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/exchange-rates/${id}`);

export const patchExchangeRateData =
  (module: EModule) =>
  (
    id: ID,
    values: Partial<IExchangeRate>
  ): Promise<AxiosResponse<IExchangeRate>> =>
    $host.patch(`/${module}/config/exchange-rates/${id}`, { ...values });

import { IMissingDaySearchParams } from 'components/api/types/employee';
import {
  EEnglishLevel,
  EGender,
  EMissingDays,
  EMissingDaysStatus,
  EPaymentStatus,
  ESalaryRevisionRequestsStatus,
} from 'components/api/types/enums';
import { makeOptionsFromEnum } from 'utils';

export const ENGLISH_LEVEL_OPTIONS = makeOptionsFromEnum(EEnglishLevel);

export const GENDER_OPTIONS = makeOptionsFromEnum(EGender);

export const MISSING_DAYS_OPTIONS = makeOptionsFromEnum(EMissingDays);

export const MISSING_DAYS_STATUS_OPTIONS =
  makeOptionsFromEnum(EMissingDaysStatus);

export const APPROVED_STATUS_OPTIONS = [
  { label: 'Подтвержден', value: 'true' },
  { label: 'Не подтвержден', value: 'false' },
];

export const PAYABLE_STATUS_OPTIONS = [
  { label: 'Оплачиваемый', value: 'true' },
  { label: 'Неоплачиваемый', value: 'false' },
];

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const phoneRegex = /^[+]\d+$/;

export const letterRegex = /[a-zA-ZёЁа-яА-Я]/;

export const REVISION_REQUEST_STATUS_OPTIONS = makeOptionsFromEnum(
  ESalaryRevisionRequestsStatus
);

export const PAYMENT_STATUS_OPTIONS = makeOptionsFromEnum(EPaymentStatus);

export const MISSING_DAYS_PARAMS: IMissingDaySearchParams = {
  vacation: EMissingDays.Отпуск,
  sick: EMissingDays.Больничный,
  'day-off': EMissingDays.Отгул,
};

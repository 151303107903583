import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { IUploaderValue, Uploader } from '../uploader';

export interface IFormUploaderProps {
  name: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
}

export const FormUploader: FC<IFormUploaderProps> = ({
  name,
  className,
  required,
  placeholder,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({
        field: { onChange, name, onBlur, value },
        fieldState: { error },
      }) => {
        const handleChange = (value: IUploaderValue[]) => {
          onChange(value);
        };

        const handleBlur = () => {
          onBlur();
        };

        return (
          <Uploader
            label="Ссылка на документ"
            labelButton="Добавить ссылку"
            required={required}
            name={name}
            placeholder={placeholder}
            value={value}
            className={className}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error?.message}
          />
        );
      }}
    />
  );
};

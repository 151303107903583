import { FC, useEffect, useState } from 'react';

import { ID } from 'components/api/types/common';
import { IDepartment } from 'components/api/types/dictionary-types';
import { DeleteBinLine, Edit2Fill } from 'design/icon';
import { IOption } from 'design/select';
import { Text } from 'design/text';
import { fullNameEmployee } from 'utils';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { DepartmentForm } from '../forms/department';

import styles from '../dictionary.module.scss';

interface IDepartmentListProps {
  departments: IDepartment[];
  parentDepartmentOptions: IOption[];
}

type TDepartmentData = Omit<IDepartment, 'headId'> & {
  headId: { head: string; id: IDepartment['headId'] };
};

const getHeadDepartmentById = async (id: ID) => {
  try {
    const { data } = await requests.getEmployeeDataById(id);

    return fullNameEmployee(data.lastName, data.firstName, data?.middleName);
  } catch (e) {
    console.log('Failed to get head id data :>> ', e);

    return 'не указан';
  }
};

export const DepartmentList: FC<IDepartmentListProps> = ({
  departments,
  parentDepartmentOptions,
}) => {
  const [departmentId, setDepartmentId] = useState<ID>();
  const [departmentsData, setDepartmentsData] = useState<TDepartmentData[]>([]);
  const { submit } = useSubmit();

  const getDepartments = async (values: IDepartment[]) => {
    const result = await Promise.all(
      values.map(async (value) => {
        if (value.headId) {
          const head = await getHeadDepartmentById(value.headId);

          return {
            ...value,
            headId: { head, id: value.headId },
          };
        }

        return {
          ...value,
          headId: { head: 'не указан', id: null },
        };
      })
    );

    setDepartmentsData(result);
  };

  useEffect(() => {
    getDepartments(departments);
  }, [departments]);

  const handleDelete = (id: ID) => {
    submit({
      entity: 'department',
      data: {
        removedId: id,
      },
    });
  };

  const handleEdit = (id: ID) => {
    setDepartmentId(id);
  };

  const getParentDepartment = (id: IDepartment['parentDepartmentId']) => {
    if (id) {
      return departments.find((department) => department.id === id)?.nameRu;
    }

    return 'не указан';
  };

  if (!departments.length) {
    return (
      <div className={styles.no_data}>
        <Text size="text-16" weight={500}>
          Нет данных
        </Text>
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {departmentsData.map(
        ({ id, nameEn, nameRu, code, headId, parentDepartmentId }) => (
          <li key={id}>
            <div className={styles.list__container}>
              {departmentId !== id ? (
                <>
                  <div className={styles.list__container__item}>
                    <Text size="text-16">
                      {`${nameEn} (русское наименование: ${nameRu}; код: ${code}; 
                      oсновной отдел: ${getParentDepartment(
                        parentDepartmentId
                      )};
                      руководитель отдела: ${headId.head})`}
                    </Text>
                  </div>
                  <div className={styles.list__container__actions}>
                    <Edit2Fill onClick={() => handleEdit(id)} />
                    <DeleteBinLine onClick={() => handleDelete(id)} />
                  </div>
                </>
              ) : (
                <DepartmentForm
                  department={{
                    id,
                    nameEn,
                    nameRu,
                    code,
                    parentDepartmentId,
                    headId: headId.id,
                  }}
                  parentDepartmentOptions={parentDepartmentOptions}
                  setDepartmentId={setDepartmentId}
                />
              )}
            </div>
          </li>
        )
      )}
    </ul>
  );
};

import { FC, useState, useEffect, ChangeEvent } from 'react';

import cn from 'clsx';

import { CheckLine } from '../icon';
import { Text } from '../text';

import styles from './checkbox.module.scss';

export type TCheckboxProps = {
  name?: string;
  label?: string;
  className?: string;
  checkboxClassName?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
};

export const Checkbox: FC<TCheckboxProps> = ({
  label,
  className,
  checkboxClassName,
  checked,
  name,
  onChange,
  error,
  required,
  disabled,
  indeterminate,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (typeof checked === 'boolean') {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);

    onChange?.(e);
  };

  return (
    <div className={cn(className, styles.container)}>
      <label
        className={cn(styles.container__indicator, {
          [styles.container__indicator_disabled]: disabled,
          [styles.container__indicator_error]: error,
        })}
      >
        <input
          className={styles.container__indicator__input}
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <div
          className={cn(
            checkboxClassName,
            styles.container__indicator__checkbox
          )}
        >
          {indeterminate && !isChecked && (
            <div
              className={styles.container__indicator__checkbox_indeterminate}
            />
          )}
          {isChecked && <CheckLine />}
        </div>
        {!!label && (
          <Text
            as="span"
            size="text-14"
            weight={500}
            className={styles.container__indicator__label}
          >
            {label}
          </Text>
        )}
        {required && (
          <Text
            as="span"
            size="text-14"
            weight={500}
            className={styles.container__indicator__required}
          >
            *
          </Text>
        )}
      </label>
      {!!error && (
        <Text as="span" size="text-12" className={styles.container__message}>
          {error}
        </Text>
      )}
    </div>
  );
};

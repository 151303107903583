import { FC, ChangeEvent } from 'react';

import cn from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { Input, TInputProps } from '../input';

import styles from './form.module.scss';

export interface IFormInputProps {
  name: string;
  label: string;
  className?: string;
  inputContainerClassName?: string;
}

export const FormInput: FC<
  Pick<TInputProps, 'inputClassName' | 'placeholder' | 'required'> &
    IFormInputProps
> = ({
  name,
  label,
  placeholder,
  className,
  inputContainerClassName,
  inputClassName,
  required,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({
        field: { onChange, name, onBlur, value },
        fieldState: { error },
      }) => {
        const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
          onChange(evt.target.value);
        };

        const handleBlur = () => {
          onBlur();
        };

        return (
          <div className={cn(styles.container, className)}>
            <Input
              label={label}
              name={name}
              className={inputContainerClassName}
              inputClassName={inputClassName}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error?.message}
              value={value}
              required={required}
            />
          </div>
        );
      }}
    />
  );
};

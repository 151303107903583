import { LoaderFunctionArgs } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { AnyType } from 'utils/types';

import { requests } from '../../api';

export const loaders = ({ params }: LoaderFunctionArgs<AnyType>) => [
  {
    name: 'salary',
    fn: () => requests.getEmployeeSalary(params.employeeId as ID),
  },
  {
    name: 'salaryHistory',
    fn: () => requests.getSalaryHistory(params.employeeId as ID),
  },
  {
    name: 'employee',
    fn: () => requests.getEmployeeDataById(params.employeeId as ID),
  },
  {
    name: 'revisionRequests',
    fn: () => requests.getEmployeeRevisionRequests(params.employeeId as ID),
  },
  {
    name: 'paymentHistory',
    fn: () => requests.getSalaryTransactionById(params.employeeId as ID),
  },
  {
    name: 'bankAccounts',
    fn: requests.getBankAccounts,
  },
];

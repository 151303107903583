import { useLoaderData } from 'react-router-dom';

import { ISalary, ISalaryHistoty } from 'components/api/types/salary';
import { Card } from 'design/card';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { Text } from 'design/text';
import { getInitialsEmployeeFromFullName } from 'utils';

import { Paths } from '../../../../../utils/types';

import styles from '../employee-salary.module.scss';

const generateColumns = <
  T extends ISalary | ISalaryHistoty
>(): TTableColumn<T>[] => [
  {
    title: 'Фио',
    dataIndex: ['fullName'] as Paths<T>,
    render: ({ fullName }) => (
      <Text size="text-14" weight={400}>
        {getInitialsEmployeeFromFullName(fullName)}
      </Text>
    ),
    minWidth: '200px',
  },
  {
    title: 'Должность',
    dataIndex: ['positionNameRu'] as Paths<T>,
    minWidth: '210px',
  },
  {
    title: 'Юр Лицо',
    dataIndex: ['legalEntityName'] as Paths<T>,
    minWidth: '200px',
  },
  {
    title: 'Оклад',
    dataIndex: ['salary'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Надбавка',
    dataIndex: ['surcharge'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Авторские',
    dataIndex: ['authorAllowances'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Премия',
    dataIndex: ['bonus'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Доп Нал',
    dataIndex: ['addCash'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'ЗП с НДФЛ',
    dataIndex: ['salaryWithNdfl'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Чистыми без НДФЛ',
    dataIndex: ['salaryWithoutNdfl'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Тотал до вычета',
    dataIndex: ['totalSalary'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Тотал на руки',
    dataIndex: ['salaryOnHands'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Страх. взносы',
    dataIndex: ['taxInsurance'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
  {
    title: 'Тотал + НДФЛ + взносы',
    dataIndex: ['salaryWithTaxInsurance'] as Paths<T>,
    minWidth: '80px',
    maxWidth: '120px',
  },
];

export const General = () => {
  const { salary, salaryHistory } = useLoaderData() as {
    salary: ISalary[];
    salaryHistory: ISalaryHistoty[];
  };

  return (
    <div className={styles.content}>
      <Card
        bodyClassName={styles.card}
        title={
          <Text size="text-18" weight={500}>
            Текущая заработная плата
          </Text>
        }
      >
        <Table columns={generateColumns<ISalary>()} data={salary} key="id" />
      </Card>
      <Card
        bodyClassName={styles.card}
        title={
          <Text size="text-18" weight={500}>
            История пересмотров заработной платы
          </Text>
        }
      >
        <div className={styles.content__table}>
          <Table
            columns={generateColumns<ISalaryHistoty>()}
            data={salaryHistory}
            key="id"
          />
        </div>
      </Card>
    </div>
  );
};

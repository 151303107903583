import { FC, useMemo } from 'react';

import { Text } from 'design/text';

import { getWeeks } from '../utils';

import styles from '../calendar-table.module.scss';

interface IWeekProps {
  column: string;
  year: number;
}

export const Week: FC<IWeekProps> = ({ column, year }) => {
  const weeks = useMemo(() => getWeeks(Number(column), year), [column, year]);

  return (
    <div className={styles.table__header__cell__column__container}>
      {weeks.map((week, index) => (
        <div
          key={index}
          className={styles.table__header__cell__column__container__week}
        >
          {week.map((day) => (
            <div
              key={day}
              className={
                styles.table__header__cell__column__container__week__day
              }
            />
          ))}
          <div
            key={index}
            className={
              styles.table__header__cell__column__container__week__text
            }
          >
            <Text size="text-12" weight={500} as="span">
              {`${week[0]}`}
            </Text>
            {week.length > 1 ? (
              <Text size="text-12" weight={500} as="span">
                {`- ${week[week.length - 1]}`}
              </Text>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

import { useEffect, useState, ChangeEvent } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { ID, TTableMode } from 'components/api/types/common';
import { IExpensesTransactions } from 'components/api/types/expenses-transactions';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { ButtonSwitch } from 'design/button-switch';
import { Checkbox } from 'design/checkbox';
import { DropdownButton, IAction } from 'design/dropdown-button';
import { AddBoxLine, Attachment, DeleteBinLine, Edit2Fill } from 'design/icon';
import { Link } from 'design/link';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { dateSorting, textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { formatDate } from 'utils';
import { useSubmit } from 'utils/useSubmit';

import { ExpensesTransactionsModal } from './modal/expenses-transactions-modal';
import { SelectedTransactionsModal } from './modal/selected-expenses-transactions-modal';

import styles from './expenses-transactions.module.scss';

const openAddExpensesTransactions = () => {
  modal.open({
    id: 'add-expenses-transactions-modal',
    title: 'Добавление данных',
    formId: 'add-expenses-transactions-form',
    content: <ExpensesTransactionsModal />,
  });
};

const openEditExpensesTransactions = (initialValue: IExpensesTransactions) => {
  modal.open({
    id: 'edit-expenses-transactions-modal',
    title: 'Редактирование данных',
    formId: 'edit-expenses-transactions-form',
    content: <ExpensesTransactionsModal initialValue={initialValue} />,
  });
};

export const ExpensesTransactionsPage = () => {
  const [checkedTransactions, setCheckedTransactions] = useState<ID[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableMode, setTableMode] = useState<TTableMode>(
    (searchParams.get('filter') as TTableMode | null) ?? 'planned'
  );

  const { submit } = useSubmit();

  useEffect(() => {
    const filter = searchParams.get('filter');

    setTableMode(filter as TTableMode);
  }, [searchParams]);

  const { expensesTransactions } = useLoaderData() as {
    expensesTransactions: IExpensesTransactions[];
  };

  const handleDelete = (id: ID) => {
    submit({
      entity: 'expensesTransactions',
      data: {
        removedId: id,
      },
    });
  };

  const allCheckedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setCheckedTransactions(
        expensesTransactions.map((transaction) => transaction.id)
      );
    } else {
      setCheckedTransactions([]);
    }
  };

  const openEditSelectedExpensesTransactions = () => {
    modal.open({
      className: styles.modal,
      id: 'selected-expenses-transactions-modal',
      title: 'Редактирование выбранных данных',
      formId: 'selected-expenses-transactions-form',
      content: (
        <SelectedTransactionsModal
          ids={checkedTransactions}
          setCheckedTransactions={setCheckedTransactions}
        />
      ),
    });
  };

  const openDeleteTransactionModal = () => {
    modal.open({
      id: 'delete-expenses-transactions-modal',
      submit: 'Да',
      onSubmit: () => {
        submit({
          entity: 'expensesTransactions',
          data: {
            removedIds: checkedTransactions,
          },
        });

        setCheckedTransactions([]);
      },
      content: (
        <Text size="text-20" weight={500}>
          Вы уверены, что хотите удалить выбранные?
        </Text>
      ),
    });
  };

  const openForcedActivationExpensesModal = () => {
    modal.open({
      id: 'forced-activation-expenses-modal',
      submit: 'Да',
      onSubmit: () =>
        submit({
          entity: 'expensesTransactions',
          data: {
            isForcedActivation: true,
          },
        }),
      content: (
        <Text size="text-20" weight={500}>
          Вы уверены, что хотите принудительно создать активацию повторных
          расходов?
        </Text>
      ),
    });
  };

  const plannedColumns: TTableColumn<IExpensesTransactions>[] = [
    {
      titleRender: () => (
        <>
          {tableMode === 'planned' && (
            <Checkbox
              checked={
                !!checkedTransactions.length &&
                expensesTransactions.length === checkedTransactions.length
              }
              indeterminate={!!checkedTransactions.length}
              onChange={allCheckedHandler}
            />
          )}
        </>
      ),
      render: (data) => {
        const checked = checkedTransactions.find((id) => id === data.id);

        const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
          if (e.currentTarget.checked) {
            setCheckedTransactions((state) => [...state, data.id]);
          } else {
            setCheckedTransactions((state) =>
              state.filter((id) => id !== data.id)
            );
          }
        };

        return (
          <Checkbox
            className={styles.columnCheckbox}
            checked={!!checked}
            onChange={onChangeHandler}
          />
        );
      },
    },
    {
      title: 'Категория',
      dataIndex: ['expensesCategory'],
      sortable: true,
      sorting: textSorting,
      render: (data) => (
        <Text size="text-14" weight={400}>
          {data.expensesCategory.name}
        </Text>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: ['amount'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Статус',
      dataIndex: ['status'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccount'],
      sortable: true,
      sorting: textSorting,
      render: ({ bankAccount }) => (
        <Text size="text-14" weight={400}>
          {bankAccount.bankName}
        </Text>
      ),
      minWidth: '180px',
    },
    {
      title: 'Комментарий',
      dataIndex: ['comment'],
      sortable: true,
      sorting: textSorting,
      render: ({ comment }) => (
        <Text size="text-14" weight={400}>
          {comment ?? ''}
        </Text>
      ),
      minWidth: '200px',
    },
    {
      title: 'Дата выплаты',
      dataIndex: ['paymentDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ paymentDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(paymentDate)}
        </Text>
      ),
      minWidth: '150px',
    },
    {
      title: 'Дата оплаты',
      dataIndex: ['paidAt'],
      sortable: true,
      sorting: dateSorting,
      render: ({ paidAt }) => (
        <Text size="text-14" weight={400} as="span">
          {paidAt ? formatDate(paidAt) : ''}
        </Text>
      ),
      minWidth: '150px',
    },
    {
      title: 'Утверждено',
      dataIndex: ['isApproved'],
      render: ({ isApproved }) => (
        <Text size="text-14" weight={400}>
          {isApproved ? 'Да' : 'Нет'}
        </Text>
      ),
    },
    {
      title: 'Ссылка на документ',
      dataIndex: ['documents'],
      render: ({ documents }) =>
        documents?.length ? (
          <div className={styles.files}>
            {documents.map((file) => (
              <div key={file.id} className={styles.files__container}>
                <div className={styles.files__container__name}>
                  <Attachment />
                  <Link
                    href={file.url}
                    target="_blank"
                    className={styles.files__container__button}
                  >
                    <Text size="text-12" weight={500} as="span">
                      {file.url}
                    </Text>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Text size="text-14" as="span">
            Нет данных
          </Text>
        ),
      minWidth: '180px',
    },
    {
      title: 'Действие',
      actions: {
        onDelete: handleDelete,
        onEdit: openEditExpensesTransactions,
      },
    },
  ];

  const paidColumns: TTableColumn<IExpensesTransactions>[] = [
    {
      title: 'Категория',
      dataIndex: ['expensesCategory'],
      sortable: true,
      sorting: textSorting,
      render: (data) => (
        <Text size="text-14" weight={400}>
          {data.expensesCategory.name}
        </Text>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: ['amount'],
      sortable: true,
      sorting: textSorting,
      groupNumber: (data) => Number(data.id),
    },
    {
      title: 'Статус',
      dataIndex: ['status'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccount'],
      sortable: true,
      sorting: textSorting,
      render: ({ bankAccount }) => (
        <Text size="text-14" weight={400}>
          {bankAccount.bankName}
        </Text>
      ),
      minWidth: '180px',
    },
    {
      title: 'Комментарий',
      dataIndex: ['comment'],
      sortable: true,
      sorting: textSorting,
      render: ({ comment }) => (
        <Text size="text-14" weight={400}>
          {comment ?? ''}
        </Text>
      ),
      minWidth: '200px',
    },
    {
      title: 'Дата выплаты',
      dataIndex: ['paymentDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ paymentDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(paymentDate)}
        </Text>
      ),
      minWidth: '150px',
    },
    {
      title: 'Дата оплаты',
      dataIndex: ['paidAt'],
      sortable: true,
      sorting: dateSorting,
      render: ({ paidAt }) => (
        <Text size="text-14" weight={400} as="span">
          {paidAt ? formatDate(paidAt) : ''}
        </Text>
      ),
      minWidth: '150px',
    },
    {
      title: 'Утверждено',
      dataIndex: ['isApproved'],
      render: ({ isApproved }) => (
        <Text size="text-14" weight={400}>
          {isApproved ? 'Да' : 'Нет'}
        </Text>
      ),
    },
    {
      title: 'Ссылка на документ',
      dataIndex: ['documents'],
      render: ({ documents }) =>
        documents?.length ? (
          <div className={styles.files}>
            {documents.map((file) => (
              <div key={file.id} className={styles.files__container}>
                <div className={styles.files__container__name}>
                  <Attachment />
                  <Link
                    href={file.url}
                    target="_blank"
                    className={styles.files__container__button}
                  >
                    <Text size="text-12" weight={500} as="span">
                      {file.url}
                    </Text>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Text size="text-14" as="span">
            Нет данных
          </Text>
        ),
      minWidth: '180px',
    },
  ];

  const employeeActions: IAction[] = [
    {
      label: 'Редактировать',
      icon: <Edit2Fill />,
      onClick: openEditSelectedExpensesTransactions,
    },
    {
      label: 'Удалить',
      icon: <DeleteBinLine />,
      variant: 'red',
      onClick: openDeleteTransactionModal,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container__header}>
        <Text
          size="text-20"
          weight={500}
          className={styles.container__header__title}
        >
          Траты
        </Text>
      </div>
      <div className={styles.container__table}>
        <div className={styles.container__table__header}>
          <Button label="ФОРС" onClick={openForcedActivationExpensesModal} />
          <div className={styles.container__table__header__buttons}>
            <ButtonSwitch
              options={[
                { value: 'planned', label: 'Запланировано' },
                { value: 'paid', label: 'Выплачено' },
              ]}
              onChange={(option) => {
                setSearchParams({ filter: `${option.value}` });
              }}
              active={tableMode}
            />
            <DropdownButton
              label="Изменить выбранное"
              disabled={!checkedTransactions.length}
              className={styles.container__table__header__buttons__dropdown}
              actions={employeeActions}
            />
            <Button
              label="Добавить"
              onClick={openAddExpensesTransactions}
              prefixIcon={<AddBoxLine />}
            />
          </div>
        </div>
        <ContentContainer>
          <Table
            columns={tableMode === 'planned' ? plannedColumns : paidColumns}
            data={expensesTransactions}
            key="id"
          />
        </ContentContainer>
      </div>
    </div>
  );
};

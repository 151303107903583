import { ID } from 'components/api/types/common';
import { ICurrency, ILegalEntity } from 'components/api/types/dictionary-types';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  сonfigureCurrency: async (data: {
    removedId: ID | null;
    сonfigureCurrencyId: ID | null;
    сonfigureCurrency: ICurrency;
  }) => {
    data.removedId && (await requests.deleteCurrencyData(data.removedId));

    data.сonfigureCurrencyId &&
      (await requests.patchCurrencyData(
        data.сonfigureCurrencyId,
        data.сonfigureCurrency
      ));

    data.сonfigureCurrency &&
      !data.сonfigureCurrencyId &&
      (await requests.postCurrencyData(data.сonfigureCurrency));

    await queryClient.invalidateQueries({
      queryKey: ['currencies'],
    });
  },
  legalEntity: async (data: {
    removedId: ID | null;
    legalEntityId: ID | null;
    legalEntity: ILegalEntity;
  }) => {
    data.removedId && (await requests.deleteLegalEntityData(data.removedId));

    data.legalEntityId &&
      (await requests.patchLegalEntityData(
        data.legalEntityId,
        data.legalEntity
      ));

    data.legalEntity &&
      !data.legalEntityId &&
      (await requests.postLegalEntityData(data.legalEntity));

    await queryClient.invalidateQueries({
      queryKey: ['legalEntity'],
    });
  },
};

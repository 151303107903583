import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IEmployee } from '../types/employee';
import { EModule } from '../types/enums';

export const getCurrentEmployees =
  (module: EModule) =>
  (
    dismissed: boolean,
    search?: string | null,
    includeMissingDays?: boolean
  ): Promise<AxiosResponse<IEmployee[]>> =>
    $host.get(`/${module}/employees`, {
      params: { dismissed, search, includeMissingDays },
    });

export const getEmployeeDataById =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IEmployee>> =>
    $host.get(`/${module}/employees/${id}`);

export const patchEmployeeData =
  (module: EModule) =>
  (id: ID, values: Partial<IEmployee>): Promise<AxiosResponse<IEmployee>> =>
    $host.patch(`/${module}/employees/${id}`, { ...values });

export const postEmployeeData =
  (module: EModule) =>
  (values: Omit<IEmployee, 'id'>): Promise<AxiosResponse<IEmployee>> =>
    $host.post(`/${module}/employees`, { ...values });

import { FC } from 'react';

import { IExpensesCategories } from 'components/api/types/expenses-categories';

import { TreeNode } from './tree-node';

interface ITreeProps {
  data: IExpensesCategories[];
}

export const Tree: FC<ITreeProps> = ({ data }) => (
  <div>
    {data.map((node) => (
      <TreeNode node={node} key={node.id} />
    ))}
  </div>
);

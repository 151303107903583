import { IModalContent } from './../types/index';
import { modalManager, Event } from './modalManager';

interface IModal {
  open: (content: IModalContent) => void;
  close: () => void;
}

function destroyModal() {
  modalManager.emit(Event.Close);
}

function createModal() {
  return (content: IModalContent) => modalManager.emit(Event.Open, content);
}

export const modal: IModal = {
  open: createModal(),
  close: destroyModal,
};

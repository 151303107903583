import { ID } from 'components/api/types/common';
import { IExchangeRate } from 'components/api/types/dictionary-types';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  exchangeRates: async (data: {
    removedId: ID | null;
    exchangeRateId: ID | null;
    exchangeRate: IExchangeRate;
  }) => {
    data.removedId && (await requests.deleteExchangeRateData(data.removedId));

    data.exchangeRateId &&
      (await requests.patchExchangeRateData(
        data.exchangeRateId,
        data.exchangeRate
      ));

    data.exchangeRate &&
      !data.exchangeRateId &&
      (await requests.postExchangeRateData(data.exchangeRate));

    await queryClient.invalidateQueries({
      queryKey: ['exchangeRates'],
    });
  },
};

import { FC } from 'react';

import cn from 'clsx';

import { CheckLine } from '../icon';
import { Text } from '../text';

import styles from './step-panel.module.scss';

export interface IStep {
  id: string;
  label: string;
}

export interface IStepPanelProps {
  steps: IStep[];
  activeStep: IStep | null;
  onStepChange?: (step: IStep) => void;
  className?: string;
  completedSteps?: IStep[];
}

export interface IStepProps extends IStep {
  active: boolean;
  step: number;
  completed?: boolean;
  onStepChange?: (step: IStep) => void;
}

const Step: FC<IStepProps> = ({
  step,
  label,
  id,
  active,
  completed = false,
  onStepChange,
}) => (
  <div
    className={cn(styles.step, {
      [styles.active]: active,
      [styles.pointer]: !!onStepChange,
    })}
    onClick={() =>
      onStepChange?.({
        id,
        label,
      })
    }
  >
    {completed ? (
      <div className={cn(styles.completed, styles.stepNumber)}>
        <CheckLine />
      </div>
    ) : (
      <Text as="span" className={styles.stepNumber} size="text-16">
        {`${step}`}
      </Text>
    )}
    <Text
      as="span"
      className={styles.stepLabel}
      size="text-14"
      weight={active ? 500 : 400}
    >
      {label}
    </Text>
  </div>
);

export const StepPanel: FC<IStepPanelProps> = ({
  steps,
  activeStep,
  className,
  completedSteps,
  onStepChange,
}) => (
  <div className={cn(styles.stepPanel, className)}>
    {steps.map((step, index) => (
      <Step
        completed={
          !!completedSteps?.find(
            (completedStep) => completedStep.id === step.id
          )
        }
        key={step.id}
        id={step.id}
        label={step.label}
        active={activeStep?.id === step.id}
        step={index + 1}
        onStepChange={onStepChange}
      />
    ))}
  </div>
);

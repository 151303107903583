import cn from 'clsx';
import { Paths } from 'utils/types';

import { SortNone, SortAsc, SortDesc } from '../icon';
import { Text } from '../text';

import styles from './table.module.scss';

export enum ESorting {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

type TVariant<T> =
  | {
      dataIndex: Paths<T>;
      title: string;
      titleRender?: () => React.ReactNode;
      sorting?: (valueA: string, valueB: string) => number;
      sortable?: boolean;
      rowSpanCount?: (data: T) => number;
      queue?: (data: T) => number;
      groupNumber?: (data: T) => number;
      actions?: never;
      minWidth?: `${number}px` | `${number}%`;
      maxWidth?: `${number}px` | `${number}%`;
    }
  | {
      dataIndex?: never;
      title?: string;
      titleRender?: () => React.ReactNode;
      sorting?: never;
      sortable?: never;
      rowSpanCount?: (data: T) => number;
      queue?: (data: T) => number;
      groupNumber?: (data: T) => number;
      actions?: {
        onEdit?: (data: T) => void;
        onDelete?: (id: number) => void;
      };
      minWidth?: `${number}px` | `${number}%`;
      maxWidth?: `${number}px` | `${number}%`;
    };

export type TTableColumn<T> = {
  loading?: boolean;
  render?: (data: T) => React.ReactNode;
} & TVariant<T>;

export type TTableGropableColumn<T, U> = T &
  U & { rowSpanCount: number; queue: number; groupNumber: number };

export const Column = <T,>(
  column: TTableColumn<T> & {
    sortOrder?: ESorting;
    sortHandler?: (dataIndex: TTableColumn<T>, direction: ESorting) => void;
  }
) => (
  <th
    className={styles.column}
    style={{
      minWidth: column.minWidth ?? 'auto',
      maxWidth: column.maxWidth ?? 'auto',
      width: column.actions ? '60px' : 'auto',
    }}
  >
    <div
      className={cn(styles.content, {
        [styles.sortable]: column?.sortable,
      })}
      {...(column.sortable
        ? {
            onClick: () =>
              column.sortHandler?.(column, column.sortOrder ?? ESorting.NONE),
          }
        : {})}
    >
      {column.title && (
        <Text size="text-12" weight={500}>
          {column.title}
        </Text>
      )}
      {column.titleRender && column.titleRender()}
      {column.sortOrder === ESorting.NONE && <SortNone />}
      {column.sortOrder === ESorting.ASC && <SortAsc />}
      {column.sortOrder === ESorting.DESC && <SortDesc />}
    </div>
  </th>
);

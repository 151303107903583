import { Paths } from 'utils/types';

import { TTableGropableColumn } from './column';

export const getNestedProperty = <T>(record: T, keys: Paths<T>): string =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  keys.reduce((rec: T, key: keyof T) => {
    if (rec && typeof rec === 'object') {
      return rec[key];
    }

    return rec;
  }, record);

export const dateSorting = (valueA: string, valueB: string) =>
  new Date(valueA).getTime() - new Date(valueB).getTime();

export const textSorting = (valueA: string, valueB: string) =>
  valueB < valueA ? -1 : 1;

export const numberSorting = (valueA: string, valueB: string) =>
  Number(valueA) - Number(valueB);

export const groupableAdapter = <T, U>(
  data: T[]
): TTableGropableColumn<T, U>[] => {
  const res: TTableGropableColumn<T, U>[] = [];

  data.forEach((el: any, index) => {
    let array: U[] = [];

    Object.keys(el).forEach((candidate) => {
      if (Array.isArray(el[candidate])) {
        array = el[candidate] as U[];
      }
    });

    array.forEach((item, i, arr) => {
      res.push({
        ...el,
        ...item,
        rowSpanCount: arr.length,
        queue: i,
        groupNumber: index,
      });
    });
  });

  return res;
};

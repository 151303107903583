import {
  useRouteError,
  isRouteErrorResponse,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import { useUserContext } from 'components/context/context-user';
import { PageLayout } from 'components/page-layout';
import { Text } from 'design/text';

import { ErrorPage } from './error-page';

import styles from './error-boundary.module.scss';

// ToDo: write your own ErrorBoundary for routes without adding PageLayout and check how much code duplication will take place

export const RootBoundary = () => {
  const { user, setLoading } = useUserContext();

  const error = useRouteError();
  const navigate = useNavigate();

  let errorPage = <Text>Что-то пошло не так и мы знаем об этом</Text>;

  if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      return <Navigate to="/auth" replace={true} />;
    }

    if (error.status === 403) {
      errorPage = (
        <PageLayout>
          <ErrorPage
            code={error.status}
            text="Доступ к запрашиваемой странице запрещен или у вас нет прав на просмотр контента"
            btnLabel="Вернуться назад"
            onClick={() => navigate(-1)}
          />
        </PageLayout>
      );
    }

    if (error.status === 404) {
      const loggedIn = localStorage.getItem('loggedIn');

      if (loggedIn && !user) {
        setLoading(true);

        return <div />;
      }

      if (loggedIn && user) {
        errorPage = (
          <PageLayout>
            <ErrorPage
              code={error.status}
              text="Кажется мы потеряли страницу и не можем найти. Попробуй по новой."
            />
          </PageLayout>
        );
      }

      if (!loggedIn && !user) {
        return <Navigate to={'/auth'} replace={true} />;
      }
    }

    if (error.status === 500) {
      errorPage = (
        <PageLayout>
          <ErrorPage
            code={error.status}
            text="Сервер прилег отдохнуть. Подождите пару минут он отдохнет и будет работать как надо"
          />
        </PageLayout>
      );
    }

    if (error.status === 503) {
      errorPage = (
        <PageLayout>
          <ErrorPage
            code={error.status}
            text="Наши сервера не готовы обрабатывать запросы. Попробуйте по новой"
          />
        </PageLayout>
      );
    }
  }

  return <div className={styles.root_boundary}>{errorPage}</div>;
};

import { FC, useEffect, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IEducationDegree } from 'components/api/types/dictionary-types';
import { Button } from 'design/button';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { useFormContext } from 'react-hook-form';
import { useSubmit } from 'utils/useSubmit';

import { educationDegreeSchema } from '../../../utils/validation';

import { IFormContentProps } from './type';

import styles from './forms.module.scss';

export type TEducationDegreeFormProps = Partial<
  IEducationDegree & { setEducationDegreeId: (value: ID) => void }
>;

type TFormValue = Omit<IEducationDegree, 'id'>;

export const EducationDegreeFormContent: FC<IFormContentProps> = ({
  loading,
  isEdit,
  setId,
  setSuccess,
  isSuccess,
}) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      reset({ degree: '' });
      setSuccess(false);
    }
  }, [isSuccess, reset, setSuccess]);

  return (
    <div
      className={cn(styles.form, {
        [styles.container]: !isEdit,
      })}
    >
      <div className={styles.form__section}>
        <FormInput required name="degree" label="Степень образования" />
      </div>
      {isEdit ? (
        <div className={styles.form__button}>
          <Button
            label="Сохранить"
            loading={loading}
            type="submit"
            buttonType="secondary"
          />
          <Button
            label="Отмена"
            type="button"
            buttonType="secondary"
            onClick={() => setId?.('')}
          />
        </div>
      ) : (
        <Button
          label="Добавить"
          loading={loading}
          type="submit"
          className={styles.form__add}
        />
      )}
    </div>
  );
};

export const EducationDegreeForm: FC<TEducationDegreeFormProps> = ({
  id,
  degree,
  setEducationDegreeId,
}) => {
  const [isSuccess, setSuccess] = useState(false);

  const { loading, submit } = useSubmit(() => {
    setEducationDegreeId?.('');
    setSuccess(true);
  });

  const onSubmit = (value: TFormValue) => {
    submit({
      entity: 'educationDegree',
      data: {
        educationDegree: value,
        educationDegreeId: id,
      },
    });
  };

  return (
    <Form
      id="education-degree"
      initialValues={{ degree }}
      onSubmit={onSubmit}
      validation={educationDegreeSchema}
    >
      <EducationDegreeFormContent
        isEdit={!!id}
        loading={loading}
        setId={setEducationDegreeId}
        setSuccess={setSuccess}
        isSuccess={isSuccess}
      />
    </Form>
  );
};

export const requiredField = 'Обязательное поле';

export const incorrectData = 'Некорректные данные';

export const startDate = 'Дата должна быть меньше даты окончания';

export const startDateEqual =
  'Дата должна быть меньше или равна дате окончания';

export const endDate = 'Дата должна быть больше даты начала';

export const endDateEqual = 'Дата должна быть больше или равна дате начала';

export const fieldFromLetters = 'В поле должны быть только буквы';

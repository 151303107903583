import { FC, useState } from 'react';

import cn from 'clsx';
import { ID } from 'components/api/types/common';
import { IExpensesCategories } from 'components/api/types/expenses-categories';
import { AddBoxLine, ArrowRight, DeleteBinLine, Edit2Fill } from 'design/icon';
import { modal } from 'design/modal/core';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { Tree } from '..';
import { ExpensesCategoryModal } from '../../modal';

import styles from './tree-node.module.scss';

interface ITreeNodeProps {
  node: IExpensesCategories;
}

const openAddExpensesCategories = (parentCategoryId: ID) => {
  modal.open({
    id: 'add-expenses-category-modal',
    title: 'Добавление данных',
    formId: 'add-expenses-category-form',
    content: <ExpensesCategoryModal parentCategoryId={parentCategoryId} />,
  });
};

const openEditExpensesCategories = (
  initialValue: Omit<IExpensesCategories, 'childCategories'>
) => {
  modal.open({
    id: 'edit-expenses-category-modal',
    title: 'Редактирование данных',
    formId: 'edit-expenses-category-form',
    content: <ExpensesCategoryModal initialValue={initialValue} />,
  });
};

export const TreeNode: FC<ITreeNodeProps> = ({ node }) => {
  const [showChildren, setShowChildren] = useState(true);
  const { submit } = useSubmit();

  const { childCategories, name, id } = node;

  const handleDelete = (id: ID) => {
    submit({
      entity: 'expensesCategories',
      data: {
        removedId: id,
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__info}>
        <div
          onClick={() => setShowChildren(!showChildren)}
          className={cn(styles.container__info__text, {
            [styles.container__info__text_active]: !!childCategories?.length,
          })}
        >
          <ArrowRight
            className={cn(styles.container__info__svg, {
              [styles.container__info__svg_open]:
                !!childCategories?.length && showChildren,
              [styles.container__info__svg_disabled]: !childCategories?.length,
            })}
          />
          <Text
            size="text-16"
            weight={400}
            as="span"
            className={cn(styles.container__info__name, {
              [styles.container__info__name_left]: !childCategories?.length,
            })}
          >
            {name}
          </Text>
        </div>
        <div className={styles.container__info__actions}>
          <AddBoxLine onClick={() => openAddExpensesCategories(id)} />
          <Edit2Fill onClick={() => openEditExpensesCategories({ name, id })} />
          <DeleteBinLine onClick={() => handleDelete(id)} />
        </div>
      </div>
      <div className={styles.container__tree}>
        {showChildren && childCategories && <Tree data={childCategories} />}
      </div>
    </div>
  );
};

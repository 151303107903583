import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { IBankAccount } from '../types/bank-account';
import { ID } from '../types/common';
import { EModule } from '../types/enums';

export const getBankAccounts =
  (module: EModule) => (): Promise<AxiosResponse<IBankAccount[]>> =>
    $host.get(`/${module}/config/bank-accounts`);

export const postBankAccountData =
  (module: EModule) =>
  (values: Omit<IBankAccount, 'id'>): Promise<AxiosResponse<IBankAccount>> =>
    $host.post(`/${module}/config/bank-accounts`, { ...values });

export const patchBankAccountData =
  (module: EModule) =>
  (
    id: ID,
    values: Partial<IBankAccount>
  ): Promise<AxiosResponse<IBankAccount>> =>
    $host.patch(`/${module}/config/bank-accounts/${id}`, { ...values });

export const deleteBankAccountData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/config/bank-accounts/${id}`);

import { FC } from 'react';

import { calendarTableColumns } from '../../constants';
import { IScheduleTableProps } from '../../types';

import { Cell } from './cell';

import styles from '../calendar-table.module.scss';

export const BodyTable: FC<IScheduleTableProps> = ({ employees, year }) => (
  <tbody className={styles.table__body}>
    {employees.map((employee) => (
      <tr key={employee.id} className={styles.table__body__row}>
        {calendarTableColumns.map((column) => (
          <td key={column.dataIndex} className={styles.table__body__row__cell}>
            <Cell
              missingDays={employee.missingDays}
              year={year}
              column={Number(column.dataIndex)}
              employeeId={employee.id}
            />
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

import { EMissingDays } from 'components/api/types/enums';
import { differenceInDays } from 'date-fns';

import { calculateDifferenceDate } from '../../../../../utils';

export const getWeeks = (month: number, year: number) =>
  Array(new Date(year, month, 0).getDate())
    .fill(0)
    .map((_, i) => new Date(year, month - 1, i + 1))
    .map((d, i, a) =>
      !i && (d.getDay() + 6) % 7 !== 0
        ? [Array((d.getDay() + 6) % 7).fill(null), d.getDate()]
        : d.getDate() === a.length && d.getDay() < 6
        ? [d.getDate(), Array(6 - d.getDay()).fill(null)]
        : d.getDate()
    )
    .flat(2)
    .map((_d, _i, a) => (a.length ? a.splice(0, 7) : null))
    .filter((w) => w && w?.length)
    .map((week) => week?.filter(Boolean))
    .filter((w) => w?.length) as number[][];

export const getDifferenceInMinutes = (dateStart: string, dateEnd: string) => {
  const currentDateEnd = new Date(dateEnd);
  currentDateEnd.setDate(currentDateEnd.getDate() + 1);

  const data = differenceInDays(new Date(currentDateEnd), new Date(dateStart));

  return data;
};

const getFormattedNumber = (str: string) =>
  str[0] === '0' ? Number(str[1]) : Number(str);

export const compareDate = (date: string, day: number, column: number) => {
  const dateArray = date.split('-');

  return (
    getFormattedNumber(dateArray[1]) === column &&
    getFormattedNumber(dateArray[2]) === day
  );
};

export const getColor = (type: EMissingDays) => {
  if (type === EMissingDays.Больничный) {
    return '#60a5fa'; //$blue-400
  }

  if (type === EMissingDays.Отгул) {
    return '#ef4444'; //$red-500
  }

  return '#fbbf24'; //$yellow-400
};

export const getDifferenceDate = (startDate: string, endDate: string) =>
  calculateDifferenceDate(startDate, endDate, 'days') + 1;

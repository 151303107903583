import { FC } from 'react';

import { EPaymentStatus } from 'components/api/types/enums';
import {
  IExpensesTransactions,
  TNewExpensesTransactions,
} from 'components/api/types/expenses-transactions';
import { queryClient } from 'components/context/query';
import { Form } from 'design/form';
import { FormDatepicker } from 'design/form/form-datepicker';
import { FormInput } from 'design/form/form-input';
import { FormSelect } from 'design/form/form-select';
import { FormUploader } from 'design/form/form-uploader';
import { IModalOverride } from 'design/modal';
import { datePlaceholder, linkPlaceholder } from 'utils/placeholder-text';
import { useSubmit } from 'utils/useSubmit';

import { requests } from '../../../api';
import { expensesTransactionsSchema } from '../../../utils/validation';

import styles from './modal.module.scss';

const APPROVED_STATUS_OPTIONS = [
  {
    label: 'Да',
    value: 'true',
  },
  {
    label: 'Нет',
    value: 'false',
  },
];

interface IExpensesTransactionsModalProps extends IModalOverride {
  initialValue?: IExpensesTransactions;
}

type TFormData = Omit<IExpensesTransactions, 'isApproved'> & {
  isApproved?: string;
};

const FormContent = () => (
  <div className={styles.content}>
    <FormSelect
      required
      search
      name="expensesCategoryId"
      label="Категория"
      options={async () => {
        const options = await queryClient.fetchQuery({
          queryKey: ['expensesCategory'],
          queryFn: () => requests.getExpensesCategories(true),
        });

        return options.data.map((option) => ({
          value: option.id,
          label: option.name,
        }));
      }}
    />
    <FormInput required name="amount" label="Сумма" />
    <FormSelect
      required
      name="bankAccountId"
      label="Банк аккаунт"
      options={async () => {
        const options = await queryClient.fetchQuery({
          queryKey: ['bankAccounts'],
          queryFn: requests.getBankAccounts,
        });

        return options.data.map((option) => ({
          value: option.id,
          label: option.bankName,
        }));
      }}
    />
    <FormDatepicker
      required
      name="paymentDate"
      label="Дата выплаты"
      placeholder={datePlaceholder}
    />
    <FormSelect
      name="isApproved"
      label="Утверждено"
      options={APPROVED_STATUS_OPTIONS}
    />
    <FormInput name="comment" label="Комментарий" />
    <FormUploader
      name="documents"
      className={styles.content__fileUploader}
      placeholder={linkPlaceholder}
    />
  </div>
);

export const ExpensesTransactionsModal: FC<IExpensesTransactionsModalProps> = ({
  formId,
  initialValue,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = ({
    documents,
    isApproved,
    comment,
    paymentDate,
    bankAccountId,
    amount,
    expensesCategoryId,
    id,
  }: TFormData) => {
    const formData = {
      isApproved: isApproved && JSON.parse(isApproved),
      amount: Number(amount),
      expensesCategoryId: Number(expensesCategoryId),
      bankAccountId: Number(bankAccountId),
      documents: documents?.map((el) => el.url),
      comment,
      paymentDate,
    } as TNewExpensesTransactions;

    if (initialValue?.status) {
      formData.status = initialValue.status;
    } else {
      formData.status = EPaymentStatus.Создан;
    }

    if (initialValue?.paidAt) {
      formData.paidAt = initialValue.paidAt;
    }

    submit({
      entity: 'expensesTransactions',
      data: {
        expensesTransaction: { ...formData },
        expensesTransactionId: initialValue ? id : null,
      },
    });
  };

  const modifiedInitialValue = {
    ...initialValue,
    isApproved: initialValue?.isApproved
      ? initialValue?.isApproved?.toString()
      : 'false',
  } as unknown as TFormData;

  return (
    <Form
      id={formId}
      validation={expensesTransactionsSchema}
      initialValues={
        Object.values(modifiedInitialValue).length
          ? modifiedInitialValue
          : {
              isApproved: 'false',
            }
      }
      onDirty={(isDirty) => setConfirm?.(isDirty)}
      onSubmit={onSubmit}
    >
      <FormContent />
    </Form>
  );
};

import { Params } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import {
  IExitInterviewAnswer,
  IMissingDay,
  IDismissal,
  TWorkHistoryFormData,
  TEducationFormData,
} from 'components/api/types/employee';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

import { TPersonalInformationData } from './profile/modals/personal';

export const actions = {
  personal: async (data: TPersonalInformationData, params: Params<string>) => {
    await requests.patchEmployeeData(params.employeeId as ID, data);

    await queryClient.invalidateQueries({ queryKey: ['employee'] });
  },
  missingDays: async (data: {
    employeeId: ID;
    missingDays: IMissingDay;
    missingDaysId: ID;
  }) => {
    data.missingDaysId
      ? await requests.patchMissingDaysData(
          data.employeeId,
          data.missingDaysId,
          data.missingDays
        )
      : await requests.postMissingDaysData(data.employeeId, data.missingDays);

    await queryClient.invalidateQueries({ queryKey: ['missingDays'] });
  },
  workHistory: async (data: {
    employeeId: ID;
    workHistory: TWorkHistoryFormData[];
    removedIds: ID[];
  }) => {
    const workHistoryRequests = data.workHistory
      ? data.workHistory.map(async (history: TWorkHistoryFormData) =>
          history.id
            ? await requests.patchWorkHistoryData(
                data.employeeId,
                history.id,
                history
              )
            : await requests.postWorkHistoryData(data.employeeId, history)
        )
      : [];

    const workHistoryRemoveRequests = data.removedIds?.length
      ? data.removedIds?.map((id: ID) =>
          requests.deleteWorkHistoryData(data.employeeId, id)
        )
      : [];

    await Promise.all([...workHistoryRequests, ...workHistoryRemoveRequests]);

    await queryClient.invalidateQueries({ queryKey: ['workHistory'] });
  },
  education: async (data: {
    employeeId: ID;
    education: TEducationFormData[];
    removedIds: ID[];
  }) => {
    const educationRequests = data.education
      ? data.education.map(async (education: TEducationFormData) =>
          education.id
            ? await requests.patchEducationData(
                data.employeeId,
                education.id,
                education
              )
            : await requests.postEducationData(data.employeeId, education)
        )
      : [];

    const educationRemoveRequests = data.removedIds?.length
      ? data.removedIds?.map((id: ID) =>
          requests.deleteEducationData(data.employeeId, id)
        )
      : [];

    await Promise.all([...educationRequests, ...educationRemoveRequests]);

    await queryClient.invalidateQueries({ queryKey: ['education'] });
  },
  exitInterview: async (data: {
    employeeId: ID;
    dismissalId: ID;
    dismissal: Omit<IDismissal, 'id'>;
    exitInterviewAnswers: IExitInterviewAnswer[];
  }) => {
    if (data.dismissalId) {
      await requests.patchDismissal(data.employeeId, data.dismissalId, {
        ...data.dismissal,
        exitInterviewAnswers: data.exitInterviewAnswers,
      });
    } else {
      await requests.postDismissal(data.employeeId, {
        ...data.dismissal,
        exitInterviewAnswers: data.exitInterviewAnswers,
      });
    }
    await queryClient.invalidateQueries({ queryKey: ['employee'] });
  },
};

import { forwardRef, MouseEvent, cloneElement, useState } from 'react';

import cn from 'clsx';
import { Button } from 'design/button';
import { createPortal } from 'react-dom';

import { Card } from '../card';
import { CloseLine } from '../icon';
import { Text } from '../text';

import { EConfirmOptions } from './types';
import { useModal } from './useModal';

import styles from './modal.module.scss';

export interface IModalOverride {
  formId?: string;
  modalClose?: () => void;
  setLoading?: (arg: boolean) => void;
  setConfirm?: (isConfirm: boolean, callback?: () => void) => void;
}

export const ModalContainer = forwardRef<HTMLDivElement>((_, ref) => {
  const { modalContent, modalClose } = useModal();
  const [isLoading, setLoading] = useState(false);
  const [showConfirmContent, setShowConfirmContent] = useState(false);
  const [isConfirm, setConfirm] = useState(false);

  const [confirmCallback, setConfirmCallback] = useState<(() => void) | null>(
    null
  );

  const handleModalClose = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();

    if (showConfirmContent) {
      setConfirm(true);
    } else {
      modalClose();
    }
  };

  const handleSetConfirm = (isConfirm: boolean, callback?: () => void) => {
    setShowConfirmContent(isConfirm);

    if (callback) {
      setConfirmCallback(() => callback);
    }
  };

  const handleConfirmReject = () => {
    setShowConfirmContent(false);
    setConfirm(false);
  };

  const handleSubmit = (evt: MouseEvent<HTMLButtonElement>) => {
    if (modalContent?.confirmType === EConfirmOptions.submit) {
      setConfirm(true);
    }

    if (modalContent?.onSubmit) {
      modalContent.onSubmit(evt);

      modalClose();
    }
  };

  const handleConfirmSubmit = () => {
    if (modalContent?.confirmType === EConfirmOptions.submit) {
      setConfirm(false);
      setShowConfirmContent(false);
      confirmCallback?.();
    } else {
      setShowConfirmContent(false);
      setConfirm(false);
      modalClose();
    }
  };

  if (modalContent) {
    const element = modalContent.containerId
      ? document.getElementById(modalContent.containerId)
      : document.body;

    return createPortal(
      <div
        ref={ref}
        className={cn(styles.modalContainer, modalContent.containerClassName)}
        id={`${modalContent.id}`}
      >
        <Card
          className={cn(styles.modal, modalContent.className)}
          headerClassName={styles.modalHeader}
          bodyClassName={styles.modalBody}
          title={
            <div className={styles.closeModalHeader}>
              {modalContent.title && (
                <Text size="text-20" weight={500}>
                  {modalContent.title}
                </Text>
              )}
              {modalContent.subTitle && (
                <Text size="text-14" className={styles.subTitle} weight={400}>
                  {modalContent.subTitle}
                </Text>
              )}
              <div onClick={handleModalClose} className={styles.closeModal}>
                <CloseLine />
              </div>
            </div>
          }
          footer={
            <div className={styles.cardFooter}>
              <Button
                onClick={handleModalClose}
                label={modalContent.cancel ? modalContent.cancel : 'Отмена'}
                buttonType="secondary"
              />
              <Button
                onClick={handleSubmit}
                form={modalContent.formId}
                label={modalContent.submit ? modalContent.submit : 'Сохранить'}
                type={modalContent?.onSubmit ? 'button' : 'submit'}
                loading={isLoading}
              />
            </div>
          }
        >
          {cloneElement(modalContent.content, {
            modalClose,
            setLoading,
            setConfirm: handleSetConfirm,
            formId: modalContent.formId,
          })}
        </Card>
        <div onClick={handleModalClose} className={styles.overlay} />
        {isConfirm && showConfirmContent && (
          <div className={styles.confirm}>
            <div className={styles.confirm__container}>
              {modalContent.confirmContent ? (
                modalContent.confirmContent
              ) : (
                <Text size="text-14" weight={500} as="span">
                  Вы уверены, что хотите закрыть без сохранения?
                </Text>
              )}
              <div className={styles.confirm__buttons}>
                <Button onClick={handleConfirmSubmit} label="Да" size="small" />
                <Button
                  onClick={handleConfirmReject}
                  label="Нет"
                  size="small"
                  buttonType="secondary"
                />
              </div>
            </div>
          </div>
        )}
      </div>,
      element as HTMLElement
    );
  }

  return null;
});

import { ID } from 'components/api/types/common';
import { INonWorkingDay } from 'components/api/types/dictionary-types';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  nonWorkingDays: async (data: {
    removedId: ID | null;
    nonWorkingDayId: ID | null;
    nonWorkingDay: INonWorkingDay;
  }) => {
    data.removedId && (await requests.deleteNonWorkingDaysData(data.removedId));

    data.nonWorkingDay &&
      !data.nonWorkingDayId &&
      (await requests.postNonWorkingDaysData(data.nonWorkingDay));

    await queryClient.invalidateQueries({
      queryKey: ['nonWorkingDays'],
    });
  },
};

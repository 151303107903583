import { FC, useState } from 'react';

import { ID } from 'components/api/types/common';
import { ILegalEntity } from 'components/api/types/dictionary-types';
import { DeleteBinLine, Edit2Fill } from 'design/icon';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { LegalEntityForm } from '../forms/legal-entity';

import styles from '../finance-dictionary.module.scss';

interface ILegalEntityListProps {
  legalEntities: ILegalEntity[];
}

export const LegalEntityList: FC<ILegalEntityListProps> = ({
  legalEntities,
}) => {
  const [legalEntityId, setLegalEntityId] = useState<ID>();
  const { submit } = useSubmit();

  const handleDelete = (id: ID) => {
    submit({
      entity: 'legalEntity',
      data: {
        removedId: id,
      },
    });
  };

  const handleEdit = (id: ID) => {
    setLegalEntityId(id);
  };

  if (!legalEntities.length) {
    return (
      <div className={styles.no_data}>
        <Text size="text-16" weight={500}>
          Нет данных
        </Text>
      </div>
    );
  }

  return (
    <ul className={styles.list}>
      {legalEntities.map(({ id, name, fullName, country }) => (
        <li key={id}>
          <div className={styles.list__container}>
            {legalEntityId !== id ? (
              <>
                <div className={styles.list__container__item}>
                  <Text size="text-16">
                    {`${name} (полное наименование: ${fullName}, 
                    страна: ${country})`}
                  </Text>
                </div>
                <div className={styles.list__container__actions}>
                  <Edit2Fill onClick={() => handleEdit(id)} />
                  <DeleteBinLine onClick={() => handleDelete(id)} />
                </div>
              </>
            ) : (
              <LegalEntityForm
                id={id}
                name={name}
                fullName={fullName}
                country={country}
                setLegalEntityId={setLegalEntityId}
              />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

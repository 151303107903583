import { useLoaderData } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { ISalaryPaymentDayConfig } from 'components/api/types/salary';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { dateSorting, textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { formatDate } from 'utils';
import { useSubmit } from 'utils/useSubmit';

import { paymentFunctionDictionary } from './constant';
import { ILoaderData } from './loaders';
import { SalaryPaymentDaysModal } from './modal';

import styles from './salary-payment-days.module.scss';

const openSalaryPaymentDays = () => {
  modal.open({
    className: styles.modal,
    id: 'create-salary-payment-days-modal',
    title: 'Добавление данных',
    formId: 'create-salary-payment-days-form',
    content: <SalaryPaymentDaysModal />,
  });
};

const openEditSalaryPaymentDays = (initialValue: ISalaryPaymentDayConfig) => {
  modal.open({
    className: styles.modal,
    id: 'edit-salary-payment-days-modal',
    title: 'Редактирование данных',
    formId: 'edit-salary-payment-days-form',
    content: <SalaryPaymentDaysModal initialValue={initialValue} />,
  });
};

export const SalaryPaymentDaysPage = () => {
  const { submit } = useSubmit();

  const { salaryPaymentDays } = useLoaderData() as ILoaderData;

  const handleDelete = (id: ID) => {
    submit({
      entity: 'salaryPaymentDays',
      data: {
        removedId: id,
      },
    });
  };

  const columns: TTableColumn<ISalaryPaymentDayConfig>[] = [
    {
      title: 'Тип',
      dataIndex: ['paymentFunction'],
      sortable: true,
      sorting: textSorting,
      render: ({ paymentFunction }) => (
        <Text size="text-14" as="span">
          {paymentFunction
            ? paymentFunctionDictionary[paymentFunction.name]
            : ''}
        </Text>
      ),
    },
    {
      title: 'Дата выплаты',
      dataIndex: ['paymentDate'],
      sortable: true,
      sorting: dateSorting,
      render: ({ paymentDate }) => (
        <Text size="text-14" weight={400} as="span">
          {formatDate(paymentDate)}
        </Text>
      ),
    },
    {
      title: 'Период',
      dataIndex: ['periodStartDate'],
      render: (data) => (
        <Text size="text-14" as="span">
          {`${formatDate(data.periodStartDate)} 
          - 
          ${formatDate(data.periodEndDate)}`}
        </Text>
      ),
    },
    {
      title: 'Юр. Лицо',
      dataIndex: ['legalEntity'],
      render: ({ legalEntity }) => (
        <Text size="text-14" as="span">
          {legalEntity ? legalEntity.name : ''}
        </Text>
      ),
    },
    {
      title: 'Действие',
      actions: {
        onEdit: openEditSalaryPaymentDays,
        onDelete: handleDelete,
      },
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container__header}>
        <Text
          size="text-20"
          weight={500}
          className={styles.container__header__title}
        >
          Конфигурация дней выплат
        </Text>
      </div>
      <div className={styles.container__table}>
        <div className={styles.container__table__header}>
          <Button
            label="Добавить"
            onClick={openSalaryPaymentDays}
            prefixIcon={<AddBoxLine />}
          />
        </div>
        <ContentContainer>
          <Table columns={columns} data={salaryPaymentDays} key="id" />
        </ContentContainer>
      </div>
    </div>
  );
};

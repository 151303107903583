import type { MouseEvent, ReactElement, ReactNode } from 'react';

export interface IModalContainerProps {
  elementContainerId?: string;
}

export enum EConfirmOptions {
  close = 'close',
  submit = 'submit',
}

interface IModalHeaderProps {
  title?: string;
  isCloseButtonShown?: boolean;
  onClose?: () => void;
}

interface IModalFotterActionButton {
  element?: ReactNode;
  onClick?: () => void;
}

interface IModalFooterProps {
  actionButtons?: IModalFotterActionButton[];
}

export interface IModalProps extends IModalHeaderProps, IModalFooterProps {
  isOpen: boolean;
  subtitle?: string;
  children?: ReactNode;
}

export interface IModalContent {
  id: Id;
  containerId?: string;
  formId?: string;
  title?: string;
  subTitle?: string;
  onSubmit?: (evt: MouseEvent<HTMLButtonElement>) => void;
  isCloseButtonShown?: boolean;
  onClose?: () => void;
  content: ReactElement;
  submit?: string;
  cancel?: string;
  confirm?: boolean;
  confirmType?: EConfirmOptions;
  confirmContent?: ReactElement;
  containerClassName?: string;
  className?: string;
}

export type Id = number | string;

import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import cn from 'clsx';
import { Link } from 'design/link';
import { Text } from 'design/text';
import { ISideMenuItem } from 'utils/types';

import styles from './list-item.module.scss';

type TListItem = Omit<ISideMenuItem, 'permissions'> & {
  filter?: string;
  className?: string;
  onClick?: () => void;
};

export const ListItem: FC<TListItem> = ({
  href,
  text,
  icon,
  filter,
  className,
  onClick,
}) => {
  const location = useLocation();

  return (
    <div onClick={onClick}>
      <Link
        className={cn(styles.link, className, {
          [styles.link_active]: href.includes(location.pathname),
        })}
        href={filter ? `${href}?filter=${filter}` : href}
        icon={icon}
        placement="prefix"
      >
        <Text
          className={cn(styles.link__text, {
            [styles.link__text_active]: href.includes(location.pathname),
          })}
          size="text-16"
          as="span"
        >
          {text}
        </Text>
      </Link>
    </div>
  );
};

import { ID } from 'components/api/types/common';
import {
  ISelectedExpensesTransaction,
  TNewExpensesTransactions,
} from 'components/api/types/expenses-transactions';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  expensesTransactions: async (data: {
    removedId: ID | null;
    removedIds: ID[] | null;
    expensesTransactionId: ID | null;
    isForcedActivation: boolean;
    expensesTransaction: TNewExpensesTransactions;
    selectedExpensesTransaction: ISelectedExpensesTransaction;
  }) => {
    data.isForcedActivation &&
      (await requests.postRepeatedExpensesForcedActivation());

    data.removedId &&
      (await requests.deleteExpensesTransactionsById(data.removedId));

    data.removedIds &&
      (await requests.deleteExpensesTransactions({ ids: data.removedIds }));

    data.selectedExpensesTransaction &&
      (await requests.patchExpensesTransactions(
        data.selectedExpensesTransaction
      ));

    data.expensesTransactionId &&
      (await requests.patchExpensesTransactionsById(
        data.expensesTransactionId,
        data.expensesTransaction
      ));

    data.expensesTransaction &&
      !data.expensesTransactionId &&
      (await requests.postExpensesTransactions(data.expensesTransaction));

    await queryClient.invalidateQueries({
      queryKey: ['expensesTransactions'],
    });
  },
};

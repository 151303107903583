import { useLayoutEffect, useRef, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { IEmployeeWithMissingDay } from 'components/api/types/employee';
import { ArrowLeft, SearchLine } from 'design/icon';
import { Input } from 'design/input';
import { Text } from 'design/text';
import { debounce, formatDate } from 'utils';

import { ScheduleTable } from './schedule-table';

import styles from './missing-days.module.scss';

export const MissingDaysPage = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [year, setYear] = useState(Number(formatDate(undefined, 'yyyy')));
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') ?? ''
  );

  useLayoutEffect(() => {
    if (tableContainerRef.current) {
      const rect = tableContainerRef.current.getBoundingClientRect();
      setTableHeight(rect.height);
    }
  }, [tableContainerRef]);

  const { employees } = useLoaderData() as {
    employees: IEmployeeWithMissingDay[];
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    debounce(() =>
      setSearchParams((prev) => {
        if (e.target.value) {
          prev.set('search', e.target.value);
        } else {
          prev.delete('search');
        }

        return prev;
      })
    )();
  };

  return (
    <div className={styles.container}>
      <Text size="text-20" weight={500}>
        Дни отсутствия
      </Text>
      <div
        className={styles.container__table}
        style={{ height: `${tableHeight}px` }}
        ref={tableContainerRef}
      >
        <div className={styles.container__table__header}>
          <Input
            className={styles.container__table__header__search}
            placeholder="Поиск по ФИО"
            value={searchValue}
            onChange={handleSearch}
            prefixIcon={<SearchLine />}
          />
          <div className={styles.container__table__header__year}>
            <ArrowLeft onClick={() => setYear((prev) => prev - 1)} />
            <Text weight={500} as="span">
              {`${year}`}
            </Text>
            <ArrowLeft
              onClick={() => setYear((prev) => prev + 1)}
              className={styles.container__table__header__year_right}
            />
          </div>
        </div>
        <ScheduleTable year={year} employees={employees} />
      </div>
    </div>
  );
};

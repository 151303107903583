import { IBankCard } from 'components/api/types/bank-card';
import { ID } from 'components/api/types/common';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

export const actions = {
  bankCards: async (data: {
    bankCard: IBankCard;
    bankCardId: ID | null;
    removedId: ID | null;
  }) => {
    data.bankCardId &&
      (await requests.patchBankCardData(data.bankCardId, data.bankCard));

    data.bankCard &&
      !data.bankCardId &&
      (await requests.postBankCardData(data.bankCard));

    data.removedId && (await requests.deleteBankCardData(data.removedId));

    await queryClient.invalidateQueries({ queryKey: ['bankCards'] });
  },
};

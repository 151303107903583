import { useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { ITax } from 'components/api/types/salary';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { ButtonSwitch } from 'design/button-switch';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { numberSorting, textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { ConfigureTaxesModal } from './modal';

import styles from './configure-taxes.module.scss';

type TTaxType = 'default' | 'custom';

const openCreateTaxModal = () => {
  modal.open({
    id: 'create-tax-modal',
    title: 'Добавление данных',
    formId: 'create-tax-day',
    content: <ConfigureTaxesModal formId="create-tax-day" />,
  });
};

const openEditTaxModal = (initialValue: ITax) => {
  modal.open({
    id: 'edit-tax-modal',
    title: 'Редактирование данных',
    formId: 'edit-tax-day',
    content: (
      <ConfigureTaxesModal formId="edit-tax-day" initialValue={initialValue} />
    ),
  });
};

export const ConfigureTaxesPage = () => {
  const { submit } = useSubmit();

  const [searchParams, setSearchParams] = useSearchParams();

  const [taxType, setTaxType] = useState<TTaxType>(
    (searchParams.get('filter') as TTaxType | null) ?? 'default'
  );

  useEffect(() => {
    const filter = searchParams.get('filter');

    if (!searchParams.get('filter')) {
      setSearchParams({ filter: 'default' });
    }

    setTaxType(filter as TTaxType);
  }, [searchParams, setSearchParams]);

  const { taxes } = useLoaderData() as {
    taxes: ITax[];
  };

  const handleDelete = (id: ID) => {
    submit({
      entity: 'taxes',
      data: {
        removedId: id,
      },
    });
  };

  const defaultColumns: TTableColumn<ITax>[] = [
    {
      title: 'Название',
      dataIndex: ['name'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Юр Лицо',
      dataIndex: ['legalEntity'],
      render: ({ legalEntity }) => (
        <Text size="text-14" as="span">
          {legalEntity ? legalEntity.name : ''}
        </Text>
      ),
    },
    {
      title: 'Налог НДФЛ',
      dataIndex: ['taxNdfl'],
      sortable: true,
      sorting: numberSorting,
    },
    {
      title: 'Налог Взносы',
      dataIndex: ['taxInsurance'],
      sortable: true,
      sorting: numberSorting,
    },
    {
      title: 'Действие',
      actions: {
        onDelete: handleDelete,
        onEdit: openEditTaxModal,
      },
    },
  ];

  const customColumns: TTableColumn<ITax>[] = [
    {
      title: 'Название',
      dataIndex: ['name'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Налог НДФЛ',
      dataIndex: ['taxNdfl'],
      sortable: true,
      sorting: numberSorting,
    },
    {
      title: 'Налог Взносы',
      dataIndex: ['taxInsurance'],
      sortable: true,
      sorting: numberSorting,
    },
    {
      title: 'Действие',
      actions: {
        onDelete: handleDelete,
        onEdit: openEditTaxModal,
      },
    },
  ];

  //ToDo: code refactoring
  const filteredTaxes = taxes.filter((tax) =>
    taxType === 'default' ? tax.legalEntityId : !tax.legalEntityId
  );

  return (
    <div className={styles.taxes}>
      <div className={styles.header}>
        <Text size="text-20" weight={500} className={styles.heading}>
          Конфигурация - Налоги ЗП
        </Text>
      </div>
      <div className={styles.body}>
        <div className={styles.tableHeader}>
          {taxType === 'default' && (
            <Text size="text-18" as="span" weight={500}>
              Дефолтные налоги
            </Text>
          )}
          {taxType === 'custom' && (
            <Text size="text-18" as="span" weight={500}>
              Кастомные налоги
            </Text>
          )}
          <div className={styles.tableButton}>
            <ButtonSwitch
              options={[
                { value: 'default', label: 'Дефолтные' },
                { value: 'custom', label: 'Кастомные' },
              ]}
              onChange={(option) => {
                setSearchParams({ filter: `${option.value}` });
              }}
              active={taxType}
            />
            <Button
              label="Добавить"
              onClick={openCreateTaxModal}
              prefixIcon={<AddBoxLine />}
            />
          </div>
        </div>
        <ContentContainer>
          <Table
            columns={taxType === 'default' ? defaultColumns : customColumns}
            data={filteredTaxes ?? []}
            key="id"
          />
        </ContentContainer>
      </div>
    </div>
  );
};

import { FC, ReactNode } from 'react';

import cn from 'clsx';

import styles from './card.module.scss';

export interface ICard {
  title?: ReactNode;
  footer?: ReactNode;
  className?: string;
  bodyClassName?: string;
  headerClassName?: string;
  footerClassName?: string;
  children: ReactNode;
}

export const Card: FC<ICard> = ({
  children,
  title: Title,
  footer: Footer,
  className,
  bodyClassName,
  headerClassName,
  footerClassName,
}) => (
  <div className={cn(styles.card, className)}>
    {!!Title && (
      <div className={cn(styles.header, headerClassName)}>{Title}</div>
    )}
    <div className={cn(styles.body, bodyClassName)}>{children}</div>
    {!!Footer && (
      <div className={cn(styles.footer, footerClassName)}>{Footer}</div>
    )}
  </div>
);

import { ID } from 'components/api/types/common';
import { INewExpensesCategories } from 'components/api/types/expenses-categories';
import { queryClient } from 'components/context/query';

import { requests } from '../../api';

interface IExpensesCategoryData {
  expensesCategory: INewExpensesCategories;
  expensesCategoryId: ID | null;
  removedId: ID | null;
}

export const actions = {
  expensesCategories: async (data: IExpensesCategoryData) => {
    data.removedId && (await requests.deleteExpensesCategory(data.removedId));

    data.expensesCategoryId &&
      (await requests.patchExpensesCategoryById(
        data.expensesCategoryId,
        data.expensesCategory
      ));

    data.expensesCategory &&
      !data.expensesCategoryId &&
      (await requests.postExpensesCategory(data.expensesCategory));

    await queryClient.invalidateQueries({
      queryKey: ['expensesCategories'],
    });
  },
};

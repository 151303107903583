import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { IOffice } from '../types/dictionary-types';
import { EModule } from '../types/enums';

export const getOffices =
  (module: EModule) => (): Promise<AxiosResponse<IOffice[]>> =>
    $host.get(`/${module}/dictionary/offices`);

export const patchOfficeData =
  (module: EModule) =>
  (id: ID, values: Partial<IOffice>): Promise<AxiosResponse<IOffice>> =>
    $host.patch(`/${module}/dictionary/offices/${id}`, { ...values });

export const postOfficeData =
  (module: EModule) =>
  (values: Omit<IOffice, 'id'>): Promise<AxiosResponse<IOffice>> =>
    $host.post(`/${module}/dictionary/offices`, { ...values });

export const deleteOfficeData =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<IOffice>> =>
    $host.delete(`/${module}/dictionary/offices/${id}`);

import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import {
  IMissingDayInfo,
  IEmployee,
  TMissingDay,
  IMissingDay,
} from 'components/api/types/employee';
import { EMissingDays } from 'components/api/types/enums';
import { Button } from 'design/button';
import { ButtonSwitch } from 'design/button-switch';
import { Attachment, Edit2Fill } from 'design/icon';
import { Link } from 'design/link';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { Text } from 'design/text';
import { formatDate } from 'utils';

import {
  calculateDifferenceDate,
  getWordDayForm,
  textSorting,
} from '../../../../utils';
import {
  MISSING_DAYS_OPTIONS,
  MISSING_DAYS_PARAMS,
} from '../../../../utils/constants';
import { IFormValue, MissingDayModal } from '../modal';

import styles from './missing-days-history.module.scss';

type TMissingDaysHistoryTableProps = Pick<IMissingDayInfo, 'missingDays'> &
  Pick<IEmployee, 'id'>;

const openCreateMissingDayModal = (
  employeeId: ID,
  missingDayType: TMissingDay
) => {
  modal.open({
    id: 'create-missing-day-modal',
    title: 'Добавление данных',
    formId: 'create-missing-day',
    content: (
      <MissingDayModal
        employeeId={employeeId}
        selectedMissingDayType={missingDayType}
        formId="create-missing-day"
      />
    ),
  });
};

const openEditMissingDayModal = (employeeId: ID, initialValue: IFormValue) => {
  modal.open({
    id: 'edit-missing-day-modal',
    title: 'Редактирование данных',
    formId: 'edit-missing-day',
    content: (
      <MissingDayModal
        formId="edit-missing-day"
        employeeId={employeeId}
        initialValue={initialValue}
      />
    ),
  });
};

const columns: TTableColumn<IMissingDay>[] = [
  {
    title: 'Статус',
    dataIndex: ['status'],
    sortable: true,
    sorting: textSorting,
  },
  {
    title: 'Период',
    dataIndex: ['startDate'],
    sortable: true,
    sorting: textSorting,
    render: (data) => {
      const differenceDays =
        calculateDifferenceDate(data.startDate, data.endDate, 'days') + 1;

      return (
        <div className={styles.date}>
          <Text size="text-14" as="span">
            {`${formatDate(data.startDate)} - ${formatDate(data.endDate)}`}
          </Text>
          <Text size="text-14" as="span">
            {`(${differenceDays} ${getWordDayForm(differenceDays)})`}
          </Text>
        </div>
      );
    },
  },
  {
    title: 'Статус подтверждения',
    dataIndex: ['approved'],
    render: (data) => (
      <Text size="text-14" as="span">
        {data.approved ? 'Подтвержден' : 'Не подтвержден'}
      </Text>
    ),
  },
  {
    title: 'Статус оплаты',
    dataIndex: ['isPayable'],
    render: (data) => (
      <Text size="text-14" as="span">
        {data.isPayable ? 'Оплачиваемый' : 'Неоплачиваемый'}
      </Text>
    ),
  },
  {
    title: 'Ссылка на документ',
    dataIndex: ['documents'],
    render: ({ documents }) =>
      documents?.length ? (
        <div className={styles.files}>
          {documents.map((file) => (
            <div key={file.id} className={styles.files__container}>
              <div className={styles.files__container__name}>
                <Attachment />
                <Text size="text-12" weight={500} as="span">
                  {file.key}
                </Text>
              </div>
              <Link
                href={file.url}
                target="_blank"
                className={styles.files__container__button}
              >
                <Text size="text-12" as="span">
                  Открыть файл
                </Text>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <Text size="text-14" as="span">
          Нет данных
        </Text>
      ),
  },
  {
    title: 'Действие',
    render: (data) => (
      <Button
        label="Редактировать"
        size="small"
        onClick={() => {
          const missingDay = {
            ...data,
            approved: `${data.approved}`,
            isPayable: `${data.isPayable}`,
          };

          return openEditMissingDayModal(data.employeeId, missingDay);
        }}
      />
    ),
  },
];

export const MissingDaysHistoryTable: FC<TMissingDaysHistoryTableProps> = ({
  missingDays,
  id,
}) => {
  const [missingDayType, setMissingDayType] = useState<TMissingDay>(
    EMissingDays.Отпуск
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get('type');

    setMissingDayType(
      MISSING_DAYS_PARAMS[
        type as keyof typeof MISSING_DAYS_PARAMS
      ] as EMissingDays
    );
  }, [searchParams]);

  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        {missingDayType === EMissingDays.Отпуск && (
          <Text size="text-18" as="span" weight={500}>
            История отпусков
          </Text>
        )}
        {missingDayType === EMissingDays.Больничный && (
          <Text size="text-18" as="span" weight={500}>
            История больничных
          </Text>
        )}
        {missingDayType === EMissingDays.Отгул && (
          <Text size="text-18" as="span" weight={500}>
            История отгулов
          </Text>
        )}
        <div className={styles.table__button}>
          <ButtonSwitch
            onChange={(option) => {
              setMissingDayType(`${option.value}` as TMissingDay);

              setSearchParams((prev) => {
                prev.set(
                  'type',
                  `${Object.keys(MISSING_DAYS_PARAMS).find(
                    (key) =>
                      MISSING_DAYS_PARAMS[
                        key as keyof typeof MISSING_DAYS_PARAMS
                      ] === option.value
                  )}`
                );

                return prev;
              });
            }}
            active={
              searchParams.get('type')
                ? MISSING_DAYS_PARAMS[
                    searchParams.get('type') as keyof typeof MISSING_DAYS_PARAMS
                  ]
                : ''
            }
            options={MISSING_DAYS_OPTIONS}
          />
          <Button
            label="Добавить"
            buttonType="secondary"
            onClick={() => openCreateMissingDayModal(id, missingDayType)}
            prefixIcon={<Edit2Fill />}
          />
        </div>
      </div>
      <Table columns={columns} data={missingDays ?? []} key="id" />
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom/client';

// ToDo: remove it. Query Client should be per microfrontend.
import { UserInfoProvider } from 'components/context/context-user';
import { QueryClientProvider } from 'components/context/query';
import { Toaster } from 'react-hot-toast';

import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routes';

import './reset.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider>
      <UserInfoProvider>
        <AppRoutes />
        <Toaster position="top-right" />
      </UserInfoProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

if (process.env.ENABLE_WEB_VITALS) {
  reportWebVitals(console.log);
}

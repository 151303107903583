import { FC } from 'react';

import { ID } from 'components/api/types/common';
import { IExpensesCategories } from 'components/api/types/expenses-categories';
import { Form } from 'design/form';
import { FormInput } from 'design/form/form-input';
import { IModalOverride } from 'design/modal';
import { useSubmit } from 'utils/useSubmit';

import { expensesCategorySchema } from '../../../../finance/utils/validation';

type TExpensesCategoryValue = Omit<IExpensesCategories, 'childCategories'>;

interface IExpensesCategoryModalProps extends IModalOverride {
  parentCategoryId?: ID;
  initialValue?: TExpensesCategoryValue;
}

export const ExpensesCategoryModal: FC<IExpensesCategoryModalProps> = ({
  formId,
  parentCategoryId,
  initialValue,
  setLoading,
  setConfirm,
  modalClose,
}) => {
  const { submit } = useSubmit(modalClose, setLoading);

  const onSubmit = (data: TExpensesCategoryValue) => {
    submit({
      entity: 'expensesCategories',
      data: {
        expensesCategory: {
          ...data,
          parentCategoryId,
        },
        expensesCategoryId: initialValue ? data.id : null,
      },
    });
  };

  return (
    <Form
      id={formId}
      initialValues={initialValue ?? {}}
      validation={expensesCategorySchema}
      onSubmit={onSubmit}
      onDirty={(isDirty) => setConfirm?.(isDirty)}
    >
      <FormInput required name="name" label="Название категории" />
    </Form>
  );
};

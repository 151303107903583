import { FC, ChangeEvent, FocusEvent } from 'react';

import cn from 'clsx';

import { Text } from '../text';

import styles from './textarea.module.scss';

export type TTextareaProps = {
  rows?: number;
  name?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  textareaClassName?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  error?: string;
  required?: boolean;
  value?: string;
  disabled?: boolean;
};

export const Textarea: FC<TTextareaProps> = ({
  label,
  placeholder,
  className,
  textareaClassName,
  name,
  onChange,
  onFocus,
  onBlur,
  error,
  required,
  value,
  disabled,
  rows = 4,
  ...rest
}) => {
  const classNames = cn(textareaClassName, styles.textarea, {
    [styles.error]: !!error,
  });

  return (
    <div className={cn(className, styles.container)}>
      <textarea
        id={name ?? ''}
        className={classNames}
        placeholder={placeholder ?? label}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        value={value}
        rows={rows}
        disabled={disabled}
        {...rest}
      />
      {!!label && (
        <label
          htmlFor={name}
          className={cn(styles.label, {
            [styles.error]: !!error,
          })}
        >
          <Text
            size="text-14"
            weight={500}
            as="span"
            className={styles.placeholder}
          >
            {label}
          </Text>
          {required && (
            <Text
              size="text-14"
              weight={500}
              as="span"
              className={styles.required}
            >
              *
            </Text>
          )}
        </label>
      )}
      {!!error && (
        <Text as="span" size="text-12" className={styles.errorMessage}>
          {error}
        </Text>
      )}
    </div>
  );
};

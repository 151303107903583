import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getBansheeCallback, getBansheeUserInfo } from 'components/api/banshee';
import { useUserContext } from 'components/context/context-user';

export const PageCallback: FC = () => {
  const [searchParams] = useSearchParams();
  const { setUser } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    const getBansheeCallbackRequest = async (
      code: string,
      state: string | null
    ) => {
      try {
        await getBansheeCallback(
          code,
          state,
          `${window.location.origin}/callback`
        );

        const { data } = await getBansheeUserInfo();

        setUser(data);

        localStorage.setItem('loggedIn', 'true');

        if (data) {
          navigate('/');
        }
      } catch (error) {
        navigate('/auth');

        console.log('Failed get banshee callback:>> ', error);
      }
    };

    if (code) {
      getBansheeCallbackRequest(code, state);
    } else {
      navigate('/auth');
    }
  }, [navigate, searchParams, setUser]);

  return <div />;
};

import { IExpensesCategories } from 'components/api/types/expenses-categories';

import { requests } from '../../api';

export interface ILoaderData {
  expensesCategories: IExpensesCategories[];
}

export const loaders = () => [
  {
    name: 'expensesCategories',
    fn: () => requests.getExpensesCategories(false),
  },
];

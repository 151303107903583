import { useLoaderData } from 'react-router-dom';

import { ID } from 'components/api/types/common';
import { IRepeatedExpenses } from 'components/api/types/repeated-expenses';
import { ContentContainer } from 'components/content-container';
import { Button } from 'design/button';
import { AddBoxLine } from 'design/icon';
import { modal } from 'design/modal/core';
import { Table } from 'design/table';
import { TTableColumn } from 'design/table/column';
import { textSorting } from 'design/table/utils';
import { Text } from 'design/text';
import { useSubmit } from 'utils/useSubmit';

import { RepeatedExpensesModal } from './modal';

import styles from './repeated-expenses.module.scss';

const openAddRepeatedExpenses = () => {
  modal.open({
    id: 'add-repeated-expenses-modal',
    title: 'Добавление данных',
    formId: 'add-repeated-expenses-form',
    content: <RepeatedExpensesModal />,
  });
};

const openEditRepeatedExpenses = (initialValue: IRepeatedExpenses) => {
  modal.open({
    id: 'edit-repeated-expenses-modal',
    title: 'Редактирование данных',
    formId: 'edit-repeated-expenses-form',
    content: <RepeatedExpensesModal initialValue={initialValue} />,
  });
};

export const RepeatedExpensesPage = () => {
  const { submit } = useSubmit();

  const { repeatedExpenses } = useLoaderData() as {
    repeatedExpenses: IRepeatedExpenses[];
  };

  const handleDelete = (id: ID) => {
    submit({
      entity: 'repeatedExpenses',
      data: {
        removedId: id,
      },
    });
  };

  const columns: TTableColumn<IRepeatedExpenses>[] = [
    {
      title: 'Категория',
      dataIndex: ['expensesCategory'],
      sortable: true,
      sorting: textSorting,
      render: (data) => (
        <Text size="text-14" weight={400}>
          {data.expensesCategory.name}
        </Text>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: ['name'],
      sortable: true,
      sorting: textSorting,
      minWidth: '180px',
    },
    {
      title: 'Сумма',
      dataIndex: ['amount'],
      sortable: true,
      sorting: textSorting,
    },
    {
      title: 'Банк аккаунт',
      dataIndex: ['bankAccount'],
      sortable: true,
      sorting: textSorting,
      render: ({ bankAccount }) => (
        <Text size="text-14" weight={400}>
          {bankAccount.bankName}
        </Text>
      ),
      minWidth: '180px',
    },
    {
      title: 'Комментарий',
      dataIndex: ['comment'],
      sortable: true,
      sorting: textSorting,
      render: ({ comment }) => (
        <Text size="text-14" weight={400}>
          {comment ?? ''}
        </Text>
      ),
      minWidth: '200px',
    },
    {
      title: 'Дата выплаты',
      dataIndex: ['repeatAt'],
    },
    {
      title: 'Действие',
      actions: {
        onDelete: handleDelete,
        onEdit: openEditRepeatedExpenses,
      },
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container__header}>
        <Text
          size="text-20"
          weight={500}
          className={styles.container__header__title}
        >
          Повторяющиеся траты
        </Text>
      </div>
      <div className={styles.container__table}>
        <div className={styles.container__table__header}>
          <Button
            label="Добавить"
            onClick={openAddRepeatedExpenses}
            prefixIcon={<AddBoxLine />}
          />
        </div>
        <ContentContainer>
          <Table columns={columns} data={repeatedExpenses} key="id" />
        </ContentContainer>
      </div>
    </div>
  );
};

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { TMissingDay, IMissingDayInfo, IMissingDay } from '../types/employee';
import { EModule } from '../types/enums';

export const getMissingDaysDataById =
  (module: EModule) =>
  (
    employeeId: ID,
    type?: TMissingDay
  ): Promise<AxiosResponse<IMissingDayInfo>> =>
    $host.get(`/${module}/employees/${employeeId}/missing-days`, {
      params: { employeeId, type },
    });

export const patchMissingDaysData =
  (module: EModule) =>
  (
    employeeId: ID,
    id: ID,
    values: Omit<IMissingDay, 'id'>
  ): Promise<AxiosResponse<IMissingDay>> =>
    $host.patch(`/${module}/employees/${employeeId}/missing-days/${id}`, {
      ...values,
    });

export const postMissingDaysData =
  (module: EModule) =>
  (
    employeeId: ID,
    values: Omit<IMissingDay, 'id'>
  ): Promise<AxiosResponse<IMissingDay>> =>
    $host.post(`/${module}/employees/${employeeId}/missing-days`, {
      ...values,
    });

import { AxiosResponse } from 'axios';

import { $host } from '../api';
import { ID } from '../types/common';
import { EModule, EPaymentStatus } from '../types/enums';
import { ISalaryTransaction, ITransactionPaid } from '../types/transaction';

export const getSalaryTransactions =
  (module: EModule) =>
  (status?: EPaymentStatus): Promise<AxiosResponse<ISalaryTransaction[]>> =>
    $host.get(`/${module}/salary-transactions`, { params: { status } });

export const getSalaryTransactionById =
  (module: EModule) =>
  (employeeId: ID): Promise<AxiosResponse<ISalaryTransaction[]>> =>
    $host.get(`/${module}/salary-transactions`, {
      params: { employeeId },
    });

export const postSalaryTransaction =
  (module: EModule) =>
  (
    values: Omit<
      ISalaryTransaction,
      'employee' | 'currency' | 'id' | 'bankAccount'
    >
  ): Promise<AxiosResponse<ISalaryTransaction>> =>
    $host.post(`/${module}/salary-transactions`, { ...values });

export const patchSalaryTransactions =
  (module: EModule) =>
  (values: ITransactionPaid): Promise<AxiosResponse<ISalaryTransaction>> =>
    $host.patch(`/${module}/salary-transactions`, {
      ...values,
      status: EPaymentStatus.Оплачен,
    });

export const patchSalaryTransactionById =
  (module: EModule) =>
  (
    id: ID,
    values: Omit<
      ISalaryTransaction,
      'employee' | 'currency' | 'id' | 'bankAccount'
    >
  ): Promise<AxiosResponse<ISalaryTransaction>> =>
    $host.patch(`/${module}/salary-transactions/${id}`, { ...values });

export const deleteSalaryTransaction =
  (module: EModule) =>
  (id: ID): Promise<AxiosResponse<string>> =>
    $host.delete(`/${module}/salary-transactions/${id}`);

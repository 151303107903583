import { FC, ReactNode } from 'react';

import styles from './popover.module.scss';

interface IPopoverProps {
  top: number;
  children: ReactNode;
}

export const Popover: FC<IPopoverProps> = ({ children, top }) => (
  <div
    className={styles.popover}
    style={{
      top: top,
    }}
  >
    <div className={styles.popover__children}>{children}</div>
  </div>
);
